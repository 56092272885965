/**
 *
 * CropImage
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ProgressBar } from 'react-bootstrap';
import PosAbsoluteFull from 'components/layout/PosAbsoluteFull/index.js';
import CoverImage from 'components/media/CoverImage/index.js';
import messages from './messages.js';
import useSelectImage from '../AddFiles/AddImage/useSelectImage.jsx';
import ImageRemoveButton from './ImageRemoveButton.jsx';

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.contentBg};
  position: relative;
  height: ${(p) => p.size === 'sm' ? '70px' : '150px'};
  width: ${(p) => p.size === 'sm' ? '70px' : '150px'};
  border-radius: ${(p) => p.group ? '20%' : '50%'};
  overflow: hidden;
  margin: ${(p) => p.center ? 'auto' : 'inherit'};
`;

const SelectArea = styled(PosAbsoluteFull)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  i {
    margin-right: 0;
    font-size: ${(p) => p.size === 'sm' ? '48px' : '72px'};
    transform: unset;
  };
`;

const SelectBottom = styled(SelectArea)`
  align-items: flex-end;
  .btn {
    padding-bottom: 0;
  }
  i {
    font-size: ${(p) => p.size === 'sm' ? '24px' : '36px'};
    color: white;
    text-shadow: 0 0 4px #000;
  }
`;

function CropImage(props) {
  const { group, value, onChange, size } = props;
  const crop = group ? 'group' : 'user';
  const [preview, setPreview] = useState();
  // const handleChange = useCallback((data) => onChange({ data, preview: preview.current }), [preview]);
  const { selectFiles, progress } = useSelectImage({ crop, onChange, setPreview });

  function handleRemove() {
    onChange(null);
  }

  const src = preview || value;
  const SelectWrapper = src ? SelectBottom : SelectArea;
  return (
    <div className="position-relative">
      <Wrapper group={group} size={size}>
        {src && <CoverImage src={src} />}
        <SelectWrapper size={size}>
          {selectFiles()}
        </SelectWrapper>
      </Wrapper>
      {src && (
        <ImageRemoveButton
          icon="ss-trash fs-3"
          onClick={handleRemove}
          confirmText={messages.confirmRemove}
        />
      )}
      {progress !== undefined && <ProgressBar now={progress} label={`${progress}%`} />}
    </div>
  );
}

CropImage.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  group: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
};

export default CropImage;
