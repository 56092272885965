// Similar to lodash.set, but return new object instead of mutated input object
import produce from 'immer';

function setIn(obj, pathString, value) {
  if (!obj) {
    return obj;
  }
  if (!pathString) {
    throw new Error('pathString is required');
  }
  if (typeof pathString !== 'string' && !Array.isArray(pathString)) {
    throw new Error('Invalid path');
  }
  const path = Array.isArray(pathString) ? pathString : pathString.split('.');
  if (path.length === 0) {
    return value;
  }
  if (path.length === 1) {
    if (value === undefined) {
      return produce(obj, (draft) => {
        delete draft[path[0]];
      });
    }
    return produce(obj, (draft) => {
      draft[path[0]] = value;
    });
  }
  return produce(obj, (draft) => {
    let result = draft;
    for (let i = 0; i < path.length - 1; i++) {
      const key = path[i];
      if (result[key] === undefined || result === null || typeof result !== 'object' || Array.isArray(result)) {
        result[key] = {};
      }
      result = result[key];
    }
    const lastKey = path[path.length - 1];
    if (value === undefined) {
      delete result[lastKey];
    } else {
      result[lastKey] = value;
    }
  });
}

export default setIn;
