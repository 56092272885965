export const PORTFOLIO_FEATURE_WORKSPACE = 1;
export const PORTFOLIO_FEATURE_GOALS = 2;
export const PORTFOLIO_FEATURE_SHOWCASE = 3;
export const PORTFOLIO_FEATURE_PROGRESS = 4;
export const PORTFOLIO_GROUP_ACTIVITY = 5;
export const PORTFOLIO_GROUP_GOALS = 6;
export const PORTFOLIO_GROUP_OVERVIEW = 7;
export const PORTFOLIO_GROUP_LIBRARY = 8;

export const PORTFOLIO_PILOT_FEATURES = [PORTFOLIO_FEATURE_WORKSPACE];

export const PORTFOLIO_GOALTYPE_INDIVIDUAL = 1;
export const PORTFOLIO_GOALTYPE_GROUP = 2;
export const PORTFOLIO_GOALTYPE_LIBRARY = 3;
