/**
 * @fileoverview InboxStatus component
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Badge, Button } from 'react-bootstrap';
import messages from './messages.js';

export const INBOX_INACTIVE = 10;
export const INBOX_ACTIVE = 20;
export const INBOX_ARCHIVED = 30;

function InboxStatus(props) {
  const { value, onChange } = props;
  const handleChange = () => onChange(value === INBOX_ACTIVE ? INBOX_ARCHIVED : INBOX_ACTIVE);
  switch (value) {
    case INBOX_INACTIVE:
      return <Button variant="primary" onClick={handleChange}><FormattedMessage {...messages.activate} /></Button>;
    case INBOX_ACTIVE:
      return <Button variant="light text-danger" onClick={handleChange}><FormattedMessage {...messages.doArchive} /></Button>;
    case INBOX_ARCHIVED:
      return (
        <div className="d-flex flex-column align-items-center">
          <Badge bg="light" text="dark" className="mb-3"><FormattedMessage {...messages.archived} /></Badge>
          <Button variant="light" onClick={handleChange}><FormattedMessage {...messages.reactivate} /></Button>
        </div>
      );
    default:
      return null;
  }
}

InboxStatus.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default InboxStatus;
