/**
*
* SettingsToggle
*
*/

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import Icon from 'components/media/Icon/index.js';
import useStorage from 'utils/storage/useStorage.js';

function SettingsToggle(props) {
  const { name, onChange } = props;
  const [enabled, setEnabled] = useStorage(name, false);
  const handleClick = useCallback(() => {
    setEnabled(!enabled);
    onChange(!enabled);
  }, [enabled, onChange]);
  return (
    <Dropdown.Item {...props} onClick={handleClick}>
      <div className="d-flex">
        <span className="flex-grow-1 me-3">{`${name}:`}</span>
        <Icon className={enabled ? 'ss-check' : 'ss-check-empty'} />
      </div>
    </Dropdown.Item>
  );
}

SettingsToggle.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SettingsToggle;
