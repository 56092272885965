/**
 * useRestApi
 *
 * @param {string|array} key - key of the query
 * @param {object} fetchOptions - fetch options
 * @param {number} os - os
 * @returns {object} query object
 */

import { useMemo } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { get } from 'lodash-es';
import useAuthorizedRequest from 'containers/Api2/useAuthorizedRequest.js';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import setIn from 'utils/setIn.js';
import defaultReducer from './defaultReducer.js';

function useRestApi(fetchOptions, queryOptions = {}) {
  const role = useCurrentRole();
  const key = [fetchOptions.url, role?.uniqueId];
  const { authFetch } = useAuthorizedRequest();

  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: key,
    queryFn: async () => authFetch(fetchOptions),
    retry: false, // Handled by authFetch in case of failed to fetch
    ...queryOptions,
  });

  const mutation = useMutation({
    mutationFn: authFetch,
    onSuccess: (result, options) => {
      const pathOffset = (options.baseUrl ?? fetchOptions.url).split('/').length;
      const { refetch, reducer = defaultReducer(options, pathOffset) } = options;
      if (refetch) {
        query.refetch();
      } else {
        queryClient.setQueryData(key, (data) => reducer(data, result));
      }
    },
  });

  const result = useMemo(() => {
    const res = {
      ...query,
      onUpdate: mutation.mutateAsync,
      getIn: (path, defaultValue) => get(query.data, path) ?? defaultValue,
      setIn: (path, value) => {
        queryClient.setQueryData(key, (data) => setIn(data, path, value));
      },
    };
    return res;
  }, [query, fetchOptions.url, role?.uniqueId]); // eslint-disable-line react-hooks/exhaustive-deps

  return result;
}

export default useRestApi;
