import replaceParams from 'utils/replaceParams.js';

export function withUrl(options) {
  if (typeof options === 'string') {
    throw new Error('No string options');
  }
  return typeof options === 'string' ? { url: options } : options;
}

export function fetchable(options) {
  return Boolean(options.url);
}

export function exceptions(options) {
  if (options.url && options.url.startsWith('portfolio/workspaces')) {
    return {
      ...options,
      url: options.url.replace('portfolio/workspaces', 'portfolio'),
    };
  }
  if (options.url && options.url.startsWith('useraccounts/contactdetails/')) {
    return {
      ...options,
      url: options.url.replace('useraccounts/contactdetails/', 'useraccounts/'),
    };
  }
  return options;
}

export function withDefaults(options) {
  const { url } = options;
  const selector = options.selector || noQuery(url);
  return {
    method: 'GET',
    name: extractName(selector),
    ...options,
    selector,
  };
}

export function extractName(selector) {
  const parts = selector.split('/');
  if (parts.length === 1) {
    return parts[0];
  }
  parts.reverse();
  if (parts[0].startsWith(':') || /^\d+$/.test(parts[0])) {
    return parts[1].replace(/s$/, '');
  }
  return parts[0];
}

export function noQuery(url) {
  return url.replace(/\?.*/, '');
}

export function substitute(params) {
  return (options) => {
    if (!params) {
      return options;
    }
    const { url, selector } = options;
    return {
      ...options,
      url: replaceParams(url, params),
      selector: replaceParams(selector, params),
    };
  };
}
