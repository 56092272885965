/*
 *
 * Dialog actions
 *
 */

import {
  DIALOG_SHOW,
  DIALOG_HIDE,
} from './constants.js';

export function showDialog(modalType, modalProps, promise) {
  return {
    type: DIALOG_SHOW,
    modalType,
    modalProps,
    promise,
  };
}

export function hideDialog(result, promise) {
  return {
    type: DIALOG_HIDE,
    result,
    promise,
  };
}
