import { get, omit } from 'lodash-es';
import setIn from 'utils/setIn.js';

export function getIn(path, obj) {
  return path?.length > 0 ? get(obj, path) : obj;
}

export function deleteItem(obj, path, id) {
  const base = getIn(path, obj);
  const newBase = { ...omit(base, [id]) };
  if (base.list) {
    newBase.list = base.list.filter((i) => i.toString() !== id.toString());
  }
  return setIn(obj, path, newBase);
}

export function addItem(obj, path, value, prepend = false) {
  const { id } = value;
  const base = getIn(path, obj);
  const newBase = { ...base, [id]: value };
  if (base.list) {
    newBase.list = prepend ? [id, ...base.list] : [...base.list, id];
  }
  return setIn(obj, path, newBase);
}
