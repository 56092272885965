/**
*
* NumberInput
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import IconButton from 'components/interaction/IconButton/index.jsx';

function NumberInput(props) {
  const { value, onChange, onStep, min, max, step, onClick, shaded } = props;

  function handleChange(diff) {
    return () => {
      if (onStep) {
        onStep(diff);
      }
      if (onChange) {
        onChange(value + diff);
      }
    };
  }

  const variant = shaded ? 'light' : 'outline-secondary';
  return (
    <div className="d-flex">
      <IconButton icon="ss-minus text-primary" variant={variant} size="sm" onClick={handleChange(-step)} disabled={min !== undefined && value <= min} />
      <Button style={{ minWidth: '3.5rem' }} className="btn-value mx-2" variant="dark" size="sm" onClick={onClick} disabled={!onClick}><span className="fs-5 lh-sm">{value}</span></Button>
      <IconButton icon="ss-plus text-primary" variant={variant} size="sm" onClick={handleChange(step)} disabled={max !== undefined && value >= max} />
    </div>
  );
}

NumberInput.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onStep: PropTypes.func,
  shaded: PropTypes.bool,
};

NumberInput.defaultProps = {
  step: 1,
};

export default NumberInput;
