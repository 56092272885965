/**
*
* CopyButton
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import useAlert from 'containers/Alerts/useAlert.js';
import Icon2 from 'components/media/Icon2/index.js';
import messages from './messages.js';

function CopyButton(props) {
  const { text, icon, children, ...rest } = props;
  const { addAlert } = useAlert();

  async function handleCopy() {
    await navigator.clipboard.writeText(text);
    addAlert(messages.copied, 'success');
  }

  if (!text) {
    return null;
  }
  return (
    <Button text={text} onClick={handleCopy} {...rest}>
      {icon && <Icon2 className="ss-copy fs-4 lower-1 pe-2" />}
      {children ?? <FormattedMessage {...messages.copy} />}
    </Button>
  );
}

CopyButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.bool,
  children: PropTypes.node,
};

export default CopyButton;
