import { createGlobalStyle } from 'styled-components';

const SupportWidgetStyle = createGlobalStyle`
  iframe#launcher {
    @media print {
      display: none;
    }
  }
`;

export default SupportWidgetStyle;
