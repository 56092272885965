/**
 *
 * AllGroupsSelect
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import Select from 'components/tags/Select/index.jsx';
import messages from '../../SideMenu/messages.js';
import LabeledSwitch from '../LabeledSwitch/index.jsx';

export const SELECT_MY_GROUPS = 1;
export const SELECT_ALL_GROUPS = 2;

const SelectSm = styled(Select)`
  &.form-control {
    width: 10em;
  }
`;

function AllGroupsSelect(props) {
  const { value, onChange, disabled, asSwitch, className } = props;
  const options = [
    { value: SELECT_MY_GROUPS, label: messages.myGroups },
    { value: SELECT_ALL_GROUPS, label: messages.allGroups },
  ];
  const handleChange = (ev) => onChange(Number(ev?.target ? ev.target.value : ev) === SELECT_ALL_GROUPS);
  const Component = asSwitch ? LabeledSwitch : SelectSm;
  const classList = classNames([asSwitch && 'bs-open', className]);
  return (
    <Component
      value={value ? SELECT_ALL_GROUPS : SELECT_MY_GROUPS}
      aria-label="Select all groups/my groups"
      className={classList}
      options={options}
      disabled={disabled}
      onChange={handleChange}
    />
  );
}

AllGroupsSelect.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  asSwitch: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default AllGroupsSelect;
export { default as isOwnGroup } from './isOwnGroup.js';
export { default as useAllGroupsState } from './useAllGroupsState.js';
