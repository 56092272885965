/*
 * ImageRestrictions Messages
 *
 * This contains all the text for the ImageRestrictions component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ImageRestrictions.header',
    defaultMessage: 'Image Use',
  },
  noConsent: {
    id: 'app.components.GroupImageUse.noConsent',
    defaultMessage: 'No consent',
  },
  unknown: {
    id: 'app.components.GroupImageUse.unknown',
    defaultMessage: 'Not Submitted',
  },
});
