export default function uniqueId() {
  return Math.random().toString(36).substr(2, 5) + timeString();
}

const c3 = 36 * 36 * 36;
export function timeString() {
  return (Math.trunc(getMinutes()) % c3).toString(36).padStart(3, '0');
}

export function codeToTime(str) {
  const int = Number.parseInt(str.slice(-3), 36);
  const now = getMinutes();
  const rest = now % c3;
  const org = (now - rest) + int + (rest < int ? c3 : 0);
  return new Date(org * 60000);
}

export function getMinutes() {
  return Date.now() / 60000;
}

/* Code for bookmarklet
var str = prompt('Code');
var c3 = 36 * 36 * 36;
var int = Number.parseInt(str.slice(-3), 36);
var now = Date.now() / 60000;
var rest = now % c3;
var org = (now - rest) + int + (rest < int ? c3 : 0);
alert((new Date(org * 60000)).toLocaleString().slice(0, -3));
*/
