import { uniqueId } from 'lodash-es';
import { makeIdObject } from 'utils/arrays.js';

export function tmpId() {
  return uniqueId('tmp_');
}

export function newTextfield() {
  return {
    id: tmpId(),
    title: '',
    type: 'textarea',
    answer: '',
  };
}

export function newChecklist() {
  return {
    id: tmpId(),
    title: '',
    type: 'checklist',
    items: makeIdObject([newItem()]),
  };
}

export function newItem() {
  return {
    id: tmpId(),
    title: '',
    checked: false,
  };
}

export function hasAnswer(field) {
  return field.type === 'checklist' ? field.items.some((i) => i.checked) : field.answer !== '';
}
