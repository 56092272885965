import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import backgroundImage from 'images/login-background.png';

const initialTheme = {
  panelBorderRadius: '16px',
  backgroundImage,
};

function useTheme() {
  return useContext(ThemeContext) || initialTheme;
}

export default useTheme;
