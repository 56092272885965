/*
 * SelectCommunities Messages
 *
 * This contains all the text for the SelectCommunities component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: {
    id: 'app.components.SelectCommunities.placeholder',
    defaultMessage: 'Select groups',
  },
});
