/**
*
* NotificationsList
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'react-bootstrap';

import Spinner from 'components/Spinner/index.jsx';
import { idValues } from 'utils/arrays.js';
import NotificationsItem from './NotificationsItem.jsx';
import messages from './messages.js';
import useNotificationsLists from './useNotificationsList.js';

function NotificationsList(props) {
  const { open, stats, onReset } = props;
  const { data, isLoading } = useNotificationsLists(stats, onReset, open);

  if (isLoading) {
    return <Spinner />;
  }
  const list = idValues(data);
  if (list.length === 0) {
    return <Dropdown.Item disabled className="no-notifications-alert"><FormattedMessage {...messages.noNotifications} /></Dropdown.Item>;
  }
  return list.map((item) => <NotificationsItem key={item.id} item={item} {...props} />);
}

NotificationsList.propTypes = {
  stats: PropTypes.object,
  onReset: PropTypes.func,
  open: PropTypes.bool,
};

export default NotificationsList;
