import config from 'config/index.js';
import { get } from 'lodash-es';
import useCurrentUser from 'containers/User/useCurrentUser.js';
import { MODULES_VIDEOCONFERENCING } from 'containers/User/constants.js';
import useStorage from 'utils/storage/useStorage.js';

function useVideoEnabled() {
  const [testEnabled, setEnabled] = useStorage('enableVideoConf', true);
  const user = useCurrentUser();
  const modules = get(user, 'role.school.modules');
  if (modules && modules.includes(MODULES_VIDEOCONFERENCING)) {
    return [true];
  }
  const email = get(user, 'profile.email');
  const userEnabled = email && email.includes('+video@socialschools.');
  if (userEnabled || config.env === 'production') {
    return [userEnabled];
  }
  return [testEnabled, setEnabled];
}

export default useVideoEnabled;
