/**
 *
 * MaterialLine
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DeleteButton from 'components/interaction/DeleteButton/index.jsx';
import Input from 'components/tags/Input/index.jsx';
import messages from './messages.js';

const Wrapper = styled.div`
  input[type="number"] {
    max-width: 8em;
    margin-left: 1em;
  }
`;

function MaterialLine(props) {
  const { name, totalCount, backers, onChange } = props;
  const hasBackers = backers && backers.length > 0;
  return (
    <Wrapper className="d-flex">
      <Input value={name} className="form-control" placeholder={messages.whoWhat} required maxLength="100" type="text" onChange={onChange('name')} />
      <Input value={totalCount} className="form-control" placeholder={messages.number} min="1" required step="1" type="number" onChange={onChange('totalCount')} />
      <DeleteButton
        aria-label="trash"
        onClick={onChange('delete')}
        confirmText={hasBackers ? messages.doDelete : undefined}
        confirmTitle={messages.hasBackers}
        confirmLabel={messages.remove}
        className="me-n3"
      />
    </Wrapper>
  );
}

MaterialLine.propTypes = {
  name: PropTypes.string,
  totalCount: PropTypes.number,
  backers: PropTypes.array,
  onChange: PropTypes.func,
};

export default MaterialLine;
