/**
*
* IconButton
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import Icon2 from 'components/media/Icon2/index.js';
import { useIntl } from 'react-intl';
import messages from './messages.js';

function IconButton(props) {
  const { icon, className, children, size, ...rest } = props;
  const intl = useIntl();

  function getAriaLabel() {
    if (children) {
      return undefined;
    }
    const match = icon.match(/ss-([a-z]+)/);
    if (!match) {
      return undefined;
    }
    const key = match[1];
    if (!messages[key]) {
      return undefined;
    }
    return intl.formatMessage(messages[key]);
  }

  const buttonClass = classNames(['btn-icon', !children && 'btn-round', className]);
  const iconClass = classNames([icon, { 'me-2': children }]);
  return (
    <Button className={buttonClass} size={size} {...rest} aria-label={getAriaLabel()}>
      <Icon2 className={iconClass} />
      {children}
    </Button>
  );
}

IconButton.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
};

export default IconButton;
