/**
 * HOC adding api2 function to Component and loads optional list of entities
 */

import React from 'react';
import api2builder from './api2builder.js';

function withApi2(entities = []) {
  const Api2 = api2builder(entities);
  return (Component) => (props) =>
    // eslint-disable-next-line react/prop-types
    <Api2 params={props.params}><Component {...props} /></Api2>;
}

export default withApi2;
