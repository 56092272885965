import authorizedRequest from 'containers/Api2/authorizedRequest.js';

export async function getDefaultInboxPath() {
  const inboxes = await authorizedRequest({ url: 'inboxes' });
  const defaultInbox = inboxes.values?.[0];
  if (!defaultInbox) {
    throw new Error('No inbox found');
  }
  return `/conversations/inboxes/${defaultInbox.id}`;
}

export default getDefaultInboxPath;
