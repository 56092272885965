/**
*
* ReactSelect
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from 'react-use';
import ReactSelect, { components } from 'react-select';
import Icon from 'components/media/Icon/index.js';
import FormControlWrapper from 'components/interaction/Form4/FormControlWrapper.jsx';
import Select from 'components/tags/Select/index.jsx';

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <Icon className="ss-dropdown" />
    </components.DropdownIndicator>
  );
}

function getValue(o) {
  if (!o || typeof o !== 'object') {
    return o;
  }
  return Array.isArray(o) ? o.map((i) => i.value) : o.value;
}

export function LargeSelect(props) {
  const {
    value, options, onChange, components: orgComponents, isMulti, style,
  } = props;
  const { className, ...rest } = props;
  const selected = isMulti
    ? options.filter((o) => value.includes(o.value))
    : options.find((o) => o.value === value);
  const handleChange = (o) => onChange(getValue(o));
  const newComponents = {
    DropdownIndicator,
    ...orgComponents,
  };
  return (
    <FormControlWrapper className={`position-relative ${className}`} border={1} style={style}>
      <ReactSelect
        {...rest}
        classNamePrefix="rs"
        value={selected || 0}
        components={newComponents}
        onChange={handleChange}
      />
    </FormControlWrapper>
  );
}

LargeSelect.propTypes = {
  components: PropTypes.object,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

LargeSelect.defaultProps = {
  components: {},
};

export function SmallSelect(props) {
  const {
    options, onChange, isMulti, isDisabled, placeholder, value, className, style,
  } = props;
  const indentedOptions = options.map((option) => (option.indent ? { ...option, label: `• ${option.label}` } : option));
  if (indentedOptions[0] && indentedOptions[0].value) {
    indentedOptions.unshift({ label: `--${placeholder || 'Select'}--` });
  }
  if (!isMulti) {
    const handleChange = (ev) => onChange(ev.target && ev.target.value);
    return (
      <Select
        value={value}
        options={indentedOptions}
        disabled={isDisabled}
        onChange={handleChange}
        className={className}
        style={style}
      />
    );
  }
  const handleChange = (ev) => onChange(Array.from(ev.target.options)
    .filter((opt) => opt.selected)
    .map((opt) => opt.value));
  return (
    <Select
      value={value}
      multiple
      options={indentedOptions}
      disabled={isDisabled}
      onChange={handleChange}
      className={className}
      style={style}
    />
  );
}

SmallSelect.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  style: PropTypes.object,
};

SmallSelect.defaultProps = {
};

function SizeSelect(props) {
  const { selectSize, isMulti, width, ...rest } = props;
  const { width: windowWidth } = useWindowSize();
  if (isMulti) {
    return <LargeSelect isMulti={isMulti} {...rest} />;
  }
  if (width) {
    rest.style = { ...rest.style, width };
  }
  switch (selectSize) {
    case 'lg':
      return <LargeSelect {...rest} />;
    case 'sm':
      return <SmallSelect {...rest} />;
    default:
      return windowWidth >= 480
        ? <LargeSelect {...rest} /> : <SmallSelect {...rest} />;
  }
}

SizeSelect.propTypes = {
  selectSize: PropTypes.string,
  width: PropTypes.string,
  isMulti: PropTypes.bool,
};

export default SizeSelect;
