import { css } from 'styled-components';

const buttonMixin = css`
  .btn-light {
    color: ${(p) => p.theme.brandPrimary};
  }
  
  .btn-group > .dropdown-toggle {
    &:after {
      border-width: 0.6em 0.5em 0.5em !important;
      margin-top: 0.4rem;
    }
    &.btn-primary:after {
      color: white;
    }
  }
  .btn-round:not(.btn-link) {
    padding-left: 0;
    padding-right: 0;
    border-radius: 1.2rem;
    min-width: 2.4rem;
    height: 2.4rem;
    &.btn-sm {
      border-radius: 0.9rem;
    }
    &.btn-lg {
      border-radius: 1.5rem;
    }
  }

  .btn > i:not(:last-child) {
    font-size: 1.5em;
    margin-right: 0.25em;
    display: inline-block;
  }

  .btn-icon {
    display: inline-flex;
    justify-content: center;
    &.btn-sm {
      min-width: 1.8rem;
      height: 1.8rem;
    }
    &.btn-lg {
      min-width: 3.0rem;
      height: 3.0rem;
    }
    & > i {
      font-size: 2em;
    }
  }

  .page-item > .page-link.page-link {
    border-radius: 50rem;
  }

    &.btn.btn-primary {
    color: ${(p) => p.theme.btnPrimaryColor};
    background-color: ${(p) => p.theme.btnPrimaryBg};
    border-color: ${(p) => p.theme.btnPrimaryBorder};
    &:focus, &.focus {
      background-color: ${(p) => p.theme.btnPrimaryBgHighlight};
      border-color: ${(p) => p.theme.btnPrimaryBorderFocus};
    }
    &:hover {
      background-color: ${(p) => p.theme.btnPrimaryBgHighlight};
      border-color: ${(p) => p.theme.btnPrimaryBorderHighlight};
    }
    &:active, &.active {
      background-color: ${(p) => p.theme.btnPrimaryBgHighlight};
      border-color: ${(p) => p.theme.btnPrimaryBorderHighlight};
      &:focus, &.focus, &:hover {
        background-color: ${(p) => p.theme.btnPrimaryBorderHighlight};
        border-color: ${(p) => p.theme.btnPrimaryBorderFocus};
      }
    }
    &.disabled,  &[disabled] {
      &:focus, &.focus, &:hover {
        background-color: ${(p) => p.theme.btnPrimaryBg};
        border-color: ${(p) => p.theme.btnPrimaryBorder};
      }
    }
    & .badge {
      color: ${(p) => p.theme.btnPrimaryBg};
    }
  }
  &.btn.btn-outline-primary {
    color: ${(p) => p.theme.textPrimary};
    border-color: ${(p) => p.theme.btnPrimaryBorder};
    &:focus, &.focus {
      border-color: ${(p) => p.theme.btnPrimaryBorderFocus};
    }
    &:hover {
      background-color: ${(p) => p.theme.contentBg};
      border-color: ${(p) => p.theme.btnPrimaryBorderHighlight};
    }
    &:active, &.active {
      background-color: ${(p) => p.theme.contentBg};
      border-color: ${(p) => p.theme.btnPrimaryBorderHighlight};
      &:focus, &.focus, &:hover {
        background-color: ${(p) => p.theme.contentBg};
        border-color: ${(p) => p.theme.btnPrimaryBorderFocus};
      }
    }
    &.disabled,  &[disabled] {
      &:focus, &.focus, &:hover {
        background-color: ${(p) => p.theme.btnPrimaryBg};
        border-color: ${(p) => p.theme.btnPrimaryBorder};
      }
    }
    & .badge {
      color: ${(p) => p.theme.btnPrimaryBg};
    }
  }

`;

export default buttonMixin;
