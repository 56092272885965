/**
*
* ControlMenu
*
*/

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { uniqueId } from 'lodash-es';
import { useIntl } from 'react-intl';
import messages from './messages.js';

export const ControlDiv = styled(Dropdown)`
  height: 21px;
  .dropdown-toggle {
    // Align to top right, compensate padding
    margin-right: -12px;
    margin-top: -6px;
    color: ${(props) => props.theme.gray};
    &:hover {
      color: ${(props) => props.theme.brandPrimary};
    }
  }
  .dropdown-menu {
    & > li > a {
      color: ${(p) => p.theme.brandPrimary};
      .danger {
        color: ${(p) => p.theme.brandDanger};
      }
    }
  }
`;

export function ControlMenu(props) { // eslint-disable-line react/prefer-stateless-function
  const { icon, items, onToggle, disabled, title, ifEnabled, hideDisabled, className } = props;
  const intl = useIntl();
  const id = useRef(uniqueId('control-options-'));

  if ((ifEnabled || hideDisabled) && items.every((item) => item.disabled)) {
    return null;
  }

  const translatedItems = items
    .filter((item) => !(hideDisabled && item.disabled))
    .map((item) => {
      // If item.label in messages (e.g. edit, delete), replace with message
      const label = messages[item.label] || item.label;
      return {
        ...item,
        label: typeof label === 'string' ? label : intl.formatMessage(label),
      };
    });

  function linkClass(item) {
    if (item.disabled) {
      return 'text-muted opaque';
    }
    return item.danger ? 'text-danger' : 'text-primary';
  }

  const classList = classNames(['d-print-none no-caret', className]);
  const popperConfig = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-12, 0],
        },
      },
    ],
  };

  return (
    <ControlDiv
      className={classList}
      onToggle={onToggle}
      align="end"
    >
      <Dropdown.Toggle
        id={id.current}
        variant="link"
        aria-label="Dropdown"
        disabled={disabled}
        data-testid={props['data-testid']} // eslint-disable-line react/destructuring-assignment
      >
        <i className={icon} title={title} />
      </Dropdown.Toggle>
      <Dropdown.Menu popperConfig={popperConfig}>
        {translatedItems.map((item, idx) => (
          <Dropdown.Item key={item.label.id || item.label} eventKey={idx} onClick={item.disabled ? undefined : item.handler} disabled={item.disabled} title={item.title}>
            <span className={linkClass(item)}>
              {item.label}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </ControlDiv>
  );
}

ControlMenu.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  ifEnabled: PropTypes.bool,
  hideDisabled: PropTypes.bool,
  onToggle: PropTypes.func,
  'data-testid': PropTypes.string,
};

ControlMenu.defaultProps = {
  icon: 'ss-ellipsis-vertical',
};

export default ControlMenu;
