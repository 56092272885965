/**
*
* LocaleToggle
*
*/

import React, { useCallback } from 'react';
// import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import useLocaleContext from 'containers/LanguageProvider/useLocaleContext.js';

function LocaleToggle(props) {
  const { locale, setLocale } = useLocaleContext();
  const options = ['nl', 'en'];
  const handleClick = useCallback(() => {
    const newLocale = options.find((loc) => loc !== locale);
    setLocale(newLocale);
  }, [locale, setLocale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dropdown.Item {...props} onClick={handleClick}>
      <div className="d-flex">
        <span className="flex-grow-1">Language:</span>
        { options.map((loc) => (
          <div key={loc} className={loc === locale ? 'ms-3 fw-semibold' : 'ms-3 text-muted'}>{loc.toUpperCase()}</div>
        )) }
      </div>
    </Dropdown.Item>
  );
}

// LocaleToggle.propTypes = {
// };

export default LocaleToggle;
