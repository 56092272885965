/**
*
* ExpandIcon
 * Points to the right when value=false, or down when value=true
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import Icon2 from './index.js';

const RotatedIcon = styled(Icon2)`
  transform: rotate(${(p) => p.rotate}deg);
  transition: transform ${(p) => p.theme.transitionDuration};
`;

function ExpandIcon(props) {
  const { expanded, className } = props;
  const classList = classNames([className, 'ss-directionright']);
  return <RotatedIcon className={classList} rotate={expanded ? 90 : 0} />;
}

ExpandIcon.propTypes = {
  expanded: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default ExpandIcon;
