/*
 * IconButton Messages
 *
 * This contains all the text for the IconButton component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  menu: {
    id: 'app.components.Button.menu',
    defaultMessage: 'Open/Close Menu',
  },
  move: {
    id: 'app.components.Button.move',
    defaultMessage: 'Move',
  },
  heart: {
    id: 'app.components.Button.like',
    defaultMessage: 'Like',
  },
  emptyheart: {
    id: 'app.components.Button.unlike',
    defaultMessage: 'Unlike',
  },
  print: {
    id: 'app.components.Button.print',
    defaultMessage: 'Print',
  },
  draw: {
    id: 'app.common.edit',
    defaultMessage: 'Edit',
  },
  write: {
    id: 'app.common.edit',
    defaultMessage: 'Edit',
  },
  copy: {
    id: 'app.common.copy',
    defaultMessage: 'Copy',
  },
  bug: {
    id: 'app.components.Button.bug',
    defaultMessage: 'Report Bug',
  },
  minus: {
    id: 'app.components.Button.minus',
    defaultMessage: 'Decrease',
  },
  plus: {
    id: 'app.components.Button.plus',
    defaultMessage: 'Increase',
  },
  navigateleft: {
    id: 'app.components.Pagination.previous',
    defaultMessage: 'Previous',
  },
  navigateright: {
    id: 'app.components.Pagination.next',
    defaultMessage: 'Next',
  },
  view: {
    id: 'app.components.Button.view',
    defaultMessage: 'View',
  },
  eyeOpen: {
    id: 'app.components.Button.eyeOpen',
    defaultMessage: 'Visible',
  },
  eyeClose: {
    id: 'app.components.Button.eyeClose',
    defaultMessage: 'Hidden',
  },
  collapse: {
    id: 'app.components.Button.collapse',
    defaultMessage: 'Collapse',
  },
  expand: {
    id: 'app.components.Button.expand',
    defaultMessage: 'Expand',
  },
  show: {
    id: 'app.components.Button.show',
    defaultMessage: 'Show',
  },
  hide: {
    id: 'app.components.Button.hide',
    defaultMessage: 'Hide',
  },
  sortByAlphabet: {
    id: 'app.components.Button.sortByAlphabet',
    defaultMessage: 'Sort by Alphabet',
  },
  sortByAttributesAlt: {
    id: 'app.components.Button.sortByAttributesAlt',
    defaultMessage: 'Sort',
  },
  trash: {
    id: 'app.common.remove',
    defaultMessage: 'Delete',
  },
  delete: {
    id: 'app.common.close',
    defaultMessage: 'Close',
  },
  unassign: {
    id: 'app.components.Button.unassign',
    defaultMessage: 'Unassign',
  },
  unblock: {
    id: 'app.components.Button.unblock',
    defaultMessage: 'Unblock',
  },
  addToShowcase: {
    id: 'app.components.Button.addToShowcase',
    defaultMessage: 'Add to Showcase',
  },
  removeFromShowcase: {
    id: 'app.components.Button.removeFromShowcase',
    defaultMessage: 'Remove from Showcase',
  },
  download: {
    id: 'app.components.Button.download',
    defaultMessage: 'Download',
  },
  ban: {
    id: 'app.components.Button.stop',
    defaultMessage: 'Stop',
  },
  refresh: {
    id: 'app.components.Button.refresh',
    defaultMessage: 'Refresh',
  },
  rotate: {
    id: 'app.components.Button.rotate',
    defaultMessage: 'Rotate',
  },
  adduser: {
    id: 'app.components.Button.adduser',
    defaultMessage: 'Add User',
  },
  submit: {
    id: 'app.common.submit',
    defaultMessage: 'Submit',
  },
  checkmark: {
    id: 'app.common.checkmark',
    defaultMessage: 'Apply',
  },
});
