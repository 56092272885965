import { isNil, omit } from 'lodash-es';
import { makeIdObject } from 'utils/arrays.js';
import applyFunctions from 'utils/applyFunctions.js';
import { serverDatesParser } from 'utils/objectDatesParser.js';
import { parseUrl, serialize } from 'utils/urlUtils.js';

function parsePayload(payload, options) {
  const { skipParseDates } = options;
  return !isNil(payload) && applyFunctions(payload, [addLoadMore(options), makeObject, skipParseDates ? null : serverDatesParser, handleExceptions(options), customParser(options), makeIdObject].filter(Boolean));
}

function addLoadMore(options) {
  return (obj) => {
    const { url, autoNext } = options;
    if (!url || !/limit=(\d+)/.test(url)) {
      return obj;
    }
    const { path, searchObject } = parseUrl(url);
    const { offset, since, limit } = searchObject;
    const { totalCount, nextSince, values, timeStamp } = obj;
    const refTime = searchObject.timeStamp || timeStamp || new Date().toISOString();

    if (since) {
      const result = omit(obj, ['nextUrl']);
      const hasNew = values.length >= limit;
      return {
        ...result,
        hasNew,
        sinceUrl: `${path}?${serialize({ ...searchObject, since: nextSince })}`,
      };
    }

    const result = { ...obj, refTime };
    const nextOffset = offset + limit;
    const nextLimit = autoNext || limit;
    const hasMore = nextOffset < totalCount && values.length >= limit;
    return {
      ...result,
      hasMore,
      nextUrl: hasMore ? `${path}?${serialize({ ...searchObject, offset: nextOffset, limit: nextLimit, timeStamp: refTime })}` : null,
    };
  };
}

function makeObject(obj) {
  if (typeof obj !== 'object') {
    return { value: obj };
  }
  const { data, ...rest } = obj;
  if (data && Array.isArray(data)) {
    rest.values = data;
    if (rest.pagination) {
      rest.totalCount = rest.pagination.totalNumberOfRecords;
    }
    return rest;
  }
  return obj;
}

function handleExceptions(options) {
  if (options.url.endsWith('pending')) {
    return (obj) => {
      if (obj.totalCount === undefined) {
        return obj;
      }
      const result = { ...obj, pendingCount: obj.totalCount };
      delete result.totalCount;
      return result;
    };
  }
  if (/students\/\d+\/imagepreferences/.test(options.url)) {
    return (obj) => obj.items || obj;
  }
  return (obj) => obj;
}

function customParser(options) {
  const { parser } = options;
  if (!parser) {
    return (obj) => obj;
  }
  return (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((o) => parser(o, options));
    }
    if (obj.values) {
      return { ...obj, values: obj.values.map((o) => parser(o, options)) };
    }
    return parser(obj, options);
  };
}

export default parsePayload;
