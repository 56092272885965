/**
 *
 * hasData
 *
 */

function hasData(obj) {
  if (obj === null) {
    return false;
  }
  if (typeof obj !== 'object') {
    return obj === 0 || Boolean(obj);
  }
  if (Array.isArray(obj)) {
    return obj.some((v) => Boolean(v));
  }
  return Object.values(obj).some((v) => Boolean(v));
}

export default hasData;
