/**
 *
 * Main
 *
 */

import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import AlertList from 'components/AlertList/index.jsx';
import ErrorBoundary from 'components/ErrorBoundary/index.jsx';
import PageTabs from 'components/PageTabs/index.jsx';
import Spinner from 'components/Spinner/index.jsx';
import IntlString from 'components/media/IntlString/index.jsx';
import ContentWrapper from './ContentWrapper.jsx';
import PageContext from './PageContext.js';

function InsideProvider(props) {
  const { children, pageTabs, loading, pageTitle, header, width, control, search } = props;
  return (
    <>
      <div>
        {control && (
          <div className="float-end w-auto m-4">{control}</div>
        )}
        {header && <div className="p-4 fw-light">{header}</div>}
        {pageTitle && <h2 className="p-4 fw-light"><IntlString message={pageTitle} /></h2>}
      </div>
      {(pageTabs || search) && (
        <div className="d-flex justify-content-between align-items-baseline flex-column-reverse flex-sm-row pe-4 d-print-none">
          <PageTabs {...pageTabs} width={width} />
          {search}
        </div>
      )}
      <ErrorBoundary>
        <ContentWrapper {...props}>
          <AlertList shaded />
          {loading ? <div><Spinner /></div> : children}
        </ContentWrapper>
      </ErrorBoundary>
    </>
  );
}

InsideProvider.propTypes = {
  children: PropTypes.node.isRequired,
  control: PropTypes.node,
  search: PropTypes.node,
  pageTabs: PropTypes.object,
  loading: PropTypes.bool,
  width: PropTypes.string,
  header: PropTypes.node,
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  banner: PropTypes.node,
};

function Main(props) {
  const [state, setState] = useReducer((s, a) => ({ ...s, ...a }), {});
  const context = useMemo(() => ({ ...state, setState }), [state, setState]); // Possible to be expanded to include other context
  const innerProps = { ...props, ...state };

  return (
    <PageContext.Provider value={context}>
      <InsideProvider {...innerProps} />
    </PageContext.Provider>
  );
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
  control: PropTypes.node,
  search: PropTypes.node,
  pageTabs: PropTypes.object,
  loading: PropTypes.bool,
  width: PropTypes.string,
  header: PropTypes.node,
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  banner: PropTypes.node,
};

export default Main;
