import { createSelector } from 'reselect';
/**
 * Direct selector to the api2 state domain
 */
export const selectApi2TopDomain = (state) => state.get('api2');

export const selectPathDomain = (state) => state.getIn(['router', 'location', 'pathname']);

const selectApi2Domain = (selector) => (state) => state.get('api2').getIn(selector.split('/'));

/**
 * Other specific selectors
 */

export const selectFromLocation = (api2, pathname) => {
  const parts = pathname.slice(1).split('/');
  const idIndex = parts.findIndex((p) => Number(p));
  if (idIndex < 1) {
    return null;
  }
  const entity = parts.slice(0, idIndex).join('/');
  const id = parts[idIndex];
  const attribute = parts[idIndex + 1];
  const selected = getSelected(api2, entity, id, attribute);
  return selected ? selected.set('attribute', attribute).toJS() : { id: Number(id) };
};

function getGroup(state, id) {
  const communities = state.get('communities');
  if (!communities || communities.get('loading') !== false) {
    return undefined;
  }
  // eslint-disable-next-line eqeqeq
  const group = communities.find((v) => v.get && v.get('groupId') == id);
  return group;
}

function getSelected(state, entity, id, attribute) {
  switch (entity) {
    case 'archive':
      return state.getIn(['communities', 'archived', id]);
    case 'groups': {
      const group = getGroup(state, id);
      if (group && attribute === 'portfolio') {
        return group.set('portfolio', state.getIn(['portfolio', 'groups', id]));
      }
      return group;
    }
    case 'portfolio':
      return state.getIn(['portfolio', 'student']);
    case 'admin/groups':
      return state.getIn(['sa', 'groups', id]);
    default:
      return state.getIn([entity, id]);
  }
}

/**
 * Default selector used by Api2
 */

const makeSelectApi2 = (selector) => () => createSelector(
  selectApi2Domain(selector),
  (substate) => substate && (substate.toJS ? substate.toJS() : substate),
);

const makeSelectFromLocation = () => createSelector(
  [selectApi2TopDomain, selectPathDomain],
  selectFromLocation,
);

export default makeSelectApi2;
export {
  selectApi2Domain,
  makeSelectFromLocation,
};
