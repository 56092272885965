import { useApi2 } from 'containers/Api2/index.js';
import messages from 'components/user/messages.js';
import { getIdNumber, getIdRole } from 'utils/userIdRole.js';
import useCurrentUser from 'containers/User/useCurrentUser.js';
import userrolesParser from './userrolesParser.js';

function getParams(userNumber, roleId) {
  if (roleId) {
    const result = { userId: userNumber, roleType: roleId };
    result.id = getIdNumber(result);
    return result;
  }
  return { id: userNumber, ...getIdRole(userNumber) };
}

function useApi2Userroles(userNumber, roleId) {
  const { userId, roleType, id } = getParams(userNumber, roleId);
  const user = useCurrentUser();
  return useApi2({
    url: !user.loading && userNumber && `userroles?userId=${userId}&roleType=${roleType}`,
    selector: `userroles/${id}`,
    error: { 403: messages.noPermission },
    noReport: [401, 403, 404],
    parser: userrolesParser,
  });
}

export default useApi2Userroles;
