/*
 * Form4 Messages
 *
 * This contains all the text for the Form4 component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  required: {
    id: 'app.components.Form.required',
    defaultMessage: 'required',
  },
  navigationWarning: {
    id: 'app.components.Form.navigationWarning',
    defaultMessage: 'Changes may not be saved, leave this page?',
  },
  requiredField: {
    id: 'app.components.Form.requiredField',
    defaultMessage: 'This is a required field',
  },
  invalidEmail: {
    id: 'app.components.Form.invalidEmail',
    defaultMessage: 'Please fill in a valid email address',
  },
  invalidUrl: {
    id: 'app.components.Form.invalidUrl',
    defaultMessage: 'Please fill in a valid web address',
  },
  dateMax: {
    id: 'app.components.Form.dateMax',
    defaultMessage: 'The date must be before {max}',
  },
  this: {
    id: 'app.components.Form.this',
    defaultMessage: 'This',
  },
  editDisabled: {
    id: 'app.components.Form.editDisabled',
    defaultMessage: 'You cannot edit this field while another field is being edited',
  },
  delete: {
    id: 'app.components.Form.delete',
    defaultMessage: 'Delete this item?',
  },
});
