/*
 *
 * LanguageProvider constants
 *
 */

import { createContext } from 'react';

export const DEFAULT_LOCALE = 'en';
export const LOCALE_KEY = 'language';
export const LocaleContext = createContext();
