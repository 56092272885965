/*
 * DatePicker Messages
 *
 * This contains all the text for the DatePicker component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  time: {
    id: 'app.components.DatePicker.time',
    defaultMessage: 'Time',
  },
});
