import { useEffect, useRef } from 'react';
import useSafeState from 'utils/useSafeState.js';

export const isVisible = () => document.visibilityState === 'visible';

function useVisible() {
  const [visible, setVisible] = useSafeState(isVisible());
  const handle = useRef(null);

  function handleVisibilityChange() {
    if (isVisible()) {
      setVisible(true);
      if (handle) {
        clearTimeout(handle.current);
        handle.current = null;
      }
      return;
    }
    handle.current = setTimeout(() => {
      setVisible(false);
    }, 300 * 1000); // 5 minutes
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return visible;
}

export default useVisible;
