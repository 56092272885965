/**
 * StatsProvider
 * Get notification and other stats when role changed
 */

import React, { createContext, useEffect, useMemo, useState } from 'react';
import config from 'config/index.js';
import useRestApi from 'utils/useRestApi/index.js';
import useUserContext from '../useUserContext.js';

import statsParser from './statsParser.js';

const POLLTIMES = {
  local: 300,
  test: 60,
};

const NOTIFICATIONS_POLLTIME = (POLLTIMES[config.env] || 300) * 1000;

export const StatsContext = createContext({});

function StatsProvider(props) {
  const { active, roleId } = useUserContext();
  const url = 'notifications/stats';
  const fetchOptions = {
    url,
    parser: statsParser,
    ignore: [401],
  };
  const queryOptions = {
    refetchInterval: NOTIFICATIONS_POLLTIME,
    enabled: active,
  };
  const stats = useRestApi(fetchOptions, queryOptions);

  const [seenItems, setSeenItems] = useState({});

  useEffect(() => {
    // Reset when role changes
    setSeenItems({});
  }, [roleId]);

  const value = useMemo(() => ({
    ...stats,
    seenItems,
    setSeenItems,
  }), [stats.data, stats.status, seenItems]); // eslint-disable-line react-hooks/exhaustive-deps

  return <StatsContext.Provider {...props} value={value} />;
}

export default StatsProvider;
