/**
 *
 * withAddAlert
 * Adds apiFetch method to children to access apiFetch actions for all entities
 */

import { connect } from 'react-redux';
import { addAlert, addError } from './actions.js';

function mapDispatchToProps(dispatch) {
  return {
    addAlert: (message, style) => dispatch(addAlert(message, style)),
    addError: (error, context) => dispatch(addError(error, context)),
    addDelayed: (message, style) => setTimeout(() => dispatch(addAlert(message, style)), 100),
  };
}

const withAddAlert = connect(null, mapDispatchToProps);

export default withAddAlert;
