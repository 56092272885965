/**
*
* NotificationsDropdown
*
*/

import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'react-bootstrap';

import useStats from 'containers/User/UserProvider/useStats.js';
import NotificationsIcon from './NotificationsIcon.jsx';
import messages from './messages.js';
import NotificationsList from './NotificationsList.jsx';

const MyDropdown = styled(Dropdown)`
  .btn.btn.dropdown-toggle {
    &, &:hover, &:focus {
      background-color: transparent;
      border: none;
      padding-top: 3px;
      box-shadow: none;
    }
    &[disabled] {
      opacity: 1;
    }
  }

  .dropdown-menu.dropdown-menu-right {
    min-width: 480px;
    .avatar-text {
      white-space: normal;
    }
    &.no-link a {
      cursor: default;
    }
    .color-block {
      font-size: 21px;
      margin-top: 6px;
      margin-left: 6px;
    }
    a {
      padding-top: 5px;
    }
    .no-notifications-alert > a {
      padding-top: 10px;
      padding-bottom: 10px;
      color: ${(p) => p.theme.stateInfoText};
    }

  }
  @media (max-width: ${(props) => props.theme.screenXsMax}) {
    .dropdown-menu.dropdown-menu-right {
      right: -80px;
      min-width: calc(100vw - 30px);
    }
  }
`;

function NotificationsDropdown() {
  const { data, setIn } = useStats();
  const [open, setOpen] = useState(false);

  if (!data) {
    return <NotificationsIcon count={0} />;
  }
  const count = data.unseenCount;

  return (
    <MyDropdown id="notifications-toggle" className="no-caret" align="end" onToggle={setOpen}>
      <Dropdown.Toggle align="end">
        <h2 className="visually-hidden"><FormattedMessage {...messages.notifications} /></h2>
        <NotificationsIcon count={count} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-right dropdown-menu-large">
        <NotificationsList open={open} stats={data} onReset={() => setIn('unseenCount', 0)} />
      </Dropdown.Menu>
    </MyDropdown>
  );
}

export default NotificationsDropdown;
