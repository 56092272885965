/* eslint-disable import/no-import-module-exports */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';
import '@formatjs/intl-locale/polyfill';
import 'sanitize.css/sanitize.css';
import { enableMapSet, enableES5 } from 'immer';

// Import all the third party stuff
import React from 'react';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { hasAuthParams } from 'react-oidc-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Import root app
import App from 'containers/App/index.jsx';
import UserProvider from 'containers/User/UserProvider/index.jsx';
import configureStore from '../../configureStore.js';

enableMapSet();
enableES5();

// Create redux store
const initialState = {};
const store = configureStore(initialState);

const queryClient = new QueryClient();
const silentRenew = hasAuthParams() && (window.location.pathname === '/silent.html') && (window.top !== window.self);

function RootApp(props) {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            {!silentRenew && (
              <App {...props} />
            )}
          </UserProvider>
        </QueryClientProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default RootApp;
