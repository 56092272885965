/**
 *
 * StudentList
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ListGroupItem } from 'react-bootstrap';
import { useApi2 } from 'containers/Api2/index.js';
import Spinner from 'components/Spinner/index.jsx';
import messages from './messages.js';
import DynamicListGroup from '../DynamicListGroup/index.jsx';

function StudentList(props) {
  const { group, portfolio } = props;
  const { id, groupId } = group;
  const options = portfolio ? {
    url: `portfolio/groups/${groupId}/students`,
  } : {
    url: `communities/${id}/members?offset=0&limit=9999&includeConnections=true&roleType=3`,
    selector: `communities/${id}/students`,
    noReport: [401],
  };
  const students = useApi2(options);

  if (students.loading) {
    return <div><Spinner /></div>;
  }
  const link = portfolio ? `/groups/${groupId}/portfolio/:id` : `/groups/${groupId}/students/:id`;
  return (
    <>
      {students && students.list && (
        <ListGroupItem className="list-group-header">
          <FormattedMessage {...messages.counter} values={{ count: students.list.length }} />
        </ListGroupItem>
      )}
      <div className="list-group-indent">
        <DynamicListGroup
          data={students}
          link={link}
          idProp={portfolio ? 'portfolioId' : 'id'}
          noRedirect
        />
      </div>
    </>
  );
}

StudentList.propTypes = {
  group: PropTypes.object,
  portfolio: PropTypes.bool,
};

export default StudentList;
