import config from 'config/index.js';
import request from 'utils/request.js';
import currentUserParser from '../currentUserParser.js';
import { makeCredentials } from './helpers.js';
import { CURRENTUSER_ENDPOINT } from '../constants.js';

async function fetchCurrentUser(user) {
  const credentials = await makeCredentials(user);
  const headers = {
    ...credentials,
    'Content-Type': 'application/json',
  };
  const data = await request({ uri: `${config.apiHost}${CURRENTUSER_ENDPOINT}`, headers, noReport: [401] });
  return currentUserParser(data);
}

export default fetchCurrentUser;
