/**
 *
 * ThemeProvider
 *
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { mergeTheme } from 'utils/makeTheme/index.js';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import themeFromSchool from 'utils/themeFromSchool.js';
import { themeVariables } from './constants.js';

function MyThemeProvider(props) {
  const { children } = props;
  const role = useCurrentRole();
  const school = role?.school;
  const mergedTheme = useMemo(() => {
    const theme = school && themeFromSchool(school);
    return mergeTheme({ ...themeVariables, ...theme });
  }, [school]);
  return (
    <ThemeProvider theme={mergedTheme}>
      <Helmet
        meta={[{ name: 'theme-color', content: mergedTheme.brandPrimary }]}
      />
      {children}
    </ThemeProvider>
  );
}

MyThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default MyThemeProvider;
