/*
 * EmojiPicker Messages
 *
 * This contains all the text for the EmojiPicker component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmRemove: {
    id: 'app.components.CropImage.confirmRemove',
    defaultMessage: 'Delete Image?',
  },
});
