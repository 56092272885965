/**
 *
 * useSideMenu
 *
 */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import useModules from 'containers/User/useModules.js';
import { canManageGroups } from 'containers/HomePage/permissions.js';

function getInitialState() {
  const { pathname } = window.location;
  if (pathname.includes('portfolio')) {
    return 'portfolio';
  }
  if (pathname.startsWith('/groups')) {
    return 'administration';
  }
  return pathname.split('/')[1];
}

function useSideMenu() {
  const role = useCurrentRole();
  const modules = useModules();
  const { pathname } = useLocation();
  const isManager = canManageGroups(role);
  const [expanded, setExpanded] = useState(getInitialState());
  const expandProps = (key) => ({
    onClick: (ev) => {
      ev.stopPropagation();
      setExpanded(key === expanded ? null : key);
    },
    expanded: expanded === key,
  });
  useEffect(() => setExpanded(getInitialState()), [role.uniqueId]);
  return { modules, role, pathname, isManager, expandProps };
}

export default useSideMenu;
