/**
 *
 * Pagination
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useEvent } from 'react-use';
import { Pagination } from 'react-bootstrap';

import { FormattedMessage, useIntl } from 'react-intl';
import Icon from 'components/media/Icon/index.js';
import messages from './messages.js';

function MyPagination(props) {
  const { value, onChange, length, children, className } = props;
  const intl = useIntl();
  const previous = intl.formatMessage(messages.previous);
  const next = intl.formatMessage(messages.next);
  const values = {
    page: value + 1,
    length,
  };
  const hasPrevious = value > 0;
  const hasNext = value < length - 1;

  function handleKey(event) {
    if (['INPUT', 'TEXTAREA'].includes(event.target.tagName)) {
      return;
    }
    switch (event.key) {
      case 'PageUp':
        if (hasPrevious) {
          onChange(value - 1);
        }
        break;
      case 'PageDown':
        if (hasNext) {
          onChange(value + 1);
        }
        break;
      case 'Home':
        onChange(0);
        break;
      case 'End':
        onChange(length - 1);
        break;
      default:
        break;
    }
  }

  useEvent('keydown', handleKey);

  const classList = ['d-flex justify-content-between justify-content-sm-around align-items-baseline', className];
  return (
    <Pagination className={classList}>
      <Pagination.Prev
        onClick={() => onChange(value - 1)}
        disabled={!hasPrevious}
      >
        <Icon inline className="ss-navigateleft " title={previous} />
        <span className="ms-3 d-none d-sm-inline">{previous}</span>
      </Pagination.Prev>
      {children || <FormattedMessage {...messages.numbers} values={values} />}
      <Pagination.Next
        onClick={() => onChange(value + 1)}
        disabled={!hasNext}
      >
        <span className="d-none d-sm-inline me-3">{next}</span>
        <Icon inline className="ss-navigateright" title={next} />
      </Pagination.Next>
    </Pagination>
  );
}

MyPagination.propTypes = {
  value: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default MyPagination;
