/*
 * StudentMenu Messages
 *
 * This contains all the text for the StudentMenu component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  counter: {
    id: 'app.components.StudentMenu.counter',
    defaultMessage: '{count} students',
  },
});
