/**
*
* Contacts
* Load and display contacts for an event
*
*/

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { useApi2 } from 'containers/Api2/index.js';
import UserWithDetails from 'components/user/UserWithDetails.jsx';
import withApi2 from 'containers/Api2/withApi2.jsx';
import { idValues } from 'utils/arrays.js';

function Contacts(props) {
  const { eventId, onLoaded, className } = props;
  const users = useApi2({ url: `events/${eventId}/users` });
  const loaded = users.list !== undefined;

  useEffect(() => {
    if (loaded) {
      onLoaded();
    }
  }, [loaded]);

  if (!loaded) {
    return null;
  }
  return (
    <ListGroup className={className}>
      {idValues(users).map((user) => (
        <ListGroupItem key={user.uniqueId} className="rounded-0 py-3 py-2 py-pe-0">
          <UserWithDetails user={user} />
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}

Contacts.propTypes = {
  className: PropTypes.string,
  onLoaded: PropTypes.func,
  eventId: PropTypes.number,
};

export default withApi2([{ url: 'events/:eventId/users' }])(Contacts);
