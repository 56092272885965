/**
 *
 * UploadModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import UploadAvatar from './index.jsx';
import messages from './messages.js';

function UploadModal(props) {
  const { user, onChange, show, onHide } = props;
  function handleChange(item) {
    onChange(item);
    onHide();
  }
  const values = { name: user.firstName || user.displayName };
  return (
    <Modal show={show} size="sm" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title><FormattedMessage {...messages.editAvatar} values={values} /></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UploadAvatar value={user.avatar} onChange={handleChange} />
      </Modal.Body>
    </Modal>
  );
}

UploadModal.propTypes = {
  user: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UploadModal;
