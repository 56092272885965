/**
 *
 * ClassesMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ListGroupItem } from 'react-bootstrap';
import { useIsAdminRole } from 'containers/HomePage/permissions.js';
import { MODULES_PAYMENTS } from 'containers/User/constants.js';
import useModules from 'containers/User/useModules.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import { hasItems } from 'utils/arrays.js';
import ExpandItem from '../ExpandItem.jsx';
import messages from '../messages.js';
import DynamicListGroup from '../DynamicListGroup/index.jsx';
import PathItem from '../PathItem.jsx';
import useGetClasses from './useGetClasses.js';

function ClassesMenu(props) {
  const { expanded, onClick, role } = props;
  const { pathname } = useLocation();
  const { isAllowedToCreatePayments } = role;

  const modules = useModules();
  const groups = useGetClasses();
  const hasPayments = (useIsAdminRole() && modules.includes(MODULES_PAYMENTS)) || isAllowedToCreatePayments;
  const hasClasses = hasItems(groups.values);

  if (!hasClasses && !hasPayments) {
    return null;
  }

  function getExpansion() {
    const classes = hasClasses ? (
      (
        <DynamicListGroup
          data={groups}
          link="/groups/:id"
          idProp="groupId"
          noRedirect
        />
      )
    ) : null;

    const payments = hasPayments ? (
      <ul className="list-unstyled">
        <PathItem to="/payments/admin">
          <AvatarWithText icon="ss-banknote">
            <FormattedMessage {...messages.paymentRequests} />
          </AvatarWithText>
        </PathItem>
      </ul>
    ) : null;

    if (classes && payments) {
      return (
        <>
          <ListGroupItem className="list-group-header">
            <FormattedMessage {...messages.classes} />
          </ListGroupItem>
          {classes}
          <ListGroupItem className="list-group-header">
            <FormattedMessage {...messages.management} />
          </ListGroupItem>
          {payments}
        </>
      );
    }
    return classes || payments;
  }

  return (
    <>
      <ExpandItem onClick={onClick} expanded={expanded}>
        <AvatarWithText icon="ss-index" active={pathname.startsWith('/groups')}>
          <FormattedMessage {...messages.administration} />
        </AvatarWithText>
      </ExpandItem>
      {expanded && (
        <div className="list-group-indent">
          {getExpansion()}
        </div>
      )}
    </>
  );
}

ClassesMenu.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
};

export default ClassesMenu;
