/**
*
* FormLabel
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import messages from './messages.js';

function FormLabel(props) {
  const intl = useIntl();
  const { children, required, ...rest } = props;
  if (!children) {
    return null;
  }
  const requiredText = intl.formatMessage(messages.required);
  const requiredTip = <Tooltip id="required">{requiredText}</Tooltip>;

  return (
    <Form.Label {...rest}>
      {required ? (
        <OverlayTrigger placement="top" overlay={requiredTip}>
          <span className="label-required">{children}</span>
        </OverlayTrigger>
      ) : children}
    </Form.Label>
  );
}

FormLabel.propTypes = {
  children: PropTypes.node,
  required: PropTypes.bool,
};

export default FormLabel;
