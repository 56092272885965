/**
 *
 * eventTimes
 * Convert times of an event between different systems
 * Local with time: date objects for start and end, actual time (end > start)
 * Local allDay: date object for start and end, indicating local midnight before first and last day (end >= start)
 * Remote with time: actual times in UTC ISOstring (end > start)
 * Remote allDay: ISOstrings with UTC midnight before for first day and UTC midnight after last day
 *
 */

import { pick, omit } from 'lodash-es';
import {
  addMinutes, isEqual, format, setHours, subMinutes, subSeconds, startOfDay, isFuture, endOfDay, isDate, isSameDay, addHours, startOfHour, parseISO,
} from 'utils/date-fns/index.js';
import { ENDASH, HELLIP } from 'utils/characters.js';

const YEAR = new Date().getFullYear();

export const TIME_FIELDS = ['start', 'end', 'allDay'];

export { default as toUTCStrings, ISODateString } from 'utils/toUTCStrings.js';
export { default as fromUTCStrings } from 'utils/fromUTCStrings.js';

export function isMidnight(d) {
  return isEqual(d, startOfDay(d));
}

/**
  * Convert time to UTC midnight with same date as local
  * @param m
  * @returns {Date}
  */
export function utcDay(d) {
  const diff = d.getTimezoneOffset();
  return subMinutes(startOfDay(d), diff);
}

export function utcDayString(d) {
  return ISOString(utcDay(d));
}

/**
  * Convert times to start and end of day
  * @param time
  * @returns {{start: *, end: *, allDay: boolean}}
  */
export function localToAllDay(time) {
  const { start, end } = time;
  return {
    start: start && startOfDay(start),
    end: end && startOfDay(subSeconds(end, 1)),
    allDay: true,
  };
}

/**
  * Convert allDay to times, i.e. start and end of day
  * @param time
  * @returns {{start, end: *, allDay: boolean}}
  */
export function localFromAllDay(time) {
  return {
    start: time.start && setHours(time.start, 9),
    end: time.end && setHours(time.end, 10),
    allDay: false,
  };
}

export function ISOString(date) {
  return date.toISOString().replace(/\.0+Z$/, 'Z');
}

/**
  * format date/time and remove year
  * @param date
  */
export function cFormat(date, tpl) {
  if (!date) {
    return HELLIP;
  }
  const result = format(date, tpl);
  const re = new RegExp(`\\W? +${YEAR}`);
  return result.replace(re, '');
}

/**
  * Check if a time refers to an allDay event, i.e. both start and end are at midnight local time
  * @param time
  * @returns {boolean}
  */
export function checkAllDay(time) {
  return isMidnight(time.start) && isMidnight(time.end);
}

const toDate = (date) => date && (typeof date === 'string') ? parseISO(date) : date;

export function isValidTime(orgTime, options = { future: false, requireEnd: true }) {
  const time = {
    ...orgTime,
    start: toDate(orgTime.start),
    end: toDate(orgTime.end),
  };
  if (options.requireEnd) {
    if (!isDate(time.start) || !isDate(time.end)) {
      return false;
    }
    if (time.end < time.start) {
      return false;
    }
    if (time.allDay === false && isEqual(time.end, time.start)) {
      return false;
    }
  }
  return !options.future || isFuture(time.allDay ? endOfDay(time.start) : time.start);
}

export function groupTime(event) {
  return {
    ...omit(event, TIME_FIELDS),
    time: pick(event, TIME_FIELDS),
  };
}

export function makeDateTimeString(event) {
  const { start, end } = event;
  return isSameDay(start, end)
    ? `${cFormat(start, 'PPPP p')}${ENDASH}${cFormat(end, 'p')}`
    : `${cFormat(start, 'PPPP p')}${ENDASH}${cFormat(end, 'eeee p')}`;
}

export function makeTimeString(event) {
  const { start, end } = event;
  return `${cFormat(start, 'p')}${ENDASH}${cFormat(end, 'p')}`;
}

export function adjustDate(date, diff) {
  return addMinutes(date, diff);
}

/**
  * Set event.end to same day to ensure display in bigCalendar
  * @param event
  * @returns {{end: Date}}
  */
export function fixEndForCalendar(event) {
  const { start, end } = event;
  const dayEnd = endOfDay(start);
  return dayEnd > end ? event : { ...event, end: dayEnd };
}

export function shortTimeString(event, formatString = 'p') {
  const { start, end } = event;
  const endString = cFormat(end, 'p');
  const startString = cFormat(start, formatString);
  return `${startString}${ENDASH}${endString}`;
}

export function defaultTime(duration) {
  const start = addHours(startOfHour(new Date()), 1);
  if (duration === undefined) {
    return start;
  }
  return { start, end: addHours(start, duration) };
}

export function eventLimits(events) {
  if (events.length === 0) {
    return {};
  }
  let { start, end } = events[0];
  events.slice(1).forEach((e) => {
    if (e.start < start) {
      start = e.start; // eslint-disable-line prefer-destructuring
    }
    if (e.end > end) {
      end = e.end; // eslint-disable-line prefer-destructuring
    }
  });
  return { start, end };
}

export function getCalendarEndDate(obj) {
  const date = obj.end;
  return obj.allDay ? endOfDay(date) : date;
}
