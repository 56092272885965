/**
 *
 * GroupBanner
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import { ColorStripe } from 'components/media/ColorBlock/index.js';
import { GROUPTYPES_GENERAL } from 'containers/User/constants.js';
import ScreenWrapper from './ScreenWrapper.jsx';
import messages from './messages.js';
import { TagLine } from './styled.js';

const GroupWrapper = styled.div`
  .avatar-text {
    max-width: 400px;
  }
`;

function groupTypeString(groupType) {
  return groupType === GROUPTYPES_GENERAL ? 'general' : 'classes';
}
function GroupBanner(props) {
  const { group } = props;
  const role = useCurrentRole();
  const { pathname } = useLocation();
  if (!group || !role) {
    return null;
  }
  const adminLink = group.type && `/manage/groups/${groupTypeString(group.type)}/${group.groupId}/settings?ref=${pathname}`;
  const { logo, themeColor, tagLine } = group.theme || role.school.theme;
  return (
    <ScreenWrapper>
      <GroupWrapper className="d-flex justify-content-between align-items-center">
        <AvatarWithText src={logo} group bsSize="large">
          <ColorStripe color={themeColor.hex} stripe />
          <h1 className="fs-2 fw-bold my-2">{group.name}</h1>
          <TagLine className="fs-5">{tagLine}</TagLine>
        </AvatarWithText>
        {group.permissions?.manage && group.type && (
          <Button variant="light" as={Link} to={adminLink}>
            <FormattedMessage {...messages.manageGroup} />
          </Button>
        )}
      </GroupWrapper>
    </ScreenWrapper>
  );
}

GroupBanner.propTypes = {
  group: PropTypes.object,
};

export default GroupBanner;
