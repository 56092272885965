import React from 'react';
import { Link } from 'react-router-dom';

export const intlBreak = { br: <br /> };

export const intlBold = { b: (msg) => <b>{msg}</b> };

export function intlTags(tags) {
  const result = {};
  tags.forEach((str) => {
    const [tag, ...classes] = str.split('.');
    const Tag = tag;
    result[tag] = (msg) => <Tag className={classes.join(' ')}>{msg}</Tag>;
  });
  return result;
}

export function intlLink(url, attributes) {
  return {
    a: (msg) => url.startsWith('/') && !/\.\w+$/.test(url)
      ? (
        <Link to={url} {...attributes}>
          {msg}
        </Link>
      )
      : (
        <a href={url} {...attributes}>
          {msg}
        </a>
      ),
  };
}
