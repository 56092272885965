/**
 *
 * withAddError
 * Adds apiFetch method to children to access apiFetch actions for all entities
 */

import { connect } from 'react-redux';
import { addError } from './actions.js';

function mapDispatchToProps(dispatch) {
  return {
    addError: (error, context) => dispatch(addError(error, context)),
  };
}

const withAddError = connect(null, mapDispatchToProps);

export default withAddError;
