import { useMemo } from 'react';
import { useApi2 } from 'containers/Api2/index.js';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import { EMPLOYEE_ROLE, MODULES_PORTFOLIOPILOT_OPTION, MODULES_PORTFOLIOPILOT_GROUPS, MODULES_STUDENTPORTFOLIO } from './constants.js';
import noPilots from './noPilots.json';

const noPilotsIds = noPilots.map((o) => o.id);

function useModules() {
  const role = useCurrentRole();
  const portfolioGroups = useApi2({ selector: 'portfolio/groups' });
  const hasEnabledPilots = portfolioGroups.list?.some((id) => portfolioGroups[id]?.pilot?.enabled);
  const { type, school, uniqueId } = role || {};
  const { modules } = school;
  const result = useMemo(() => {
    if (!uniqueId) {
      return [];
    }
    if (modules.includes(MODULES_STUDENTPORTFOLIO)) {
      return modules;
    }
    const blockPilots = noPilotsIds.includes(school.id);
    if (blockPilots) {
      return modules.filter((m) => m !== MODULES_PORTFOLIOPILOT_GROUPS);
    }
    if (type !== EMPLOYEE_ROLE) {
      return modules;
    }
    if (modules.includes(MODULES_PORTFOLIOPILOT_GROUPS) || !hasEnabledPilots) {
      return [...modules, MODULES_PORTFOLIOPILOT_OPTION];
    }
    return [...modules, MODULES_PORTFOLIOPILOT_GROUPS, MODULES_PORTFOLIOPILOT_OPTION];
  }, [uniqueId, hasEnabledPilots, modules, type, school]);

  return result;
}

export default useModules;
