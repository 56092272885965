/**
*
* AvatarWithText
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from 'components/media/Avatar/index.jsx';
import { paddingHorizontal } from 'utils/bsStyler.js';

const Wrapper = styled.div`
  display: flex;
  align-items: ${(props) => props.align};
`;

const TextWrapper = styled.div`
  flex-grow: 1;
  text-overflow: ellipsis;
  padding-left: ${paddingHorizontal};
  text-align: left;
  & > * {
    margin: 0;
  }
  && {
    /* max-width: calc(100% - 34px); */
  }
`;

function AvatarWithText(props) {
  const { id, title, children, className, bsSize, ...rest } = props;
  const imgProps = {
    ...rest,
    bsSize: bsSize || findSize(children),
  };
  return (
    // eslint-disable-next-line react/destructuring-assignment
    <Wrapper align={props.align || 'normal'} id={id} title={title} className={className}>
      <Avatar {...imgProps} />
      <TextWrapper className="avatar-text">{children}</TextWrapper>
    </Wrapper>
  );
}

function findSize(children) {
  if (!Array.isArray(children)) {
    return 'small';
  }
  const notEmpty = children.filter(Boolean);
  if (notEmpty.length === 0) {
    return 'small';
  }
  return notEmpty[0].type === 'h1' ? 'large' : 'base';
}

AvatarWithText.propTypes = {
  bsSize: PropTypes.string,
  children: PropTypes.node,
  align: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default AvatarWithText;
