/**
 *
 * urlUtils
 *
 */

import { useLocation, useNavigate } from 'react-router-dom';
import linkify from 'linkify-it';
import { omit } from 'lodash-es';

const PREFIXES = { hash: '#', search: '?' };

export function serialize(obj) {
  if (obj === undefined || obj === null) {
    return '';
  }
  return Object.keys(obj)
    .map((k) => {
      switch (obj[k]) {
        case undefined:
        case null:
        case false:
          return false;
        default:
      }
      const v = encodeURIComponent(obj[k]);
      return `${k}=${v}`;
    }).filter(Boolean).join('&');
}

export function parseUrl(url) {
  const result = { path: url, search: '', searchObject: {}, hash: '' };
  const match = url.match(/([^?#]*)(\?[^#]*)?(#.*)?/);
  if (!match) {
    return result;
  }
  const [path, search, hash] = match.slice(1);
  result.path = path;
  Object.assign(result, parsePath(match[1]));
  if (search) {
    result.search = decodeURI(search.slice(1));
    result.searchObject = parseString(search);
  }
  if (hash) {
    result.hash = decodeURI(hash.slice(1));
  }
  return result;
}

export function parsePath(path) {
  const result = { params: {} };
  const parts = path.split('/');
  let key;
  while (parts.length > 0) {
    const term = parts.shift();
    if (key && /^[a-zA-Z]/.test(key) && /^\d+$/.test(term)) {
      result.params[key] = Number(term);
    }
    key = term;
  }
  if (/^[a-zA-Z]/.test(key)) {
    result.verb = key;
  }
  return result;
}

function parseValue(val) {
  const test = typeof val === 'string' ? val.toLowerCase() : val;
  switch (test) {
    case undefined:
    case 'true':
      return true;
    case 'null':
    case 'false':
      return false;
    default:
  }
  const numVal = Number(val);
  return (Number.isNaN(numVal) || numVal.toString() !== val) ? decodeURIComponent(val) : numVal;
}

export function parseString(str, raw) {
  const result = {};
  str.replace(/^[#?]/, '').trim().split('&').forEach((item) => {
    const [key, encodedValue] = item.split('=');
    const value = encodedValue && decodeURIComponent(encodedValue);
    if (key) {
      result[key] = raw ? value : parseValue(value);
    }
  });
  return result;
}

export function strToLink(str) {
  const match = str && linkify().match(str);
  if (!match || match.length === 0) {
    return undefined;
  }
  return match[0].url;
}

export function getParam(url, param) {
  const re = new RegExp(`[?&]${param}=([^&]+)`);
  const match = url.match(re);
  return match ? decodeURIComponent(match[1]) : undefined;
}

const useLocationPart = (part, raw) => {
  const prefix = PREFIXES[part];
  if (!prefix) {
    throw new Error(`Unknown location part ${part}`);
  }
  const location = useLocation();
  const navigate = useNavigate();
  const asObject = parseString(location[part], raw);
  function setValue(o, v) {
    if (typeof o === 'string') {
      // Update property o
      const result = v === undefined ? omit(asObject, [o]) : { ...asObject, [o]: v };
      return setValue(result);
    }
    const str = serialize(o);
    const newLocation = { ...location, [part]: str ? `${prefix}${str}` : '' };
    return `${location.pathname}${newLocation.search}${newLocation.hash}`;
  }
  return {
    ...asObject,
    push: (o, v) => navigate(setValue(o, v)),
    replace: (o, v) => navigate(setValue(o, v), { replace: true }),
  };
};

export function makeUrl(str) {
  if (!str || /^\w+:/.test(str)) {
    return str;
  }
  if (str.includes('@')) {
    return `mailto:${str}`;
  }
  return `https://${str}`;
}

export const useHash = (raw) => useLocationPart('hash', raw);
export const useSearch = (raw) => useLocationPart('search', raw);
