/**
*
* fixedBottomStyle
*
*/

import { useMeasure } from 'react-use';

function getStyle(height) {
  const maxHeight = `calc(100vh - ${height}px)`;
  return {
    maxHeight,
    overflowY: 'auto',
    paddingBottom: '1px', // as list-items have bottom-margin -1px
  };
}

function fixedBottomStyle(bottom, elem) {
  const top = elem ? elem.getBoundingClientRect().y : 0;
  return getStyle(top + bottom);
}

export function useFixedBottomStyle(bottom) {
  const [ref, { top }] = useMeasure();
  const style = getStyle(top + bottom);
  return [ref, style];
}

export default fixedBottomStyle;
