/* global Userlane */
import { useEffect, useRef } from 'react';
import { get } from 'lodash-es';
import useLocaleContext from 'containers/LanguageProvider/useLocaleContext.js';
import useCurrentUser from 'containers/User/useCurrentUser.js';
import { EMPLOYEE_ROLE } from 'containers/User/constants.js';
import { canManageGroups } from 'containers/HomePage/permissions.js';
import usePortfolioStatus from 'components/portfolio/usePortfolioStatus.js';
import { PORTFOLIO_STATUS_PILOT, PORTFOLIO_STATUS_SUBSCRIBER } from 'components/portfolio/constants.js';
import useDragElement from 'utils/useDragElement.js';

const userlaneStatus = { loaded: false };

function loadUserlane() {
  if (userlaneStatus.loaded) {
    return;
  }
  userlaneStatus.loaded = true;
  /* eslint-disable */
  (function (i, s, o, g, r, a, m) { i['UserlaneCommandObject'] = r; i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments) }; a = s.createElement(o), m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m) })(window, document, 'script', 'https://cdn.userlane.com/userlane.js', 'Userlane');
  /* eslint-enable */
}

function useUserlane() {
  const user = useCurrentUser();
  const { locale } = useLocaleContext();
  const info = useRef({});
  const portfolioStatus = usePortfolioStatus();
  const isLoaded = 'UserlaneCommandObject' in window;
  const roleType = get(user, 'role.type');
  const userProfileId = get(user, 'profile.userProfileId');
  const isEmployee = roleType === EMPLOYEE_ROLE;
  const isManager = canManageGroups(user.role);
  useDragElement('#userlane-assistant-container');

  function loggedUserlane(action, value) {
    switch (action) {
      case 'init':
        console.log('Userlane init', info.current);
        break;
      case 'hide':
        console.log('Userlane hide');
        break;
      default:
        info.current[action] = value;
    }
    Userlane(action, value);
  }

  function setLanguage() {
    loggedUserlane('lang', locale);
  }

  useEffect(() => {
    console.log('Portfolio Status', portfolioStatus);
  }, [portfolioStatus]);

  useEffect(() => {
    if (!roleType) {
      return;
    }
    // check and disable if role changed to non-employee
    if (!isEmployee) {
      if (isLoaded) {
        loggedUserlane('hide');
      }
      return;
    }
    if (!isLoaded) {
      loadUserlane();
      setLanguage();
    }
    loggedUserlane('identify', `userProfileId-${userProfileId}`);
    if (isManager) {
      loggedUserlane('tag', 'socialCommunity');
    }
    switch (portfolioStatus) {
      case PORTFOLIO_STATUS_SUBSCRIBER:
        loggedUserlane('tag', 'portfolioSubscriber');
        // console.log('Userlane Tag', 'portfolioSubscriber');
        break;
      case PORTFOLIO_STATUS_PILOT:
        loggedUserlane('tag', 'portfolioPilot');
        // console.log('Userlane Tag', 'portfolioPilot');
        break;
      default:
        // console.log('Userlane No Tag');
    }
    loggedUserlane('init', 32653);
  }, [roleType, userProfileId, portfolioStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoaded) {
      setLanguage();
    }
  }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useUserlane;
