/**
*
* Userlane
*
*/

import React from 'react';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import { isSmallScreen } from 'utils/checkMedia.js';
import useUserlane from './useUserlane.js';

const enabled = !isSmallScreen();

function ApplyUserlane() {
  useUserlane();
  return null;
}

function Userlane() {
  const role = useCurrentRole();
  return enabled && role ? <ApplyUserlane /> : null;
}

export default Userlane;
