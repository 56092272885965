/**
 *
 * UserHeader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from 'components/media/Avatar/index.jsx';
import UserText from 'components/media/UserText/Loadable.js';
import cx from 'utils/cx.js';
import Snoozing from '../Snoozing.jsx';

const Wrapper = styled.div`
  max-width: 360px;
`;

function UserHeader(props) {
  const { user, onEdit, className } = props;
  if (user === undefined || user.error) {
    return null;
  }
  const name = !user.loading && (user.accountName || user.displayName || user.alias || `${user.firstName} ${user.lastName}`);
  return (
    <Wrapper className={cx(className, 'd-flex flex-column align-items-center text-center m-auto mt-4 mb-3')}>
      <Avatar bsSize="xlarge" src={user.avatar} onEdit={onEdit} />
      <h1 className="fs-1 mt-4">{name}</h1>
      {user.aboutMe && <div className="mt-3"><UserText className="text-white">{user.aboutMe}</UserText></div>}
      <Snoozing className="fw-light mt-3" user={user} />
    </Wrapper>
  );
}

UserHeader.propTypes = {
  user: PropTypes.object,
  onEdit: PropTypes.func,
  className: PropTypes.string,
};

export default UserHeader;
