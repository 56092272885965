/*
 * InfiniteScroll Messages
 *
 * This contains all the text for the InfiniteScroll component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  loadMore: {
    id: 'app.components.InfiniteScroll.loadMore',
    defaultMessage: 'Load More',
  },
  loadPrevious: {
    id: 'app.components.InfiniteScroll.loadPrevious',
    defaultMessage: 'Load Previous',
  },
});
