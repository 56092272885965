import en from 'date-fns/locale/en-GB';
import nl from 'date-fns/locale/nl';
import storage from 'utils/storage/index.js';
import { DEFAULT_LOCALE, LOCALE_KEY } from 'containers/LanguageProvider/constants.js';

const locales = { en, nl };

export default locales;

export function getLocale() {
  const key = storage.getItem(LOCALE_KEY) || DEFAULT_LOCALE;
  return locales[key];
}
