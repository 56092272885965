/*
 * ArchivedCommunities Messages
 *
 * This contains all the text for the ArchivedCommunities component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.AdminGroups.viewArchive',
    defaultMessage: 'View Archive',
  },
});
