/**
 *
 * ContacDetails
 * Show contact details for user
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import hasData from 'utils/hasData.js';
import { Card } from 'react-bootstrap';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';

import { FormattedMessage } from 'react-intl';
import messages from '../messages.js';

const ContactBody = styled(Card.Body)`
  .avatar-icon {
    width: 20px;
    color: ${(props) => props.theme.brandPrimary}
  }
  .avatar-text a {
    overflow-wrap: anywhere;
  }
`;

function ContactDetails(props) {
  const { user } = props;
  const contactInfo = [];
  if (hasData(user.address)) {
    contactInfo.push({ icon: 'ss-home', text: addressString(user.address) });
  }
  if (hasData(user.emailAddresses)) {
    contactInfo.push({ icon: 'ss-mail', text: user.emailAddresses.map(emailLink) });
  }
  if (hasData(user.phoneNumbers)) {
    contactInfo.push({ icon: 'ss-phone', text: user.phoneNumbers.join(', ') });
  }
  return (
    <Card className="border-0">
      <Card.Header>
        <h2 className="card-title"><FormattedMessage {...messages.contactDetails} /></h2>
      </Card.Header>
      <ContactBody className="d-flex flex-column flex-sm-row align-items-between px-0 me-n3">
        { contactInfo.map((item) => <AvatarWithText key={item.icon} bsSize="small" icon={item.icon} className="me-3">{item.text}</AvatarWithText>)}
        { contactInfo.length === 0 && <FormattedMessage {...messages.noContactInfo} /> }
      </ContactBody>
    </Card>
  );
}

ContactDetails.propTypes = {
  user: PropTypes.object,
};

export default ContactDetails;

function addressString(address) {
  return (
    <span>
      {address.street}
      {' '}
      {address.streetNumber}
      {address.streetNumberAdd || ''}
      <br />
      {address.postalCode}
      {address.city}
    </span>
  );
}

function emailLink(address) {
  return <a key={address} href={`mailto:${address}`}>{address}</a>;
}
