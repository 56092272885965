/*
* StudentPortfolio
* @desc: get the workspace id for the student and redirect
*/
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApi2 from 'containers/Api2/useApi2.js';
import useCurrentRole from 'containers/User/useCurrentRole.js';

function StudentPortfolio() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const role = useCurrentRole();
  const { sid, '*': path } = useParams();
  const studentId = sid ? Number(sid) : role?.id;
  const workspaces = useApi2({ url: `portfolio/students/${studentId}`, selector: '/portfolio/workspaces' });
  const workspaceId = workspaces.list?.find((id) => workspaces[id].student.id === studentId);

  useEffect(() => {
    if (workspaceId) {
      const newPath = pathname.replace(/portfolio\/.*/, `portfolio/${workspaceId}/${path}`);
      navigate(newPath, { replace: true });
    }
  }, [workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export default StudentPortfolio;
