import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';
import IntlString from 'components/media/IntlString/index.jsx';
import { DIALOG_ALERT, DIALOG_CONFIRM, DIALOG_PROMPT } from './constants.js';
import messages from './messages.js';

const ModalFooter = styled(Modal.Footer)`
    display: flex;
    justify-content: flex-end;
    border-top: none;
    .btn {
      min-width: 4em;
    }
    .btn + .btn {
      margin-left: 15px;
    }
`;

const defaultButtons = [
  { label: messages.cancel, value: false, style: 'default' },
  { label: messages.ok, value: true, style: 'primary' },
];

const yesNoButtons = [
  { label: messages.no, value: false, style: 'danger' },
  { label: messages.yes, value: true, style: 'success' },
];

function DialogFooter(props) {
  const { modalProps, modalType, disabled, onClose } = props;
  const { yesNo, confirmLabel, confirmStyle } = modalProps;
  function renderButtons() {
    switch (modalType) {
      case DIALOG_ALERT:
        return <Button type="button" variant="primary" autoFocus onClick={() => onClose()}>OK</Button>;
      case DIALOG_CONFIRM: {
        const buttons = yesNo ? yesNoButtons : defaultButtons;
        return buttons.map((button) => (
          <Button key={button.value} type="button" variant={(button.value && confirmStyle) || button.style} onClick={() => onClose(button.value)}>
            <IntlString message={button.value && confirmLabel ? confirmLabel : button.label} />
          </Button>
        ));
      }
      case DIALOG_PROMPT:
        return defaultButtons.map((button) => (
          <Button
            key={button.value}
            type="button"
            variant={button.style}
            disabled={disabled && button.value}
            onClick={() => onClose(button.value)}
          >
            <IntlString message={button.label} />
          </Button>
        ));
      default:
        return null;
    }
  }
  return <ModalFooter>{renderButtons()}</ModalFooter>;
}

DialogFooter.propTypes = {
  modalProps: PropTypes.object,
  modalType: PropTypes.number,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DialogFooter;
