import parseGoal from 'components/portfolio/Goals/GoalEditor/parseGoal.js';
import { idList } from 'utils/arrays.js';
import naturalSort from 'utils/naturalSort.js';

function reduceCategories(state, action) {
  const { payload } = action;
  const result = { ...state, ...payload, goals: {} };
  function parseCategory(cat, sourcePath = []) {
    const path = [...sourcePath, cat.id];
    result[cat.id] = {
      ...cat,
      path,
      goals: idList(cat.goals),
      children: idList(cat.children),
    };
    cat.goals.forEach((g) => {
      result.goals[g.id] = { ...parseGoal(g), path, categoryId: cat.id };
    });
    cat.children.forEach((c) => {
      parseCategory(c, path);
    });
  }
  payload.list.forEach((id) => {
    parseCategory(payload[id]);
  });
  if (result.list) {
    result.list = result.list.slice(0).sort((a, b) => naturalSort(result[a].title, result[b].title));
  }
  return result;
}

export default reduceCategories;
