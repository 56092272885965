/**
 *
 * Connections
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';

import messages from '../messages.js';

function Connections(props) {
  const { user, type, getButton, className } = props;
  if (!user.connections || user.connections.length === 0) {
    return null;
  }
  return (
    <Card className={className}>
      <Card.Header>
        <Card.Title as="h2"><FormattedMessage {...messages[type]} /></Card.Title>
      </Card.Header>
      <ListGroup variant="flush" className="mb-2">
        {user.connections.map(((conn) => (
          <ListGroupItem key={conn.id} className="d-flex">
            <div className="d-flex align-items-center flex-grow-1">
              <a href={`#user=${conn.id}${conn.roleType}`}>
                <AvatarWithText src={conn.avatar} align="center">
                  {conn.displayName}
                </AvatarWithText>
              </a>
              <span>{conn.groups && conn.groups.map((g) => g.name).join(', ')}</span>
            </div>
            {getButton && getButton(conn)}
          </ListGroupItem>
        )))}
      </ListGroup>
    </Card>
  );
}

Connections.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  className: PropTypes.string,
  getButton: PropTypes.func,
};

export default Connections;
