/**
 *
 * ScreenWrapper
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PosAbsoluteFull from 'components/layout/PosAbsoluteFull/index.js';
import { useMeasure } from 'react-use';

const Wrapper = styled.header`
  background-image: url(${(props) => props.theme.bannerBackground});
  background-position: center center;
  background-size: cover;
  background-color: ${(props) => props.theme.contentBg};

  height: ${(p) => p.height}px;
  max-height: ${(p) => p.height}px;
  flex: 0 0 ${(p) => p.height}px;
  position: relative;
  .avatar-icon {
     background-color: ${(props) => props.theme.bodyBg};
  }
  @media print {
    & > div {
      position: static;
    }
    .btn {
      display: none;
    }
    .avatar-text {
      max-width: none;
    }
  }
`;

const Screen = styled(PosAbsoluteFull)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px 15px;
  img {
    background-color: #fff;
    padding: 2px;
    border-radius: 50%;
  }
  .ss-camera {
    opacity: 0.5;
  }
  background: linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0));
  & > * {
    width: 908px;
    max-width: 100%;
  }
  overflow: hidden;
`;

function ScreenWrapper(props) {
  const { children, aspectRatio } = props;
  const [ref, { width }] = useMeasure();
  const height = aspectRatio ? width / aspectRatio : 160;
  return <Wrapper ref={ref} height={height} className="no-webview"><Screen>{children}</Screen></Wrapper>;
}

ScreenWrapper.propTypes = {
  children: PropTypes.node,
  aspectRatio: PropTypes.number,
};

export default ScreenWrapper;
