/**
*
* Transition
* className will be className-0 after trigger changes, changes to className-1 after delays ms, className-2 etc if more delays
*
*/

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Effects from './Effects.js';
// import styled from 'styled-components';

function Transition(props) {
  const { children, trigger, className, delays, initial } = props;
  const [count, setCount] = useState(0);
  const enabled = useRef(initial);
  const timer = useRef(null);

  function resetTimer() {
    if (timer.current) {
      window.clearTimeout(timer.current);
      timer.current = null;
    }
  }

  function doStep(step) {
    const delay = delays[step];
    const nextStep = (step + 1) % (delays.length + 1);
    setCount(nextStep);
    if (nextStep > 0) {
      timer.current = window.setTimeout(() => {
        doStep(nextStep);
        timer.current = null;
      }, delay);
    }
  }

  // Reset timer when unmounting
  useEffect(() => resetTimer, []);

  // Start effect when trigger changes
  useEffect(() => {
    if (!enabled.current) {
      enabled.current = true;
      return;
    }
    resetTimer();
    doStep(0);
  }, [trigger]);

  return (
    <Effects className={`${className}-${count}`}>
      {children}
    </Effects>
  );
}

Transition.propTypes = {
  children: PropTypes.node,
  trigger: PropTypes.any,
  className: PropTypes.string,
  delays: PropTypes.array,
  initial: PropTypes.bool,
};

export default Transition;
