/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import '@formatjs/intl-pluralrules/polyfill.js';
import '@formatjs/intl-pluralrules/locale-data/nl.js';
import '@formatjs/intl-pluralrules/locale-data/en.js';

import { DEFAULT_LOCALE } from './containers/App/constants.js'; // eslint-disable-line
import enTranslationMessages from './translations/en.json' assert { type: "json" }; // eslint-disable-line
import nlTranslationMessages from './translations/nl.json' assert { type: "json" }; // eslint-disable-line

const defaultTranslationsMessages = DEFAULT_LOCALE === 'nl' ? nlTranslationMessages : enTranslationMessages;

export const appLocales = [
  'en',
  'nl',
];

export const formatTranslationMessages = (locale, messages) => {
  if (locale === DEFAULT_LOCALE) return messages;
  const defaultFormattedMessages = locale !== DEFAULT_LOCALE
    ? formatTranslationMessages(DEFAULT_LOCALE, defaultTranslationsMessages)
    : {};
  const formattedMessages = { ...messages };
  Object.entries(messages).forEach(([key, message]) => {
    if (!message) {
      formattedMessages[key] = defaultFormattedMessages[key];
    }
  }, {});
  return formattedMessages;
};

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  nl: formatTranslationMessages('nl', nlTranslationMessages),
};
