/**
 * sessionConsole
 * Writes to console and keeps in sessionStorage
 * On leaving pages, show recent messages on startup again.
 */

import { TEST_OIDC } from 'containers/User/constants.js';

/* eslint-disable no-console */
const storage = window.sessionStorage;
const isTop = window.window.self === window.top;
const MAX_SIZE = 50;

function expired(date) {
  return Date.now() - date.getTime() > 60 * 1000;
}

const logList = window.location.pathname === '/' ? [] : JSON.parse(storage.getItem('console') || '[]')
  .map((item) => ({ ...item, ts: new Date(item.ts) }))
  .filter((item) => !expired(item.ts));

function save() {
  storage.setItem('console', JSON.stringify(logList));
}

function push(level, args) {
  console[level](...args);
  logList.push({
    level, args, ts: new Date(),
  });
  while (logList.length > MAX_SIZE) {
    logList.shift();
  }
  save();
}

if (TEST_OIDC) {
  logList.forEach(({ level, ts, args }) => {
    console[level](ts.toLocaleTimeString(), ...args);
  });
}

const sessionConsole = {};

Object.keys(console)
  .filter((func) => typeof console[func] === 'function')
  .forEach((func) => {
    sessionConsole[func] = (...args) => push(func, args);
  });

export default sessionConsole;

console.session = sessionConsole;
const self = isTop ? 'top' : 'iframe';
push('log', ['location', self, window.location.pathname, window.location.search, window.location.hash]);
