/*
 * Checklist Messages
 *
 * This contains all the text for the Checklist component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  select: {
    id: 'app.components.Checklist.select',
    defaultMessage: 'Select/Deselect all',
  },
});
