/**
*
* ConversationIcon
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/media/Icon/index.js';
import { asShade } from 'utils/tinyFunction.js';
// import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 50%;
  height: 36px;
  min-width: 36px;
  text-align: center;
  color: ${(p) => p.color || p.theme.brandPrimary};
  background: ${(p) => p.color ? asShade(p.color) : p.theme.contentBg};
  i.ss-usergroup {
    margin-top: 8px;
    font-size: 24px;
  }
  i.ss-user-lock {
    margin-top: 10px;
    font-size: 14px;
  }
`;

function ConversationIcon(props) {
  const { isPrivate, className, color } = props;
  const iconClass = isPrivate ? 'ss-user-lock' : 'ss-usergroup';
  return <Wrapper className={className} color={color}><Icon className={iconClass} /></Wrapper>;
}

ConversationIcon.propTypes = {
  isPrivate: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
};
export default ConversationIcon;
