/**
*
* FormControlWrapper
* Accepts a className prop to handle is-invalid class, optionally adds border and spacing
* border = 0 (default) no border, 1 border, 2 border and spacing
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function FormControlWrapper(props) {
  const { className, border, ...rest } = props;
  const classSet = new Set(className.split(' '));
  const isInvalid = classSet.has('is-invalid');
  classSet.delete('form-control');
  const invalidClasses = {
    'form-control rounded-4': border > 0,
    'py-0 ps-0': border === 1,
    'mx-n2 py-2 ps-2': border === 2,
  };
  const classList = classNames([Array.from(classSet), isInvalid && invalidClasses]);
  return <div className={classList} {...rest} />;
}

FormControlWrapper.propTypes = {
  className: PropTypes.string,
  border: PropTypes.oneOf([0, 1, 2]),
};

FormControlWrapper.defaultProps = {
  className: '',
  border: 0,
};

export default FormControlWrapper;
