/*
 * LoadMore Messages
 *
 * This contains all the text for the LoadMore component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'app.common.cancel',
    defaultMessage: 'Cancel',
  },
  submit: {
    id: 'app.common.submit',
    defaultMessage: 'Submit',
  },
  save: {
    id: 'app.common.save',
    defaultMessage: 'Save',
  },
});
