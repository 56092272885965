/**
 *
 * SideMenuListGroup
 *
 */

// import React from 'react';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';

const SideMenuListGroup = styled(ListGroup)`
  .list-group-indent {
    @media (min-height: 640px) {
      overflow-y: auto;
    }
    .list-group-item {
      padding-left: 2rem;
      &:not(.active) {
        background-color: ${(p) => p.theme.listGroupHoverBg};
      }
      &:hover {
        background-color: ${(p) => p.theme.grayLighter};
      }
    }
  }
  .list-group-item.list-group-header.list-group-header {
      font-size: smaller;
      background-color: ${(p) => p.theme.grayLighter};
      padding-top: 5px;
      padding-bottom: 5px;
    }
`;

export default SideMenuListGroup;
