import { useEffect } from 'react';
import { focusManager } from '@tanstack/react-query';

function useQueryFocusManager(focus) {
  useEffect(() => {
    focusManager.setEventListener(() => () => ({}));
  }, []);

  useEffect(() => {
    focusManager.setFocused(focus);
  }, [focus]);
}

export default useQueryFocusManager;
