/**
 *
 * Input
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import Textarea from 'react-textarea-autosize';
import { omit } from 'lodash-es';
import { withEmoji } from 'components/interaction/EmojiPicker/index.jsx';
import placeholderMixin from 'styles/placeholderMixin.js';
import colorSvg from 'utils/colorSvg.jsx';

import { inputBorderRadius, inputHeight } from 'utils/bsStyler.js';
import { useReplaceIntlProps } from 'utils/replaceIntlProps.js';
import hasClass from 'utils/hasClass.js';
import messages from './messages.js';

function getStyledSearch(p) {
  const bsOpen = hasClass(p.className, 'bs-open');
  return colorSvg('search', bsOpen && p.theme.brandPrimary);
}

export const inputMixin = css`
  background-color: ${(props) => props.theme.inputBg};
  border: 1px solid ${(props) => props.theme.inputBorder};
  &:focus {
    border: 1px solid ${(props) => props.theme.inputBorderFocus};
  }
  color: ${(props) => props.theme.inputColor};
  &::placeholder,
   &.input-placeholder,
   .CodeMirror-placeholder,
   .input-placeholder {
     ${placeholderMixin};
  }
  option {
    color: ${(props) => props.theme.inputColor};
    font-style: normal;
  }
  opacity: ${(props) => props.disabled ? 0.5 : 1};
  border-radius: ${(props) => inputBorderRadius(props)};
`;

export const StyledTextarea = styled(Textarea).attrs((p) => ({ minRows: p.rows || 3 }))`
  padding: ${(props) => `${props.theme.paddingBaseVertical} ${props.theme.paddingBaseHorizontal}`};
  ${inputMixin}
`;

const StyledInput = styled.input`
  height: ${inputHeight};
  padding: ${(props) => `1px ${props.theme.paddingLargeHorizontal}`};
  ${inputMixin}
`;

export const SearchInput = styled(StyledInput)`
  background: url(${getStyledSearch}) 1em center no-repeat;
  background-size: 1em;
  padding-left: 2.5em;
`;

export function MyInput(props) {
  const intl = useIntl();
  const { type } = props;
  const intlProps = useReplaceIntlProps(props, ['placeholder', 'aria-label']);
  const newProps = omit(intlProps, ['onPopup']);
  switch (type) {
    case 'textarea':
      return <StyledTextarea maxLength={2000} {...newProps} />;
    case 'search':
      return <SearchInput placeholder={intl.formatMessage(messages.search)} {...newProps} />;
    default:
      return <StyledInput maxLength={250} {...newProps} />;
  }
}

MyInput.propTypes = {
  type: PropTypes.string,
};

export default withEmoji(MyInput);
