/**
 *
 * CalendarLink
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import messages from './messages.js';
import PathItem from './PathItem.jsx';

function CalendarLink(props) { // eslint-disable-line react/prefer-stateless-function
  const { pathname } = props;

  return (
    <PathItem to="/calendar">
      <AvatarWithText icon="ss-calendar" active={pathname.startsWith('/calendar')}>
        <FormattedMessage {...messages.calendar} />
      </AvatarWithText>
    </PathItem>
  );
}

CalendarLink.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default CalendarLink;
