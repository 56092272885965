import axios from 'axios';
import sanitize from 'sanitize-filename';

export async function upload(item, api2fetch, onChange, onError) {
  const uploadItem = { ...item };
  try {
    const result = await getUploadUrl(item.file.name, api2fetch);
    uploadItem.data = result;
    onChange({ ...uploadItem, progress: 0, loading: true });
    await uploadToUrl(uploadItem, onChange);
    onChange({ ...uploadItem, progress: 100, loading: false });
  } catch (error) {
    onChange({ ...uploadItem, error: error.message });
    if (onError) {
      onError(error);
    }
  }
}

export async function getUploadUrl(filename, api2fetch) {
  return api2fetch({
    url: `media/uploads/RequestSignedUploadUrl?fileName=${encodeURIComponent(sanitize(filename))}`,
  });
}

export async function uploadToUrl(item, onChange) {
  const config = {
    url: item.data.uploadUrl,
    method: 'PUT',
    headers: {},
    data: item.file.blob || item.file,
    onUploadProgress: (ev) => onChange({ ...item, progress: calcProgress(ev), loading: true }),
    transformRequest: [(data, headers) => {
      // remove content-type header not possible, setting empty string
      headers.setContentType('', true);

      return data;
    }],
  };

  return axios(config);
}

export function calcProgress(ev) {
  return ev.total && Math.floor(100.0 * (ev.loaded / ev.total));
}
