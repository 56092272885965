/**
 *
 * Sidebar
 *
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import useGroupFromPathname from 'containers/Api2/useGroupFromPathname.js';
import { STUDENT_ROLE, MODULES_PHOTOBOOK } from 'containers/User/constants.js';
import { useUiControl } from 'containers/UiControl/index.js';
import useCurrentUser from 'containers/User/useCurrentUser.js';
import SideMenu from 'components/SideMenu/index.jsx';
import StudentMenu from 'components/SideMenu/StudentMenu/index.jsx';
import PhotobookLink from 'components/PhotoBook/PhotobookLink.jsx';

const SidebarDiv = styled.div`
  & > * {
    width: var(--bs-offcanvas-width);
  }
  position: relative;   
  display: flex;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid ${(props) => props.theme.listGroupBorder};
  margin-left: ${(p) => p.shifted ? '-240px' : 0};
  transition: margin-left ${(props) => props.theme.transitionDuration};
  button.list-group-item, a.list-group-item {
    &.list-group-item {
      align-items: center;
      &, &:hover, &:focus {
        color: inherit;
      }
    }
  }
`;

const StyledOffcanvas = styled(Offcanvas)`
 @media (min-width: ${(p) => p.theme.screenMdMin}) {
  min-width: var(--bs-offcanvas-width);
  .offcanvas-body {
    position: fixed;
    height: calc(100vh - 120px);
  }
}
`;

function Sidebar(props) {
  const { communities, show, onHide } = props;
  const { pathname } = useLocation();
  const [sbExpanded] = useUiControl('sbExpanded');
  const user = useCurrentUser();
  const { type, school } = user.role;
  const isManage = pathname.startsWith('/manage/');
  const pathRef = useRef(pathname);
  if (!isManage) {
    pathRef.current = pathname;
  }

  const groupsMatch = /^\/groups\/\d+/.test(pathname);
  const admin = pathname.startsWith('/admin');
  const { group, portfolioGroup } = useGroupFromPathname(pathname);
  const hasGroup = groupsMatch && Boolean(group || portfolioGroup);
  const hasPhotobook = type !== STUDENT_ROLE && school.modules.includes(MODULES_PHOTOBOOK);

  return (
    <StyledOffcanvas show={show} onHide={onHide} responsive="lg" placement="start" className="h-100vh">
      <Offcanvas.Body className="overflow-y-hidden d-flex flex-column">
        <SidebarDiv className="flex-grow-1" shifted={hasGroup}>
          <SideMenu communities={communities} aria-hidden={hasGroup} role={user.role} pathname={pathRef.current} expanded={sbExpanded} />
          {hasGroup && <StudentMenu pathname={pathRef.current} />}
        </SidebarDiv>
        {hasPhotobook && !admin && <PhotobookLink />}
      </Offcanvas.Body>
    </StyledOffcanvas>
  );
}

Sidebar.propTypes = {
  communities: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default Sidebar;
