import colors from 'material-colors';
import tinycolor from 'tinycolor2';
import { omit } from 'lodash-es';
import { fillArray } from './arrays.js';

export function getColors(options = {}) {
  const names = Object.keys(colors).slice(0, 16);
  let list = getColorsByNames(names);
  if (options.filter) {
    list = list.filter(options.filter);
  }
  if (options.count) {
    const base = list.length / options.count;
    const indices = fillArray(options.count, (i) => Math.floor(i * base));
    list = indices.map((i) => list[i]);
  }
  if (options.shuffle) {
    list = shuffle(list);
  }
  return list;
}

export const contrastColors = getColors({ shuffle: true, filter: isDark(0.5) });

export function getColorsByNames(names) {
  return names
    .map((id) => ({ id, value: colors[id]['500'] }));
}

export function isDark(limit = 0.25) {
  return (clr) => tinycolor(clr.value).getLuminance() <= limit;
}

export function isLight(limit = 0.25) {
  return (clr) => tinycolor(clr.value).getLuminance() > limit;
}

export function getColorSpread(n) {
  const list = fillArray(n, (i) => tinycolor({ h: (i * 360) / n, s: 0.8, l: 0.5 }).toHexString());
  return list;
}

export function shuffle(list) {
  const sortList = fillArray(list.length);
  const n = Math.ceil(Math.log2(list.length));
  const reverseList = sortList.map((i) => reverseBits(i, n));
  sortList.sort((a, b) => reverseList[a] - reverseList[b]);
  return sortList.map((i) => list[i]);
}

export function reverseBits(v, n) {
  const bits = v.toString(2).padStart(n, '0');
  const reversed = bits.split('').reverse().join('');
  return Number.parseInt(reversed, 2);
}

let colorIndex = 0;
const idColors = {};

export function getColorForId(id) {
  if (idColors[id] === undefined) {
    idColors[id] = toRgb(contrastColors[colorIndex]);
    colorIndex = (colorIndex + 1) % contrastColors.length;
  }
  return idColors[id];
}

export function toRgb(obj) {
  const hex = obj.value;
  return { ...omit(tinycolor(hex).toRgb(), ['a']), hex };
}
