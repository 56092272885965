/*
 * Iewarning Messages
 *
 * This contains all the text for the Iewarning component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.Iewarning.title',
    defaultMessage: 'Browser Not Fully Supported',
  },
  update: {
    id: 'app.components.Iewarning.update',
    defaultMessage: 'You are using Internet Explorer. This browser is no longer fully supported. Please use Edge instead or another modern browser (Chrome, Firefox).',
  },
});
