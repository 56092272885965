/**
 *
 * Dialog
 * based on https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680#35641680
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { Modal } from 'react-bootstrap';
import IntlString from 'components/media/IntlString/index.jsx';
import injectReducer from 'utils/injectReducer.jsx';
import injectSaga from 'utils/injectSaga.jsx';
import { DAEMON } from 'utils/constants.js';
import makeSelectDialog from './selectors.js';
import { hideDialog } from './actions.js';
import reducer from './reducer.js';
import saga from './saga.js';
import { DIALOG_ALERT, DIALOG_PROMPT } from './constants.js';
import DialogHeader from './DialogHeader.jsx';
import DialogFooter from './DialogFooter.jsx';
import DialogMessage from './DialogMessage.jsx';
import DialogPrompt from './DialogPrompt.jsx';

function Dialog(props) {
  const { onClose, dialog } = props;
  const { modalType, modalProps, promise } = dialog;
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue(modalType && (modalProps.value || ''));
  }, [modalType]);

  function handleClose(result) {
    const finalResult = modalType === DIALOG_PROMPT ? result && value : result;
    return onClose(finalResult, promise);
  }

  if (!modalType) {
    return null;
  }
  const { title, message, required, help } = modalProps;
  const backdrop = modalType === DIALOG_ALERT || 'static';
  const size = modalProps.size || 'sm';
  const bsSize = ['lg', 'sm', 'xl'].includes(size) ? size : undefined;
  const disabled = modalType === DIALOG_PROMPT && required && !value;
  return (
    <Modal show size={bsSize} backdrop={backdrop} onHide={() => onClose()}>
      <DialogHeader title={title} />
      {(message || modalType === DIALOG_PROMPT) && (
        <Modal.Body className="narrow">
          <DialogMessage {...modalProps} />
          {modalType === DIALOG_PROMPT && <DialogPrompt {...modalProps} value={value || ''} setValue={setValue} />}
          {help && <div className="text-muted small mt-3"><IntlString message={help} /></div>}
        </Modal.Body>
      )}
      <DialogFooter {...dialog} disabled={disabled} onClose={handleClose} />
    </Modal>
  );
}

Dialog.propTypes = {
  dialog: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  dialog: makeSelectDialog(),
});

function mapDispatchToProps(dispatch) {
  return {
    onClose: (result, promise) => dispatch(hideDialog(result, promise)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'dialog', reducer });
const withSaga = injectSaga({ key: 'dialog', saga, mode: DAEMON });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Dialog);
