/**
*
* useUpdateAvatar
*
*/

import { omit } from 'lodash-es';
import { useApi2call } from 'containers/Api2/index.js';
import messages from './messages.js';

function useUpdateAvatar(options) {
  const api2call = useApi2call();

  function updateAvatar(item) {
    if (item) {
      const { preview, ...body } = item;
      return api2call({
        ...options,
        method: 'PUT',
        body,
        success: messages.avatarUpdated,
        reducer: (state) => ({ ...state, avatar: preview }),
        withPromise: true,
      });
    }
    return api2call({
      ...options,
      method: 'DELETE',
      success: messages.avatarRemoved,
      reducer: (state) => omit(state, ['avatar']),
      withPromise: true,
    });
  }

  return updateAvatar;
}

export default useUpdateAvatar;
