/*
 * AskMaterials Messages
 *
 * This contains all the text for the AskMaterials component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  askFirst: {
    id: 'app.components.AskMaterials.askFirst',
    defaultMessage: 'Ask for materials/volunteers',
  },
  askMore: {
    id: 'app.components.AskMaterials.askMore',
    defaultMessage: 'More materials/volunteers',
  },
  whoWhat: {
    id: 'app.components.AskMaterials.whoWhat',
    defaultMessage: 'What or whom do you need?',
  },
  number: {
    id: 'app.components.AskMaterials.number',
    defaultMessage: 'Number',
  },
  hasBackers: {
    id: 'app.components.AskMaterials.hasBackers',
    defaultMessage: 'Volunteers registered already',
  },
  doDelete: {
    id: 'app.components.AskMaterials.doDelete',
    defaultMessage: 'Delete anyway?',
  },
  remove: {
    id: 'app.common.delete',
    defaultMessage: 'Delete',
  },
});
