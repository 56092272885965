function currentUserParser(profile) {
  const roles = profile.roles.reduce((acc, role) => ({
    ...acc,
    [role.uniqueId]: role,
    list: [...acc.list, role.uniqueId],
  }), { list: [] });
  return { ...profile, roles };
}

export default currentUserParser;
