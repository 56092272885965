/**
 *
 * Api2
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { isEqual, find } from 'lodash-es';
import memoize from 'fast-memoize';
import { getCurrentRole } from 'containers/User/UserProvider/userStorage.js';
import replaceParams from 'utils/replaceParams.js';
import sameRole from 'utils/sameRole.js';
import makeSelectApi2 from './selectors.js';

import { api2call, api2update } from './actions.js';
import { withDefaults, fetchable, substitute } from './parseOptions.js';

function api2builder(entities = []) {
  const parsedEntities = entities.map(withDefaults);
  class Api2 extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    componentDidMount() {
      this.fetch();
    }

    componentDidUpdate(prevProps) {
      this.fetch(prevProps);
    }

    fetch(prevProps) {
      const { role, api2, reset, params } = this.props;
      if (prevProps && sameRole(role, prevProps.role) && isEqual(params, prevProps.params)) {
        return;
      }
      parsedEntities.filter(fetchable).map(substitute(params)).forEach((entity) => {
        if (entity.public || role) {
          api2({ ...entity, params });
          return;
        }
        if (prevProps) {
          reset(entity);
        }
      });
    }

    // eslint-disable-next-line react/sort-comp
    loadMore = memoize((name) => () => {
      if (window.matchMedia('print').matches) {
        return;
      }
      const { api2, params } = this.props;
      const entity = find(parsedEntities, { name });
      if (!entity) {
        throw new Error(`Unknown entity '${name}'`);
      }
      // eslint-disable-next-line react/destructuring-assignment
      const { nextUrl } = this.props[entity.name];
      const url = nextUrl;
      api2({ ...entity, url }, params);
    });

    render() {
      const { children, ...rest } = this.props;
      const attribs = {
        ...rest,
        loadMore: this.loadMore,
      };
      return React.Children.map(
        children,
        (c) => React.cloneElement(c, attribs),
      );
    }
  }

  Api2.propTypes = {
    api2: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    params: PropTypes.object,
    reset: PropTypes.func,
    role: PropTypes.object,
  };

  const selectors = parsedEntities.reduce((acc, entity) => ({
    ...acc,
    [entity.name]: makeSelectApi2(entity.selector)(),
  }), { role: getCurrentRole });

  const mapStateToProps = (state, props) => {
    const result = createStructuredSelector(selectors)(state, props);
    const { params } = props;
    if (!props.params) {
      return result;
    }
    return parsedEntities.reduce((acc, entity) => {
      const paramSelector = replaceParams(entity.selector, params);
      if (paramSelector === entity.selector) {
        return acc;
      }
      return {
        ...acc,
        [entity.name]: makeSelectApi2(paramSelector)()(state),
      };
    }, result);
  };

  function mapDispatchToProps(dispatch) {
    return {
      api2: (entity, params) => {
        const { withPromise, ...rest } = entity;
        if (!withPromise) {
          return dispatch(api2call(rest, params));
        }
        return new Promise((resolve, reject) => {
          dispatch(api2call({ ...rest, promise: { resolve, reject } }, params));
        });
      },
      api2update: (options) => dispatch(api2update(options)),
    };
  }

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  return compose(
    withConnect,
  )(Api2);
}

export default api2builder;
