/**
 * CurrentProvider
 * Gets the current user from backend once authenticated
 */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash-es';
import roleFromLocation from 'utils/roleFromLocation.js';
import { addBreadcrumb } from 'utils/sentry.js';
import setIn from 'utils/setIn.js';
import sessionConsole from 'utils/sessionConsole.js';
import { findStoredRole, updateStoredProfile } from './storedRole.js';
import useAuthWithWebView from '../useAuthWithWebView.js';
import { setUser } from './userStorage.js';
import fetchCurrentUser from './fetchCurrentUser.js';
import { TEST_OIDC, USERROLE_SET } from '../constants.js';
import StatsProvider from './StatsProvider.jsx';
import UserContext from './UserContext.js';
import useVisible from './useVisible.js';
import useExpired, { isExpiredUser } from './useExpired.js';
import useQueryFocusManager from './useQueryFocusManager.js';

function CurrentProvider(props) {
  const auth = useAuthWithWebView();
  const { isLoading, isAuthenticated } = auth;
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [loading, setLoading] = useState(false);
  const visible = useVisible();
  const expired = useExpired(auth);
  const requestedSilentRenew = useRef(null);
  const active = Boolean(auth.user && roleId && visible && !expired);
  useQueryFocusManager(active);
  const silentRenewing = window.location.pathname === '/silent.html';

  useEffect(() => {
    if (TEST_OIDC) {
      sessionConsole.log('CurrentProvider', { isAuthenticated, isLoading });
    }
  }, [isLoading, isAuthenticated]);

  function log(label) {
    if (silentRenewing) return;
    const data = { active, visible, expired, requestedSilentRenew: requestedSilentRenew.current && Date.now() - requestedSilentRenew.current };
    addBreadcrumb({
      category: 'userStatus',
      message: `${label} ${JSON.stringify(data)}`,
      level: 'log',
    });
  }

  useEffect(() => {
    log('active');
    if (visible && isExpiredUser(auth) && expired && !requestedSilentRenew.current) {
      log('renew'); // eslint-disable-line no-console
      requestedSilentRenew.current = Date.now();
      auth.signinSilent();
    }
    if (!expired && requestedSilentRenew.current) {
      requestedSilentRenew.current = null;
    }
  }, [active, visible, expired]); // eslint-disable-line react-hooks/exhaustive-deps

  function updateProfile(path, updater) {
    const current = get(profile, path);
    setProfile(setIn(profile, path, updater(current)));
  }

  function setUserRole(id, newUser) { // newUser param in case profile is not updated yet
    const user = newUser || profile;
    if (id !== roleId && id in user.roles) {
      setRoleId(id);
      dispatch({ type: USERROLE_SET, id });
      updateStoredProfile(user, id);
    }
  }

  function findUserRole(user) {
    if (user.roles.list.length > 0) {
      setUserRole(roleFromLocation(window.location) || findStoredRole(user), user);
    }
  }

  function updateRole(updatedRole) {
    setProfile({ ...profile, roles: { ...profile.roles, [updatedRole.uniqueId]: updatedRole } });
  }

  async function loadCurrentUser() {
    try {
      window.webAppTiming.fetchProfile = Date.now() - window.webAppTiming.start;
      const user = await fetchCurrentUser({ auth });
      setProfile(user);
      findUserRole(user);
    } catch (e) {
      console.error('fetchCurrentUser', e); // eslint-disable-line no-console
    }
    setLoading(false);
  }

  const value = useMemo(() => {
    const result = {
      auth,
      profile,
      roleId,
      active,
      loading: isLoading || loading,
    };
    setUser(result);
    return { ...result, setUserRole, loadCurrentUser, updateRole, setProfile, updateProfile };
  }, [profile, auth, roleId, loading, active]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.location.pathname === '/silent.html') {
      log('silent');
      return;
    }
    if (isLoading) {
      setLoading(true);
      return;
    }
    if (!isAuthenticated) {
      setProfile(null);
      setRoleId(null);
      setLoading(false);
      return;
    }
    setLoading(true);
    loadCurrentUser();
  }, [isAuthenticated, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return <UserContext.Provider value={value}><StatsProvider {...props} /></UserContext.Provider>;
}

export default CurrentProvider;
