const requestStack = new Set();

export function checkStack(options) {
  const { url, method } = options;
  return method === 'GET' && requestStack.has(url);
}

export function addToStack(options) {
  const { url, method } = options;
  if (method === 'GET') {
    requestStack.add(url);
  }
}

export function removeFromStack(options) {
  const { url, method } = options;
  if (method === 'GET' && requestStack.has(url)) {
    requestStack.delete((url));
  }
}
