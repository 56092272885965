/**
 *
 * Router.js
 *
 */

import React from 'react';
import { Route, Navigate, createRoutesFromElements, createBrowserRouter, createMemoryRouter } from 'react-router-dom';
import PrivateRoute from 'containers/PrivateRoute/index.jsx';
import { waitForProfile } from 'containers/User/UserProvider/userStorage.js';
import StudentPortfolio from 'containers/PortfolioPage/StudentPortfolio.js';
import ActivateEmail from 'containers/RegisterPage/ActivateEmail.js';
import getDefaultInboxPath from 'components/Conversations/getDefaultInboxPath.jsx';
import PageLayout from 'components/PageLayout/index.jsx';
import ErrorPage from '../ErrorPage.jsx';
import getIndexPath from './getIndexPath.js';
import PortfolioContext from '../../PortfolioPage/PortfolioContext.jsx';
import PortfolioWorkspaceContext from '../../PortfolioPage/PortfolioWorkspaceContext.jsx';

async function navigateTo(func) {
  await waitForProfile();
  const newPath = await func();
  return {
    element: <Navigate to={newPath} />,
  };
}

const routes = createRoutesFromElements(
  <Route element={<PageLayout />} errorElement={<PageLayout><ErrorPage /></PageLayout>}>
    {/* Redirects */}
    <Route path="/communities?" element={<Navigate to="/home" />} />
    <Route path="/admin" element={<Navigate to="/admin/settings" />} />
    {/* Access without logging in */}
    <Route path="/password/forgot" lazy={() => import('containers/PasswordPage/loader.js')} />
    <Route path="/password/reset/:userGuid/:nonce/:hash" lazy={() => import('containers/PasswordPage/loader.js')} />
    <Route path="/register/*">
      <Route index lazy={() => import('containers/RegisterPage/loader.js')} />
      <Route path="activate/:emailId/:pendingId/:hash" element={<ActivateEmail />} />
      <Route path="success" lazy={() => import('containers/RegisterPage/ConfirmationPage/loader.js')} />
      <Route path="verify/:emailId/:pendingId" lazy={() => import('containers/RegisterPage/ConfirmationPage/loader.js')} />
      <Route path=":code/:cmd?" lazy={() => import('containers/RegisterPage/loader.js')} />
    </Route>
    <Route path="/leerling" lazy={() => import('containers/RegisterPage/loader.js')} />
    <Route path="/callback.html" lazy={() => import('containers/RedirectPage/loader.js')} />
    <Route path="/silent.html" lazy={() => import('containers/RedirectPage/loader.js')} />
    <Route path="/signout" lazy={() => import('containers/RedirectPage/loader.js')} />
    <Route path="/statusclose" lazy={() => import('components/ShowStatusAndClose/loader.js')} />
    <Route path="/communityposts/:postId/public" lazy={() => import('containers/CommunityPostsPage/loader.js')} />
    {/* Protected pages */}
    <Route element={<PrivateRoute />}>
      <Route index lazy={() => navigateTo(getIndexPath)} />
      {/* Regular routes */}
      <Route path="/home" lazy={() => import('containers/HomePage/loader.js')} />
      <Route path="/newsletters/:id?" lazy={() => import('components/Newsletters/loader.js')} />
      <Route path="/calendar" lazy={() => import('containers/CalendarPage/loader.js')} />
      <Route path="/absences" lazy={() => import('containers/AbsencesPage/loader.js')} />
      <Route path="/communities/:gid/*" lazy={() => import('containers/CommunityPage/loader.js')} />
      <Route path="/archive" lazy={() => import('containers/DefaultPage/loader.js')} />
      <Route path="/archive/:gid/*" lazy={() => import('containers/CommunityPage/loader.js')} />
      <Route path="/user/*" lazy={() => import('containers/UserPage/loader.js')} />
      <Route path="/admin/*" lazy={() => import('components/dashboard/loader.js')} />
      <Route path="/manage/*" lazy={() => import('components/dashboard/loader.js')} />
      <Route path="/communityposts/:postId" lazy={() => import('containers/CommunityPostsPage/loader.js')} />
      <Route path="/conversations/inboxes/*">
        <Route index lazy={() => navigateTo(getDefaultInboxPath)} />
        <Route path=":inboxId/archive/:topicId?/:subTopicId?" lazy={() => import('containers/DefaultPage/loader.js')} />
        <Route path=":inboxId/:topicId?/:subTopicId?" lazy={() => import('containers/DefaultPage/loader.js')} />
      </Route>
      <Route path="/groups/:gid/*">
        <Route index element={<Navigate to="meetings" />} />
        <Route path="portfolio/*" element={<PortfolioContext />}>
          <Route index element={<Navigate to="activity" />} />
          <Route path="settings" lazy={() => import('containers/PortfolioPage/groupLoader.js')} />
          <Route path="activity" lazy={() => import('containers/PortfolioPage/groupLoader.js')} />
          <Route path="groupgoals" lazy={() => import('containers/PortfolioPage/groupLoader.js')} />
          <Route path="overview" lazy={() => import('containers/PortfolioPage/groupLoader.js')} />
          <Route path="library" lazy={() => import('containers/PortfolioPage/groupLoader.js')} />
          <Route path="students/:sid" element={<StudentPortfolio />} />
          <Route path=":wid/*" element={<PortfolioWorkspaceContext />}>
            <Route index element={<Navigate to="workspace" />} />
            <Route path="*" lazy={() => import('containers/PortfolioPage/baseLoader.js')} />
          </Route>
        </Route>
        <Route path="students/:sid" lazy={() => import('containers/StudentsPage/loader.js')} />
        <Route path="meetings/*" lazy={() => import('containers/MeetingPlannerPage/loader.js')} />
        <Route path="absences" lazy={() => import('containers/AbsencesPage/loader.js')} />
        <Route path="consent" lazy={() => import('containers/ConsentPage/loader.js')} />
      </Route>
      <Route path="/payments/admin/*" lazy={() => import('components/PaymentRequests/PaymentsAdmin/loader.js')} />
      <Route path="/payments/user/*" lazy={() => import('components/PaymentRequests/PaymentsUser/loader.js')} />
      <Route path="/photobook/:albumId?" lazy={() => import('containers/DefaultPage/loader.js')} />
      <Route path="/portfolio/*" element={<PortfolioContext />}>
        <Route index element={<Navigate to="/portfolio/workspace" />} />
        <Route path="library/import" lazy={() => import('containers/PortfolioPage/goalImportLoader.jsx')} />
        <Route path="library" lazy={() => import('containers/PortfolioPage/libraryLoader.jsx')} />
        <Route path="students/:sid" element={<StudentPortfolio />} />
        <Route path="workspace" element={<StudentPortfolio />} />
        <Route path="showcase" element={<StudentPortfolio />} />
        <Route path="goals" element={<StudentPortfolio />} />
        <Route path="progress" element={<StudentPortfolio />} />
        <Route path="completed" element={<StudentPortfolio />} />
        <Route path=":wid/*" element={<PortfolioWorkspaceContext />}>
          <Route index element={<Navigate to="workspace" />} />
          <Route path="*" lazy={() => import('containers/PortfolioPage/baseLoader.js')} />
        </Route>
      </Route>
      <Route path="/settings" lazy={() => import('containers/SettingsPage/loader.js')} />
      <Route path="/students/*">
        <Route path="consents" lazy={() => import('containers/DefaultPage/loader.js')} />
        <Route path="leaverequests" lazy={() => import('containers/StudentsPage/loader.js')} />
        <Route path=":sid/*">
          <Route index lazy={() => import('containers/StudentsPage/loader.js')} />
          <Route path="meetings/:mid" lazy={() => import('containers/StudentsPage/loader.js')} />
          <Route path="photobook" lazy={() => import('containers/StudentsPage/loader.js')} />
        </Route>
      </Route>
      <Route path="/meetings" lazy={() => import('containers/ParentMeetingsPage/loader.js')} />
    </Route>
    <Route path="*" lazy={() => import('containers/NotFoundPage/loader.js')} />
  </Route>,
);

function getRouter(location) {
  return location ? createMemoryRouter(routes, { initialEntries: [location] }) : createBrowserRouter(routes);
}

export default getRouter;
