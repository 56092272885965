/*
 *
 * Alerts actions
 *
 */

import {
  ADD_ALERT, DISMISS_ALERT, CLEAR_ALERTS,
} from './constants.js';

let lastId = 0;

export function addError(error, context, persistent) {
  return {
    type: ADD_ALERT,
    id: ++lastId,
    style: 'danger',
    persistent,
    error,
    context,
  };
}

export function addAlert(message, style, persistent) {
  return {
    type: ADD_ALERT,
    id: ++lastId,
    style: style || 'info',
    toast: style === 'success',
    persistent,
    message,
  };
}

export function dismissAlert(id) {
  return {
    type: DISMISS_ALERT,
    id,
  };
}

export function clearAlerts() {
  return {
    type: CLEAR_ALERTS,
  };
}
