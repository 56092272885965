import 'whatwg-fetch';
import readBlob from 'utils/readBlob.js';
import sleep from './sleep.js';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const contentType = response.headers.get('Content-Type') || '';
  if (contentType.startsWith('image')) {
    return response.blob().then((blob) => readBlob(blob, 'dataURL'));
  }
  if (/json/.test(contentType)) {
    return response.json();
  }
  if (/^(application|video)/.test(contentType)) {
    return response.blob();
  }
  return response.text();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status >= 400) {
    const error = new Error(response.statusText || response.status);
    error.response = response;
    throw error;
  }
  return null;
}

/**
 * fetch and retry on fail
 * @param {string} uri
 * @param {object} options
 */
async function retryFetch(uri, options) {
  const { ignore, ...rest } = options;
  const maxAttempts = ignore ? 1 : 3;
  let error;
  let delay = 1000;
  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const response = await fetch(uri, rest);
      return response;
    } catch (err) {
      // Only if no response received
      error = err;
      // eslint-disable-next-line no-await-in-loop
      await sleep(delay);
      delay *= 2;
    }
  }
  error.request = options; // eslint-disable-line no-param-reassign
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
function request(options) {
  const { uri, noRetry, log, ...rest } = options;
  const promise = noRetry ? fetch(uri, rest) : retryFetch(uri, rest);
  return promise
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      const timestamp = new Date().toISOString();
      error.request = { ...options, timestamp }; // eslint-disable-line no-param-reassign
      error.request_uri = uri; // eslint-disable-line no-param-reassign
      throw error;
    });
}

/**
 * request return false or response, not throwing error
 * @param {*} options
 */
export async function testRequest(options, acceptStatus) {
  const { uri, ...rest } = options;
  const response = await fetch(uri, rest);
  const { status } = response;
  if (status < 400) {
    return response;
  }
  if (status === acceptStatus) {
    return null;
  }
  throw new Error(`Server error ${status}`);
}

export default request;
