import { debounce } from 'lodash-es';
import { useEffect, useState } from 'react';

export function isSmallScreen() {
  return !window.matchMedia('(min-width: 480px)').matches;
}

// Based on https://getbootstrap.com/docs/5.1/layout/breakpoints/
const BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export function useWidth(size) {
  const [width, setWidth] = useState(window.innerWidth);
  const handleResize = debounce(() => setWidth(window.innerWidth), 500);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size && BREAKPOINTS[size] ? width >= BREAKPOINTS[size] : width;
}
