/*
 *
 * parseError
 * get error message from action or response
 */

import { pick } from 'lodash-es';
import { filterText } from 'utils/strings.js';

export default async function parseError(error) {
  if (!error.response) {
    return null;
  }
  // Get relevant text from response
  const response = await parseResponse(error.response);
  const message = getErrorMessage(response, error);
  return { response, message };
}

async function parseResponse(response) {
  const result = pick(response, ['status', 'statusText', 'bodyText']);
  result.text = await response.text();
  if (/\/json\b/.test(response.headers.get('Content-type'))) {
    result.bodyContent = JSON.parse(result.text);
  }
  result.bodyText = filterText(result.text, /<(?:div|Message)[^>]*>([^<]*)/u) || result.text;
  return result;
}

function getErrorMessage(response, error) {
  if (error && error[response.status]) {
    return error[response.status];
  }
  return getErrorText(response.bodyContent) || `Server returned ${response.status} ${response.text || response.statusText}`;
}

const errorMessageProperties = ['errors', 'error', 'errorMessage', 'ErrorMsg'];

export function getErrorText(content) {
  if (typeof content !== 'object') {
    return content;
  }
  return errorMessageProperties.reduce((acc, key) => {
    if (acc) {
      return acc;
    }
    const error = content[key];
    if (!error) {
      return false;
    }
    if (Array.isArray(error)) {
      return error.map(getErrorText).join(', ');
    }
    return getErrorText(error);
  }, '');
}
