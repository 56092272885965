import useUserContext from './useUserContext.js';

export function findRoleForCode(roles, code) {
  return roles.list.find((id) => roles[id].connections.some((conn) => conn.code === code));
}

function useSetRoleForCode() {
  const { profile, setUserRole } = useUserContext();
  return (code) => {
    const { roles } = profile;
    const newRoleId = findRoleForCode(roles, code);
    if (newRoleId) {
      setUserRole(newRoleId);
    }
  };
}

export default useSetRoleForCode;
