/*
 * NotificationsDropdown Messages
 *
 * This contains all the text for the NotificationsDropdown component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  noNotifications: {
    id: 'app.components.NotificationsDropdown.noNotifications',
    defaultMessage: 'No new notifications',
  },
  notifications: {
    id: 'app.components.NotificationSettings.notifications',
    defaultMessage: 'Notifications',
  },
});
