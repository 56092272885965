/*
 * Dialog Messages
 *
 * This contains all the text for the Dialog component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  ok: {
    id: 'app.common.ok',
    defaultMessage: 'OK',
  },
  cancel: {
    id: 'app.common.cancel',
    defaultMessage: 'Cancel',
  },
  yes: {
    id: 'app.common.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'app.common.no',
    defaultMessage: 'No',
  },
  remove: {
    id: 'app.common.remove',
    defaultMessage: 'Remove',
  },
  delete: {
    id: 'app.common.delete',
    defaultMessage: 'Delete',
  },
});
