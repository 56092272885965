import React from 'react';
import { Form } from 'react-bootstrap';
import SelectCommunities from 'components/interaction/SelectCommunities/index.jsx';
import StartEndPicker from 'components/interaction/StartEndPicker/index.jsx';
import { FormattedMessage } from 'react-intl';
import { isValidTime } from 'utils/eventTimes.js';
import AskMaterials from './AskMaterials/index.jsx';
import messages from './messages.js';

const schema = {
  id: 'event',
  properties: [
    {
      id: 'communities',
      as: SelectCommunities,
      icon: 'ss-usergroup',
      attributes: { permission: 'post', showAll: true },
      required: true,
      type: 'array',
    },
    {
      id: 'title',
      type: 'text',
      placeholder: messages.title,
      attributes: { emoji: true },
      icon: true,
      required: true,
    },
    {
      id: 'time',
      as: StartEndPicker,
      icon: 'ss-clock',
      validate: isValidTime,
      attributes: { requireEnd: true },
      required: true,
    },
    {
      id: 'location',
      type: 'text',
      icon: 'ss-location',
      placeholder: messages.location,
    },
    {
      id: 'description',
      type: 'textarea',
      icon: 'ss-horizontalbarchart',
      placeholder: messages.description,
      attributes: { emoji: true, rows: 3, maxRows: 10 },
    },
    {
      id: 'rsvp',
      as: Form.Check,
      type: 'checkbox',
      icon: 'ss-checkclipboard',
      attributes: { label: <FormattedMessage {...messages.rsvp} />, className: 'form-control-unstyled' },
    },
    {
      id: 'materials',
      as: AskMaterials,
      icon: 'ss-emptycart',
      attributes: { children: <FormattedMessage {...messages.rsvp} /> },
      type: 'array',
    },
  ],
};

export default schema;
