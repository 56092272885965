/*
 *
 * Api2 constants
 *
 */

export const API2_CALL = 'app/Api2/API2_CALL';
export const API2_CALL_CANCEL = 'app/Api2/API2_CALL_CANCEL';
export const API2_CALL_REQUEST = 'app/Api2/API2_CALL_REQUEST';
export const API2_CALL_SUCCESS = 'app/Api2/API2_CALL_SUCCESS';
export const API2_CALL_ERROR = 'app/Api2/API2_CALL_ERROR';
export const API2_UPDATE = 'app/Api2/API2_UPDATE';
