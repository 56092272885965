/*
 * PortfolioPage Messages
 *
 * This contains all the text for the PortfolioPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.containers.PortfolioPage.title',
    defaultMessage: 'Portfolio of {name}',
  },
  description: {
    id: 'app.containers.PortfolioPage.description',
    defaultMessage: 'Portfolio of {name}',
  },
  noPortfolio: {
    id: 'app.containers.PortfolioPage.noPortfolio',
    defaultMessage: 'Portfolio has not been enabled for this school',
  },
  noModules: {
    id: 'app.containers.PortfolioPage.noModules',
    defaultMessage: 'There are no modules activated yet. Please contact your school administrator.',
  },
  studentsOnly: {
    id: 'app.containers.PortfolioPage.studentsOnly',
    defaultMessage: 'There is no portfolio for {name}, this is not a student',
  },
  workspace: {
    id: 'app.containers.PortfolioPage.workspace',
    defaultMessage: 'Workspace',
  },
  goals: {
    id: 'app.containers.PortfolioPage.goals',
    defaultMessage: 'Goals',
  },
  showcase: {
    id: 'app.containers.PortfolioPage.showcase',
    defaultMessage: 'Showcase',
  },
  progress: {
    id: 'app.containers.PortfolioPage.progress',
    defaultMessage: 'Progress',
  },
  activity: {
    id: 'app.containers.PortfolioPage.activity',
    defaultMessage: 'Activity',
  },
  groupgoals: {
    id: 'app.containers.PortfolioPage.groupgoals',
    defaultMessage: 'Group Goals',
  },
  overview: {
    id: 'app.containers.PortfolioPage.overview',
    defaultMessage: 'Group Overview',
  },
  library: {
    id: 'app.containers.PortfolioPage.library',
    defaultMessage: 'Library',
  },
  settings: {
    id: 'app.containers.PortfolioPage.settings',
    defaultMessage: 'Settings',
  },
});
