/**
*
* ExternalContacts
*
*/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { useApi2 } from 'containers/Api2/index.js';
import Checklist from 'components/interaction/Checklist/index.jsx';
import PrimaryAction from 'components/interaction/PrimaryAction/index.jsx';
import InfiniteScroll from 'components/interaction/InfiniteScroll/index.jsx';
import Spinner from 'components/Spinner/index.jsx';
import { idValues } from 'utils/arrays.js';
import fixedBottomStyle from 'utils/fixedBottomStyle.js';
import messages from './messages.js';

const PAGESIZE = 20;
const MARGIN_BOTTOM = 120;

function ExternalContacts(props) {
  const { onChange, postId, onHide } = props;
  const [selected, setSelected] = useState([]);

  const externalcontacts = useApi2({ url: `communityposts/${postId}/externalcontacts?offset=0&&limit=${PAGESIZE}` });
  const { list, loading, hasMore, loadMore } = externalcontacts;

  useEffect(() => {
    if (list) {
      setSelected(list.filter((item) => item.selected).map((item) => item.id));
    }
  }, [list]);

  function handleSubmit(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (list) {
      const allContacts = list.length === selected.length;
      if (allContacts) {
        onChange({ allContacts: true });
      } else {
        const contacts = selected.filter((id) => !externalcontacts[id].selected);
        if (contacts.length > 0) {
          onChange({ contacts });
        }
      }
      onHide();
    }
  }

  const renderContact = (contact) => (
    <div className="d-flex w-100 align-items-baseline">
      <div className="w-50">{contact.displayName}</div>
      <div className="small">{contact.email}</div>
    </div>
  );

  const items = idValues(externalcontacts);
  const readOnly = (item) => item.selected;
  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title><FormattedMessage {...messages.header} /></Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="px-0">
          <p className="text-mute px-4">
            <FormattedMessage {...messages.intro} />
          </p>
          {(loading && !list) ? <Spinner /> : (
            <InfiniteScroll
              hasMore={hasMore}
              loadMore={loadMore}
              loading={loading}
              getStyle={(elem) => fixedBottomStyle(MARGIN_BOTTOM, elem)}
            >
              <Checklist
                name="externalcontacts"
                items={items}
                readOnly={readOnly}
                value={selected}
                renderItem={renderContact}
                onChange={setSelected}
                variant="flush"
                labelClass="d-block"
                asList
                selectAll
              />
            </InfiniteScroll>

          )}
        </Modal.Body>
        <Modal.Footer>
          <PrimaryAction onCancel={onHide} submitText={messages.share} />
        </Modal.Footer>
      </form>
    </Modal>
  );
}

ExternalContacts.propTypes = {
  onHide: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  postId: PropTypes.number.isRequired,
};

export default ExternalContacts;
