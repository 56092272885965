import { omit } from 'lodash-es';
import { makeIdObject } from 'utils/arrays.js';
import { tmpId } from '../GoalFields/newFields.js';

function parseGoal(goal) {
  const fieldsArray = goal.formData && goal.formData.startsWith('[') ? JSON.parse(goal.formData) : [];
  const parseItems = fieldsArray.map((field) => field.items ? { ...field, items: asObject(field.items) } : field);
  return {
    ...omit(goal, ['formData']),
    categoryId: goal.category && goal.category.id,
    fields: asObject(parseItems),
  };
}

function withId(obj) {
  if (obj.id) {
    return obj;
  }
  return { ...obj, id: tmpId() };
}

function asObject(arr) {
  return makeIdObject(arr.map(withId));
}

export default parseGoal;
