import config from 'config/index.js';
import { pick } from 'lodash-es';
import useAlert from 'containers/Alerts/useAlert.js';
import { reportApiError } from 'utils/sentry.js';
import parsePayload from './parsePayload.js';
import parseError from './parseError.js';
import authorizedRequest from './authorizedRequest.js';

/**
 * api2call handle request sending
 * @param {*} action
 */
function useAuthorizedRequest() {
  const { addAlert, addError } = useAlert();

  const authRequest = async (options) => {
    if (options.skip) {
      return null;
    }
    const result = await authorizedRequest(options);
    if (options.success) {
      addAlert(options.success, 'success');
    }
    return result;
  };

  async function apiError(error, options) {
    const { ignore, noReport } = options;
    const status = error.response?.status;
    // eslint-disable-next-line no-param-reassign
    error.noReport = (noReport?.includes(status)) || !error.response;
    if (ignore === true || ignore?.includes(status)) {
      return false;
    }
    if (!error.request) {
      console.error(error); // eslint-disable-line no-console
      reportError('request', error);
      addError(error);
      return true;
    }
    if (!error.response) {
      // Failed to fetch, could not load, apparent connectivity problem
      addError(error);
      return true;
    }
    const parsed = await parseError(error);
    const message = (options.error?.[status]) || parsed.message;
    if (!error.noReport) {
      const response = { ...pick(error.response, ['status', 'statusText']), message };
      reportApiError(error.message, { error, options }, response);
    }
    addError({ ...error, message });
    return true;
  }

  const authFetch = async (options) => {
    try {
      const payload = await authRequest(options);
      return parsePayload(payload, options);
    } catch (error) {
      if (config.env !== 'production' && options.mock) {
        return parsePayload(options.mock, options);
      }
      if (await apiError(error, options)) {
        throw error;
      }
      return { error };
    }
  };

  const authPost = async (options) => {
    const { id, ...body } = options.body;
    if (!id) {
      return authFetch({ ...options, method: 'POST' });
    }
    const url = options.url.replace(/^([^?#]*)/, `$1/${id}`);
    return authFetch({ method: options.method || 'PUT', ...options, body, url });
  };

  return { authRequest, authFetch, authPost };
}

export default useAuthorizedRequest;
