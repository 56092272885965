/**
*
* ExpandItem
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'react-bootstrap';
import ExpandIcon from 'components/media/Icon2/ExpandIcon.jsx';

function ExpandItem(props) {
  const { children, expanded, onClick, disabled, ...rest } = props;
  const handleClick = disabled ? undefined : onClick;
  return (
    <ListGroupItem action variant="link" {...rest} onClick={handleClick} disabled={disabled}>
      <ExpandIcon className="float-end fs-3 mt-2 me-3" expanded={expanded} />
      {children}
    </ListGroupItem>
  );
}

ExpandItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ExpandItem;
