/**
*
* LabeledSwitch
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useSwipeable } from 'react-swipeable';
import { Button } from 'react-bootstrap';

const Wrapper = styled.div`
  white-space: nowrap;
  .btn {
    background-color: ${(p) => p.theme.grayLighter};
    color: ${(p) => p.theme.gray};
    padding: 6px 30px;
    border-color: ${(p) => p.theme.inputBorder};
    margin: -1px -12px;
    z-index: -1;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active {
      color: ${(p) => p.theme.brandPrimary};
      border-color: ${(p) => p.theme.inputBorder};
      background-color: #fff;
      opacity: 0.999;
      z-index: 1;
      cursor: default;
    }
  }
`;

function LabeledSwitch(props) {
  const { value, options, onChange, disabled, className } = props;
  function onSwipe(d) {
    const index = options.findIndex((o) => o.value === value);
    const result = options[index + d];
    if (result) {
      onChange(result.value);
    }
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => onSwipe(-1),
    onSwipedRight: () => onSwipe(1),
    trackMouse: true,
  });
  return (
    <Wrapper {...handlers} className={className}>
      {options.map((o) => (
        <Button variant="light" key={o.value} onClick={() => onChange(o.value)} active={value === o.value} disabled={disabled || value === o.value}>
          <FormattedMessage {...o.label} />
        </Button>
      ))}
    </Wrapper>
  );
}

LabeledSwitch.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default LabeledSwitch;
