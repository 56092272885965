/**
*
* Container
*
*/

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.width};
  margin: auto;
`;

Container.propTypes = {
  width: PropTypes.string,
};

Container.defaultProps = {
  width: '908px',
};

export default Container;
