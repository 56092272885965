import { useEffect, useRef, useState } from 'react';

function getTimeToExpiry(expiresAt) {
  return expiresAt ? 1000 * (expiresAt - 10) - Date.now() : -1;
}

export function isExpiredUser(auth) {
  const expiresAt = auth?.user?.expires_at;
  return expiresAt && getTimeToExpiry(expiresAt) <= 0;
}

function useExpired(auth) {
  const expiresAt = auth?.user?.expires_at;
  // 10 seconds before expiry time
  const [expired, setExpired] = useState(getTimeToExpiry(expiresAt) <= 0);
  const expiryHandle = useRef(null);
  useEffect(() => {
    const timeToExpiry = getTimeToExpiry(expiresAt);
    if (timeToExpiry > 0) {
      setExpired(false);
      expiryHandle.current = setTimeout(() => setExpired(true), timeToExpiry);
    }
    return () => {
      if (expiryHandle.current) {
        clearTimeout(expiryHandle.current);
        expiryHandle.current = null;
      }
    };
  }, [expiresAt]);
  return expiresAt ? expired : false;
}

export default useExpired;
