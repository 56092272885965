/**
 *
 * useSelectImage
 *
 */

import React, { useRef, useState } from 'react';
import useUpload from 'containers/FileUpload/useUpload.js';
import useSafeState from 'utils/useSafeState.js';
import { createImageFile } from 'utils/imageFile.js';
import convertHeic from 'utils/convertHeic.js';
import SelectFiles from '../SelectFiles/index.jsx';
import useCropImage from './useCropImage.jsx';

function useSelectImage(props) {
  const { crop, onChange, setPreview, onStatus } = props;
  const [progress, setProgress] = useSafeState();
  const [counter, setCounter] = useState(0);
  const { getCrop, cropModal } = useCropImage();
  const upload = useUpload();
  const preview = useRef();

  function handleUpload(item) {
    setCounter(counter + 1);
    if (item.loading) {
      setProgress(item.progress);
    } else {
      window.setTimeout(setProgress, 1000);
      onChange({ preview: preview.current, ...item.data });
    }
  }

  async function handleSelect(files) {
    if (!files[0]) {
      return;
    }
    if (onStatus) {
      onStatus('uploading');
    }
    let result = await convertHeic(files[0]);
    result = await createImageFile(result);
    if (crop) {
      result = await getCrop(result, crop);
    }
    setPreview?.(result.preview);
    preview.current = result.preview;
    const item = {
      file: result,
      progress: 0,
      loading: true,
    };
    await upload(item, handleUpload);
    if (onStatus) {
      onStatus(null);
    }
  }

  function selectFiles(options) {
    return (
      <>
        <SelectFiles
          type="image"
          counter={counter} // Enables uploading same file again
          onChange={handleSelect}
          {...options}
        />
        {Boolean(crop) && cropModal()}
      </>
    );
  }

  return { selectFiles, progress };
}

export default useSelectImage;
