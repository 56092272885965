import { get } from 'lodash-es';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import { EMPLOYEE_ROLE, PARENT_ROLE, STUDENT_ROLE, MODULES_PORTFOLIOPILOT_GROUPS, MODULES_STUDENTPORTFOLIO } from 'containers/User/constants.js';
import useModules from 'containers/User/useModules.js';
import { PORTFOLIO_STATUS_NONE, PORTFOLIO_STATUS_PARENT, PORTFOLIO_STATUS_PILOT, PORTFOLIO_STATUS_STUDENT, PORTFOLIO_STATUS_SUBSCRIBER } from './constants.js';

function usePortfolioStatus() {
  const role = useCurrentRole();
  const modules = useModules();
  const roleType = get(role, 'type');
  const hasSubscription = modules.includes(MODULES_STUDENTPORTFOLIO);
  const hasPilotGroups = modules.includes(MODULES_PORTFOLIOPILOT_GROUPS);

  switch (roleType) {
    case STUDENT_ROLE: {
      return hasSubscription ? PORTFOLIO_STATUS_STUDENT : PORTFOLIO_STATUS_NONE;
    }
    case PARENT_ROLE: {
      return hasPilotGroups ? PORTFOLIO_STATUS_PARENT : PORTFOLIO_STATUS_NONE;
    }
    case EMPLOYEE_ROLE: {
      if (hasSubscription) {
        return PORTFOLIO_STATUS_SUBSCRIBER;
      }
      return hasPilotGroups ? PORTFOLIO_STATUS_PILOT : PORTFOLIO_STATUS_NONE;
    }
    default:
      return PORTFOLIO_STATUS_NONE;
  }
}

export default usePortfolioStatus;
