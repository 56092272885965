/**
 *
 * HashPopup
 *
 */

import React from 'react';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import EventModal from 'components/Calendar/EventModal/index.jsx';
import UserModal from 'components/user/UserModal/index.jsx';
import { useHash } from 'utils/urlUtils.js';

function HashPopup() {
  const hash = useHash();
  const role = useCurrentRole();
  if (!role) {
    return null;
  }
  const { event, user, push } = hash;
  const handleHide = () => push({});
  if (event) {
    return <EventModal eventId={Number(event)} onHide={handleHide} />;
  }
  if (user) {
    return (
      <UserModal userId={user} onHide={handleHide} />
    );
  }
  return null;
}

export default HashPopup;
