/**
*
* NotificationsItem
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import { calendar } from 'utils/date-fns/index.js';
import useSeenItems from 'containers/User/UserProvider/useSeenItems.js';
import getAttributes from './getAttributes.js';
import Dot from './Dot.js';

const ContentWrapper = styled(AvatarWithText)`
  .dim {
    color: ${(props) => props.theme.gray};
  }
  .created {
    display: block;
    color: ${(props) => props.theme.gray};
    text-align: right;
  }
`;

function NotificationsItem(props) {
  const { item, onSelect, ...rest } = props;
  const { setSeen, isNew } = useSeenItems('notifications');
  const { icon, link } = getAttributes(item);

  function handleSelect(ev) {
    if (link) {
      setSeen(item.id);
    }
    if (onSelect) {
      onSelect(ev);
    }
  }

  const className = link ? '' : 'no-link';
  return (
    <Dropdown.Item eventKey={item.id} {...rest} as={Link} className={className} to={link} onClick={handleSelect}>
      <ContentWrapper src={item.icon} color={item.iconColor} overlay={icon}>
        {isNew(item.id, item.created) && <Dot />}
        <div className="fw-bold small">{item.title}</div>
        <div className="dim clear-fix">{item.body}</div>
        <div className="created">{calendar(item.created, true)}</div>
      </ContentWrapper>
    </Dropdown.Item>
  );
}

NotificationsItem.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
};

export default NotificationsItem;
