/*
 *
 * Configuration information
 *
 */

import webViewTest from 'containers/WebViewProvider/webViewTest.js';
import storage from 'utils/storage/index.js';
import host from './host.js';
import sessionVars from './sessionVars.js';

webViewTest();

const settingVars = Object.keys(sessionVars).filter((key) => sessionVars[key].includes(host));

const config = {
  sentryDsn: 'https://c7b299ab0c0e4dd9a2620d3ceb3f5517@sentry.io/280505',
  sentryKey: '622b53fa067011e88b074201c0a8d046',
  statisticsKey: 'vmja6gKzNA4PnRu7yqTIGwx8Tlybu400REbSXZcg2a9IOaLHeNHGzg==',
  env: host,
  settingVars,
  gapiOptions: {
    discoveryDocs: [
      'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
      'https://www.googleapis.com/discovery/v1/apis/oauth2/v2/rest',
    ],
    scope: 'email https://www.googleapis.com/auth/calendar.events',
  },
  msalOptions: {
    scopes: ['User.Read', 'Calendars.ReadWrite', 'email'],
    graphApiHost: 'https://graph.microsoft.com/v1.0',
  },
};

const testEnvironment = {
  apiHost: 'https://api-testing.socialschools.eu/api/v1/',
  ssoHost: 'https://login-testing.socialschools.eu',
  ssoId: 'eu.socialschools.webapp',
  adminHost: 'https://admin-testing.socialschools.eu',
  statisticsUrl: 'https://statistics-testing.socialschools.eu/api/PostsSeenBy',
  photoalbumHost: 'https://photoalbums-testing.socialschools.eu/api/v1',
  portfolioExport: {
    bucketLink: 'https://socialschools-portfolioexports-test.s3.eu-west-1.amazonaws.com/',
    exportLink: 'https://portfolioexport-test.socialschools.eu/export',
  },
  gapiClientId: '567584566722-2bojtm7jj9k805gcarb4h1q994821gte.apps.googleusercontent.com',
  msalClientId: 'd54b0476-2c97-41dd-8839-775d0b416987', // Social Schools Test
  // msalClientId: 'a3dbf095-f229-4206-90c0-7225df19a994', // Web App Calendar Integration
};

const liveEnvironment = {
  apiHost: 'https://api.socialschools.eu/api/v1/',
  ssoHost: 'https://login.socialschools.eu',
  ssoId: 'eu.socialschools.webapp',
  adminHost: 'https://admin.socialschools.eu',
  statisticsUrl: 'https://statistics.socialschools.eu/api/PostsSeenBy',
  photoalbumHost: 'https://photoalbums.socialschools.eu/api/v1',
  portfolioExport: {
    bucketLink: 'https://socialschools-portfolioexports-prod.s3.eu-west-1.amazonaws.com/',
    exportLink: 'https://portfolioexport-prod.socialschools.eu/export',
  },
  gapiClientId: '163442191772-nnsddknejuk19su12qbtbovec96ivi70.apps.googleusercontent.com',
  msalClientId: 'b8b85fc1-ac30-4e55-9373-1e58f895ab74',
};

switch (host) {
  case 'staging':
  case 'production':
    Object.assign(config, liveEnvironment);
    break;
  case 'local':
    if (storage.getItem('forceProduction')) {
      // eslint-disable-next-line no-console
      console.warn('Local uses production');
      Object.assign(config, liveEnvironment);
    } else {
      Object.assign(config, testEnvironment);
    }
    config.statisticsUrl = null;
    if (storage.getItem('localServer')) {
      config.apiHost = 'http://localhost:22663/api/v1/';
    }
    break;
  default:
    Object.assign(config, testEnvironment);
    if (storage.getItem('localServer')) {
      config.apiHost = 'http://localhost:22663/api/v1/';
    }
    // TODO for validation process
    config.gapiClientId = liveEnvironment.gapiClientId;
}

export default config;
