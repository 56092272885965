/**
 *
 * EventModal
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { useApi2 } from 'containers/Api2/index.js';
import useDialog from 'containers/Dialog/useDialog.js';
import { print } from 'components/interaction/PrintButton/index.jsx';
import IconButton from 'components/interaction/IconButton/index.jsx';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import UserText from 'components/media/UserText/Loadable.js';
import Spinner from 'components/Spinner/index.jsx';
import { eventParser } from 'utils/events.js';
import Rsvp from '../Rsvp/index.jsx';
import RsvpPrint from '../Rsvp/RsvpPrint/index.jsx';
import Materials from '../Materials/index.jsx';
import messages from '../messages.js';
import Contacts from './Contacts.jsx';
import EventHeader from './EventHeader.jsx';

const CONTACTS_NONE = 0;
const CONTACTS_REQUESTED = 1;
const CONTACTS_LOADED = 2;

const DetailModal = styled(Modal)`
  overflow-wrap: anywhere;
  * {
    max-width: 100%;
  }
  &.modal {
    color: ${(props) => props.theme.gray};
    @media screen {
      /* font-size: 1.2rem; */
      border: 1px solid ${(props) => props.theme.listGroupBorder};
    }
    @media print {
      position: relative;
    }
    .modal-dialog {
      @media screen and (min-width: ${(props) => props.theme.screenSmMin}) {
        width: 400px;    
      }
      @media print {
        width: 640px;
        margin: 10px auto;
        .modal-content {
          border: none;
        }
      }
    }
    h5 {
      /* font-size: 1.4rem; */
      /* line-height: 2rem; */
      font-weight: normal;
      margin-top: 0;

    }
    .time {
      /* font-weight: 500; */
      /* font-size: 1.15em; */
      color: ${(props) => props.theme.grayDark};
    }
    .groups {
      color: ${(props) => props.theme.gray};
    }
    .rsvp-text {
      margin: 4px 0;
    }
    .modal-body {
      position: static;
      border-top: 1px solid ${(props) => props.theme.listGroupBorder};
      button[icon="ss-print"] {
        float: right;
        margin-top: -10px;
        color: ${(p) => p.theme.gray};
        z-index: 2;
      }
      & > div+div {
        margin-top: 15px;
      }
      .btn.float-end {
        margin-top: -6px;
        margin-right: -12px;
      }
      .avatar-icon {
        min-width: 24px;
      }
    }
  }
`;

function EventModal(props) {
  const { eventId, onHide } = props;
  const event = useApi2({ url: `events/${eventId}`, replace: false, parser: eventParser, noReport: [401, 403, 410] });
  const channels = useApi2({ url: 'publish/channels', selector: 'channels', noReport: [401] });
  const { dlgAlert, dlgConfirm } = useDialog();
  const [contactsStatus, setContactsStatus] = useState(CONTACTS_NONE);

  function goPrint() {
    print(dlgAlert);
  }

  function handleLoaded() {
    setContactsStatus(CONTACTS_LOADED);
    goPrint();
  }

  function handlePrint() {
    dlgConfirm({ title: messages.includeContactDetails, yesNo: true })
      .then(() => {
        switch (contactsStatus) {
          case CONTACTS_NONE:
            setContactsStatus(CONTACTS_REQUESTED);
            break;
          case CONTACTS_LOADED:
            goPrint();
            break;
          default:
        }
      }).catch(() => {
        setContactsStatus(CONTACTS_NONE);
        goPrint();
      });
  }

  if (!event || event.loading || event.error) {
    return null;
  }
  const menuItemLabels = ['close', 'edit', 'remove'];
  const canPublish = channels && channels.list && channels.list.length > 0;
  if (canPublish) {
    if (event.publishedChannels.includes(1)) {
      menuItemLabels.splice(1, 0, 'revoke');
    } else {
      menuItemLabels.splice(1, 0, 'publish');
    }
  }
  const canEdit = get(event, 'permissions.moderate');
  const hasMaterials = event.materials.list && event.materials.list.length > 0;
  const withContactDetails = (event.rsvp || hasMaterials) && contactsStatus !== CONTACTS_NONE && canEdit;
  return (
    <DetailModal show animation={false} onHide={onHide}>
      <EventHeader event={event} onHide={onHide} />
      {(event.description || event.link || event.location || event.rsvp || hasMaterials) && (
        <Modal.Body>
          {canEdit && (event.users && event.users.loading ? <Spinner />
            : <IconButton variant="link" className="d-print-none float-end" icon="ss-print fs-3 text-gray" onClick={handlePrint} />)}
          {event.location
            && <AvatarWithText icon="ss-location" bsSize="small"><UserText>{event.location}</UserText></AvatarWithText>}
          {event.description
            && (
              <AvatarWithText icon="ss-horizontalbarchart" bsSize="small">
                <UserText>{event.description}</UserText>
              </AvatarWithText>
            )}
          {event.rsvp
            && (
              <AvatarWithText icon="ss-checkclipboard" bsSize="small">
                <Rsvp className="d-print-none" event={event} />
                <RsvpPrint className="visible-print" params={{ eventId: event.id }} />
              </AvatarWithText>
            )}
          {hasMaterials
            && (
              <AvatarWithText icon="ss-emptycart" bsSize="small">
                <Materials className="d-print-none" withList event={event} />
              </AvatarWithText>
            )}
          {withContactDetails
            && (
              <div className="visible-print">
                <AvatarWithText icon="ss-usergroup" bsSize="small">
                  <div>
                    <h5><FormattedMessage {...messages.contactDetails} /></h5>
                    <Contacts eventId={eventId} onLoaded={handleLoaded} />
                  </div>
                </AvatarWithText>
              </div>
            )}
          {event.link
            && <AvatarWithText icon="ss-link" bsSize="small">{event.link}</AvatarWithText>}
        </Modal.Body>
      )}
    </DetailModal>
  );
}

EventModal.propTypes = {
  eventId: PropTypes.number,
  onHide: PropTypes.func,
};

export default EventModal;
