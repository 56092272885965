import { get } from 'lodash-es';
import setIn from 'utils/setIn.js';
import { addItem, deleteItem } from './helpers.js';

/**
 * defaultReducer applies changes to base object based on REST api call options and result
 * @param {*} options used in fetch call
 * @param {number} pathOffset offset in url path to base object
 * @returns
 */
function defaultReducer(options, pathOffset) {
  const { method, url, prepend } = options;
  const path = url.split('/').slice(pathOffset);
  return (data, result) => {
    switch (method) {
      case 'POST':
        return addItem(data, path, result, prepend);
      case 'PUT':
        return setIn(data, path, result);
      case 'PATCH': {
        const item = get(data, path);
        return setIn(data, path, { ...item, ...result });
      }
      case 'DELETE': {
        const id = path.pop();
        return deleteItem(data, path, id);
      }
      default:
        throw new Error(`Unknown method ${method}`);
    }
  };
}

export default defaultReducer;
