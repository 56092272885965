/**
 *
 * NewslettersLink
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import { MODULES_NEWSLETTERS } from 'containers/User/constants.js';
import messages from './messages.js';
import PathItem from './PathItem.jsx';

function NewslettersLink(props) { // eslint-disable-line react/prefer-stateless-function
  const { modules, pathname } = props;
  if (!modules.includes(MODULES_NEWSLETTERS)) {
    return null;
  }

  return (
    <PathItem to="/newsletters">
      <AvatarWithText icon="ss-newspaper" active={pathname.startsWith('/newsletters')}>
        <FormattedMessage {...messages.newsletters} />
      </AvatarWithText>
    </PathItem>
  );
}

NewslettersLink.propTypes = {
  pathname: PropTypes.string.isRequired,
  modules: PropTypes.array.isRequired,
};

export default NewslettersLink;
