/**
 *
 * SocsBanner
 *
 */

import React from 'react';
import styled from 'styled-components';
import logo from 'images/logo-socialschools-03.png';

const Wrapper = styled.div`
  text-align: center;
  margin: 20px 0;
  img {
    width: 40px;
    margin-right: 10px;
  }
  font-weight: bold;
  font-size: 18px;
`;

function SocsBanner() {
  return (
    <Wrapper>
      <img src={logo} alt="Social Schools" />
      Social Schools
    </Wrapper>
  );
}

export default SocsBanner;
