/**
 * logSearch
 * check if word is includes in search like http:/...?log=<word>
 */

import { parseString } from 'utils/urlUtils.js';

function logSearch(word) {
  const logValue = parseString(window.location.search).log;
  return logValue && logValue.split(/\W+/).includes(word);
}

export default logSearch;
