import { css } from 'styled-components';
import alertMixin from './alert.js';
import tabsMixin from './tabs.js';
import cardMixin from './card.js';
import dropdownMixin from './dropdown.js';
import buttonMixin from './button.js';
import formMixin from './form.js';
import layoutMixin from './layout.js';
import './table.css';

const componentsMixin = css`
  ${alertMixin};
  ${buttonMixin};
  ${cardMixin};
  ${tabsMixin};
  ${dropdownMixin};
  ${formMixin};
  ${layoutMixin};
`;

export { default as withColor } from './withColor.js';

export default componentsMixin;
