import { WebStorageStateStore } from 'oidc-client-ts';
import MemoryStorage from 'memorystorage';
// import { USER_SIGNED_OUT } from 'redux-oidc';
// import { OIDC_ACTION, OIDC_SKIP_MONITOR } from 'containers/User/constants.js';
import { getStorage } from 'utils/storage/index.js';
import windowString from 'utils/windowString.js';
// import { getParam } from 'utils/urlUtils.js';
import config from './index.js';
import host from './host.js';

const oidcStorage = new MemoryStorage('socs-oidc');

// TODO check use of monitorSession

// function canMonitor() {
//   const setMonitor = getParam(window.location.search, 'monitorSession');
//   switch (setMonitor) {
//     case 'true':
//       storage.removeItem(OIDC_SKIP_MONITOR);
//       return true;
//     case 'false':
//       storage.setItem(OIDC_SKIP_MONITOR, new Date());
//       return false;
//     default:
//   }
//   const oidcAction = storage.getItem(OIDC_ACTION);
//   if (oidcAction === USER_SIGNED_OUT) {
//     storage.setItem(OIDC_SKIP_MONITOR, new Date());
//     return false;
//   }
//   const oidcSkipMonitor = storage.getItem(OIDC_SKIP_MONITOR);
//   if (host === 'production' && oidcSkipMonitor && differenceInDays(new Date(), new Date(oidcSkipMonitor)) < 30) {
//     return false;
//   }
//   return true;
// }

const monitorSession = false; // canMonitor();
if (host !== 'production') {
  // eslint-disable-next-line no-console
  console.log('Loading', windowString(), 'monitorSession', monitorSession);
}

const automaticSilentRenew = !window.socsIsInAppWebView && (window.location.pathname !== '/silent.html');

const oidcConfig = {
  authority: config.ssoHost,
  client_id: config.ssoId,
  redirect_uri: `${window.location.origin}/callback.html`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  response_mode: 'query',

  // See https://github.com/IdentityModel/oidc-client-js/issues/269
  userStore: new WebStorageStateStore({ store: host === 'local' ? getStorage(true) : oidcStorage }),
  stateStore: new WebStorageStateStore({ store: getStorage(true) }),

  // these two will be done dynamically from the buttons clicked, but are
  // needed if you want to use the silent_renew
  response_type: 'code',
  scope: 'openid SocsWebApi',

  // this will toggle if profile endpoint is used
  loadUserInfo: false,

  // silent renew will get a new access_token via an iframe
  // just prior to the old access_token expiring (60 seconds prior)
  silent_redirect_uri: `${window.location.origin}/silent.html`,
  automaticSilentRenew,

  // will revoke (reference) access tokens at logout time
  revokeAccessTokenOnSignout: true,

  // this will allow all the OIDC protocol claims to be visible in the window. normally a client app
  // wouldn't care about them or want them taking up space
  filterProtocolClaims: false,

  monitorSession,
};

export default oidcConfig;
