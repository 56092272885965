import { localToAllDay, localFromAllDay } from 'utils/eventTimes.js';
import { addMinutes, addHours, isDate, differenceInMinutes } from 'utils/date-fns/index.js';
import { startOfDay } from 'date-fns';

function defaultEnd(start) {
  return addHours(start, 1);
}

function fixDurationFromStart(val, duration, { maxDate, requireEnd }) {
  const result = {
    start: val,
  };
  if (duration !== undefined) {
    result.end = addMinutes(val, duration);
  } else if (requireEnd) {
    result.end = defaultEnd(val);
  }
  if (maxDate && result.end > maxDate) {
    result.end = maxDate;
    result.start = addMinutes(maxDate, -duration);
  }
  return result;
}

function fixEndAfterStart(val, start) {
  if (val < start) {
    return defaultEnd(start);
  }
  return val;
}

function handleChange(prop, val, { value, onChange, withTime, requireEnd, maxDate }) {
  const { start, end, allDay } = value;
  const duration = isDate(start) && isDate(end) ? differenceInMinutes(end, start) : undefined;

  let newValue = { [prop]: val };
  if (prop === 'allDay') {
    newValue = allDay ? localFromAllDay(value) : localToAllDay(value);
  }
  if (prop === 'start' && isDate(val)) {
    newValue = fixDurationFromStart(val, duration, { maxDate, requireEnd });
  }
  if (prop === 'end' && isDate(val)) {
    newValue.end = fixEndAfterStart(val, start);
  }
  if (!withTime) {
    if (newValue.start) {
      newValue.start = startOfDay(newValue.start);
    }
    if (newValue.end) {
      newValue.end = startOfDay(newValue.end);
    }
    newValue.allDay = true;
  }
  onChange({ ...value, ...newValue });
}

export default handleChange;
