/*
 * ControlMenu Messages
 *
 * This contains all the text for the ControlMenu component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  close: {
    id: 'app.common.close',
    defaultMessage: 'Close',
  },
  publish: {
    id: 'app.components.ControlMenu.publish',
    defaultMessage: 'Publish',
  },
  publishModal: {
    id: 'app.components.ControlMenu.publishModal',
    defaultMessage: 'Publication Options',
  },
  revoke: {
    id: 'app.components.ControlMenu.revoke',
    defaultMessage: 'Unpublish',
  },
  view: {
    id: 'app.components.ControlMenu.view',
    defaultMessage: 'View',
  },
  edit: {
    id: 'app.common.edit',
    defaultMessage: 'Edit',
  },
  remove: {
    id: 'app.common.delete',
    defaultMessage: 'Delete',
  },
});
