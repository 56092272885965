import storage from 'utils/storage/index.js';
import { USERROLE_KEY } from '../constants.js';

/**
 * Find previous role from localStorage, if not found or not exists anymore, return first role
 * @param profile
 * @returns {*}
 */
export function findStoredRole(profile) {
  if (profile.roles.list.length === 0) {
    return 0;
  }
  const stored = storage.getItem(USERROLE_KEY);
  const selectedId = stored && stored[profile.userProfileId];
  return (selectedId && selectedId in profile.roles) ? selectedId : profile.roles.list[0];
}

export function updateStoredProfile(profile, roleId) {
  if (profile === undefined) {
    storage.removeItem(USERROLE_KEY);
    return;
  }
  const stored = storage.getItem(USERROLE_KEY) || {};
  stored[profile.userProfileId] = roleId;
  storage.setItem(USERROLE_KEY, stored);
}
