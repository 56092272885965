/*
 * CallbackPage Messages
 *
 * This contains all the text for the CallbackPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  redirecting: {
    id: 'app.containers.PrivateRoute.redirecting',
    defaultMessage: 'Redirecting...',
  },
  noProfile: {
    id: 'app.containers.PrivateRoute.noProfile',
    defaultMessage: 'No profile found or no role selected. You can enter a code to add a role or log in as a different user.',
  },
  enterCode: {
    id: 'app.containers.PrivateRoute.enterCode',
    defaultMessage: 'Enter Code',
  },
  changeUser: {
    id: 'app.containers.PrivateRoute.changeUser',
    defaultMessage: 'Change User',
  },
  wrongRole: {
    id: 'app.containers.PrivateRoute.wrongRole',
    defaultMessage: 'Please select a role as ',
  },
  missingRole: {
    id: 'app.containers.PrivateRoute.missingRole',
    defaultMessage: 'You do not have permission for this role, log in as a different user',
  },
  parent: {
    id: 'app.containers.PrivateRoute.parent',
    defaultMessage: 'parent',
  },
  employee: {
    id: 'app.containers.PrivateRoute.employee',
    defaultMessage: 'employee',
  },
  student: {
    id: 'app.containers.PrivateRoute.student',
    defaultMessage: 'student',
  },
  differentUser: {
    id: 'app.containers.PrivateRoute.differentUser',
    defaultMessage: 'Sign in as a different user',
  },
});
