/**
 *
 * PortfolioMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  PARENT_ROLE,
  EMPLOYEE_ROLE,
  STUDENT_ROLE,
  MODULES_PORTFOLIOPILOT_OPTION,
  MODULES_PORTFOLIOPILOT_GROUPS,
  MODULES_STUDENTPORTFOLIO,
  GROUPLEVELS_FOUNDATION,
} from 'containers/User/constants.js';
import Employee from './Employee.jsx';
import Student from './Student.jsx';
import Parent from './Parent.jsx';

function PortfolioMenu(props) {
  const { modules, role } = props;
  if (role.school.type === GROUPLEVELS_FOUNDATION) {
    return null;
  }
  const pilotModule = role.type === EMPLOYEE_ROLE ? MODULES_PORTFOLIOPILOT_OPTION : MODULES_PORTFOLIOPILOT_GROUPS;
  if (!modules.includes(MODULES_STUDENTPORTFOLIO) && !modules.includes(pilotModule)) {
    return null;
  }
  switch (role.type) {
    case EMPLOYEE_ROLE:
      return <Employee {...props} />;
    case PARENT_ROLE:
      return <Parent {...props} />;
    case STUDENT_ROLE:
      return <Student {...props} />;
    default:
      return null;
  }
}

PortfolioMenu.propTypes = {
  role: PropTypes.object.isRequired,
  modules: PropTypes.array.isRequired,
};

export default PortfolioMenu;
