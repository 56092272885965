export function getIdNumber({ userId, roleType, groupType, type, id } = {}) {
  return (10 * (userId || id)) + (roleType || groupType || type || 0);
}

export function getIdRole(idNumber, idField = 'userId') {
  return {
    [idField]: Math.floor(idNumber / 10),
    roleType: idNumber % 10,
  };
}

export function makeUserIdObject(users) {
  const result = { list: [] };
  users.forEach((user) => {
    const id = getIdNumber(user);
    result[id] = user;
    result.list.push(id);
  });
  return result;
}

export function getUserParams(key) {
  if (typeof key === 'object') {
    return {
      ...key,
      id: getIdNumber(key),
    };
  }
  return {
    id: key,
    ...getIdRole(key),
  };
}
