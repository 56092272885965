export const PAYMENT_STATUS_DRAFT = 1;
export const PAYMENT_STATUS_CREATED = 2;
export const PAYMENT_STATUS_SENT = 3;
export const PAYMENT_STATUS_REVOKED = 4;
export const PAYMENT_STATUS_BEING_CREATED = 15;

export const PMT_REQUEST_STATUS_CREATED = 10;
export const PMT_REQUEST_STATUS_PARTIAL = 40;
export const PMT_REQUEST_STATUS_COMPLETED = 70;
export const PMT_REQUEST_STATUS_REVOKED = 80;

export const PMT_TRANSACTION_STATUS_OPEN = 10;
export const PMT_TRANSACTION_STATUS_CANCELED = 20;
export const PMT_TRANSACTION_STATUS_PENDING = 30;
export const PMT_TRANSACTION_STATUS_AUTHORIZED = 40;
export const PMT_TRANSACTION_STATUS_EXPIRED = 50;
export const PMT_TRANSACTION_STATUS_FAILED = 60;
export const PMT_TRANSACTION_STATUS_PAID = 70;
export const PMT_TRANSACTION_STATUS_SCHEDULED = 1;
export const PMT_TRANSACTION_STATUS_PLANNED = 80;

export const isScheduled = (status) => [PMT_TRANSACTION_STATUS_PLANNED, PMT_TRANSACTION_STATUS_SCHEDULED].includes(status);

export const PMT_METHODS_CASH = 10;
export const PMT_METHODS_TRANSFER = 20;
export const PMT_METHODS_MOLLIE = 30;

export const PAYMENT_DEFAULT_ACCOUNT_KEY = 'payment-default-account';

export const SKIPMOLLIECHECKS = 'skipMollieChecks';
