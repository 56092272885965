/**
 * Call loadMore() when the sentry intersects with the root.
 */

import { useRef, useEffect, useState } from 'react';
import { useIntersection } from 'react-use';

function useSentryLoading({ root, rootMargin, enabled, loadMore }) {
  const sentryRef = useRef(null);
  const intersection = useIntersection(sentryRef, { root, rootMargin });
  const [delayedEnabled, setDelayedEnabled] = useState(enabled);

  // enabled may change before intersection was updated, prevent loadMore() called too faste
  useEffect(() => {
    const handler = setTimeout(() => {
      setDelayedEnabled(enabled);
    }, 1000);
    return () => clearTimeout(handler);
  }, [enabled, delayedEnabled]);

  useEffect(() => {
    const overlap = intersection?.intersectionRatio > 0;
    if (enabled && delayedEnabled && overlap) {
      setDelayedEnabled(false);
      loadMore();
    }
  }, [enabled, delayedEnabled, intersection, loadMore]);
  return sentryRef;
}

export default useSentryLoading;
