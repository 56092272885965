/*
 * EditProfile Messages
 *
 * This contains all the text for the EditProfile component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  avatarUpdated: {
    id: 'app.components.EditProfile.avatarUpdated',
    defaultMessage: 'The avatar has been updated',
  },
  avatarRemoved: {
    id: 'app.components.EditProfile.avatarRemoved',
    defaultMessage: 'The avatar has been removed',
  },
  editAvatar: {
    id: 'app.components.Banner.editAvatar',
    defaultMessage: 'Edit picture for {name}',
  },
});
