/**
 *
 * useStats
 */

import { useContext } from 'react';
import { StatsContext } from './StatsProvider.jsx';

function useStats() {
  return useContext(StatsContext);
}

export default useStats;
