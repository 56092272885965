import { useLocation } from 'react-router-dom';
import { idValues } from 'utils/arrays.js';
import useApi2 from './useApi2.js';
import useCommunities from './useCommunities.js';

function useGroupFromPathname(path) {
  const { pathname } = useLocation();
  const communities = useCommunities(true);
  const match = (path || pathname).match(/groups\D+(\d+)(\/portfolio)?/);
  const groupId = match && Number(match[1]);
  const portfolioUrl = match && match[2] ? `portfolio/groups/${groupId}` : null;
  const pfGroup = useApi2({ selector: portfolioUrl });
  const portfolioGroup = portfolioUrl && pfGroup;
  const group = groupId && idValues(communities).find((c) => c.groupId === groupId);
  return { group, portfolioGroup };
}

export default useGroupFromPathname;
