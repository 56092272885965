/**
 *
 * ConversationsMenu
 *
 */

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ListGroupItem } from 'react-bootstrap';
import { MODULES_CONVERSATIONS } from 'containers/User/constants.js';
import { useApi2 } from 'containers/Api2/index.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import NewConversationBadge from 'components/notifications/NewConversationBadge/index.jsx';
import { INBOX_ARCHIVED } from 'components/dashboard/Users/InboxStatus.jsx';
import messages from './messages.js';
import PathItem from './PathItem.jsx';
import ExpandItem from './ExpandItem.jsx';
import DynamicListGroup from './DynamicListGroup/index.jsx';

const PATHNAME = '/conversations/inboxes';
function ConversationsLink(props) { // eslint-disable-line react/prefer-stateless-function
  const { expanded, onClick, modules, pathname } = props;
  const hasConversations = modules.includes(MODULES_CONVERSATIONS);
  const [showArchived, toggleShowArchived] = useReducer((f) => !f, false);
  const inboxes = useApi2({ url: hasConversations && 'inboxes' });

  if (!hasConversations) {
    return null;
  }

  if (!inboxes.ready || inboxes.list.length < 2) {
    const href = inboxes.list?.length > 0 ? `${PATHNAME}/${inboxes.list[0]}` : PATHNAME;
    return (
      <PathItem to={href}>
        <AvatarWithText icon="ss-chat">
          <span>
            <FormattedMessage {...messages.conversations2} />
            <NewConversationBadge className="ms-3" />
          </span>
        </AvatarWithText>
      </PathItem>
    );
  }

  const counter = (id) => <NewConversationBadge inboxId={id} className="ms-3" />;

  function isArchived(inbox) {
    return inbox.status === INBOX_ARCHIVED;
  }

  const active = { ...inboxes, list: [] };
  const archived = { ...inboxes, list: [] };
  inboxes.list?.forEach((id) => {
    if (isArchived(inboxes[id])) {
      archived.list.push(id);
    } else {
      active.list.push(id);
    }
  });

  const noRedirect = pathname.startsWith(PATHNAME);
  return (
    <>
      <ExpandItem onClick={onClick} expanded={expanded}>
        <AvatarWithText icon="ss-chat" bsSize="small" active={pathname.startsWith(PATHNAME)}>
          <FormattedMessage {...messages.conversations2} />
          <NewConversationBadge className="ms-3" />
        </AvatarWithText>
      </ExpandItem>
      {expanded && (
        <div className="list-group-indent">
          <DynamicListGroup
            data={active}
            link={`${PATHNAME}/:id`}
            current={pathname}
            counter={counter}
            noRedirect={noRedirect}
          />
          {archived.list.length > 0 && (
            <>
              <ListGroupItem action variant="link" onClick={toggleShowArchived}>
                <AvatarWithText icon="ss-archive fs-5 text-muted" bsSize="small" className="small">
                  <FormattedMessage {...messages.showArchivedInboxes} values={{ showArchived }} />
                </AvatarWithText>
              </ListGroupItem>
              {showArchived && (
                <DynamicListGroup
                  data={archived}
                  link={`${PATHNAME}/:id`}
                  current={pathname}
                  counter={counter}
                  noRedirect={noRedirect}
                />
              )}
            </>
          )}
        </div>
      )}
    </>

  );
}

ConversationsLink.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  modules: PropTypes.array.isRequired,
};

export default ConversationsLink;
