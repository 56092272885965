/**
 *
 * AlertList
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withAlerts } from 'containers/Alerts/index.js';
import AlertItem from './AlertItem.jsx';

const Wrapper = styled.div`
  width: 640px;
  max-width: 100%;
  margin: 20px auto;
`;

function NcAlertList(props) {
  const { alerts, dismiss, shaded, errorsOnly } = props;
  const selectedAlerts = errorsOnly ? alerts.filter((alert) => alert.style === 'danger') : alerts;
  return selectedAlerts.map((alert) => (
    <AlertItem key={alert.id} alert={alert} shaded={shaded} onHide={dismiss(alert.id)} />
  ));
}

NcAlertList.Wrapper = Wrapper;

NcAlertList.propTypes = {
  alerts: PropTypes.array.isRequired,
  dismiss: PropTypes.func.isRequired,
  errorsOnly: PropTypes.bool,
  shaded: PropTypes.bool,
};

export default withAlerts(NcAlertList);
export { default as AlertItem } from './AlertItem.jsx';
