/**
 *
 * UiControl
 * Simple container to make change to Redux state
 * Add 'prop' string to indicate which property of state.ui will be changed
 * children will have onClick/onChange handlers
 * Set inital value in reducer
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { uiToggle, uiSet } from './actions.js';
import makeSelectUiControl from './selectors.js';

export class NcUiControl extends React.PureComponent {
  getProps() {
    const { prop, uicontrol, handleToggle, handleChange, noControl } = this.props;
    const currentVal = uicontrol[prop];
    if (typeof currentVal === 'boolean') {
      return { val: Number(currentVal), onClick: handleToggle(prop) };
    }
    if (noControl) {
      return { onChange: handleChange(prop) };
    }
    return { value: currentVal, onChange: handleChange(prop) };
  }

  render() {
    const { children } = this.props;
    return React.Children.map(children, (child) => React.cloneElement(child, this.getProps()));
  }
}

NcUiControl.propTypes = {
  prop: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  uicontrol: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  noControl: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  uicontrol: makeSelectUiControl(),
});

export function mapDispatchToProps(dispatch) {
  return {
    handleToggle: (prop) => (ev) => {
      ev.stopPropagation();
      return dispatch(uiToggle(prop));
    },
    handleChange: (prop) => (val) => dispatch(uiSet(prop, val.target ? val.target.value : val)),
  };
}

const UiControl = connect(mapStateToProps, mapDispatchToProps)(NcUiControl);

export default UiControl;

export { default as withUiControl } from './withUiControl.js';
export { default as useUiControl } from './useUiControl.js';
