import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import photobookImage from './images/photobook.png';
import messages from './messages.js';

const Wrapper = styled(Link)`
  margin: 10px 20px;
  text-align: left;
  height: 40px;
  border-radius: 20px;
  width: auto;
  img {
    width: 32px;
  }
`;

function PhotobookLink() {
  return (
    <Wrapper to="/photobook" className="btn btn-default">
      <img src={photobookImage} alt="" className="me-3" />
      <FormattedMessage {...messages.createPhotoBook} />
    </Wrapper>

  );
}

export default PhotobookLink;
