import { screenLog } from 'components/ScreenLog/index.jsx';

function webViewTest() {
  if (typeof window.authBridge !== 'undefined') {
    window.socsAccessToken = window.authBridge.getAccessToken();
    window.socsIsInAppWebView = true;
    window.socsWebViewEmulate = false;
  } else if (window.location.search.includes('webview')) {
    window.socsAccessToken = sessionStorage.getItem('socsAccessToken');
    if (!window.socsAccessToken) {
      throw new Error('No access token');
    }
    window.socsIsInAppWebView = true;
    window.socsWebViewEmulate = true;
  }
  const keys = Object.keys(window).filter((k) => k.startsWith('socs'));
  if (keys.length > 0) {
    keys.sort();
    screenLog('window keys', keys.join(', '));
  }
  return window.socsIsInAppWebView;
}

export default webViewTest;
