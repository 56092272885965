/**
*
* usePublishModal
*
*/

import { useState } from 'react';
import { useApi2call } from 'containers/Api2/index.js';
import useDialog from 'containers/Dialog/useDialog.js';
import mergePayload from 'containers/Api2/mergePayload.js';
import { intlTags } from 'utils/intlFunctions.jsx';
import doNothing from 'utils/doNothing.js';
import { CHANNEL_EXTERNAL } from './constants.jsx';
import messages from './messages.js';

export { default as PublishedIcons } from './PublishedIcons.jsx';

const CHANNEL_LINK = 99;

function usePublishModal(item) {
  const api2call = useApi2call();
  const { dlgClick } = useDialog();
  const [working, setWorking] = useState({});

  function handleChange(options, key) {
    setWorking((o) => ({ ...o, [key]: true }));
    api2call({
      ...options,
      withPromise: true,
    })
      .catch(doNothing)
      .finally(() => {
        setWorking((o) => ({ ...o, [key]: false }));
      });
  }

  function handlePublish(key, value, contactOptions) {
    const options = {
      method: value ? 'POST' : 'DELETE',
      url: `communityposts/${item.id}/publish`,
      selector: `communityposts/${item.id}`,
      reducer: mergePayload,
      body: { channels: [key], ...contactOptions },
      success: messages[value ? 'published' : 'revoked'],
    };
    handleChange(options, key);
  }

  function handleLink(val) {
    const options = {
      method: val ? 'POST' : 'DELETE',
      url: `communityposts/${item.id}/public`,
      selector: `communityposts/${item.id}`,
      reducer: mergePayload,
      success: val ? undefined : messages.revoked,
    };
    if (val) {
      return handleChange(options, CHANNEL_LINK);
    }
    const linkRevokeMessage = { ...messages.linkRevokeMessage, values: intlTags(['ul', 'li']) };
    return dlgClick({
      title: messages.linkRevokeTitle,
      message: linkRevokeMessage,
      confirmLabel: messages.linkRevokeConfirm,
      confirmStyle: 'danger',
      danger: true,
      size: 'default',
    }, () => handleChange(options, CHANNEL_LINK));
  }

  function handleShareExternal(options) {
    handlePublish(CHANNEL_EXTERNAL, true, options);
  }

  return { handleChange, handlePublish, handleLink, handleShareExternal, working };
}

export default usePublishModal;
