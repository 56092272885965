/**
 *
 * Icon
 *
 */

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { iconSize, styleColor } from 'utils/bsStyler.js';
import { FileIconMixin } from 'utils/files.js';

const Icon = styled.i`
  font-size: ${(props) => props.size || iconSize(props)};
  display: inline-block;
  font-weight: normal;
  transition: ${transition};
  transform: ${transform};
  margin-right: ${(props) => props.space ? '10px' : 0};
  color: ${styleColor};
  ${FileIconMixin};
  &.ss-external-link {
    opacity: 0.7;
    margin-left: 2px;
    font-size: 0.75em;
    height: 1em;
    line-height: 1.5em;
    vertical-align: top;
  }
  &:not(:last-child) {
    line-height: 0.66em;
  }
  &.icon-square {
    width: ${(props) => props.size || iconSize(props)};
  }
  `;

Icon.propTypes = {
  bsSize: PropTypes.string,
  className: PropTypes.string.isRequired,
  rotate: PropTypes.number,
};

export default Icon;

function transition(props) {
  return props.rotate === undefined ? '' : `transform ${props.theme.transitionDuration}`;
}

function transform(props) {
  const result = [];
  // result.push('translateY(12%)');
  if (props.rotate !== undefined) {
    result.push(`rotate(${props.rotate}deg)`);
  }
  if (props.inline) {
    result.push('translateY(15%)');
  }
  return result.join(' ');
}
