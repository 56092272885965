import { css } from 'styled-components';

const tabsMixin = css`
  .socs-tabs .nav-item {
    border: none;
    .nav-link{
      border: none;
      &:not(.active) {
        color: ${(p) => p.theme.gray};
      }
      /* Bump specificity by doubling classes */
      &.active,
      &.active:focus,
      &.active:hover {
        color: ${(p) => p.theme.brandPrimary};
        padding-bottom: 6px;
        border: none;
        border-bottom: 5px solid ${(p) => p.theme.brandPrimary};
        cursor: default;
      }
      &.disabled {
        opacity: 0.5;
      }
    }
  }
`;

export default tabsMixin;
