/**
 * In iframe view, send message on location change
 */
import { LOCATION_CHANGE } from 'connected-react-router';

// From: https://redux.js.org/advanced/middleware/
const locationMessage = () => (next) => (action) => {
  if (action.type === LOCATION_CHANGE && window !== window.top && !window.location.href.includes('silent.html')) {
    window.parent.postMessage(action.payload, '*');
  }
  return next(action);
};

export default locationMessage;
