/*
 * StartEndPicker Messages
 *
 * This contains all the text for the StartEndPicker component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.StartEndPicker.header',
    defaultMessage: 'Select period',
  },
  start: {
    id: 'app.components.StartEndPicker.start',
    defaultMessage: 'Start date',
  },
  end: {
    id: 'app.components.StartEndPicker.end',
    defaultMessage: 'End date',
  },
  time: {
    id: 'app.components.StartEndPicker.time',
    defaultMessage: '/time',
  },
  until: {
    id: 'app.components.StartEndPicker.until',
    defaultMessage: 'until',
  },
  upto: {
    id: 'app.components.StartEndPicker.upto',
    defaultMessage: 'up to and including',
  },
  allDay: {
    id: 'app.components.StartEndPicker.allDay',
    defaultMessage: 'All day  ',
  },
});
