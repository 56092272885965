/*
 *
 * Alerts reducer
 *
 */

import { fromJS } from 'immutable';
import { pick } from 'lodash-es';
import {
  ADD_ALERT, DISMISS_ALERT, CLEAR_ALERTS,
} from './constants.js';

const initialState = fromJS([]);

// eslint-disable-next-line default-param-last
function alertsReducer(state = initialState, action) {
  let parsed;
  switch (action.type) {
    case ADD_ALERT:
      parsed = parseAction(action);
      return parsed ? state.push(parsed) : state;
    case DISMISS_ALERT:
      return state.filter((v) => v.id !== action.id);
    case CLEAR_ALERTS:
      return state.filter((item) => item.style === 'success' || item.persistent);
    default:
      return state;
  }
}

export default alertsReducer;

function parseAction(action) {
  const result = pick(action, ['style', 'id', 'message', 'context', 'persistent', 'toast']);
  if (!action.error) {
    return result;
  }
  const { error } = action;
  const context = error.response ? pick(error, ['request', 'response']) : error.stack && error.stack.split(/\r?\n/)[0];
  const { message } = error;
  if (message === 'OK') {
    return null;
  }
  return { context, ...result, message };
}
