import { createSelector } from 'reselect';

/**
 * Direct selector to the uiControl state domain
 */
const selectUiControlDomain = (state) => state.get('uiControl');

/**
 * Other specific selectors
 */

/**
 * Default selector used by UiControl
 */

const makeSelectUiControl = (key) => createSelector(
  selectUiControlDomain,
  (substate) => key ? substate.toJS()[key] : substate.toJS(),
);

export default makeSelectUiControl;
export {
  selectUiControlDomain,
};
