/*
 * Select Messages
 *
 * This contains all the text for the Select component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  selectOne: {
    id: 'app.common.selectOne',
    defaultMessage: 'Select One',
  },
});
