import { dateString } from 'utils/timeFormat.js';

function getValue(value, field) {
  if (field.as) {
    return value;
  }
  if (field.type === 'date' && value instanceof Date) {
    return dateString(value);
  }
  return value ?? '';
}

export default getValue;
