import React from 'react';
import PropTypes from 'prop-types';
import { FormCheck } from 'react-bootstrap';
import Input from 'components/tags/Input/index.jsx';
import IntlString from 'components/media/IntlString/index.jsx';

function DialogPrompt(props) {
  const { rows, options, type, value, setValue } = props;
  const inputType = type || (rows > 1 ? 'textarea' : 'text');
  function handleChange(ev) {
    setValue(ev.target.value);
  }
  switch (inputType) {
    case 'textarea':
      return <Input className="form-control mt-4" autoFocus type={inputType} rows={rows} value={value} onChange={handleChange} />;
    case 'radio':
      return (
        <div className="mt-4">
          {options.map((option) => (
            <FormCheck key={option.value} id={`check_${option.value}`} type="radio" checked={value === option.value} onChange={() => setValue(option.value)} label={<IntlString message={option.label} />} />
          ))}
        </div>
      );
    default:
      return <Input className="form-control mt-4" autoFocus type={inputType} value={value} onChange={handleChange} />;
  }
}

DialogPrompt.propTypes = {
  rows: PropTypes.number,
  options: PropTypes.array,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
};

export default DialogPrompt;
