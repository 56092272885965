/*
 * SelectFiles Messages
 *
 * This contains all the text for the SelectFiles component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  image: {
    id: 'app.components.SelectFiles.image',
    defaultMessage: 'Select pictures',
  },
  video: {
    id: 'app.components.SelectFiles.video',
    defaultMessage: 'Select videos',
  },
  document: {
    id: 'app.components.SelectFiles.document',
    defaultMessage: 'Select documents',
  },
  any: {
    id: 'app.components.SelectFiles.any',
    defaultMessage: 'Select file',
  },
  longName: {
    id: 'app.components.SelectFiles.longName',
    defaultMessage: 'Filename too long (max. {max}): {name}',
  },
  wrongType: {
    id: 'app.components.SelectFiles.wrongType',
    defaultMessage: "File ''{name}'' is not a valid file type",
  },
  tooLarge: {
    id: 'app.components.SelectFiles.tooLarge',
    defaultMessage: "File ''{name}'' is too large ({size}MB, max. is {maxSize})",
  },
  partialDownload: {
    id: 'app.components.SelectFiles.partialDownload',
    defaultMessage: "File ''{name}'' is a partially downloaded file",
  },
});
