import webViewToken from 'containers/WebViewProvider/webViewToken.js';

export function makeCurrentRole(user) {
  const { profile, roleId } = user;
  return profile?.roles[roleId];
}

export function makeCurrentUser(user) {
  const { auth, loading, profile, active } = user;
  const role = makeCurrentRole(user);
  return {
    expiresAt: auth?.user?.expires_at,
    loading,
    active,
    hasRole: !!role,
    loggedIn: !!profile,
    role,
    profile,
  };
}

export async function makeCredentials(user) {
  const { auth, profile, roleId } = user;
  const result = {};
  const token = window.socsIsInAppWebView ? await webViewToken() : auth?.user?.access_token;
  if (token) {
    result.Authorization = `${auth.user.token_type} ${token}`;
  }
  const role = profile?.roles[roleId];
  if (role) {
    result.Schoolid = role.school.id;
    result.Roletypeid = role.type;
  }
  return result;
}
