/**
*
* UserAvatar
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import RoleDisplay from 'components/user/RoleDisplay.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import UserText from 'components/media/UserText/Loadable.js';
import { FormattedMessage } from 'react-intl';
import messages from '../messages.js';

function UserAvatar(props) {
  const { user, withRole, link, ...rest } = props;
  const { pathname } = window.location;
  const isAdmin = pathname.startsWith('/admin');
  function getName() {
    if (user.name) {
      return user.name;
    }
    return isAdmin ? user.accountName || user.alias || user.displayName : user.displayName;
  }
  const roleType = user?.roleType || user?.type;
  const name = user === null
    ? <span className="text-muted"><FormattedMessage {...messages.deletedUser} /></span>
    : <UserText inline>{getName()}</UserText>;
  const linkedName = link ? <Link to={link}>{name}</Link> : name;
  if (user && user.theme) {
    rest.group = true;
    rest.color = user.theme.themeColor.hex;
  }
  return (
    <AvatarWithText src={user && user.avatar} {...rest}>
      { withRole ? (
        <>
          <div className="fs-5 fw-semibold lh-sm">{linkedName}</div>
          {roleType && <div><RoleDisplay type={roleType} capitalized /></div>}
        </>
      ) : linkedName}
    </AvatarWithText>
  );
}

UserAvatar.propTypes = {
  user: PropTypes.object,
  link: PropTypes.string,
  withRole: PropTypes.bool,
};

export default UserAvatar;
