/*
 * CopyButton Messages
 *
 * This contains all the text for the CopyButton component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  copy: {
    id: 'app.common.copy',
    defaultMessage: 'Copy',
  },
  copied: {
    id: 'app.components.PublishModal.copied',
    defaultMessage: 'The link has been copied to the clipboard',
  },
});
