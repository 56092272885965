/**
*
* UploadAvatar
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CropImage from 'components/interaction/CropImage/index.jsx';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .progress {
    width: ${(p) => p.size === 'small' ? '70px' : '150px'};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

function UploadAvatar(props) {
  const { value, group, onChange, size } = props;
  return (
    <Wrapper size={size}>
      <CropImage value={value} group={group} onChange={onChange} size={size} />
    </Wrapper>
  );
}

UploadAvatar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  group: PropTypes.bool,
  size: PropTypes.string,
};

export default UploadAvatar;
