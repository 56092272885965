/**
 *
 * PathItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import { ListGroupItem } from 'react-bootstrap';
import cx from 'utils/cx.js';

function PathItem(props) { // eslint-disable-line react/prefer-stateless-function
  const { to } = props;
  const { pathname } = useLocation();
  const { disabled } = props;

  return (
    <ListGroupItem action={!disabled} as={Link} active={pathname.startsWith(to)} {...props} className={cx('d-block', disabled && 'opacity-50')} />
  );
}

PathItem.propTypes = {
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default PathItem;
