/**
 *
 * Recursively handle object
 *
 *
 */

import { last } from 'lodash-es';

/**
 * Invoke callback recursively, replacing subitems
 * @param value   object
 * @param key     key, can be undefined
 * @param depth  depth of recursion
 * @param callback  callback function, invoked with value, key, depth attributes
 * @returns {*} result from callback call
 */

function callRecursive(...rest) {
  const [value, key, depth] = rest;
  const callback = last(rest);
  const result = callback(value, key, depth);
  if (!canRecurse(result)) {
    return result;
  }
  if (Array.isArray(result)) {
    return result.map((item) => callRecursive(item, key, depth + 1, callback));
  }
  const keys = Object.keys(result);
  return keys.reduce((acc, k) => ({ ...acc, [k]: callRecursive(result[k], k, depth + 1, callback) }), {});
}

export default callRecursive;

export function canRecurse(value) {
  return value && typeof value === 'object' && Object.keys(value).length > 0;
}
