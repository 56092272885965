/*
 * PrintButton Messages
 *
 * This contains all the text for the PrintButton component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  useBrowserPrint: {
    id: 'app.components.PrintButton.userBrowserPrint',
    defaultMessage: 'Use the print function of your browser to print this page.',
  },
});
