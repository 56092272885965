import { serialize } from 'utils/urlUtils.js';

function replaceParams(str, params) {
  return str && str.replace(/:\w+/g, withParam(params));
}

function withParam(params) {
  return (match) => {
    const key = match.slice(1);
    if (params[key] === undefined || params[key] === null || params[key].toString() === 'NaN') {
      return match;
    }
    if (params[key] instanceof Date) {
      return params[key].toISOString();
    }
    if (typeof params[key] === 'object') {
      return serialize(params[key]);
    }
    return params[key];
  };
}

export default replaceParams;
