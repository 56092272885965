/**
*
* DebugInfo
*
*/

import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import config from 'config/index.js';
import classNames from 'classnames';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import Icon from 'components/media/Icon/index.js';
import { reportBug } from 'utils/sentry.js';
import storage from 'utils/storage/index.js';
import LocaleToggle from '../LocaleToggle.jsx';
import VideoToggle from '../VideoToggle.jsx';
import SettingsToggle from '../SettingsToggle.jsx';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: baseline;
  bottom: 0;
  right: 0;
  z-index: 9999;
  .dropdown-menu i {
    width: 1em;
  }
`;

function initEnabled(keys) {
  const enabled = {};
  keys.forEach((key) => {
    enabled[key] = storage.getItem(key);
  });
  return enabled;
}

function DebugInfo() {
  const { env, settingVars } = config;
  const [enabled, setEnabled] = useState(initEnabled(settingVars));
  const someEnabled = useMemo(() => Object.values(enabled).some(Boolean), [enabled]);

  function handleChange(key, value) {
    setEnabled({ ...enabled, [key]: value });
    if (['forceProduction', 'localServer'].includes(key)) {
      window.location.reload();
    }
  }

  if (env === 'production') {
    return null;
  }
  const classList = classNames(['ss-burger', { 'text-danger': someEnabled }]);
  const title = <Icon className={classList} bsSize="large" />;

  function handleToggle(flag) {
    if (flag) {
      document.body.classList.add('with-debug-menu');
    } else {
      document.body.classList.remove('with-debug-menu');
    }
  }

  return (
    <Wrapper className="d-print-none">
      <DropdownButton
        id="debug-menu"
        variant="link"
        title={title}
        onToggle={handleToggle}
        drop="up"
        align="end"
        className="no-caret"
      >
        {settingVars.map((key, idx) => (
          <SettingsToggle eventKey={idx + 10} key={key} name={key} onChange={(v) => handleChange(key, v)} />
        ))}
        <VideoToggle eventKey="2" onChange={(v) => handleChange('videoEnabled', v)} />
        <LocaleToggle eventKey="1" />
        <Dropdown.Item eventKey="3" onClick={reportBug}>
          <div className="d-flex">
            <span className="flex-grow-1">Report Bug</span>
            <Icon className="ss-bug ms-3" />
          </div>
        </Dropdown.Item>
      </DropdownButton>
    </Wrapper>
  );
}

export default DebugInfo;
