/**
 *  Show icons for the published channels
 */

import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/media/Icon/index.js';
import { CHANNEL_PROPS } from './constants.jsx';

function PublishedIcons(props) {
  const { channels, publicUrl, className } = props;
  if (!channels) {
    return null;
  }
  if (channels.length === 0 && publicUrl) {
    return <Icon className={`ss-link ${className}`} />;
  }
  return channels
    .filter((ch) => CHANNEL_PROPS[ch])
    .map((ch) => <Icon key={ch} className={`${CHANNEL_PROPS[ch].icon} ${className}`} />);
}

PublishedIcons.propTypes = {
  channels: PropTypes.array,
  publicUrl: PropTypes.string,
  className: PropTypes.string,
};

export default PublishedIcons;
