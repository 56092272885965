/**
*
* InfiniteScroll
* Selects auto or manual scroll
*/

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import Spinner from 'components/Spinner/index.jsx';
import useSafeState from 'utils/useSafeState.js';
import sleep from 'utils/sleep.js';
import useTabbing from './useTabbing.js';
import useSentryLoading from './useSentryLoading.js';
import messages from './messages.js';

function InfiniteScroll(props) {
  const { children, hasMore, loadMore, loading, getStyle } = props;
  // Following states are set in async function, useSafeState to avoid error
  const [style, setStyle] = useSafeState(getStyle?.());
  const tabbing = useTabbing();
  const rootRef = useRef(null);
  const rootNode = rootRef.current;
  const enabled = hasMore && !loading;

  // Set ready and style when new rendering done
  async function afterRendering() {
    if (getStyle) {
      await sleep();
      setStyle(getStyle(rootNode));
    }
  }

  useEffect(() => {
    if (!loading) {
      afterRendering();
    }
  }, [loading, getStyle]); // eslint-disable-line react-hooks/exhaustive-deps

  const sentryRef = useSentryLoading({
    root: rootRef.current,
    rootMargin: '0px 0px 200px 0px',
    enabled,
    loadMore,
  });

  const body = (
    <>
      {children}
      {!tabbing && <div className="scroll-sentry" ref={sentryRef} />}
      {loading && <Spinner />}
      {!loading && tabbing && hasMore && (
        <footer className="p-4">
          <Button onClick={loadMore}>
            <FormattedMessage {...messages.loadMore} />
          </Button>
        </footer>
      )}
    </>
  );

  if (!getStyle) {
    return body;
  }

  return (
    <div style={style} className="no-print-max-height" ref={rootRef}>
      {body}
    </div>
  );
}

InfiniteScroll.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  getStyle: PropTypes.func,
};

export default InfiniteScroll;
