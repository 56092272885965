/*
* ActivateEmail
* @desc: get the workspace id for the student and redirect
*/
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi2call } from 'containers/Api2/useApi2.js';
import { ERROR_MESSAGES } from 'containers/User/constants.js';

import useCurrentAuth from '../User/UserProvider/useCurrentAuth.js';

function ActivateEmail() {
  const { removeUser } = useCurrentAuth();

  const navigate = useNavigate();
  const api2call = useApi2call();
  const { emailId, pendingId, hash } = useParams();

  useEffect(() => {
    removeUser();
    api2call({
      url: `useraccounts/emailaddresses/${emailId}/validate`,
      method: 'POST',
      body: {
        pendingId: Number(pendingId),
        hash,
      },
      error: { ...ERROR_MESSAGES, 409: 'OK' },
      noReport: [409],
      withPromise: true,
    })
      .then(() => navigate('/register/success'))
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          navigate('/register/success', { replace: true });
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export default ActivateEmail;
