/**
 * Implement storage object, based on availability,
 * in order of preference: localStorage, sessionStorage or CookieStorage
 * see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 */

import storageAvailable from './storageAvailable.lib.js';
import CookieStorage from './CookieStorage.js';

export function getStorage(sessionFirst) {
  const storageTypes = ['localStorage', 'sessionStorage'];
  if (sessionFirst) {
    storageTypes.reverse();
  }
  const result = storageTypes.find(storageAvailable);
  return result ? window[result] : new CookieStorage();
}
