/**
*
* MetaTags
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

function MetaTags(props) {
  const { vars, tags, ...otherTags } = props;
  const allTags = { ...tags, ...otherTags };
  const intl = useIntl();
  const getContent = (message) => {
    if (Array.isArray(message)) {
      return message.map(getContent).join(' - ');
    }
    return message && (message.id ? intl.formatMessage(message, vars) : message);
  };
  const title = (getContent(allTags.title) || '').replace('/', '-'); // Issue title is used as filename for save/print
  const metaFields = ['description'];
  const meta = Object.keys(allTags).filter((name) => metaFields.includes(name)).map((name) => ({ name, content: getContent(allTags[name]) }));
  return <Helmet title={title} meta={meta} />;
}

MetaTags.propTypes = {
  tags: PropTypes.object,
  vars: PropTypes.object,
};

export default MetaTags;
