/**
 *
 * PageLayout
 *
 */

import React, { useRef, useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';
import useHasFeature from 'config/useHasFeature.js';
import Dialog from 'containers/Dialog/index.jsx';
import SupportWidget from 'components/SupportWidget/Loadable.js';
import useCurrentUser from 'containers/User/useCurrentUser.js';
import HashPopup from 'containers/HashPopup/index.jsx';
import { STUDENT_ROLE } from 'containers/User/constants.js';
import ErrorBoundary from 'components/ErrorBoundary/index.jsx';
import Banner from 'components/Banner/index.jsx';
import IEWarning from 'components/IEWarning/index.js';
import ScreenLog, { showScreenLog } from 'components/ScreenLog/index.jsx';
import DebugInfo from 'components/debug/DebugInfo/index.jsx';
// Import CSS reset and Global Styles
import GlobalStyles from 'styles/global-styles.js';
import SupportWidgetStyle from 'components/SupportWidget/SupportWidgetStyle.js';
import Userlane from 'components/Userlane/index.jsx';
import Header from './Header/index.jsx';
import Sidebar from './Sidebar/index.jsx';
import { PageWrapper, BodyWrapper } from './wrappers.jsx';

function PageLayout() {
  const [showSidebar, setShowSidebar] = useState(false);
  const user = useCurrentUser();
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const isManage = pathname.startsWith('/manage/');
  const pathRef = useRef(pathname);
  if (!isManage) {
    pathRef.current = pathname;
  }

  const isPrint = useMedia('print') || pathname.startsWith('/print/');
  const isWebView = window.socsIsInAppWebView || window !== window.top;
  const isTop = window.self === window.top;
  const hasUserlane = useHasFeature('userlane') && isTop;
  const isIE = /MSIE|Trident/.test(window.navigator.userAgent);

  const withSidebar = user.hasRole && !isWebView && !isPrint;
  const closeSidebar = () => setShowSidebar(false);
  useEffect(closeSidebar, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps
  if (hash[1] === '/') {
    navigate(hash.slice(1), { replace: true });
    return null;
  }

  return (
    <>
      <GlobalStyles />
      {isTop && <SupportWidgetStyle />}
      {hasUserlane && <Userlane />}
      {showScreenLog() && <ScreenLog />}
      <PageWrapper id="page-wrapper" className={isWebView ? 'webview' : ''} loading={user.loading ? 'true' : ''}>
        <ErrorBoundary>
          <Header role={user.role} onClick={() => setShowSidebar((f) => !f)} />
          {user.role && user.role.type !== STUDENT_ROLE && !isWebView && !isPrint && isTop && <SupportWidget />}
          <BodyWrapper withSidebar={withSidebar}>
            {withSidebar && (
              <Sidebar show={showSidebar} onHide={closeSidebar} />
            )}
            <main id="main-content" className="overflow-y-auto">
              <Banner />
              {isIE && <IEWarning />}
              <Outlet />
            </main>
          </BodyWrapper>
          <Dialog />
          <HashPopup />
        </ErrorBoundary>
        <DebugInfo user={user} />
      </PageWrapper>
    </>
  );
}

export default PageLayout;
