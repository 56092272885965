/**
 *
 * Banner
 *
 */

import React from 'react';
import useCurrentUser from 'containers/User/useCurrentUser.js';
import { useLocation } from 'react-router-dom';
import useSelectFromLocation from 'containers/Api2/useSelectFromLocation.js';
import SchoolBanner from './SchoolBanner.jsx';
import GroupBanner from './GroupBanner.jsx';
import UserBanner from './UserBanner.jsx';
import SocsBanner from './SocsBanner.jsx';

function Banner() {
  const { pathname } = useLocation();
  const current = useSelectFromLocation();
  const user = useCurrentUser();

  if (pathname.startsWith('/admin')) {
    return null;
  }
  if (pathname === '/newsletters') {
    return <SchoolBanner />;
  }
  if (pathname === '/portfolio/library') {
    return <SchoolBanner />;
  }
  if (/\/portfolio\/\d+/.test(pathname)) {
    return <UserBanner />;
  }
  if (current) {
    if (/\/students?/.test(pathname)) {
      return <UserBanner />;
    }
    if (/^\/(groups|communities|archive)/.test(pathname)) {
      return <GroupBanner group={current} />;
    }
    if (/\/public/.test(pathname)) {
      return <SchoolBanner />;
    }
  }
  if (pathname === '/home' || /\/communitypost/.test(pathname)) {
    return <SchoolBanner />;
  }
  if (user.loading || user.loggedIn) {
    return null;
  }
  return <SocsBanner />;
}

export default Banner;
