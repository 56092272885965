/*
 *
 * Api2 reducer
 *
 */

import { fromJS, OrderedSet, List } from 'immutable';
import { last } from 'lodash-es';
import { USERROLE_SET } from 'containers/User/constants.js';
import { getParam } from 'utils/urlUtils.js';
import {
  API2_CALL_REQUEST, API2_CALL_ERROR, API2_CALL_SUCCESS, API2_UPDATE,
} from './constants.js';
import parsePayload from './parsePayload.js';

const initialState = fromJS({});
const times = {};

function api2Reducer(paramState, action) {
  const state = paramState || initialState;
  const { options, payload, roleId } = action;
  if (options && options.selector === '-') {
    return state;
  }
  const prop = options && (options.busyProp || (options.method === 'GET' ? 'loading' : 'working'));
  const path = options?.selector?.split('/');
  const signature = options && { url: options.url, roleId };

  function timingLog(label, reset) {
    const { timing, url } = options;
    if (!timing) {
      return;
    }
    const t1 = Date.now();
    const logItems = ['timing', label, url];
    if (!reset && times[signature]) {
      logItems.push(t1 - times[signature]);
    }
    console.log(...logItems); // eslint-disable-line no-console
    times[signature] = Date.now();
  }

  switch (action.type) {
    case API2_CALL_REQUEST: {
      timingLog('start', true);
      if (shouldReplace(options) || !state.getIn(path)) {
        return state.setIn(path, fromJS({ [prop]: true, ready: false, error: false, signature }));
      }
      const ready = Boolean(options.busyProp);
      return state.mergeIn(path, { [prop]: true, ready, error: false, signature });
    }
    case API2_CALL_SUCCESS: {
      timingLog('success');
      const parsedPayload = parsePayload(payload, options);
      timingLog('parsed');
      const result = onSuccess(state, {
        ...action,
        path,
        prop,
        payload: parsedPayload,
      });
      timingLog('stored');
      return result;
    }
    case API2_CALL_ERROR:
      return state.mergeIn(path, { [prop]: false, error: true });
    case API2_UPDATE:
      return state.updateIn(path, options.updater);
    case USERROLE_SET:
      return initialState;
    default:
      return state;
  }
}

function onSuccess(state, action) {
  const { options, path, prop } = action;
  const { reducer, method, replaceOnSuccess } = options;
  const status = { [prop]: false, ready: true };
  if (reducer) {
    const subState = state.getIn(path) || {};
    const reduced = reducer(subState.toJS ? subState.toJS() : subState, action);
    if (reduced === null || typeof reduced !== 'object') {
      return state.setIn(path, reduced);
    }
    return state.setIn(path, fromJS(reduced)).mergeIn(path, status);
  }
  const nextState = replaceOnSuccess ? state.setIn(path, fromJS({})) : state;
  switch (method) {
    case 'GET':
      return getHandler(nextState, action).mergeIn(path, status);
    case 'POST':
      return postHandler(nextState, action).mergeIn(path, status);
    case 'PUT':
    case 'PATCH':
      return putHandler(nextState, action).mergeIn(path, status);
    case 'DELETE':
      return deleteHandler(nextState, action);
    default:
      throw new Error(`Method not recognized: ${method}`);
  }
}

function shouldReplace(options) {
  const { url, replace, refresh, method } = options;
  if (replace !== undefined) {
    return replace;
  }
  if (refresh) {
    return false;
  }
  if (method !== 'GET') {
    return false;
  }
  const offset = getParam(url, 'offset');
  return !offset || Number(offset) === 0;
}

function getHandler(state, action) {
  const { path, payload } = action;
  if (!payload) {
    return state;
  }
  if (payload.id) {
    const parentPath = last(path) == payload.id ? path.slice(0, -1) : path; // eslint-disable-line eqeqeq
    const parentAction = { ...action, path: parentPath };
    return insertSingleItem(state, parentAction);
  }
  if (payload.list) {
    return insertMultipleItems(state, action);
  }
  return state.mergeIn(path, payload);
}

function putHandler(state, action) {
  const { path, payload, options } = action;
  if (payload) {
    if (payload.list && !payload.id) {
      return insertMultipleItems(state, action);
    }
    const stateWithPayload = options.merge ? state.mergeIn(path, payload) : state.setIn(path, fromJS(payload));
    if (!options.wasPending || options.isPending) {
      return stateWithPayload;
    }
    // Post not pending anymore
    const parentPath = path.slice(0, -1);
    return stateWithPayload.updateIn([...parentPath, 'pendingCount'], dec).updateIn([...parentPath, 'totalCount'], inc);
  }
  // If API does not return updated item, merge options.body with existing
  return state.mergeIn(path, parsePayload(options.body, options));
}

function postHandler(state, action) {
  const { path, options, payload } = action;
  if (!payload) {
    return state;
  }
  if (payload.id) {
    const countProp = options.isPending ? 'pendingCount' : 'totalCount';
    return insertSingleItem(state, action).updateIn([...path, countProp], inc);
  }
  if (payload.list) {
    return insertMultipleItems(state, action);
  }
  return state.mergeIn(path, payload);
}

function deleteHandler(state, action) {
  const { path, payload, options } = action;
  if (payload) {
    return putHandler(state, action);
  }
  const deletedId = path[path.length - 1];
  const parentPath = path.slice(0, -1);
  const listFilter = (list) => list?.filterNot((id) => id == deletedId); // eslint-disable-line eqeqeq
  const countProp = options.wasPending ? 'pendingCount' : 'totalCount';
  return state.updateIn([...parentPath, 'list'], listFilter).updateIn([...parentPath, countProp], dec).deleteIn(path);
}

function insertSingleItem(state, action) {
  const { path, payload, options } = action;
  const { refresh, merge, append } = options;
  const current = state.getIn(path);
  const { id } = payload;
  const currentItem = current.get(id.toString())?.toJS();
  const newItem = currentItem && (refresh || merge) ? { ...currentItem, ...payload } : payload;
  const list = updatedList(getList(current), id, append);
  const merged = { [id]: newItem, list };
  return state.mergeIn(path, merged);
}

function insertMultipleItems(state, action) {
  const { path, payload, options } = action;
  const pathState = state.getIn(path);
  function getMergedList() {
    switch (options.method) {
      case 'PUT':
        return payload.list;
      case 'POST': // New items on top
        return mergedList(payload.list, getList(pathState));
      default:
        return mergedList(getList(pathState), payload.list);
    }
  }
  const list = getMergedList();
  const totalCount = pathState?.has('totalCount') ? pathState.get('totalCount') + payload.list.length : undefined;
  return state.mergeIn(path, { totalCount, ...payload, list });
}

function getList(state) {
  return state?.get('list') || fromJS([]);
}

function mergedList(list, arr) {
  return List(OrderedSet(list).union(arr));
}

function updatedList(list, id, append) {
  if (list.includes(id)) {
    return list;
  }
  return append ? list.push(id) : list.unshift(id);
}

export function inc(count) {
  return (count || 0) + 1;
}

export function dec(count) {
  if (count <= 0) {
    throw new Error('Cannot decrease counter');
  }
  return count - 1;
}

export default api2Reducer;
