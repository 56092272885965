/* eslint-disable import/no-import-module-exports */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';
import '@formatjs/intl-locale/polyfill';

// Import all the third party stuff
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

// Import root app
import RootApp from './containers/App/RootApp.jsx';
const MOUNT_NODE = document.getElementById('app');
const root = createRoot(MOUNT_NODE);
const TEST = (typeof process !== 'undefined') && process.env.TEST;

const appComponent = TEST ? <RootApp /> : <StrictMode><RootApp /></StrictMode>;

root.render(appComponent);

const serviceWorker = '/sw_s3.js?v=1';
if ('serviceWorker' in navigator) {
  // eslint-disable-next-line no-console
  console.log('Registering ServiceWorker', serviceWorker);
  navigator.serviceWorker.register(serviceWorker).then((registration) => {
    // Registration was successful
    // eslint-disable-next-line no-console
    console.log('ServiceWorker registration successful with scope: ', registration.scope);
  }, (err) => {
    // registration failed :(
    // eslint-disable-next-line no-console
    console.log('ServiceWorker registration failed: ', err);
  });
}
