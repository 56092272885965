/**
*
* MaterialsList
*
*/

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import Spinner from 'components/Spinner/index.jsx';
import UserListItem from 'components/user/UserListItem.jsx';
import Icon from 'components/media/Icon/index.js';
import UserText from 'components/media/UserText/Loadable.js';
import { idValues } from 'utils/arrays.js';
import messages from '../messages.js';

const Wrapper = styled.div`
  .list-group-item.list-group-item {
    border-radius: 0;
    padding: 10px 5px;
    @media print {
      padding: 10px 0;
    }
  }
`;

const HeaderButton = styled(Button).attrs({ variant: 'link' })`
  &.btn {
    width: 100%;
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
  }
`;

const HeaderItem = styled(ListGroupItem)`
  &.list-group-item.list-group-item {
    background-color: ${(p) => p.theme.grayLighter};
    color: ${(p) => p.theme.gray};
    padding: 5px;
    @media print {
      background-color: #fff;
      border-top: 1px solid ${(p) => p.theme.tableBorderColor};
      border-bottom: 1px solid ${(p) => p.theme.tableBorderColor};
      .material-name {
        font-weight: 700;
        font-size: 0.875rem;
      }
      :not(:first-child) {
        padding-top: 20px;
      }
    }
  }
`;

const Material = styled.div`
  @media print {
    break-inside: avoid;
  }
`;

function MaterialsList(props) {
  const { materials, show, className } = props;
  const [expanded, toggleShow] = useReducer((f) => !f, show);

  if (!materials) {
    return null;
  }
  const loading = materials.loading && !materials.list;
  return (
    <Wrapper className={className}>
      { !show && (
        <HeaderButton onClick={toggleShow}>
          <FormattedMessage {...messages.view} />
          <span><Icon className="ss-directionright" rotate={expanded ? -90 : 90} /></span>
        </HeaderButton>
      )}
      { expanded && (loading ? <Spinner /> : (
        <ListGroup className="small">
          { idValues(materials).map((mat) => (
            <Material key={mat.id}>
              <HeaderItem className="d-flex justify-content-between">
                <span className="material-name"><UserText inline>{mat.name}</UserText></span>
                <span className="ms-2 text-nowrap">
                  (
                  {mat.backing.reserved}
                  /
                  {mat.backing.needed}
                  )
                </span>
              </HeaderItem>
              {mat.backers && mat.backers.map((backer) => (
                <UserListItem key={backer.user.id} user={backer.user}>{backer.amount}</UserListItem>
              ))}
            </Material>
          ))}
        </ListGroup>
      ))}
    </Wrapper>
  );
}

MaterialsList.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  materials: PropTypes.object,
};

export default MaterialsList;
