/**
 * PrivateRoute
 * Route checks for logged in user, if not, redirects to login
 *
 */

import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate, Link, Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Alert, Button } from 'react-bootstrap';
import Main from 'components/PageLayout/Main/index.jsx';
import useSetRoleForCode from 'containers/User/useSetRoleForCode.js';
import { TEST_OIDC } from 'containers/User/constants.js';
import useUserContext from 'containers/User/useUserContext.js';
import useAlert from 'containers/Alerts/useAlert.js';
import Spinner from 'components/Spinner/index.jsx';
import sessionConsole from 'utils/sessionConsole.js';
import messages from './messages.js';

function PrivateRoute() {
  const userContext = useUserContext();
  const { auth, profile, roleId, setUserRole, loading } = userContext;
  const { signinRedirect, removeUser, isAuthenticated } = auth;
  const [newRoute, setNewRoute] = useState(null);
  const location = useLocation();
  const [errorState, setErrorState] = useState(auth.loginError ? { loginError: auth.loginError, location } : {});
  const setRoleForCode = useSetRoleForCode();
  const redirecting = useRef(0);
  const { pathname, hash } = location;
  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const connectionCode = search.get('connectioncode');
  const { addAlert } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    if (TEST_OIDC) {
      sessionConsole.log('PrivateRoute', { isAuthenticated, loading });
    }
  }, [loading, isAuthenticated]);

  useEffect(() => {
    if (newRoute) {
      navigate(newRoute);
    }
  }, [newRoute]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    redirecting.current = false;
  }, [location, search]);

  function asString(searchParams) {
    const result = searchParams.toString();
    return result ? `?${result}` : '';
  }

  function omitSearchComponent(component) {
    search.delete(component);
    return `${pathname}${asString(search)}${hash}`;
  }

  function checkRole() {
    const newRoleId = search.get('role');
    if (newRoleId) {
      if (!profile.roles[newRoleId]) {
        setErrorState(((prevState) => {
          if (!prevState.loginError) {
            addAlert(messages.missingRole, 'danger');
          }
          return { loginError: 'role', location };
        }));
        return false;
      }
      if (Number(newRoleId) !== roleId) {
        setUserRole(Number(newRoleId));
      }
      setNewRoute(omitSearchComponent('role'));
      return false;
    }
    if (connectionCode) {
      setRoleForCode(connectionCode);
      setNewRoute(omitSearchComponent('connectioncode'));
      return false;
    }
    return true;
  }

  function alert(message, style) {
    return (
      <div className="shaded p-5">
        <Alert className="alert-shaded" variant={style}>
          {message.id ? <FormattedMessage {...message} /> : message}
        </Alert>
      </div>
    );
  }

  function signOut() {
    removeUser();
    signIn();
  }

  function signIn() {
    redirecting.current = true; // Avoid multiple redirects on re-rendering
    const state = { path: `${pathname}${asString(search)}` };
    setTimeout(() => {
      signinRedirect({ state });
    }, 0);
  }

  function runChecks() {
    const { loginError, location: errorLocation } = errorState;
    if (loginError && errorLocation === location) {
      return (
        <Button onClick={signOut} bsSize="large" variant="primary">
          <FormattedMessage {...messages.differentUser} />
        </Button>
      );
    }

    if (redirecting.current || window.redirecting) {
      return alert(messages.redirecting, 'info');
    }
    // If not logged in, redirect to login page
    if (!isAuthenticated) {
      if (window.socsIsInAppWebView) {
        return alert('Waiting for token', 'warning');
      }
      signIn();
      return alert(messages.redirecting, 'info');
    }

    // If logged in, but no role selected, show error
    if (!roleId) {
      return (
        <>
          { alert(messages.noProfile, 'warning') }
          <div className="d-flex align-items-baseline justify-content-around">
            <Button as={Link} size="lg" to="/register"><FormattedMessage {...messages.enterCode} /></Button>
            <Button size="lg" onClick={removeUser}><FormattedMessage {...messages.changeUser} /></Button>
          </div>
        </>
      );
    }

    if (!checkRole()) {
      return null;
    }

    return null;
  }

  if (!profile) {
    if (loading) {
      return auth.user ? alert('Loading Profile...') : alert('Authenticating...');
    }
    switch (auth.activeNavigator) {
      case 'signinSilent':
        return alert('Signing you in...');
      case 'signoutRedirect':
        return alert('Signing you out...');
      default:
    }
  }

  const error = runChecks();
  if (redirecting.current) {
    return (
      <div><Spinner /></div>
    );
  }
  if (error) {
    return <Main shaded padding width="480px">{error}</Main>;
  }
  return <Outlet />;
}

export default PrivateRoute;
