/**
*
* DatePicker
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { date } from 'yup';
import { createGlobalStyle } from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useIntl } from 'react-intl';
import { addMinutes, getLocale, parse, set, isSameDay, endOfDay } from 'utils/date-fns/index.js';
import messages from './messages.js';
import FormControlWrapper from '../Form4/FormControlWrapper.jsx';

export const DatePickerStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    width: auto;
    min-width: 12em;
  }
  .react-datepicker {
    font-size: 0.875rem !important;
  }

  .react-datepicker__current-month {
    font-size: 1rem !important;
  }

  .react-datepicker__header {
    padding-top: 6px !important;
  }

  .react-datepicker__navigation {
    top: 0.875rem !important;
  }

  .react-datepicker__day-name, .react-datepicker__day {
    margin: 5px !important;
  };

  .react-datepicker__close-icon::after {
    background-color: transparent;
    color: ${(p) => p.theme.gray};
    font-size: 1rem;
    font-weight: 500;
  }
`;

function MyDatePicker(props) {
  const { onChange, showTimeSelect, minDate, className } = props;
  const { value, ...rest } = props;

  const intl = useIntl();
  const locale = getLocale();
  const timeFormat = locale.formatLong.time({ width: 'short' });
  let dateFormat = locale.formatLong.date({ width: 'short' });
  if (showTimeSelect) {
    dateFormat = `${dateFormat} ${timeFormat}`;
  }

  function handleChangeRaw(ev) {
    const dateStr = ev.target.value;
    if (showTimeSelect && /^[012]?\d:\d\d$/.test(dateStr)) {
      const [hours, minutes] = dateStr.split(':').map(Number);
      const baseDate = minDate || new Date();
      const datetime = set(baseDate, { hours, minutes });
      onChange(datetime);
      return;
    }
    const dateObject = dateStr ? parse(dateStr, dateFormat, new Date()) : null;
    if (dateObject instanceof Date && !Number.isNaN(dateObject.getTime())) {
      onChange(dateObject);
    }
  }

  const defaultProps = {
    timeFormat,
    dateFormat,
    className: 'form-control',
    locale,
    autoComplete: 'off',
    timeCaption: intl.formatMessage(messages.time),
  };
  const newProps = { ...defaultProps, ...rest, selected: value, onChangeRaw: handleChangeRaw };
  if (minDate && value && isSameDay(minDate, value)) {
    newProps.minTime = addMinutes(minDate, 1);
    newProps.maxTime = endOfDay(minDate);
  }
  return (
    <FormControlWrapper className={className} border={0}>
      <DatePickerStyle />
      <DatePicker {...newProps} />
    </FormControlWrapper>
  );
}

MyDatePicker.getSchema = (options) => {
  const { required, attributes } = options;
  let result = date();
  if (required) {
    result = result.required();
  }
  if (attributes?.minDate) {
    result = result.min(attributes.minDate);
  }
  return result;
};

MyDatePicker.propTypes = {
  value: PropTypes.object,
  minDate: PropTypes.object,
  showTimeSelect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default MyDatePicker;
