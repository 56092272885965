import { css } from 'styled-components';

const cardMixin = css`
  .card-title {
    font-weight: 300;
  }
  .card {
    margin-bottom: 0.945rem;
  }
`;

export default cardMixin;
