import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectApi2TopDomain, selectFromLocation } from './selectors.js';

function useSelectFromLocation() {
  const { pathname } = useLocation();
  const api2TopDomain = useSelector(selectApi2TopDomain);
  return useMemo(() => selectFromLocation(api2TopDomain, pathname), [api2TopDomain, pathname]);
}

export default useSelectFromLocation;
