/**
*
* PosAbsoluteFull
*
*/

// import React from 'react';
import styled, { css } from 'styled-components';

export const centerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PosAbsoluteFullCss = css`
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PosAbsoluteFull = styled.div`
 ${PosAbsoluteFullCss};
 ${(p) => p.center ? centerCss : ''}
 background-color: ${(p) => p.shaded ? p.theme.grayLighter : 'transparent'};
`;

export default PosAbsoluteFull;
