/**
 *
 * withAddAlert
 * Adds apiFetch method to children to access apiFetch actions for all entities
 */

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useCreateSelector from 'utils/useCreateSelector.js';
import { addAlert, addError, dismissAlert } from './actions.js';
import { selectAlertsDomain } from './selectors.js';

function useAlert() {
  const dispatch = useDispatch();
  const alerts = useCreateSelector(
    selectAlertsDomain,
    (substate) => substate.toJS(),
  );
  return useMemo(() => ({
    alerts,
    dismiss: (index) => () => dispatch(dismissAlert(index)),
    addAlert: (message, style, persistent) => dispatch(addAlert(message, style, persistent)),
    addError: (error, context, persistent) => dispatch(addError(error, context, persistent)),
    addDelayed: (message, style) => setTimeout(() => dispatch(addAlert(message, style)), 100),
  }), [dispatch, alerts]);
}

export default useAlert;
