import { takeEvery } from 'redux-saga/effects';
import { DIALOG_SHOW, DIALOG_HIDE } from './constants.js';

let focusElement;

export function saveFocus() {
  focusElement = document.activeElement;
}

export function restoreFocus() {
  if (focusElement) {
    window.setTimeout(() => focusElement.focus());
  }
}

export function resolvePromise(action) {
  if (action.promise) {
    if (action.promise.rejectOnFalse && action.result === false) {
      action.promise.reject(action.result);
    } else {
      action.promise.resolve(action.result);
    }
  }
}

export default function* defaultSaga() {
  yield takeEvery(DIALOG_SHOW, saveFocus);
  yield takeEvery(DIALOG_HIDE, restoreFocus);
  yield takeEvery(DIALOG_HIDE, resolvePromise);
}
