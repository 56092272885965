/**
 *
 * PageTabs
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useModules from 'containers/User/useModules.js';
import Icon2 from 'components/media/Icon2/index.js';
import Container from 'components/layout/Container/index.js';
import { addPropertyById } from 'utils/arrays.js';
import messages from './messages.js';

function PageTabs(props) {
  const { active, base, options, width, tabMessages } = props;
  const modules = useModules();
  const intl = useIntl();

  function getHref(option) {
    const href = option.href === undefined ? option.id.toString().toLowerCase() : option.href;
    const sep = !/[/#?]$/.test(base) && /^[^/#?]/.test(href) ? '/' : '';
    return `${base}${sep}${href}`;
  }

  function getLabel(label) {
    return typeof label === 'string' ? label : intl.formatMessage(label);
  }
  function hasModule(option) {
    return option.module === undefined || modules.includes(option.module);
  }

  if (!options || window.socsIsInAppWebView) {
    return null;
  }
  const pageTabOptions = addPropertyById(options, 'label', tabMessages || messages);
  return (
    <Container className="nopadding d-print-none mb-0" width={width}>
      <Nav activeKey={active} size={pageTabOptions.length} className="socs-tabs">
        {pageTabOptions.filter(hasModule).map((option) => (
          <Nav.Item key={option.id}>
            <Nav.Link
              as={Link}
              eventKey={option.id}
              disabled={option.disabled}
              to={getHref(option)}
              className={classNames([option.className, 'text-primary-dark text-nowrap py-2'])}
            >
              {option.icon && <Icon2 className={`${option.icon} me-3 fs-2 lower-1`} />}
              <span className="text-truncate">{getLabel(option.label)}</span>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </Container>
  );
}

PageTabs.propTypes = {
  base: PropTypes.string,
  active: PropTypes.string,
  width: PropTypes.string,
  options: PropTypes.array,
  tabMessages: PropTypes.object,
};

PageTabs.defaultProps = {
  base: '',
};

export default PageTabs;
