import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { reportError } from 'utils/sentry.js';
import sleep from 'utils/sleep.js';
import { LOCATION_CHANGE } from 'connected-react-router';
import { dismissAlert, clearAlerts } from './actions.js';
import { ADD_ALERT } from './constants.js';

export function* autoDismiss(action) {
  if (!action.toast) {
    return;
  }
  yield sleep(5000);
  yield put(dismissAlert(action.id));
}

export function report(action) {
  const { error, context } = action;
  if (action.error && action.context && !action.error.noReport) {
    reportError(context, error);
  }
}

function* reset() {
  yield put(clearAlerts());
}

export default function* defaultSaga() {
  yield takeEvery(ADD_ALERT, autoDismiss);
  yield takeEvery(ADD_ALERT, report);
  yield takeLatest(LOCATION_CHANGE, reset);
}
