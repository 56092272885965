import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import detectMobile from 'utils/detectMobile.lib.js';
import PickerPopover from './Loadable.js';

const EmojiStyle = createGlobalStyle`
  .btn.btn-emoji {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 0.5rem;
  }
  .with-emoji {
    padding-right: calc(1.5em + 0.75rem);
  }
  input.form-control.is-invalid {
    &.with-emoji {
      padding-right: calc(3.0em + 0.75rem);
    }
    &~div .btn.btn-emoji {
      right: 1.5em;
    }
  }
  textarea + div .btn.btn-emoji {
    margin-right: 1rem;
    padding-right: 0.2rem;
  }
`;

function EmojiPicker(props) {
  if (detectMobile()) {
    return null;
  }
  const { value, onChange } = props;

  function handleClick(obj) {
    onChange(`${value}${obj.native}`);
  }
  const popover = <Popover className="emoji-popover"><PickerPopover onClick={handleClick} /></Popover>;

  return (
    <div className="position-relative w-100">
      <EmojiStyle />
      <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
        <Button className="btn-emoji" aria-label="emoji" variant="link">
          <i className="ss-smile fs-5" />
        </Button>
      </OverlayTrigger>
    </div>
  );
}

EmojiPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default EmojiPicker;

/* eslint-disable react/prop-types  */
export const withEmoji = (Component) => ({ emoji, ...props }) => {
  if (detectMobile() || !emoji) {
    return <Component {...props} />;
  }
  return (
    <>
      <Component {...props} />
      <EmojiPicker {...props} />
    </>
  );
};
