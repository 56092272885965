import { useDispatch } from 'react-redux';
import doNothing from 'utils/doNothing.js';
import { DIALOG_ALERT, DIALOG_CONFIRM, DIALOG_PROMPT, REMOVE_OPTIONS } from './constants.js';
import { showDialog } from './actions.js';

function dialog(dispatch, modalType, modalProps) {
  return new Promise((resolve, reject) => {
    dispatch(
      showDialog(modalType, modalProps, {
        resolve,
        reject,
        rejectOnFalse: modalType !== DIALOG_ALERT,
      }),
    );
  });
}

function useDialog() {
  const dispatch = useDispatch();
  return {
    dlgAlert: (options) => dialog(dispatch, DIALOG_ALERT, options),
    dlgConfirm: (options) => dialog(dispatch, DIALOG_CONFIRM, options),
    dlgPrompt: (options) => dialog(dispatch, DIALOG_PROMPT, options),
    dlgClick: (options, onClick) => dialog(dispatch, DIALOG_CONFIRM, options)
      .then(onClick)
      .catch(doNothing),
    REMOVE_OPTIONS,
  };
}

export default useDialog;
