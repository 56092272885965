/* eslint-disable import/no-import-module-exports */
/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import createSentryMiddleware from 'redux-sentry-middleware';
import createSagaMiddleware from 'redux-saga';
import getUserContext from 'containers/User/getUserContext.js';
import Sentry from 'utils/sentry.js';
import actionLogger from 'utils/actionLogger.js';
import locationMessage from 'utils/locationMessage.js';
import logSearch from 'utils/logSearch.js';
import createReducer from './reducers.js';
import { selectApi2Domain } from './containers/Api2/selectors.js';

const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line default-param-last
export default function configureStore(initialState = {}) {
  let composeEnhancers = compose;

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (import.meta.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) { composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}); }

    // NOTE: Uncomment the code below to restore support for Redux Saga
    // Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__) {
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    //   };
    // }
    /* eslint-enable */
  }

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    locationMessage,
    createSentryMiddleware(Sentry, {
      getUserContext,
      stateTransformer: (state) => state
        .set('meetings', getCurrentMeeting(state))
        .delete('api2'),
    }),
  ];

  if (logSearch('redux')) {
    middlewares.push(actionLogger);
  }

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  return store;
}

function getCurrentMeeting(state) {
  const match = window.location.pathname.match(/meetings\/(\d+)/);
  if (!match) {
    return null;
  }
  return selectApi2Domain(`meetings/sets/${match[1]}`)(state);
}
