/*
 *
 * UserRole constants
 *
 */

import messages from './messages.js';

export const CURRENTUSER_ENDPOINT = 'useraccounts/current';

export const USERROLE_INIT = 'app/User/USERROLE_INIT';
export const USERROLE_SET = 'app/User/USERROLE_SET';
export const USERROLE_FOR_CODE = 'app/User/USERROLE_FOR_CODE';

export const USERROLE_KEY = 'profiles';

export const PARENT_ROLE = 1;
export const EMPLOYEE_ROLE = 2;
export const STUDENT_ROLE = 3;
export const APPLICATION_ROLE = 4;
export const SCHOOL_ROLE = 10;

export const USERROLES = [
  { id: PARENT_ROLE, name: 'parent' },
  { id: EMPLOYEE_ROLE, name: 'employee' },
  { id: STUDENT_ROLE, name: 'student' },
];

export const MODULES_MESSAGING = 1;
export const MODULES_ABSENCES = 2;
export const MODULES_LEAVEREQUESTS = 4;
export const MODULES_STUDENTREPORTS = 5;
export const MODULES_IMAGEPREFERENCES = 6;
export const MODULES_STUDENTPORTFOLIO = 7;
export const MODULES_STUDENTPORTFOLIOLIBRARY = 8;
export const MODULES_PHOTOBOOK = 9;
export const MODULES_GROUPPOSTS = 10;
export const MODULES_WORKSPACEPOSTS = 11;
export const MODULES_STUDENTAVATAR = 12;
export const MODULES_DIGEST = 13;
export const MODULES_VIDEOCONFERENCING = 14;
export const MODULES_PORTFOLIOPILOT_GROUPS = 15;
export const MODULES_CONVERSATIONS = 16;
export const MODULES_PAYMENTS = 17;
export const MODULES_NEWSLETTERS = 18;
export const MODULES_TRANSLATIONS = 19;
export const MODULES_PORTFOLIOPILOT_OPTION = 99;

export const BETA_MODULES_INBOX = 1;
export const BETA_MODULES_TRANSLATIONS = 2;
export const BETA_MODULES_ESISABSENCES = 3;

export const ROLES_BY_ID = USERROLES.reduce((acc, role) => ({ ...acc, [role.id]: role.name }), {});

export const ROLEID_BY_NAME = USERROLES.reduce((acc, role) => ({ ...acc, [role.name]: role.id }), {});

export const ROLENAMES = USERROLES.map((role) => role.name);

export const CONTACTDETAILS = [
  { id: 'emailAddresses', idField: 'emailAddressId', publicField: 'emailAddressPublic', singular: 'emailAddress' },
  { id: 'phoneNumbers', idField: 'phoneNumberId', publicField: 'phoneNumberPublic', singular: 'phoneNumber' },
  { id: 'addresses', idField: 'addressId', publicField: 'addressPublic', singular: 'address' },
];

export const GROUPTYPES_GENERAL = 1;
export const GROUPTYPES_CLASS = 2;
export const GROUPTYPES_SCHOOL = 3;
export const GROUPTYPES_FOUNDATION = 4;

export const GROUPTYPE_KEYS = {
  general: GROUPTYPES_GENERAL,
  classes: GROUPTYPES_CLASS,
  school: GROUPTYPES_SCHOOL,
  foundation: GROUPTYPES_FOUNDATION,
};

export const GROUPKEYS_BY_TYPE = Object.keys(GROUPTYPE_KEYS).reduce((acc, key) => ({ ...acc, [GROUPTYPE_KEYS[key]]: key }), {});

export const GROUPLEVELS_FOUNDATION = 1;
export const GROUPLEVELS_SCHOOL = 2;

export const ERROR_MESSAGES = {
  400: messages.wrongCode,
  404: messages.mailNotFound,
  409: messages.alreadyConfirmed,
  410: messages.expired,
  422: messages.validationErrors,
};

export const OIDC_SKIP_MONITOR = 'oidcSkipMonitor';
export const OIDC_ACTION = 'oidcAction';

export const INITIAL_USER = { loading: false, loggedIn: false, auth: null, profile: null, roleId: null };

export const TEST_OIDC = false;
