import { isBefore } from 'date-fns';

const CHANGEMONTH = 7; // August

function isNextYear(date, yearEnd) {
  const now = new Date();
  const nextYear = yearEnd || new Date(now.getFullYear() + (now.getMonth() < CHANGEMONTH ? 0 : 1), CHANGEMONTH, 1);
  return !isBefore(date, nextYear);
}

export default isNextYear;
