/**
*
* IntlString
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { intlBold, intlBreak } from 'utils/intlFunctions.jsx';

function IntlString(props) {
  const { message, className } = props;
  if (!message) {
    return null;
  }
  if (!message.id) {
    return <span className={className}>{message}</span>;
  }
  const values = { ...intlBreak, ...intlBold, ...message.values };
  return <span className={className}><FormattedMessage {...message} values={values} /></span>;
}

IntlString.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.node]),
  className: PropTypes.string,
};

export default IntlString;

export const IntlPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]);
