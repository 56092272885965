/*
 * User Messages
 *
 * This contains all the text for the User component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  wrongCode: {
    id: 'app.containers.User.wrongCode',
    defaultMessage: 'This is not the correct code',
  },
  mailNotFound: {
    id: 'app.containers.User.mailNotFound',
    defaultMessage: 'Email address not found',
  },
  alreadyConfirmed: {
    id: 'app.containers.User.alreadyConfirmed',
    defaultMessage: 'The request has been confirmed already',
  },
  expired: {
    id: 'app.containers.User.expired',
    defaultMessage: 'The request has expired',
  },
  validationErrors: {
    id: 'app.containers.User.validationErrors',
    defaultMessage: 'Validation error',
  },
  authenticating: {
    id: 'app.containers.User.authenticating',
    defaultMessage: 'Authenticating...',
  },
});
