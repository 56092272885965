import tinycolor from 'tinycolor2';
import tinyFunction from './tinyFunction.js';

const RE_CALC = /(\d+\.?\d*)\s*([*/+-])\s*(\d+\.?\d*)/g;
const RE_FUNC = /(\w*)\(([^()]+)\)/g;

/* eslint-enable no-unused-vars */
function reducer(acc, str) {
  const [key, val] = str.split(':');
  return { ...acc, [key]: val };
}

function parseParam(str) {
  const match = str.match(/^\{(.*)}$/);
  if (!match) {
    return Number(str);
  }
  return match[1].split(', ').reduce(reducer, {});
}

function cb(func, color, paramStr) {
  return tinyFunction(func)(color, parseParam(paramStr));
}

function calculate(m, a, op, b) {
  switch (op) {
    case '*': return Number(a) * Number(b);
    case '/': return Number(a) / Number(b);
    case '+': return Number(a) + Number(b);
    case '-': return Number(a) - Number(b);
    default:
      throw new Error('Invalid operator', op, m);
  }
}

/**
 * Check for a bracket pair with an optional function name in front
 * @param {*} str
 * @returns
 */
function resolveFuncs(m, func, paramStr) {
  switch (func) {
    case '':
      return paramStr;
    case 'ceil':
    case 'floor':
      return Math[func](Number(paramStr));
    case 'rgba': {
      const params = paramStr.split(',').map(Number);
      return tinycolor(...params).toHexString();
    }
    default: {
      const paramMatch = paramStr.match(/(#\w+),\s*(.*)/);
      if (paramMatch) {
        const [color, param] = paramMatch.slice(1);
        return cb(func, color, param);
      }
      throw new Error('Invalid function', func, m);
    }
  }
}

export function evaluate(str) {
  let before;
  let after = str.replace(/'(#\w+)'/g, '$1');
  do {
    before = after;
    after = after.replace(RE_CALC, calculate);
    after = after.replace(RE_FUNC, resolveFuncs);
  } while (before !== after);
  return after;
}

export default evaluate;
