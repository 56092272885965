import React from 'react';
import PropTypes from 'prop-types';
import Icon2 from 'components/media/Icon2/index.js';
import IntlString from 'components/media/IntlString/index.jsx';

function DialogMessage(props) {
  const { message, danger } = props;
  if (!message) {
    return null;
  }
  if (!danger) {
    return <div><IntlString message={message} /></div>;
  }
  return (
    <div className="d-flex align-items-start">
      <span key="icon">
        <Icon2 className="ss-alert fs-0 text-danger m-3" />
      </span>
      <span key="text">
        <IntlString message={message} />
      </span>
    </div>
  );
}

DialogMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  danger: PropTypes.bool,
};

export default DialogMessage;
