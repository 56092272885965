/**
*
* checklistHandlers
*
*/

import { idList } from 'utils/arrays.js';

function checklistHandlers(items, value, onChange, readOnly) {
  const handleChange = (item) => (ev) => {
    const val = ev.target.checked;
    const included = value.includes(item.id);
    if (included === val) {
      return;
    }
    if (!onChange) {
      return;
    }
    if (val) {
      onChange([...value, item.id]);
    } else {
      onChange(value.filter((id) => id !== item.id));
    }
  };

  function handleCheckAll(ev) {
    if (ev.target.checked) {
      return onChange(idList(items));
    }
    if (!readOnly) {
      return onChange([]);
    }
    return onChange(idList(items.filter((item) => readOnly(item) && value.includes(item.id))));
  }

  return { handleChange, handleCheckAll };
}

export default checklistHandlers;
