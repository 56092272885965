import { parseUrl } from 'utils/urlUtils.js';

function userrolesParser(obj, options) {
  const { params, url } = options;
  if (params) {
    return { ...params, ...obj };
  }
  const { searchObject } = parseUrl(url);
  return { ...searchObject, ...obj };
}

export default userrolesParser;
