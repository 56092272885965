/**
 *
 * SchoolBanner
 *
 */

import React from 'react';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import useSelectFromLocation from 'containers/Api2/useSelectFromLocation.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import ScreenWrapper from './ScreenWrapper.jsx';
import { TagLine } from './styled.js';

function SchoolBanner() {
  const current = useSelectFromLocation();
  const role = useCurrentRole();
  const school = current?.school || role?.school;
  return school && (
    <ScreenWrapper>
      <AvatarWithText src={school.theme.logo} group bsSize="large">
        <h1 className="fs-0 fw-bold">{school.displayName}</h1>
        <TagLine className="fs-4">{school.theme.tagLine}</TagLine>
      </AvatarWithText>
    </ScreenWrapper>
  );
}

export default SchoolBanner;
