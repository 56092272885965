import { setLocale } from 'yup';
import en from 'yup/es/locale';
import { nl } from 'yup-locales';

// Individual exports for testing
function setYupLocale(locale) {
  setLocale(locale === 'nl' ? nl : en);
}

export default setYupLocale;
