/**
 *
 * FormattedDate
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import timeFormat from 'utils/timeFormat.js';
import { startOfDay, startOfWeek } from 'utils/date-fns/index.js';
import classNames from 'classnames';

function FormattedDate(props) {
  const { children, format, className } = props;
  function getFormat() {
    if (format !== 'short') {
      return format;
    }
    if (startOfDay(new Date()) < children) {
      return 'LT';
    }
    if (startOfWeek(new Date()) < children) {
      return 'dddd';
    }
    return 'll';
  }

  if (!children) {
    return null;
  }
  const classList = classNames(['formatted-date', className]);
  return (
    <span className={classList}>
      {timeFormat(children, getFormat())}
    </span>
  );
}

FormattedDate.propTypes = {
  children: PropTypes.object,
  format: PropTypes.string,
  className: PropTypes.string,
};

export default FormattedDate;
