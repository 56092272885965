/**
*
* useAllGroupsState
*
*/

import { useEffect, useState } from 'react';
import useUiControl from 'containers/UiControl/useUiControl.js';
import isOwnGroup from './isOwnGroup.js';

function isNonMemberGroup(g) {
  return g && g.permissions && !isOwnGroup(g);
}

function useAllGroupsState(checkGroups, uiProp) {
  const [showAllGroups, setShowAllGroups] = useState(false);
  const [uiValue, setUiValue] = useUiControl(uiProp);
  // show initially if current community if not own group
  const initial = checkGroups.some((g) => isNonMemberGroup(g));

  useEffect(() => {
    if (initial) {
      setShowAllGroups(true);
    }
  }, [initial]);

  return uiProp ? [uiValue, setUiValue] : [showAllGroups, setShowAllGroups];
}

export default useAllGroupsState;
