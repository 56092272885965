/*
 *
 * UiControl reducer
 *
 */

import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { UI_TOGGLE, UI_SET } from './constants.js';
import { USERROLE_SET } from '../User/constants.js';

const HISTORY_SIZE = 20;

export const initialState = fromJS({
  dummy: 123,
  calendarGroups: {},
  absenceShowAll: false,
  showAllGroups: false,
  showTransitionAlert: true,
  showBlacklistedEmail: true,
  showCheckDetailsAlert: true,
  history: [],
});

// eslint-disable-next-line default-param-last
function uiControlReducer(state = initialState, action) {
  const { type, val, prop, payload } = action;
  switch (type) {
    case UI_TOGGLE:
      return state.set(prop, !state.toJS()[action.prop]);
    case UI_SET:
      if (typeof val === 'function') {
        return state.update(prop, val);
      }
      return state.set(prop, val);
    case LOCATION_CHANGE:
      return state.update('history', updateList(payload));
    case USERROLE_SET:
      return initialState;
    default:
      return state;
  }
}

function updateList(payload) {
  return (list) => {
    const { action, location } = payload;
    switch (action) {
      case 'PUSH':
        return list.unshift(location.pathname).slice(0, HISTORY_SIZE);
      case 'POP':
        return list.shift();
      case 'REPLACE':
        return list.set(0, location.pathname);
      default:
        // eslint-disable-next-line no-console
        console.warn(`Unknown location change action: ${action}`);
        return list;
    }
  };
}

export default uiControlReducer;
