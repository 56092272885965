/**
 *
 * Permissions for user, posts, communities/groups, etc
 *
 */
import useCurrentRole from 'containers/User/useCurrentRole.js';
import { COMMUNITY_ACTIVE } from './constants.js';

export function hasPermission(key) {
  return (community) => {
    if (community.status !== COMMUNITY_ACTIVE) {
      return false;
    }
    if (!key) {
      return true;
    }
    return community.permissions?.[key];
  };
}

export function canManageGroups(role) {
  const { permissions } = role;
  return permissions && (permissions.admin || permissions.super || permissions.administrative);
}

export function useIsAdminRole() {
  const role = useCurrentRole();
  return canManageGroups(role);
}
