/**
 *
 * TryNow
 * PortfolioMenu
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Badge } from 'react-bootstrap';
import config from 'config/index.js';
import { MODULES_PORTFOLIOPILOT_OPTION, MODULES_PORTFOLIOPILOT_GROUPS, MODULES_STUDENTPORTFOLIO } from 'containers/User/constants.js';
import useStats from 'containers/User/UserProvider/useStats.js';
import Transition from 'components/media/Transition/index.jsx';
import useStorage from 'utils/storage/useStorage.js';
import messages from '../messages.js';

function TryNow(props) {
  const { modules } = props;
  const showInitial = modules.includes(MODULES_PORTFOLIOPILOT_OPTION);
  const [fromStorage, setFromStorage] = useStorage('showTryNow', showInitial);
  const [fromState, setFromState] = useState(showInitial);
  const { getIn } = useStats();
  const showTryNow = config.env === 'production' ? fromStorage : fromState;
  const total = getIn('unseenPortfolioActivitiesCount.total');
  if (showTryNow && (total > 0 || modules.includes(MODULES_PORTFOLIOPILOT_GROUPS) || modules.includes(MODULES_STUDENTPORTFOLIO))) {
    setFromStorage(false);
    setFromState(false);
  }
  return showTryNow && (
    <Transition className="me-2 scale" delays={[10000, 1000]} initial>
      <Badge className="bg-primary rounded-pill">
        <FormattedMessage {...messages.tryNow} />
      </Badge>
    </Transition>
  );
}

TryNow.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default TryNow;
