/**
 *
 * PortfolioContext
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import useApi2 from 'containers/Api2/useApi2.js';
import useUpdateOutletContext from 'containers/App/router/useUpdateOutletContext.js';
import { MODULES_STUDENTPORTFOLIO, MODULES_PORTFOLIOPILOT_OPTION, MODULES_PORTFOLIOPILOT_GROUPS } from 'containers/User/constants.js';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import MetaTags from 'components/MetaTags/index.jsx';
import useModules from 'containers/User/useModules.js';
import Main from 'components/PageLayout/Main/index.jsx';
import reduceCategories from './reduceCategories.js';
import messages from './messages.js';

function hasPortfolioModules(modules) {
  return modules.includes(MODULES_STUDENTPORTFOLIO)
    || modules.includes(MODULES_PORTFOLIOPILOT_GROUPS)
    || modules.includes(MODULES_PORTFOLIOPILOT_OPTION);
}

function PortfolioContext() {
  const categories = useApi2({ url: 'portfolio/categories', ignore: [403], reducer: reduceCategories });
  const modules = useModules();
  const { pathname } = useLocation();
  const [editGoal, setEditGoal] = useState(null);
  const lastCat = useRef(null);
  const role = useCurrentRole();
  const newContext = useUpdateOutletContext({ categories, lastCat, editGoal, setEditGoal });

  useEffect(() => {
    setEditGoal(null);
  }, [pathname]);

  if (!hasPortfolioModules(modules)) {
    return <Main shaded><Alert variant="warning" className="alert-shaded"><FormattedMessage {...messages.noPortfolio} /></Alert></Main>;
  }

  const values = { name: role.school.displayName };

  return (
    <>
      <MetaTags tags={messages} vars={values} />
      <Outlet context={newContext} />
    </>
  );
}

export default PortfolioContext;
