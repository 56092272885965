/* eslint-disable radix */
import { useEvent } from 'react-use';

/**
 * useDragElement - A custom React hook for handling drag events on an element.
 *
 * @param {String} parent - The parent element querySelector that contains the draggable element.
 * @param {Array} posProps - The style properties to adapt the position (e.g. ['right', 'bottom'])
 *
 * @returns {void}
 */
function useDragElement(parent) {
  useEvent('dragend', (event) => {
    const { offsetX, offsetY, target } = event;
    const parentElement = document.querySelector(parent);
    if (!parentElement?.contains(target)) {
      return;
    }
    const currentStyle = getComputedStyle(parentElement);
    const { transformOrigin, top, left } = currentStyle;
    const [originX, originY] = transformOrigin.split(' ').map((v) => parseInt(v));
    parentElement.style.left = `${parseInt(left) - originX + offsetX}px`;
    parentElement.style.top = `${parseInt(top) - originY + offsetY}px`;
  });
}

export default useDragElement;
