/**
 *
 * UserListItem
 * Show user as a clickable ListGroupItem
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { ListGroupItem } from 'react-bootstrap';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import UserText from 'components/media/UserText/Loadable.js';
import { getIdNumber } from 'utils/userIdRole.js';
import RoleDisplay from './RoleDisplay.js';
import SchoolDisplay from './SchoolDisplay.jsx';

const Groups = styled.span`
  color: ${(props) => props.theme.gray};
`;

function UserListItem(props) {
  const { user, withRole, withSchool, children, className } = props;
  const userId = user.id > 0 ? getIdNumber({
    userId: user.id,
    roleType: user.roleType,
  }) : undefined;
  const hash = userId && `#user=${userId}`;
  const classList = classNames([className, 'd-flex align-items-center']);

  return (
    <ListGroupItem action={!!hash} href={hash} className={classList}>
      <div className="flex-grow-1">
        <AvatarWithText src={user.avatar} align="center">
          <UserText inline>{user.displayName}</UserText>
        </AvatarWithText>
        <Groups>{user.groups && user.groups.map((g) => g.name).join(', ')}</Groups>
      </div>
      { withRole && <RoleDisplay type={user.roleType} />}
      { withSchool && <SchoolDisplay school={user.school} />}
      { children }
    </ListGroupItem>
  );
}

UserListItem.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node,
  withRole: PropTypes.bool,
  withSchool: PropTypes.bool,
  className: PropTypes.string,
};

export default UserListItem;
