function getHost() {
  const { hostname } = window.location;
  if (hostname.includes('local')) { // localhost and bs-local
    return 'local';
  }
  switch (hostname.toLowerCase()) {
    case 'app-testing.socialschools.eu':
    case 'web3.develop.socialschools.nl':
      return 'test';
    case 'app-staging.socialschools.eu':
      return 'staging';
    default:
      return 'production';
  }
}

export default getHost();
