/**
*
* LoginButton
*
*/

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import messages from './messages.js';

function LoginButton(props) {
  const { onLogin } = props;
  return (
    <Button variant="link" onClick={onLogin}>
      <FormattedMessage {...messages.login} />
    </Button>
  );
}

LoginButton.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default LoginButton;
