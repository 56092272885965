import React from 'react';
import { useRouteError } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import Main from 'components/PageLayout/Main/index.jsx';

function ErrorPage() {
  const error = useRouteError();
  const targetError = error.error ?? error;
  return (
    <Main shaded width="640px">
      <Alert variant="danger" className="alert-shaded ">
        {targetError.statusText || targetError.message}
      </Alert>
    </Main>
  );
}

export default ErrorPage;
