/**
*
* SelectCommunities
*
*/

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import styled from 'styled-components';
import { pick } from 'lodash-es';
import { useIntl } from 'react-intl';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import useCommunities from 'containers/Api2/useCommunities.js';
import { canManageGroups, hasPermission } from 'containers/HomePage/permissions.js';
import AllGroupsSelect, { isOwnGroup, useAllGroupsState } from 'components/interaction/AllGroupsSelect/index.jsx';
import FormControlWrapper from 'components/interaction/Form4/FormControlWrapper.jsx';
import useArchive from 'components/ArchiveIndex/useArchive.js';
import { idValues, hasItems } from 'utils/arrays.js';
import ReactSelect from '../ReactSelect/index.jsx';

import messages from './messages.js';
import CommunityBadge from './CommunityBadge.jsx';

const Wrapper = styled(FormControlWrapper)`
  display: flex;
  width: 100%;
  & > :first-child {
    flex-grow: 1;
  }
  & > * + select {
    margin-left: 10px;
  }
  .color-block + .avatar-text {
    padding-left: 0;
  }
  @media (max-width: 479px) {
    flex-direction: column;
  }
`;

const withColorBox = (Component) => (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, ...rest } = props;
  return (
    <Component {...rest}>
      <CommunityBadge color={rest.data.color} label={children} />
    </Component>
  );
};

const menuListWithControl = (control) => (props) => {
  const { MenuList } = components;
  // eslint-disable-next-line react/prop-types
  // const { children, ...rest } = props;
  return (
    <>
      <div className="text-center bg-transparent">
        {control}
      </div>
      <MenuList {...props} />
    </>
  );
};

function SelectCommunities(props) {
  const { permission, value, onChange, withArchived, showAll, disabled, onBlur, className } = props;
  const role = useCurrentRole();
  const intl = useIntl();
  const archived = useArchive(withArchived);
  const communities = useCommunities();
  const initial = useRef(true);
  const [showAllGroups, setShowAllGroups] = useAllGroupsState(communities.ready ? value.map((id) => communities[id]) : []);
  const canSelect = communities.hasNotOwned && (showAll || canManageGroups(role));

  useEffect(() => {
    if (showAllGroups || communities.loading) {
      return;
    }
    if (initial.current) {
      initial.current = false;
      return;
    }

    // If not all groups, remove not-own groups
    const newValue = value.filter((id) => isOwnGroup(communities[id]));
    if (newValue.length < value.length) {
      onChange(newValue);
    }
  }, [showAllGroups, communities.loading]);

  function getOptions() {
    const makeOption = (comm) => ({ label: comm.name, value: comm.id, color: comm.color.hex });
    if (!hasItems(communities)) {
      return [];
    }
    const values = idValues(communities);
    const items = values.filter((comm) => hasPermission(permission)(comm)
      && (showAllGroups || isOwnGroup(comm)));
    if (withArchived && archived.list) {
      items.push(...idValues(archived));
    }
    return items.map(makeOption);
  }

  const handleChange = (val) => onChange(val ? val.map(Number) : []);
  const options = getOptions();
  const adaptedComponent = {
    MultiValueLabel: withColorBox(components.MultiValueLabel),
    Option: withColorBox(components.Option),
  };
  if (canSelect) {
    adaptedComponent.MenuList = menuListWithControl(<AllGroupsSelect value={showAllGroups} onChange={setShowAllGroups} disabled={disabled} className="pt-2 bg-transparent" asSwitch />);
  }
  return (
    <Wrapper className={className} border={1} onBlur={onBlur}>
      <ReactSelect
        {...pick(props, ['value', 'name'])}
        components={adaptedComponent}
        placeholder={intl.formatMessage(messages.placeholder)}
        options={options}
        isDisabled={disabled}
        onChange={handleChange}
        // onMenuOpen={() => onPopup(true)}
        // onMenuClose={() => onPopup(false)}
        aria-label="Type a group name to filter the options"
        isMulti
      />
    </Wrapper>
  );
}

SelectCommunities.propTypes = {
  disabled: PropTypes.bool,
  permission: PropTypes.string,
  value: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  // onPopup: PropTypes.func,
  showAll: PropTypes.bool,
  withArchived: PropTypes.bool,
};

// SelectCommunities.getSchema = (options) => {
//   const result = array();
//   if (options.required)
// }
export default SelectCommunities;
