/* eslint-disable no-console */
import { takeEvery } from 'redux-saga/effects';
import { API2_CALL } from 'containers/Api2/constants.js';
import { getCurrentRole } from 'containers/User/UserProvider/userStorage.js';
import markSeen from './markSeen.js';

/**
 * api2call check from request for specific communitypost
 * @param {*} action
 */
function api2call(action) {
  const { options } = action;
  const match = options.url && options.url.match(/communityposts\/(\d+)/);
  if (match) {
    const role = getCurrentRole();
    if (role) {
      markSeen({
        postId: Number(match[1]),
        roleType: role.type,
        roleId: role.id,
      });
    }
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeEvery(API2_CALL, api2call);
}
