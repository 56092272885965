/**
 *
 * useGetClasses
 *
 */

import { COMMUNITY_ACTIVE } from 'containers/HomePage/constants.js';
import { useCommunities } from 'containers/Api2/index.js';
import { GROUPTYPES_CLASS } from 'containers/User/constants.js';
import { idValues } from 'utils/arrays.js';
import { isOwnGroup } from 'components/interaction/AllGroupsSelect/index.jsx';

function useGetClasses() {
  const communities = useCommunities();
  const values = idValues(communities).filter((comm) => comm.status === COMMUNITY_ACTIVE && comm.type === GROUPTYPES_CLASS && isOwnGroup(comm));
  return {
    ...communities,
    entity: 'groups',
    values,
  };
}

export default useGetClasses;
