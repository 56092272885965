/**
*
* ImageRestrictionsModalModal
*
*/

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useApi2 } from 'containers/Api2/index.js';
import Spinner from 'components/Spinner/index.jsx';
import UserList from 'components/user/UsersByRole/UserList.jsx';
import { idValues } from 'utils/arrays.js';
import messages from './messages.js';

function ImageRestrictionsModal(props) {
  const { fetchOptions, inModal, label, onHide } = props;
  const users = useApi2(fetchOptions);

  function renderUsers() {
    if (users.loading || users.working) {
      return <Spinner />;
    }
    // TODO fix when backend adapted
    const noConsentUsers = users.noConsent || idValues(users);
    const unknownUsers = users.unknown || [];
    const showCount = (list) => list && (
      <span className="within-parentheses ms-2">{list.length}</span>
    );
    const noConsentTitle = (
      <span>
        <span className="with-colon"><FormattedMessage {...messages.noConsent} /></span>
        {showCount(noConsentUsers)}
      </span>
    );
    const unknownTitle = (
      <span>
        <span className="with-colon"><FormattedMessage {...messages.unknown} /></span>
        {showCount(unknownUsers)}
      </span>
    );
    const initialKey = noConsentUsers.length === 0 && unknownUsers.length > 0 ? 2 : 1;
    return (
      <Tabs defaultActiveKey={initialKey} className="socs-tabs" id="image-restrictions-users">
        <Tab eventKey={1} title={noConsentTitle}>
          <UserList users={noConsentUsers} prop="noConsent" />
        </Tab>
        <Tab eventKey={2} title={unknownTitle}>
          <UserList users={unknownUsers} prop="unknown" />
        </Tab>
      </Tabs>
    );
  }

  return (
    <Modal show backdropClassName={inModal ? 'modal-backdrop-clear' : ''} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage {...messages.header} />
          <span className="ms-2">{label}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        {users && renderUsers()}
      </Modal.Body>
    </Modal>
  );
}

ImageRestrictionsModal.propTypes = {
  inModal: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  label: PropTypes.node,
  fetchOptions: PropTypes.object.isRequired,
};

export default ImageRestrictionsModal;
