/**
*
* useArchive
*
*/

import { useApi2 } from 'containers/Api2/index.js';
import archiveParser from './archiveParser.js';

function useArchive(fetch = true) {
  return useApi2({ url: fetch && 'communities/archived', parser: archiveParser, noReport: [401] });
}

export default useArchive;
