/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';

import uiControlReducer from 'containers/UiControl/reducer.js';
import alertsReducer from 'containers/Alerts/reducer.js';
import api2Reducer from 'containers/Api2/reducer.js';

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    uiControl: uiControlReducer,
    alerts: alertsReducer,
    api2: api2Reducer,
    ...injectedReducers,
  });
}
