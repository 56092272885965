import React, { createContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { EMPLOYEE_ROLE, PARENT_ROLE, STUDENT_ROLE } from 'containers/User/constants.js';
import messages from './messages.js';
import ConversationIcon from './ConversationIcon.jsx';

export const PAGESIZE = 20;
export const POLL_RECENT = 10;
export const SHORT_POLL = 5000;
export const LONG_POLL = 30000;
export const TOPICS_POLL = 30000;

export const CONVERSATION_MAX_LENGTH = 5000;

export const MIN_SEARCH_LENGTH = 3;

// export const EDIT_UNKNOWN = Symbol('No Post, not showing');
// export const EDIT_NEW_TOPIC = Symbol('Creating new topic');
// export const EDIT_TOPIC_TITLE = Symbol('Editing subject');
// export const EDIT_MESSAGE = Symbol('Create/edit message');

export const CONVERSATION_TYPE_GROUP = 1;
export const CONVERSATION_TYPE_INDIVIDUAL = 2;

// export const MESSAGE_TYPE_NORMAL = 2;
export const MESSAGE_TYPE_META = 3;

export const FILTER_PARTICIPANTS_OTHER = 0b0001;
export const FILTER_PARTICIPANTS_CURRENT = 0b0010;
export const FILTER_PARTICIPANTS_CURRENT_OTHER = 0b0011;

function makeLabel(option) {
  const { title, intro, value } = option;
  const label = (
    <section className="d-flex flex-column h-100">
      <span className="flex-grow-1 d-flex align-items-center">
        <ConversationIcon className="me-3" isPrivate={value === CONVERSATION_TYPE_INDIVIDUAL} />
        <div>
          <h1 className="mt-2 mb-1 fw-semibold"><FormattedMessage {...title} /></h1>
          {intro && <div><FormattedMessage {...intro} /></div>}
        </div>
      </span>
    </section>
  );
  return { ...option, label };
}

export const typeOptions = [
  { value: CONVERSATION_TYPE_GROUP, title: messages.groupConversation, intro: messages.groupConvIntro },
  { value: CONVERSATION_TYPE_INDIVIDUAL, title: messages.individualConversations, intro: messages.indivConvIntro },
].map(makeLabel);

export const ConversationsContext = createContext(null);

export const INBOX_ROLE = 10;

export const CONVERSATIONROLES = [
  { id: INBOX_ROLE, name: 'inboxes' },
  { id: EMPLOYEE_ROLE, name: 'employee' },
  { id: PARENT_ROLE, name: 'parent' },
  { id: STUDENT_ROLE, name: 'student' },
];
