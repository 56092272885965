import {
  endOfDay,
  isSameDay,
  formatDistanceToNow as formatDistanceToNowOrg,
  isSameWeek as isSameWeekOrg,
  startOfWeek as startOfWeekOrg,
  endOfWeek as endOfWeekOrg,
  format as formatOrg,
  formatRelative,
  isAfter,
  differenceInWeeks,
} from 'date-fns';
import { getLocale } from './locales.js';

const yearRe = new RegExp(` ${new Date().getFullYear()}`);

function fix(str, options = 'ydt') {
  return Array.from(options).reduce((acc, opt) => {
    switch (opt) {
      case 'y':
        return acc.replace(yearRe, ''); // Remove current year
      case 'd':
        return acc.replace(/\b([a-z]{3})\./, '$1'); // Remove dot after month
      case 't':
        return acc.replace(/\W+$/, ''); // Remove trailing non-word characters
      default:
        return acc;
    }
  }, str);
}

export const isSameWeek = (date) => isSameWeekOrg(date, { weekStartsOn: 1 });
export const startOfWeek = (date) => startOfWeekOrg(date, { weekStartsOn: 1 });
export const endOfWeek = (date) => endOfWeekOrg(date, { weekStartsOn: 1 });
export const format = (date, formatStr, options) => fix(formatOrg(date, formatStr, { locale: getLocale() }), options);
export const calendar = (date, short) => short ? relativeDate(date) : formatRelative(date, new Date(), { locale: getLocale() })
  .replace(/^\d+.*/, fix(format(date, 'PPPp', { locale: getLocale() })));

export const isPast = (date) => isAfter(new Date(), date);
export const isFuture = (date) => isAfter(date, new Date());
export const formatDistanceToNow = (date) => formatDistanceToNowOrg(date, { locale: getLocale() });
export const SHORTDATE = 'eeeeee d MMM';

function relativeDate(date) {
  return format(date, relativeFormat(date));
}

function relativeFormat(date) {
  if (isSameDay(date, new Date())) {
    return 'p';
  }
  return differenceInWeeks(endOfDay(new Date()), date) === 0 ? 'eeee' : 'PP';
}
