/**
 *
 * useEventMenu
 *
 */

import { useApi2call } from 'containers/Api2/index.js';
import useDialog from 'containers/Dialog/useDialog.js';
import { CHANNEL_WEBSITE } from 'components/PublishModal/constants.jsx';
import { useHash } from 'utils/urlUtils.js';
import doNothing from 'utils/doNothing.js';
import messages from '../messages.js';
import { SHOW_EDITOR, SHOW_PUBLISH } from './constants.js';

function useEventMenu(event, onHide, setShow) {
  const { dlgConfirm } = useDialog();
  const api2call = useApi2call();
  const { replace } = useHash();

  const onMenu = (label) => () => {
    switch (label) {
      case 'close':
        onHide();
        break;
      case 'publishModal':
        setShow(SHOW_PUBLISH);
        break;
      case 'publish':
        publishEvent();
        break;
      case 'revoke':
        revokeEvent();
        break;
      case 'edit':
        setShow(SHOW_EDITOR);
        break;
      case 'remove':
        dlgConfirm({
          title: messages.removeTitle,
          message: { ...messages.removeMessage, values: { title: event.title } },
          confirmStyle: 'danger',
          confirmLabel: messages.remove,
        })
          .then(() => handleDelete())
          .catch(doNothing);
        break;
      default:
        console.error('Unknown label', label); // eslint-disable-line no-console
    }
  };

  function handleDelete() {
    api2call({ method: 'DELETE', url: `events/${event.id}`, withPromise: true })
      .then(() => replace(event));
  }

  function handlePublish(options) {
    const selectedChannels = [CHANNEL_WEBSITE];
    const reducer = (state, action) => ({ ...state, ...action.payload });
    api2call({
      ...options,
      url: `events/${event.id}/publish`,
      selector: `events/${event.id}`,
      body: { channels: selectedChannels },
      reducer,
    });
  }

  function publishEvent() {
    dlgConfirm({
      title: messages.publishTitle,
      message: { ...messages.publishMessage, values: { title: event.title } },
    })
      .then(() => handlePublish({
        method: 'POST',
        success: messages.published,
      }))
      .catch(doNothing);
  }

  function revokeEvent() {
    dlgConfirm({
      title: messages.revokeTitle,
      message: { ...messages.revokeMessage, values: { title: event.title } },
      confirmLabel: messages.revoke,
      confirmStyle: 'danger',
    })
      .then(() => handlePublish({
        method: 'DELETE',
        success: messages.revoked,
      }))
      .catch(doNothing);
  }

  return onMenu;
}

export default useEventMenu;
