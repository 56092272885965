/**
 *
 * usePreventNavigation
 *
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBlocker } from 'react-router-dom';
import useDialog from 'containers/Dialog/useDialog.js';
import messages from './messages.js';

function usePreventNavigation(warning, onNavigate) {
  const { dlgConfirm } = useDialog();

  const blocker = useBlocker(warning);

  useEffect(() => {
    if (blocker?.state === 'blocked') {
      dlgConfirm({ message: messages.navigationWarning, confirmStyle: 'danger' })
        .then(() => {
          onNavigate?.();
          blocker.proceed();
        })
        .catch(blocker.reset);
    }
  }, [blocker, warning]); // eslint-disable-line react-hooks/exhaustive-deps
}

usePreventNavigation.propTypes = {
  warning: PropTypes.bool,
  onNavigate: PropTypes.func,
};

export default usePreventNavigation;
