import { screenLog } from 'components/ScreenLog/index.jsx';

// Remember last refresh and only update after some time
let lastRefresh = 0;
const REFRESH_DELAY = 300000; // For now, 10 seconds, TODO increase to 5 minutes

function checkRecent() {
  const now = Date.now();
  if (now - lastRefresh < REFRESH_DELAY) {
    return true;
  }
  lastRefresh = now;
  return false;
}

// When new token is requested, keep FIFO list of callbacks
const pending = [];

/**
 * Store new token, resolve any pending promises
 * @param {*} token
 */
function storeNewToken(token) {
  screenLog('received token, resolve', pending.length);
  window.socsAccessToken = token;
  while (pending.length > 0) {
    const resolve = pending.shift();
    resolve(token);
  }
}

/**
 * get promise to update webViewToken
 */
export default function webViewToken() {
  return new Promise((resolve) => {
    if (pending.length > 0) {
      // Request for new token pending, resolve when done
      screenLog('busy');
      pending.push(resolve);
      return;
    }
    const token = getUserWithFreshToken();
    if (!token) {
      // Token requested, resolve when received
      screenLog('waiting');
      pending.push(resolve);
      return;
    }
    // screenLog('resolve immediately');
    resolve(token);
  });
}

/**
 * Request new token, return new token or null if waiting for callback
 */
function getUserWithFreshToken() {
  const message = 'I want a new one';
  if (checkRecent()) {
    // screenLog('recently refreshed, use current');
    return window.socsAccessToken;
  }
  if (typeof window.webkit !== 'undefined' && window.webkit.messageHandlers) {
    // iOS
    screenLog('request token from ios');
    window.webkit.messageHandlers.socsGetAccessToken.postMessage(message);
    return null;
  }
  if (typeof window.authBridge !== 'undefined') {
    // Android
    screenLog('request token from android');
    window.socsAccessToken = window.authBridge.refreshToken();
    return window.socsAccessToken;
  }
  screenLog('request token from web');
  window.setTimeout(() => window.socsAccessTokenCallback(window.socsAccessToken), 1000);
  return null;
}

// Callback for iOS request
window.socsAccessTokenCallback = storeNewToken;
