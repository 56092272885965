/**
 *
 * CommunitiesMenu
 * SideMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ListGroupItem } from 'react-bootstrap';
import { useCommunities } from 'containers/Api2/index.js';
import useSelectFromLocation from 'containers/Api2/useSelectFromLocation.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import AllGroupsSelect, { useAllGroupsState } from 'components/interaction/AllGroupsSelect/index.jsx';

import { hasItems } from 'utils/arrays.js';
import DynamicListGroup from '../DynamicListGroup/index.jsx';
import ArchivedCommunities from '../ArchivedCommunities/index.jsx';
import groupFilterCommunities from './groupFilterCommunities.js';
import messages from '../messages.js';
import ExpandItem from '../ExpandItem.jsx';

function CommunitiesMenu(props) {
  const { expanded, onClick, isManager, pathname } = props;
  const current = useSelectFromLocation();
  const [showAllGroups, setShowAllGroups] = useAllGroupsState([current]);
  const communities = useCommunities();
  const { typedCommunities, groupTypes } = groupFilterCommunities(communities, showAllGroups);

  function copyCurrentPath(options) {
    const re = new RegExp(`(${options.join('|')})$`);
    const match = pathname.match(re);
    return match ? match[0] : '';
  }

  if (!hasItems(communities)) {
    return null;
  }
  return (
    <>
      <ExpandItem onClick={onClick} expanded={expanded}>
        <AvatarWithText icon="ss-usergroup"><FormattedMessage {...messages.myGroups} /></AvatarWithText>
      </ExpandItem>
      {expanded && (
        <div className="list-group-indent">
          {isManager && (
            <ListGroupItem>
              <AllGroupsSelect value={showAllGroups} onChange={setShowAllGroups} />
            </ListGroupItem>
          )}
          {groupTypes.map((type) => {
            const typeList = typedCommunities[type.id];
            if (!typeList || typeList.length === 0) {
              return null;
            }
            const page = copyCurrentPath(['calendar', 'documents', 'media', 'members']);
            return (
              <React.Fragment key={type.id}>
                {type.label && (
                  <ListGroupItem className="list-group-header">
                    <FormattedMessage {...type.label} />
                  </ListGroupItem>
                )}
                <DynamicListGroup
                  data={{ values: typeList }}
                  link={`/communities/:id/${page}`}
                  group
                  current={pathname}
                  noRedirect
                />
              </React.Fragment>
            );
          })}
          <ArchivedCommunities pathname={pathname} />
        </div>
      )}
    </>
  );
}

CommunitiesMenu.propTypes = {
  isManager: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default CommunitiesMenu;
