import {
  GROUPTYPES_GENERAL,
  GROUPTYPES_CLASS,
  GROUPTYPES_SCHOOL,
  GROUPTYPES_FOUNDATION,
  GROUPLEVELS_FOUNDATION,
} from 'containers/User/constants.js';
import { group, idValues } from 'utils/arrays.js';
import { COMMUNITY_ACTIVE } from 'containers/HomePage/constants.js';
import isOwnGroup from 'components/interaction/AllGroupsSelect/isOwnGroup.js';
import messages from '../messages.js';

const groupTypes = [
  { id: GROUPTYPES_SCHOOL },
  { id: GROUPTYPES_CLASS, label: messages.classes },
  { id: GROUPTYPES_GENERAL, label: messages.generic },
  { id: GROUPTYPES_FOUNDATION, label: messages.foundation },
];

function groupFilterCommunities(communities, showAllGroups) {
  const allCommunities = idValues(communities).filter((comm) => comm.status === COMMUNITY_ACTIVE);
  const memberCommunities = allCommunities.filter(isOwnGroup);
  const filteredCommunities = showAllGroups ? allCommunities : memberCommunities;

  function getGroupType(comm) {
    if (comm.level === GROUPLEVELS_FOUNDATION) {
      return GROUPTYPES_FOUNDATION;
    }
    return comm.type;
  }
  const typedCommunities = group(filteredCommunities, getGroupType, true);
  const groups = {
    ...communities,
    entity: 'groups',
    values: memberCommunities.filter((comm) => comm.type === GROUPTYPES_CLASS),
  };
  return { typedCommunities, groups, groupTypes };
}

export default groupFilterCommunities;
