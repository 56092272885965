import styled from 'styled-components';

const Effects = styled.span`
  &.hilite-0 {
    display: inline-block;
    transition-duration: 1s;
  }

  &.hilite-1 {
    display: inline-block;
    transform: scale(1, 1.2);
    transition-duration: 0.2s;
    color: #000;
  }

  &.scale-0 {
    display: none;
    transform: scale(0);
  }

  &.scale-1 {
    display: inline-block;
    transform: scale(1);
    transition-duration: 0.2s;
  }

  &.scale-2 {
    display: inline-block;
    transform: scale(0);
    transition-duration: 1s;
  }

`;

export default Effects;
