// Based on https://github.com/leny/react-use-store
import { useState, useEffect } from 'react';
import 'utils/CustomEvent';

let evtTarget;

try {
  evtTarget = new EventTarget();
} catch (err) {
  evtTarget = document.createElement('phony');
}

function usePersistentState(store, key, defaultValue) {
  const [value, setValue] = useState(initialValue());

  function initialValue() {
    if (store.getItem(key) === undefined) {
      store.setItem(key, defaultValue);
    }
    return store.getItem(key);
  }

  function updater(updatedValue) {
    setValue(updatedValue);
    store.setItem(key, updatedValue);
    evtTarget.dispatchEvent(
      new CustomEvent('global_storage_change', { detail: { key } }),
    );
  }

  useEffect(() => {
    function listener({ detail }) {
      if (detail.key === key) {
        setValue(store.getItem(key));
      }
    }
    evtTarget.addEventListener('global_storage_change', listener);
    return () => evtTarget.removeEventListener('global_storage_change', listener);
  }, []);
  return [value, updater];
}

export default usePersistentState;
