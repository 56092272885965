import { getStorage } from './storage.js';

export function AppStorage(name) {
  this.name = name;
  this.storage = getStorage();
  try {
    this.data = JSON.parse(this.storage.getItem(this.name) || {});
  } catch (e) {
    this.data = {};
  }
}

AppStorage.prototype.save = function save() {
  this.storage.setItem(this.name, JSON.stringify(this.data));
};

AppStorage.prototype.getKeys = function getKeys() {
  return Object.keys(this.data);
};

AppStorage.prototype.getItem = function getItem(key) {
  return this.data[key];
};

AppStorage.prototype.setItem = function setItem(key, value) {
  this.data[key] = value;
  this.save();
};

AppStorage.prototype.removeItem = function removeItem(key) {
  delete this.data[key];
  this.save();
};

AppStorage.prototype.clear = function clear() {
  this.data = {};
  this.save();
};
