import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import { parseUrl } from 'utils/urlUtils.js';
import makeSelectApi2 from './selectors.js';
import { api2call, api2update } from './actions.js';

const AUTONEXT_DELAY = 100;

function fromUrl(url) {
  if (!url) {
    return { path: '' };
  }
  return parseUrl(url || '');
}

function useApi2(options) {
  const { url, selector, autoNext } = options;
  const { uniqueId } = useCurrentRole() || {};
  const dispatch = useDispatch();
  const { path } = fromUrl(url);
  const selectApi2 = useMemo(makeSelectApi2(selector || path), [selector || path]); // eslint-disable-line react-hooks/exhaustive-deps
  const result = useSelector(selectApi2);
  const loading = useMemo(() => ({ loading: Boolean(url) }), [url]);
  function loadMore(moreOptions = {}) {
    dispatch(api2call({ ...options, url: result.nextUrl, ...moreOptions }));
  }
  function loadNew(moreOptions = {}) {
    if (result.hasNew) {
      dispatch(api2call({ ...options, url: result.sinceUrl, ...moreOptions }));
    }
  }
  // Reload if url changes
  useEffect(() => {
    if (url) {
      dispatch(api2call(options));
    }
  }, [url, uniqueId]); // eslint-disable-line react-hooks/exhaustive-deps
  // Load more for autoNext
  useEffect(() => {
    if (autoNext && result && !result.loading && result.hasMore) {
      setTimeout(() => {
        loadMore({ busyProp: 'appending' });
      }, AUTONEXT_DELAY);
    }
  }, [autoNext, result]); // eslint-disable-line react-hooks/exhaustive-deps
  const memoizedResult = useMemo(() => ({ ...result, loadMore, loadNew }), [result]); // eslint-disable-line react-hooks/exhaustive-deps
  if (result === undefined) {
    return loading;
  }
  return (result.nextUrl || result.sinceUrl) ? memoizedResult : result;
}

export function useApi2call() {
  const dispatch = useDispatch();
  return (options) => {
    const { withPromise, mock, ...rest } = options;
    if (mock) {
      // eslint-disable-next-line no-console
      console.log('Mock API call:', options);
      return withPromise ? Promise.resolve({ options }) : undefined;
    }
    if (!withPromise) {
      return dispatch(api2call(rest));
    }
    return new Promise((resolve, reject) => {
      dispatch(api2call({ ...rest, promise: { resolve, reject } }));
    });
  };
}

function getIdBody(options) {
  if (options.id) {
    return options;
  }
  const { id, ...rest } = options.body;
  return { id, body: rest };
}

export function useApi2post() {
  const apiCall = useApi2call();
  return (options) => {
    const { id, body } = getIdBody(options);
    if (!id) {
      return apiCall({ ...options, method: 'POST' });
    }
    const url = options.url.replace(/^([^?#]*)/, `$1/${id}`);
    return apiCall({
      ...options,
      method: options.method || 'PUT',
      url,
      selector: options.selector && `${options.selector}/${id}`,
      body,
    });
  };
}

export function useApi2fetch() {
  const dispatch = useDispatch();
  return (options) => {
    const { mock, ...rest } = options;
    if (mock) {
      // eslint-disable-next-line no-console
      console.log('Mock API call:', options);
      return Promise.resolve({ options });
    }
    return new Promise((resolve, reject) => {
      dispatch(api2call({ ...rest, selector: '-', promise: { resolve, reject } }));
    });
  };
}

export function useApi2update() {
  const dispatch = useDispatch();
  return (options) => dispatch(api2update(options));
}

export default useApi2;
