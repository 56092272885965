/**
*
* ArchivedCommunities
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import messages from './messages.js';
import PathItem from '../PathItem.jsx';

const Wrapper = styled(PathItem)`
  &.list-group-item.list-group-item {
    i {
      margin-right: 5px;
    }
    font-size: 1.2rem;
    padding-left: 56px;
    background-color: ${(p) => p.theme.grayLighter};
    &, &:focus {
      color: ${(p) => p.theme.gray};
    }
  }
`;

function ArchivedCommunities(props) {
  const { pathname } = props;
  return (
    <Wrapper to="/archive" active={pathname.startsWith('/archive')}>
      <AvatarWithText icon="ss-archive fs-5 text-muted" className="ms-5">
        <FormattedMessage {...messages.header} />
      </AvatarWithText>
    </Wrapper>
  );
}

ArchivedCommunities.propTypes = {
  pathname: PropTypes.string,
};

export default ArchivedCommunities;
