// For use in sagas
import { addBreadcrumb } from 'utils/sentry.js';
import { reject } from 'lodash-es';
import { INITIAL_USER } from '../constants.js';
import { makeCredentials, makeCurrentRole, makeCurrentUser } from './helpers.js';

let userObject = INITIAL_USER;

export function setUser(user) {
  userObject = user;
}

export function isValidUser() {
  const expiresAt = userObject.auth?.user?.expires_at;
  return expiresAt && (1000 * (expiresAt - 10) > Date.now());
}

export function getCurrentUser() {
  return makeCurrentUser(userObject);
}

export function getCurrentRole() {
  return makeCurrentRole(userObject);
}

// Wait for signinSilent to complete
// If not signed in after 10 seconds, give up
function waitForSignin() {
  const t0 = Date.now();
  const te = t0 + 10000;
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (isValidUser() || Date.now() > te) {
        clearInterval(interval);
        const data = { time: Date.now() - t0, isValidUser: isValidUser() };
        addBreadcrumb({
          category: 'userStatus',
          message: `waitedForSignin ${JSON.stringify(data)}`,
          level: 'log',
        });
        resolve();
      }
    }, 100);
  });
}

export async function getCredentials() {
  const expiresAt = userObject.auth?.user?.expires_at;
  if (expiresAt && !isValidUser()) {
    await waitForSignin();
  }
  return makeCredentials(userObject);
}

export function isActive() {
  return Boolean(userObject.active);
}

function checkSignIn() {
  const { auth } = userObject;
  if (!auth || auth.user || auth.isLoading) {
    return;
  }
  auth.signinRedirect();
}

export async function waitForProfile() {
  const t0 = Date.now();
  const te = t0 + 10000;
  checkSignIn();
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      checkSignIn();
      if (userObject.profile || Date.now() > te) {
        clearInterval(interval);
        if (userObject.profile) {
          resolve();
        } else {
          reject(new Error('No profile found'));
        }
      }
    }, 100);
  });
}
