/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { RouterProvider } from 'react-router-dom';
import injectSaga from 'utils/injectSaga.jsx';
import { DAEMON } from 'utils/constants.js';
import ThemeProvider from 'containers/ThemeProvider/index.jsx';
import api2Saga from 'containers/Api2/saga.js';
import trackSeenSaga from 'containers/TrackSeen/saga.js';
import LanguageProvider from 'containers/LanguageProvider/index.jsx';
import Spinner from 'components/Spinner/index.jsx';

import { compose } from 'redux';
import getRouter from './router/index.jsx';

function NcApp(props) {
  const { location } = props;
  const router = getRouter(location);
  return (
    <LanguageProvider>
      <ThemeProvider>
        <RouterProvider router={router} fallbackElement={<Spinner />} />
      </ThemeProvider>
    </LanguageProvider>
  );
}

NcApp.propTypes = {
  location: PropTypes.string,
};

const withApi2Saga = injectSaga({ key: 'api2', saga: api2Saga, mode: DAEMON });
const withTrackSeenSaga = injectSaga({ key: 'trackSeen', saga: trackSeenSaga, mode: DAEMON });

const App = compose(
  withApi2Saga,
  withTrackSeenSaga,
)(NcApp);

export default App;
