/**
 *
 * Asynchronously loads the component for PickerPopover
 *
 */

import loadable from 'utils/loadable.jsx';

export default loadable(() => import('./PickerPopover.jsx'), {
  fallback: null,
});
