import { SKIPMOLLIECHECKS } from 'components/PaymentRequests/constants.js';

const sessionVars = {
  forceProduction: ['local'],
  localServer: ['local', 'test'],
  esisSync: ['local', 'test'],
  [SKIPMOLLIECHECKS]: ['local', 'test'],
};

export default sessionVars;
