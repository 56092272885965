/**
 *
 * UserWithDetails
 * Load and Display a user
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/media/Icon/index.js';
import UserText from 'components/media/UserText/Loadable.js';
import UserAvatar from './UserAvatar/index.jsx';
import { PARENT_ROLE } from '../../containers/User/constants.js';

const ContactDetails = styled.div`
  i {
    color: ${(p) => p.theme.gray};
    margin-right: 8px;
  } 
  text-align: right;
`;

const ChildrenList = styled.div`
  font-size: 0.875rem;
  margin-left: 34px;
`;

function UserWithDetails(props) {
  const { user } = props;
  const contactTypes = [
    { id: 'phone', icon: 'ss-phone' },
    { id: 'email', icon: 'ss-mail' },
  ];
  return (
    <>
      <div className="d-flex justify-content-between">
        <UserAvatar user={user} withRole />
        <ContactDetails className="d-flex flex-column justify-content-end text-end">
          {contactTypes.filter((type) => user[type.id]).map((type) => (
            <div key={type.id}>
              <Icon className={type.icon} inline />
              {user[type.id]}
            </div>
          ))}
        </ContactDetails>
      </div>
      { user && user.roleType === PARENT_ROLE && (
        <ChildrenList>
          <UserText inline>{user.connections.map((child) => child.displayName).join(', ')}</UserText>
        </ChildrenList>
      )}
    </>
  );
}

UserWithDetails.propTypes = {
  user: PropTypes.object,
};

export default UserWithDetails;
