import { useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import config from 'config/index.js';
import useAlert from 'containers/Alerts/useAlert.js';
import storage from 'utils/storage/index.js';
import { addBreadcrumb } from '../../utils/sentry.js';

const isTop = window.top === window.self;

const autoResolve = () => Promise.resolve();

const TEST = (typeof process !== 'undefined') && process.env.TEST;
if (TEST) {
  window.webAppTiming = { start: Date.now() };
}

// addBreadcrumbs if renewed via silent.html
if (isTop) {
  window.addEventListener('message', (event) => {
    if (event.origin !== window.location.origin) {
      return;
    }
    const { data } = event;
    if (origin !== window.location.origin || data.category !== 'userStatus') {
      return;
    }
    addBreadcrumb(data);
  });
} else if (window.location.pathname === '/silent.html') {
  window.parent.postMessage({ category: 'userStatus', level: 'log', message: `silent.html${window.location.search}` }, window.location.origin);
}

function log(message) {
  const data = { category: 'userStatus', level: 'log', message };
  if (isTop) {
    addBreadcrumb(data);
  }
}

function useAuthWithWebView() {
  const auth = useAuth();
  const { removeUser, signoutRedirect, signinRedirect, ...rest } = auth;
  const [loginError, setLoginError] = useState(null);
  const { addError } = useAlert();

  const authResult = useMemo(() => ({
    ...rest,
    loginError,
    removeUser: () => {
      log('removeUser');
      removeUser();
      storage.clear();
    },
    signoutRedirect: () => {
      log('signoutRedirect');
      removeUser();
      storage.clear();
      window.redirecting = true;
      return signoutRedirect({
        id_token_hint: auth.user?.id_token,
      });
    },
    signinRedirect: (options) => {
      log('signinRedirect');
      const prompt = storage.getItem('signinPrompt') || 'login';
      return signinRedirect({ prompt, ...options, response_mode: 'query' })
        .catch((err) => {
          addError(err, 'oAuth');
          setLoginError(err);
        });
    },
  }), [auth]); // eslint-disable-line react-hooks/exhaustive-deps

  if (window.socsIsInAppWebView || TEST) {
    return {
      ...rest,
      user: { token_type: 'Bearer', access_token: window.socsAccessToken },
      isLoading: false,
      isAuthenticated: true,
      removeUser: autoResolve,
      signoutRedirect: autoResolve,
      signinRedirect: autoResolve,
    };
  }
  if (config.env === 'local') {
    // Remember for webview simulation
    sessionStorage.setItem('socsAccessToken', rest.user?.access_token);
  }
  return authResult;
}

export default useAuthWithWebView;
