import { useEffect } from 'react';
import { useApi2, useApi2call, useApi2update, useCommunities } from 'containers/Api2/index.js';
import { fromJS } from 'immutable';
import { EMPLOYEE_ROLE, MODULES_PORTFOLIOPILOT_OPTION, MODULES_STUDENTPORTFOLIO, GROUPTYPES_CLASS } from 'containers/User/constants.js';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import useModules from 'containers/User/useModules.js';
import { idValues, makeIdObject } from 'utils/arrays.js';
import { PORTFOLIO_PILOT_FEATURES } from './constants.js';

function usePortfolioGroups(groupId) {
  const modules = useModules();
  const { type } = useCurrentRole();
  const enabled = type === EMPLOYEE_ROLE;
  const hasPilot = modules.includes(MODULES_PORTFOLIOPILOT_OPTION);
  const selector = 'portfolio/groups';
  const url = modules.includes(MODULES_STUDENTPORTFOLIO) ? selector : undefined;
  const groups = useApi2({ url, selector, noReport: [401] });
  const communities = useCommunities();
  const api2call = useApi2call();
  const api2update = useApi2update();
  useEffect(() => {
    if (enabled && !hasPilot) {
      // Subscribed, fetch portfolio groups
      api2call({ url: selector });
    }
  }, [hasPilot, enabled]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (communities.loading || !enabled || !hasPilot) {
      return;
    }
    // Pilot, list classes where user is owner as portfolio groups
    if (!groups.list) {
      const pilotGroups = idValues(communities)
        .filter((g) => g.type === GROUPTYPES_CLASS && g.permissions.owner)
        .map((g) => ({
          id: g.groupId,
          theme: g.theme,
          name: g.name,
          features: PORTFOLIO_PILOT_FEATURES,
          pilot: {},
        }));
      api2update({ selector, updater: () => fromJS(makeIdObject(pilotGroups)) });
    }
    if (groupId) {
      api2call({ url: `${selector}/${groupId}/pilot`, reload: true });
      api2call({ url: `portfolio/groups/${groupId}/students` });
    }
  }, [communities.loading, hasPilot, groupId, enabled]); // eslint-disable-line react-hooks/exhaustive-deps
  return groups;
}

export default usePortfolioGroups;
