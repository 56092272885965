/**
*
* ScreenLog
*
*/

import React from 'react';
// import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components';
import { uniqueId } from 'lodash-es';
import { format } from 'date-fns';
let logList = [];

const Ul = styled.ul`
  max-height: calc(25vh);
  overflow-y: auto;
  font-family: ${(p) => p.theme.fontFamilyMonospace};
  position: fixed;
  bottom: 0;
  width: 100%;
  list-style: none;
  z-index: 1100;
  background: white;
  margin-bottom: 0;
  padding: 10px;
  padding-inline-start: 40px;
  li {
    text-indent: -30px;
  }
`;

function ScreenLog() {
  const [list, setList] = React.useState(logList);

  React.useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setList(logList);
  }

  return (
    <Ul id="screen-log">
      {list.map((item) => (
        <li key={item.id}>
          {item.time}
          {' '}
          {item.info}
        </li>
      ))}
    </Ul>
  );
}

export default ScreenLog;

export function screenLog(...args) {
  console.log(...args); // eslint-disable-line no-console
  const info = args.join(' ');
  if (showScreenLog()) {
    const time = format(new Date(), 'HH:mm:ss.SSS');
    logList = [...logList, { id: uniqueId('log_'), time, info }];
  }
}

export function showScreenLog() {
  return false;
  // return host !== 'production' && window.socsIsInAppWebView && !window.socsWebViewEmulate;
}

['info', 'error', 'warn'].forEach((func) => {
  ScreenLog[func] = (...args) => screenLog(func, args);
});
