/**
 *
 * Icon2
 *
 */

// import React from 'react';
import styled from 'styled-components';

const Icon2 = styled.i`
  display: inline-block;
  font-weight: normal;
  &.auto-color {
    &.ss-alert, &.ss-delete, &.ss-trash, &.ss-heart, &.ss-emptyheart {
      color: ${(p) => p.theme.brandDanger};
    }
    &.ss-check, &.ss-check-sign, &.ss-checkmark {
      color: ${(p) => p.theme.brandSuccess};
    }
    &.ss-info {
      color: ${(p) => p.theme.brandInfo};
    }
    &.ss-google {
      color: #4285f4;
    }
    &.ss-windows8 {
      color: #ea3e23;
    }
    &.ss-appleinc {
      color: #000000;
    }
  }
`;

export default Icon2;
