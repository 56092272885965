import { createSelector } from 'reselect';

/**
 * Direct selector to the alerts state domain
 */
export const selectAlertsDomain = (state) => state.get('alerts');

/**
 * Other specific selectors
 */

/**
 * Default selector used by Alerts
 */

const makeSelectAlerts = () => createSelector(
  selectAlertsDomain,
  (substate) => substate.toJS(),
);

export default makeSelectAlerts;
