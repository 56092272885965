/**
*
* ImageRestrictions
*
*/

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import Icon2 from 'components/media/Icon2/index.js';
import ImageRestrictionsModal from './ImageRestrictionsModal.jsx';
import messages from './messages.js';

const Wrapper = styled(Button)`
  &:focus, &:hover {
    background-color: inherit;
  }
  &.btn, &.btn:hover, &.btn:focus {
    color: ${(p) => p.theme.stateWarningText};
    background-color: ${(p) => p.theme.stateWarningBg};
    border-color: ${(p) => p.theme.stateWarningBorder};
  }
`;

function ImageRestrictions(props) {
  const { noConsentCount, unknownCount, link, label, inModal, audiences } = props;
  const [showDetails, toggleShowDetails] = useReducer((f) => !f, false);
  const options = { url: link, selector: link };
  if (audiences) {
    Object.assign(options, { method: 'POST', body: audiences });
  }
  return (
    <>
      <Wrapper variant="warning" size="sm" className="py-0" onClick={toggleShowDetails}>
        <Icon2 className="ss-camera me-2 fs-4 lower-1" />
        <span className="with-colon"><FormattedMessage {...messages.noConsent} /></span>
        <b>{noConsentCount}</b>
        <span className="with-colon ms-3"><FormattedMessage {...messages.unknown} /></span>
        <b>{unknownCount}</b>
      </Wrapper>
      {showDetails && <ImageRestrictionsModal inModal={inModal} fetchOptions={options} label={label} onHide={toggleShowDetails} />}
    </>
  );
}

ImageRestrictions.propTypes = {
  noConsentCount: PropTypes.number.isRequired,
  unknownCount: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  audiences: PropTypes.object,
  label: PropTypes.node,
  inModal: PropTypes.bool,
};

export default ImageRestrictions;
