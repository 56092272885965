import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import IntlString from 'components/media/IntlString/index.jsx';

function DialogHeader(props) {
  const { title } = props;
  if (!title) {
    return null;
  }
  return (
    <Modal.Header>
      <Modal.Title>
        <IntlString message={title} />
      </Modal.Title>
    </Modal.Header>
  );
}

DialogHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default DialogHeader;
