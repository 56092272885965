/*
 * Rsvp Messages
 *
 * This contains all the text for the Rsvp component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  yes: {
    id: 'app.common.yes',
    defaultMessage: 'Yes',
  },
  maybe: {
    id: 'app.components.Rsvp.maybe',
    defaultMessage: 'Maybe',
  },
  no: {
    id: 'app.common.no',
    defaultMessage: 'No',
  },
  attend: {
    id: 'app.components.Rsvp.attend',
    defaultMessage: 'Will you attend?',
  },
  viewResponses: {
    id: 'app.components.Rsvp.viewResponses',
    defaultMessage: 'See {count} responses',
  },
});
