/**
*
* NewConversationBadge
*
*/

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { Badge } from 'react-bootstrap';
import useStats from 'containers/User/UserProvider/useStats.js';

function NewConversationBadge(props) {
  const { inboxId, ...rest } = props;
  const { getIn } = useStats();

  const path = inboxId ? ['inboxes', inboxId, 'unseen'] : ['totalUnseen'];
  const unseenCount = getIn?.(path);
  if (!unseenCount) {
    return null;
  }
  return <Badge variant="primary" {...rest}>{unseenCount}</Badge>;
}

NewConversationBadge.propTypes = {
  inboxId: PropTypes.number,
};

export default NewConversationBadge;
