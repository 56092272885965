import { get, uniqueId } from 'lodash-es';

export const byId = (item) => item.id === undefined ? item : item.id;

export function idList(arr) {
  return arr.map(byId);
}

export function idObject(arr, key = 'id') {
  const result = {};
  arr.forEach((item) => {
    result[item[key]] = item;
  });
  return result;
}

export function hasItems(obj) {
  return Array.isArray(obj) ? obj.length > 0 : Boolean(get(obj, 'list.length'));
}

// eslint-disable-next-line no-underscore-dangle
export function idValues(obj, key = 'list') {
  if (!obj || !obj[key]) {
    return [];
  }
  return obj[key].map((id) => obj[id]);
}

export function makeIdObject(obj = [], arrayProps = []) {
  if (typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return fromArray(obj, 'id', arrayProps);
  }
  if (obj.values && Array.isArray(obj.values)) {
    const { values, ...rest } = obj;
    return {
      ...rest,
      ...fromArray(values, 'id', arrayProps),
    };
  }
  const result = { ...obj };
  arrayProps.forEach((prop) => {
    const item = obj[prop];
    if (item) {
      result[prop] = makeIdObject(item, arrayProps);
    }
  });
  return result;
}

export function idMap(obj, callback) {
  if (!obj || !obj.list) {
    return obj;
  }
  const result = { ...obj };
  obj.list.forEach((id) => {
    result[id] = callback(obj[id]);
  });
  return result;
}

export function addWithId(obj, item) {
  return {
    ...obj,
    [item.id]: item,
    list: [...obj.list, item.id],
  };
}

export function deleteById(obj, delId) {
  const result = { ...obj, list: obj.list.filter((id) => id !== delId) };
  delete result[delId];
  return result;
}

function withTempIds(arr) {
  return arr.map((item) => item.id === undefined ? { ...item, id: uniqueId('tmp_id_') } : item);
}

// eslint-disable-next-line default-param-last
export function fromArray(arr, key = 'id', arrayProps) {
  const filteredArray = withTempIds(arr)
    .filter((item) => Boolean(item[key]))
    .map((item) => arrayProps.length > 0 ? makeIdObject(item, arrayProps) : item);
  return {
    ...idObject(filteredArray, key),
    list: idList(filteredArray),
  };
}

/**
 * Add property from source, selected by id from array item
 * e.g. to add label from messages object
 * @param arr
 * @param key
 * @param source
 */
export function addPropertyById(arr, key, source) {
  return arr.map((item) => ({ ...item, [key]: source[item.id] }));
}

export function group(arr, grouper, asObject) {
  const keys = [];
  const grouped = {};
  arr.forEach((item) => {
    const key = typeof grouper === 'string' ? item[grouper] : grouper(item);
    if (!keys.includes(key)) {
      keys.push(key);
      grouped[key] = [];
    }
    grouped[key].push(item);
  });
  if (asObject) {
    return grouped;
  }
  return keys.map((key) => ({ key, items: grouped[key] }));
}

export function replace(arr, idx, item) {
  const copy = arr.slice(0);
  copy[idx] = item;
  return copy;
}

export function fillArray(len, cb) {
  return new Array(len).fill().map((v, i) => cb ? cb(i) : i);
}

export function reverse(arr) {
  return arr.slice(0).reverse();
}

export function arrayMove(arr, from, to) {
  const array = arr.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

/**
 * array push, but handles undefined input array and return array
 * @param {array} arr
 * @param {*} item
 */
// eslint-disable-next-line default-param-last
export function push(arr = [], item) {
  arr.push(item);
  return arr;
}

export function groupBy(list, prop) {
  const result = {};
  list.forEach((item) => {
    const key = item[prop];
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
  });
  return result;
}
