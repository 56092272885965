import { css } from 'styled-components';

const alertMixin = css`
  .alert.alert-toast {
    position: fixed;
    z-index: 2000;
    top: 60px;
    right: 10px;
    width: 25em;
    border: 1px solid ${(props) => props.theme.stateSuccessText};
    color: ${(props) => props.theme.bodyBg};
    background-color: ${(props) => props.theme.brandSuccess};
    border: none;
  }
  .alert.alert-shaded:not(.alert-toast) {
    background-color: ${(props) => props.theme.bodyBg};
    border: 2px solid ${(props) => props.theme.stateInfoText};
    border-color: ${(props) => props.theme.stateInfoText};
    &.alert-danger {
      color: ${(props) => props.theme.textDanger};
      border-color: ${(props) => props.theme.stateDangerText};
    }
    &.alert-warning {
      color: ${(props) => props.theme.textWarning};
      border-color: ${(props) => props.theme.stateWarningText};
    }
  }
`;

export default alertMixin;
