/*
 *
 * Dialog constants
 *
 */

import messages from './messages.js';

export const DIALOG_SHOW = 'app/Dialog/DIALOG_SHOW';
export const DIALOG_HIDE = 'app/Dialog/DIALOG_HIDE';

export const DIALOG_ALERT = 1;
export const DIALOG_CONFIRM = 2;
export const DIALOG_PROMPT = 3;

export const REMOVE_OPTIONS = {
  confirmLabel: messages.remove,
  confirmStyle: 'danger',
};
