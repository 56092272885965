/**
 *
 * Groups
 * Show event groups or community
 */

import React from 'react';
import PropTypes from 'prop-types';
import ColorBlock from 'components/media/ColorBlock/index.js';

function Groups(props) {
  const { event, withColors } = props;
  if (event.communities) {
    return (
      <>
        {withColors && event.communities.map((g) => <ColorBlock key={g.id} color={g.color.hex} />) }
        {event.communities.map((g) => g.name).join(', ') }
      </>
    );
  }
  if (event.community) {
    return (
      <>
        { withColors && <ColorBlock color={event.community.color.hex} /> }
        {event.community.name}
      </>
    );
  }
  return <ColorBlock color={event.color.hex} />;
}

Groups.propTypes = {
  event: PropTypes.object,
  withColors: PropTypes.bool,
};

export default Groups;
