import replaceParams from 'utils/replaceParams.js';

function getAttributes(item) {
  const { route, routeArgs } = item;
  switch (route) {
    case 'post-detail':
      return { link: replaceParams('/communityposts/:postId', routeArgs), icon: 'ss-heart' };
    case 'consent-items':
      return { link: replaceParams('/groups/:groupId/students/:studentId', routeArgs), icon: 'ss-checkmark' };
    case 'comment-detail':
      return { link: replaceParams('/communityposts/:postId#comment=:commentId', routeArgs), icon: 'ss-chat' };
    case 'event-detail':
      return { link: replaceParams('/calendar#event=:eventId', routeArgs), icon: 'ss-calendar' };
    case 'conversationplanning-overview':
      return { link: replaceParams('/students/:studentId', routeArgs), icon: 'ss-calendar' };
    case 'leaverequest-student':
    case 'leaverequest-detail':
      return { link: replaceParams('/students/:studentId', routeArgs), icon: 'ss-luggage' };
    case 'news-bulletins':
      return { link: replaceParams('/newsletters/:newsBulletinId', routeArgs), icon: 'ss-newspaper' };
    case 'studentabsence-detail': {
      const url = routeArgs.groupId ? '/groups/:groupId/students/:studentId' : '/students/:studentId';
      return { link: replaceParams(url, routeArgs), icon: 'ss-stethoscope' };
    }
    case 'portfolio-goal-detail':
      return { link: replaceParams('/portfolio/:portfolioId/goals/:portfolioGoalId', routeArgs), icon: 'ss-target' };
    case 'portfolio-post-detail':
      return { link: replaceParams('/portfolio/:portfolioId/workspace/:portfolioPostId', routeArgs), icon: 'ss-mail' };
    case 'portfolio-post-comment-detail':
      return { link: replaceParams('/portfolio/:portfolioId/workspace/:portfolioPostId#comment=:commentId', routeArgs), icon: 'ss-mail' };
    case 'portfolio-showcase-detail':
      return { link: replaceParams('/portfolio/:portfolioId/showcase/:portfolioPostId', routeArgs), icon: 'ss-mail' };
    case 'payment-request':
    case 'payment-request-revoked':
    case 'payment-request-reminder':
      return routeArgs.paymentRequestId
        ? { link: replaceParams('/payments/user#payment=:paymentId&paymentRequest=:paymentRequestId', routeArgs), icon: 'ss-banknote' }
        : { link: replaceParams('/payments/user#payment=:paymentId', routeArgs), icon: 'ss-banknote' };
    case null:
      return {};
    default:
      console.error('Unknown route', route, routeArgs); // eslint-disable-line no-console
  }
  return {};
}

export default getAttributes;
