/**
 *
 * AskMaterials
 *
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import FormControlWrapper from 'components/interaction/Form4/FormControlWrapper.jsx';
import MaterialLine from './MaterialLine.jsx';

import messages from './messages.js';

const Wrapper = styled(FormControlWrapper)`
  width: 100%;
  ul li + li {
    margin-top: 10px;
  }
  button {
    padding-left: 10px;
  }
`;

function AskMaterials(props) {
  const { value, onChange } = props;
  const lastRef = useRef();

  function focusNew() {
    const input = lastRef.current?.getElementsByTagName('input');
    input[0].focus();
  }

  function addMaterial() {
    onChange([...value, { name: '', totalCount: 1, backing: {} }]);
    window.setTimeout(focusNew, 0);
  }

  const handleChange = (index) => (tag) => (ev) => {
    if (tag === 'delete') {
      onChange(value.filter((mt, idx) => idx !== index));
      return;
    }
    const material = { ...value[index], [tag]: ev.target.value };
    onChange(value.map((mt, idx) => idx === index ? material : mt));
  };

  const notEmpty = value.length > 0;
  const lastIndex = value.length - 1;
  const message = notEmpty ? messages.askMore : messages.askFirst;
  const materials = value.map((val, idx) => ({ ...val, key: idx, totalCount: val.totalCount && Number(val.totalCount) }));
  return (
    <Wrapper border={1}>
      {notEmpty && (
        <ul className="list-unstyled">
          {materials.map((material, idx) => (
            <li key={material.key} ref={idx === lastIndex ? lastRef : undefined}>
              <MaterialLine {...material} onChange={handleChange(idx)} />
            </li>
          ))}
        </ul>
      )}
      <FormGroup>
        <Button variant="link" onClick={addMaterial}><FormattedMessage {...message} /></Button>
      </FormGroup>
    </Wrapper>
  );
}

AskMaterials.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default AskMaterials;
