import config from 'config/index.js';
const ADMIN_HOST = config.adminHost;

function makeAdminLink(role, url) {
  const { school: { id }, type } = role;
  const base = `${ADMIN_HOST}/auth/${id}/${type}`;
  return url ? `${base}/true?redirecturl=${encodeURIComponent(ADMIN_HOST + url)}` : base;
}

export default makeAdminLink;
