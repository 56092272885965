import { makeIdObject } from 'utils/arrays.js';

function getInboxes(inboxes = []) {
  return makeIdObject(inboxes);
}

function getUnseenPortfolioActivitiesCount(unseenPortfolioActivitiesCount) {
  if (!unseenPortfolioActivitiesCount) {
    return unseenPortfolioActivitiesCount;
  }
  const result = { total: 0 };
  const date = new Date();
  unseenPortfolioActivitiesCount.forEach((item) => {
    result.total += item.unseenCount;
    result[item.groupId] = { ...item, date };
  });
  return result;
}

function statsParser(stats) {
  const { unseenPortfolioActivitiesCount, inboxes, ...rest } = stats;
  const totalUnseen = inboxes?.reduce((acc, item) => acc + item.unseen, 0) || 0;
  return {
    ...rest,
    totalUnseen,
    unseenPortfolioActivitiesCount: getUnseenPortfolioActivitiesCount(unseenPortfolioActivitiesCount),
    inboxes: getInboxes(inboxes),
  };
}

export default statsParser;
