/**
 *
 * ChildrenMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ListGroupItem } from 'react-bootstrap';
import useModules from 'containers/User/useModules.js';
import { MODULES_ABSENCES, MODULES_PAYMENTS, MODULES_IMAGEPREFERENCES } from 'containers/User/constants.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import { hasItems } from 'utils/arrays.js';
import ExpandItem from '../ExpandItem.jsx';
import messages from '../messages.js';
import DynamicListGroup from '../DynamicListGroup/index.jsx';
import PathItem from '../PathItem.jsx';

function ChildrenMenu(props) {
  const { expanded, onClick, role } = props;
  const { connections, isAllowedToCreatePayments } = role;
  const canManage = isAllowedToCreatePayments;
  const modules = useModules();

  const { pathname } = useLocation();
  if (!hasItems(connections)) {
    return null;
  }
  return (
    <>
      <ExpandItem onClick={onClick} expanded={expanded}>
        <AvatarWithText icon="ss-index" active={pathname.startsWith('/students')}>
          <FormattedMessage {...messages.administration} />
        </AvatarWithText>
      </ExpandItem>
      {expanded && (
        <div className="list-group-indent">
          <DynamicListGroup
            data={{ values: connections }}
            link="/students/:id"
            noRedirect
          />
          <ListGroupItem className="list-group-header">
            <FormattedMessage {...messages.overviews} />
          </ListGroupItem>
          {modules.includes(MODULES_ABSENCES) && (
            <PathItem to="/absences">
              <AvatarWithText icon="ss-stethoscope" active={pathname.startsWith('/absences')}>
                <FormattedMessage {...messages.absences} />
              </AvatarWithText>
            </PathItem>
          )}
          {modules.includes(MODULES_PAYMENTS) && (
            <PathItem to="/payments/user">
              <AvatarWithText icon="ss-banknote">
                <FormattedMessage {...messages.paymentRequests} />
              </AvatarWithText>
            </PathItem>
          )}
          <PathItem to="/meetings">
            <AvatarWithText icon="ss-calendar">
              <FormattedMessage {...messages.parentMeetings} />
            </AvatarWithText>
          </PathItem>
          {modules.includes(MODULES_IMAGEPREFERENCES) && (
            <PathItem to="/students/consents">
              <AvatarWithText icon="ss-checkmark">
                <FormattedMessage {...messages.consents} />
              </AvatarWithText>
            </PathItem>
          )}
          {canManage && (
            <>
              <ListGroupItem className="list-group-header">
                <FormattedMessage {...messages.management} />
              </ListGroupItem>
              {isAllowedToCreatePayments && (
                <PathItem to="/payments/admin">
                  <AvatarWithText icon="ss-banknote">
                    <FormattedMessage {...messages.paymentRequests} />
                  </AvatarWithText>
                </PathItem>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

ChildrenMenu.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
};

export default ChildrenMenu;
