function getControlValue(e) {
  if (!e?.target) {
    return e;
  }
  if (e.target.type === 'checkbox') {
    return e.target.checked;
  }
  return e.target.value;
}

export default getControlValue;
