/**
*
* ColorBlock
*
*/

import PropTypes from 'prop-types';
import styled from 'styled-components';

function colorString(props) {
  return props.color ? props.color.hex || props.color : props.theme.grayLighter;
}

const ColorBlock = styled.div.attrs({
  className: 'color-block',
})`
  display: inline-block;
  height: 1.2em;
  width: 1.2em;
  min-width: 1.2em;
  background-color: ${colorString} !important;
  border-radius: 4px;
  margin-right: 3px;
  margin-left: 2px;
`;

ColorBlock.propTypes = {
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default ColorBlock;

export const ColorStripe = styled.div`
  height: 8px;
  width: 50px;
  background-color: ${colorString};
  border-radius: 4px;
`;
