import { css } from 'styled-components';
import placeholderMixin from 'styles/placeholderMixin.js';

const reactSelectMixin = css`
  .rs__control.rs__control {
    margin-left: 1px;
    border-radius: 16px;
    min-height: 34px;
    &.rs__control--is-focused, &:focus, &:hover {
      color: #212529;
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
    .rs__indicator {
      padding: 6px;
      .ss-dropdown {
        font-size: 21px;
      }
    }
    .rs__option--is-focused {
      background-color: #eee;
    }
    .rs__placeholder {
      ${placeholderMixin};
    }
    .rs__multi-value, .rs__multi-value__remove {
      border-radius: 12px;
    }
  }
  .is-invalid .rs__control.rs__control {
    border: transparent;
    box-shadow: none;
    &.rs__control--is-focused, &:focus, &:hover {
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
    }
  }
`;

export default reactSelectMixin;
