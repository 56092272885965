/*
 *
 * Api2 actions
 *
 */
import applyFunctions from 'utils/applyFunctions.js';
import { withDefaults, substitute, exceptions } from './parseOptions.js';

import {
  API2_CALL,
  API2_UPDATE,
  API2_CALL_CANCEL,
} from './constants.js';

export function api2call(entity, params) {
  const options = applyFunctions(entity, [withDefaults, substitute(params), exceptions]);
  return {
    type: API2_CALL,
    options,
  };
}

export function api2cancel(options, reason) {
  return {
    type: API2_CALL_CANCEL,
    options,
    reason,
  };
}

export function api2update(options) {
  return {
    type: API2_UPDATE,
    options,
  };
}
