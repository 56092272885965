import { css } from 'styled-components';
import iconStyles from '../iconStyles.js';

const dropdownMixin = css`
  .no-caret .dropdown-toggle:after { 
    content: none;
  }

  .caret.caret {
    border: none !important;
    width: auto;
    height: auto;
    &:before {
      ${iconStyles};
      font-size: 21px;
      content: "\\25be";
    }
  }

  .dropdown-toggle {
    color: ${(p) => p.theme.gray};
    display: flex;
    align-items: baseline;
    &:after {
      color: ${(p) => p.theme.grayLight};
    }
  }

  .dropdown-toggle:after {
    border-width: 0.6em 0.5em 0.5em !important;
    border-radius: 3px;
  }

  .dropdown-menu-large {
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    .dropdown-item + .dropdown-item {
    border-top: 1px solid ${(p) => p.theme.grayLighter};
    }
    .dropdown-item {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
  }

`;

export default dropdownMixin;
