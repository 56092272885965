import { camelCase } from 'lodash-es';
import bs3config from './config.json'; // From bootstrap 3 config utility
import { themeVariables } from './constants.js';
import themeFromSchool from './themeFromSchool.js';
import evaluate from './evaluate.js';

const { vars } = bs3config;

function camelKey(key) {
  return camelCase(key.substr(1));
}

// Change keys of vars to camelCase
const camelVars = Object.keys(vars)
  .reduce((result, key) => ({ ...result, [camelKey(key)]: vars[key] }), {});

/**
 * Parse less string to valid CSS
 * @param key
 * @param obj
 * @returns {*}
 */
export function parse(key, obj) {
  /**
   * Recursively substitute variables for values until all replaced
   * @param str
   * @returns {*}
   */
  function substitute(str) {
    const result = str.replace(/@[\w-]+/g, (k) => obj[camelKey(k)]);
    return result === str ? result : substitute(result);
  }

  const str = obj[key] || '';
  // Substitute current variables
  const substituted = substitute(str);
  // css knows how to handle rgba function, remove bracket for testing
  const cssKnows = substituted.replace(/rgba\(/g, 'rgba');
  if (!cssKnows.includes('(') && !cssKnows.includes(' / ')) {
    // No evaluation needed
    return substituted;
  }
  if (/px/.test(substituted)) {
    // Dimensions, just evaluate and add px back later
    const evaluated = evaluate(substituted.replace(/px/g, ''));
    return `${evaluated}px`;
  }
  const colorRe = /(#([\da-f]+))/gi;
  if (colorRe.test(substituted) || /rgba/.test(substituted)) {
    const forEval = substituted
      .replace(/(#([\da-f]+))/gi, '\'$1\'') // Color string in quotes
      .replace(/%/g, ''); // Remove %, such values are on a scale 0-100 anyway
    return evaluate(forEval).replace(/'/g, '');
  }
  try {
    return evaluate(substitute);
  } catch (e) {
    return substituted; // In case of error evaluating, return original
  }
}

export function mergeTheme(theme) {
  const merged = { ...camelVars, ...themeVariables, ...theme };
  Object.keys(merged)
    .forEach((key) => {
      merged[key] = parse(key, merged);
    });
  return merged;
}

export function getTestTheme() {
  return mergeTheme({});
}

export function mergeConfig(school) {
  const theme = themeFromSchool(school);
  return mergeTheme(theme);
}
