import { useMemo } from 'react';
import useApi2 from 'containers/Api2/useApi2.js';
import { isOwnGroup } from 'components/interaction/AllGroupsSelect/index.jsx';

function useCommunities(noFetch) {
  const options = noFetch ? { selector: 'communities' } : { url: 'communities?showAll=true', noReport: [401] };
  const communities = useApi2(options);
  const result = useMemo(() => {
    if (!communities.list) {
      return communities;
    }
    return {
      ...communities,
      hasNotOwned: communities.list.some((id) => !isOwnGroup(communities[id])),
    };
  }, [communities]);
  return result;
}

export default useCommunities;
