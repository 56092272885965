/*
 * PhotoBook Messages
 *
 * This contains all the text for the PhotoBook component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.PhotoBook.title',
    defaultMessage: 'Photobook',
  },
  description: {
    id: 'app.components.PhotoBook.description',
    defaultMessage: 'Photobook',
  },
  myAlbums: {
    id: 'app.components.PhotoBook.myAlbums',
    defaultMessage: 'My Photobooks',
  },
  createPhotoBook: {
    id: 'app.components.PhotoBook.createPhotoBook',
    defaultMessage: 'Create Photobook',
  },
  createAlbum: {
    id: 'app.components.PhotoBook.createAlbum',
    defaultMessage: 'Create New Photobook',
  },
  confirmDeleteAlbum: {
    id: 'app.components.PhotoBook.confirmDeleteAlbum',
    defaultMessage: 'Delete this photobook?',
  },
  pageCount: {
    id: 'app.components.PhotoBook.pageCount',
    defaultMessage: '{count} of {total} pages filled',
  },
  lastModified: {
    id: 'app.components.PhotoBook.lastModified',
    defaultMessage: 'Last modified:',
  },
  settings: {
    id: 'app.components.PhotoBook.settings',
    defaultMessage: 'Settings',
  },
  viewAlbum: {
    id: 'app.components.PhotoBook.viewAlbum',
    defaultMessage: 'View Photobook',
  },
  selectOne: {
    id: 'app.common.selectOne',
    defaultMessage: 'Select One',
  },
  noPosts: {
    id: 'app.components.PhotoBook.noPosts',
    defaultMessage: 'There are no posts with images in the selected period and group',
  },
  deletedUser: {
    id: 'app.components.UserModal.deletedUser',
    defaultMessage: 'Deleted user',
  },
  ready: {
    id: 'app.common.ready',
    defaultMessage: 'Ready',
  },
  close: {
    id: 'app.common.close',
    defaultMessage: 'Close',
  },
  loading: {
    id: 'app.components.PhotoBook.loading',
    defaultMessage: 'Posts are being loaded…',
  },
  selectPictures: {
    id: 'app.components.PhotoBook.selectPictures',
    defaultMessage: 'Select Your Pictures',
  },
  selectPicturesIntro: {
    id: 'app.components.PhotoBook.selectPicturesIntro',
    defaultMessage: 'Select the pictures from the posts in your groups',
  },
  previewBtn: {
    id: 'app.components.PhotoBook.previewBtn',
    defaultMessage: 'Preview',
  },
  preview: {
    id: 'app.components.PhotoBook.preview',
    defaultMessage: 'Preview and Personalize Your Photobook',
  },
  previewIntro: {
    id: 'app.components.PhotoBook.previewIntro',
    defaultMessage: 'We automatically compile your pictures in a beautiful photobook. Simple!',
  },
  order: {
    id: 'app.components.PhotoBook.order',
    defaultMessage: 'Order Your Photobook',
  },
  orderBtn: {
    id: 'app.components.PhotoBook.orderBtn',
    defaultMessage: 'Order',
  },
  orderIntro: {
    id: 'app.components.PhotoBook.orderIntro',
    defaultMessage: 'Order your photobook, we deliver is as soon as possible!',
  },
  getStarted: {
    id: 'app.components.PhotoBook.getStarted',
    defaultMessage: 'Let\'s Get Started!',
  },
  orderSuccess: {
    id: 'app.components.PhotoBook.orderSuccess',
    defaultMessage: 'Thank You for Your Order!',
  },
  orderSuccessHelp: {
    id: 'app.components.PhotoBook.orderSuccessHelp',
    defaultMessage: 'You will receive a confirmation email with details about this order.',
  },
  backToOverview: {
    id: 'app.components.PhotoBook.backToOverview',
    defaultMessage: 'Back to the Overview',
  },
  paymentError: {
    id: 'app.components.PhotoBook.paymentError',
    defaultMessage: 'Something went wrong while ordering your photobook.',
  },
  notSelected: {
    id: 'app.components.PhotoBook.notSelected',
    defaultMessage: 'Not Selected',
  },
  tryAgain: {
    id: 'app.common.tryAgain',
    defaultMessage: 'Try again',
  },
  view: {
    id: 'app.components.Button.view',
    defaultMessage: 'View',
  },
  noImages: {
    id: 'app.components.PhotoBook.noImages',
    defaultMessage: 'You have not selected any pictures yet.',
  },
  ordered: {
    id: 'app.components.PhotoBook.ordered',
    defaultMessage: 'Ordered',
  },
  orderedHelp: {
    id: 'app.components.PhotoBook.orderedHelp',
    defaultMessage: 'Your photobook will be delivered as soon as possible.{br}Keep an eye on your email for updates',
  },
  draft: {
    id: 'app.common.draft',
    defaultMessage: 'Draft',
  },
  pagesText: {
    id: 'app.components.PhotoBook.pagesText',
    defaultMessage: 'A photobook contains at least 24 pages, these are included in the price.{br}For additional pages, you pay { pricePerPage, plural, =0 {an extra amount} other {€#}} per page.',
  },
  termsOfUse: {
    id: 'app.components.PhotoBook.termsOfUse',
    defaultMessage: 'Terms of Use',
  },
  agreeTerms: {
    id: 'app.components.PhotoBook.agreeTerms',
    defaultMessage: 'I agree with the <a>Terms of Service</a>',
  },
  understand: {
    id: 'app.common.understand',
    defaultMessage: 'I understand!',
  },
  submit: {
    id: 'app.common.submit',
    defaultMessage: 'Submit',
  },
  notEnabled: {
    id: 'app.components.PhotoBook.notEnabled',
    defaultMessage: 'Photobooks are not enabled at your school.{br}Please contact the manager of Social Schools to enable photobooks.',
  },
  tooManyPosts: {
    id: 'app.components.PhotoBook.tooManyPosts',
    defaultMessage: 'The selection contains a large number ({numPosts}) of posts. Only images from the first {max} posts are selected.',
  },
  groupsNotFound: {
    id: 'app.components.PhotoBook.groupsNotFound',
    defaultMessage: 'One or more groups could not be found. Posts from these groups have been removed from the photobook.',
  },
  requiredField: {
    id: 'app.components.Form.requiredField',
    defaultMessage: 'This is a required field',
  },
});
