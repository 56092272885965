/*
 * PublishModal Messages
 *
 * This contains all the text for the PublishModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.Post.publishTitle',
    defaultMessage: 'Publish Post',
  },
  intro: {
    id: 'app.components.PublishModal.intro',
    defaultMessage: 'Set the channels on which you want to publish this post:',
  },
  website: {
    id: 'app.components.PublishModal.website',
    defaultMessage: 'Web Site',
  },
  externalContacts: {
    id: 'app.components.PublishModal.externalContacts',
    defaultMessage: 'External Contacts',
  },
  shareExternal: {
    id: 'app.components.PublishModal.shareExternal',
    defaultMessage: 'Share with external contacts',
  },
  shareExternalCount: {
    id: 'app.components.PublishModal.shareExternalCount',
    defaultMessage: 'Shared with {count} external contact(s)',
  },
  publicLink: {
    id: 'app.components.PublishModal.publicLink',
    defaultMessage: 'Public link',
  },
  notYet: {
    id: 'app.components.PublishModal.notYet',
    defaultMessage: 'nog niet beschikbaar',
  },
  published: {
    id: 'app.components.Post.published',
    defaultMessage: 'The post has been published',
  },
  revoked: {
    id: 'app.components.Post.revoked',
    defaultMessage: 'Publication of the post has been revoked',
  },
  linkRevokeTitle: {
    id: 'app.components.PublishModal.linkRevokeTitle',
    defaultMessage: 'Revoke Public Link?',
  },
  linkRevokeMessage: {
    id: 'app.components.PublishModal.linkRevokeMessage',
    defaultMessage: '<ul><li>If you revoke the public link, the item will also be removed from connected Social Media on which you have published it.</li><li>External contacts with whom you have shared the item, will no longer have access to it.</li></ul>',
  },
  linkRevokeConfirm: {
    id: 'app.components.PublishModal.linkRevokeConfirm',
    defaultMessage: 'Revoke',
  },
});
