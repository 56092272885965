/**
 *
 * StudentMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, ListGroupItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useGroupFromPathname from 'containers/Api2/useGroupFromPathname.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import Icon2 from 'components/media/Icon2/index.js';
import StudentList from './StudentList.jsx';
import SideMenuListGroup from '../SideMenuListGroup.js';

function StudentMenu(props) {
  const { pathname } = props;
  const { group, portfolioGroup } = useGroupFromPathname(pathname);
  if (!group) {
    return null;
  }
  const link = `/groups/${group.groupId}${portfolioGroup ? '/portfolio' : ''}`;

  function showStudents() {
    if (!portfolioGroup) {
      return true;
    }
    if (portfolioGroup.loading) {
      return false;
    }
    return (!portfolioGroup.pilot || portfolioGroup.pilot.enabled === true);
  }
  return (
    <SideMenuListGroup id="student-menu">
      <ListGroupItem className="d-flex align-items-center">
        <Link className="px-2 ms-n4" to="/">
          <Icon2 className="ss-navigateleft fs-2 lower-1" />
        </Link>
        <Link as={Button} variant="link" className="ms-1" to={link}>
          <AvatarWithText color={group.theme.themeColor} group>
            <div className="fs-5 lh-1 ms-n2 text-gray">{group.name}</div>
          </AvatarWithText>
        </Link>
      </ListGroupItem>
      {showStudents() && <StudentList group={group} portfolio={Boolean(portfolioGroup)} />}
    </SideMenuListGroup>
  );
}

StudentMenu.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default StudentMenu;
