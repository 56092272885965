/*
 * LoginButton Messages
 *
 * This contains all the text for the LoginButton component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  login: {
    id: 'app.components.LoginButton.login',
    defaultMessage: 'Log In',
  },
});
