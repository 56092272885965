/**
 *
 * AdministrationMenu
 *
 */

import React from 'react';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import {
  PARENT_ROLE,
  EMPLOYEE_ROLE,
  GROUPLEVELS_FOUNDATION,
} from 'containers/User/constants.js';
import ClassesMenu from './ClassesMenu.jsx';
import ChildrenMenu from './ChildrenMenu.jsx';

function AdministrationMenu(props) {
  const role = useCurrentRole();
  if (role.school.type === GROUPLEVELS_FOUNDATION) {
    return null;
  }
  switch (role.type) {
    case EMPLOYEE_ROLE:
      return <ClassesMenu {...props} />;
    case PARENT_ROLE:
      return (
        <ChildrenMenu {...props} role={role} />
      );
    default:
      return null;
  }
}

export default AdministrationMenu;
