/**
 *
 * Replace intl props with formattedMessage
 *
 */

import { useIntl } from 'react-intl';

export function useReplaceIntlProps(props, propList) {
  const intl = useIntl();
  const result = { ...props };
  propList.forEach((key) => {
    const prop = props[key];
    if (prop && prop.id) {
      const { values, ...rest } = prop;
      result[key] = intl.formatMessage(rest, values);
    }
  });
  return result;
}
