/**
 *
 * SideMenu
 *
 */

import React from 'react';
import HomeLink from './HomeLink.jsx';
import PortfolioMenu from './PortFolioMenu/index.jsx';
import CommunitiesMenu from './CommunitiesMenu/index.jsx';
import AdministrationMenu from './AdministrationMenu/index.jsx';
import useSideMenu from './useSideMenu.js';
import CalendarLink from './CalendarLink.jsx';
import ConversationsMenu from './ConversationsMenu.jsx';
import SideMenuListGroup from './SideMenuListGroup.js';
import DashboardMenu from './DashboardMenu/index.jsx';
import NewslettersLink from './NewslettersLink.jsx';

function SideMenu() {
  const { expandProps, ...otherProps } = useSideMenu();

  return (
    <SideMenuListGroup id="side-menu" variant="flush">
      <HomeLink {...otherProps} />
      <NewslettersLink {...otherProps} />
      <ConversationsMenu {...expandProps('conversations')} {...otherProps} />
      <CommunitiesMenu {...expandProps('communities')} {...otherProps} />
      <PortfolioMenu {...expandProps('portfolio')} {...otherProps} />
      <AdministrationMenu {...expandProps('administration')} {...otherProps} />
      <CalendarLink {...otherProps} />
      <DashboardMenu {...expandProps('admin')} {...otherProps} />
    </SideMenuListGroup>
  );
}

export default SideMenu;
