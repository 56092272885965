function readBlob(blob, type) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader(); // eslint-disable-line no-undef
    reader.onload = () => resolve(reader.result);
    reader.onabort = (ev) => {
      reject(new Error('File Aborted'), ev);
    };
    reader.onerror = (ev) => {
      reject(new Error('File Error'), ev);
    };
    switch (type) {
      case 'arrayBuffer':
        reader.readAsArrayBuffer(blob);
        break;
      case 'binaryString':
        reader.readAsBinaryString(blob);
        break;
      case 'dataURL':
        reader.readAsDataURL(blob);
        break;
      case 'text':
        reader.readAsText(blob);
        break;
      default:
        reject(new Error(`Invalid result type: ${type}`));
    }
  });
}

export default readBlob;
