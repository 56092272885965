/**
 *
 * PortfolioWorkspaceContext
 *
 */

import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useApi2 from 'containers/Api2/useApi2.js';
import useUpdateOutletContext from 'containers/App/router/useUpdateOutletContext.js';

function PortfolioWorkspaceContext() {
  const { wid } = useParams();
  const workspace = useApi2({ url: `portfolio/${wid}`, selector: `portfolio/workspaces/${wid}`, noReport: [401] });
  const newContext = useUpdateOutletContext({ workspace });
  return <Outlet context={newContext} />;
}

export default PortfolioWorkspaceContext;
