/**
 *
 * Student
 * PortfolioMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { useApi2 } from 'containers/Api2/index.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import parsePortfolioStudent from 'components/portfolio/parsePortfolioStudent.js';
import messages from '../messages.js';
import PathItem from '../PathItem.jsx';

function Student(props) {
  const { role, pathname } = props;
  const portfolio = useApi2({ url: `portfolio/students/${role.id}`, parser: parsePortfolioStudent, ignore: [403, 404] });
  if (!get(portfolio, 'features.length')) {
    return null;
  }
  return (
    <PathItem to="/portfolio">
      <AvatarWithText icon="ss-suitcase" active={pathname.startsWith('/portfolio')}>
        <FormattedMessage {...messages.portfolio} />
      </AvatarWithText>
    </PathItem>
  );
}

Student.propTypes = {
  role: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default Student;
