/**
*
* NotificationsIcon
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/media/Icon/index.js';

const Wrapper = styled.div`
  position: relative;
  padding-top: 4px;
  padding-right: 8px;
`;

const size = 9;
const RedCircle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${(p) => p.theme.brandDanger};
  color: #fff;
  height: ${2 * size}px;
  width: ${2 * size}px;
  border-radius: ${size}px;
  text-align: center;
  font-size: 12px;
  display: ${(p) => p.show ? 'inherit' : 'none'}
`;

function NotificationsIcon(props) {
  const { count } = props;
  return (
    <Wrapper>
      <Icon bsSize="large" inline className="ss-notifications" />
      <RedCircle show={count > 0}>{count}</RedCircle>
    </Wrapper>
  );
}

NotificationsIcon.propTypes = {
  count: PropTypes.number,
};

export default NotificationsIcon;
