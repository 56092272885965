/**
*
* DynamicListGroup
*
*/

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ListGroupItem } from 'react-bootstrap';
import Spinner from 'components/Spinner/index.jsx';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import { idValues } from 'utils/arrays.js';
import PathItem from '../PathItem.jsx';

function DynamicListGroup(props) {
  const { data, link, idProp, noRedirect, group, disabledTitle, counter } = props;
  const redirected = useRef();
  const navigate = useNavigate();

  const makeLink = (item) => item.disabled ? '' : link.replace(':id', item[idProp]);

  useEffect(() => {
    if (noRedirect || redirected.current || data === undefined || data.loading) {
      return;
    }
    const values = data.values || idValues(data);
    const filtered = values?.filter((s) => !s.disabled);
    if (!filtered || filtered.length !== 1) {
      return;
    }
    redirected.current = true;
    navigate(makeLink(values[0]), { replace: true });
  }, [data, noRedirect]); // eslint-disable-line react-hooks/exhaustive-deps

  if (data === undefined) {
    return null;
  }
  if (data.loading) {
    return <ListGroupItem><Spinner /></ListGroupItem>;
  }
  const values = data.values || idValues(data);
  if (!values) {
    return null;
  }
  return values.map((item) => (
    <PathItem key={item.id} disabled={item.disabled} to={makeLink(item)} title={item.disabled ? disabledTitle : undefined}>
      <AvatarWithText
        color={item.theme?.themeColor}
        src={item.avatar}
        group={group}
      >
        <span>
          {item.name || item.displayName}
          {counter?.(item.id)}
        </span>
      </AvatarWithText>
    </PathItem>
  ));
}

DynamicListGroup.propTypes = {
  data: PropTypes.object,
  link: PropTypes.string,
  idProp: PropTypes.string,
  disabledTitle: PropTypes.string,
  noRedirect: PropTypes.bool,
  group: PropTypes.bool,
  counter: PropTypes.func,
};

DynamicListGroup.defaultProps = {
  idProp: 'id',
};

export default DynamicListGroup;
