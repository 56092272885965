/**
 *
 * UserList
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Alert, ListGroup } from 'react-bootstrap';
import UserListItem from 'components/user/UserListItem.jsx';
import usePagination from 'components/Pagination/usePagination.jsx';
import { idValues } from 'utils/arrays.js';
import messages from './messages.js';

const PAGE_LENGTH = 15;

function UserList(props) {
  const { users, withSchool, className, variant } = props;
  const isArray = Array.isArray(users);
  const totalCount = isArray ? users.length : users.totalCount;
  const { slice, Paginator, offset } = usePagination(totalCount, PAGE_LENGTH);
  useEffect(() => {
    if (isArray || !users.hasMore || !users.loadMore) {
      return;
    }
    // Preload for next page already to avoid spinner on "Next page"
    if (offset + (2 * PAGE_LENGTH) > users.list.length) {
      users.loadMore();
    }
  }, [isArray, offset]);
  if (totalCount === 0) {
    return <Alert className="m-3"><FormattedMessage {...messages.noMembers} /></Alert>;
  }
  const values = isArray ? users : idValues(users);
  return (
    <>
      <ListGroup className={className} variant={variant}>
        {slice(values).map((item) => (
          <UserListItem key={item.id} user={item} withSchool={withSchool} />
        ))}
      </ListGroup>
      <Paginator />
    </>
  );
}

UserList.propTypes = {
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  withSchool: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
};

UserList.defaultProps = {
  variant: 'flush',
};

export default UserList;
