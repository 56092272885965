/**
 *
 * useCropImage
 *
 */

import React, { useState, useRef } from 'react';

import { cropImageFile } from 'utils/imageFile.js';
import CropModal from './CropModal.jsx';

function useCropImage() {
  const [show, setShow] = useState(false);
  const params = useRef({});

  function getCrop(file, cropType) {
    return new Promise((resolve) => {
      params.current = { file, resolve, cropType };
      setShow(true);
    });
  }

  function handleCrop(crop) {
    const { file, resolve } = params.current;
    cropImageFile(file, crop)
      .then((result) => {
        resolve(result);
        setShow(false);
      });
  }

  function cropModal() {
    if (!show) {
      return null;
    }
    const { file, cropType } = params.current;
    return <CropModal show={show} file={file} onChange={handleCrop} cropType={cropType} onHide={() => setShow(false)} />;
  }

  return { getCrop, cropModal };
}

export default useCropImage;
