/*
 * Conversations Messages
 *
 * This contains all the text for the Conversations component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  conversations: {
    id: 'app.components.Conversations.conversations',
    defaultMessage: 'Conversations',
  },
  selectConversation: {
    id: 'app.components.Conversations.selectConversation',
    defaultMessage: 'Select a Conversation',
  },
  conversationShowsHere: {
    id: 'app.components.Conversations.conversationShowsHere',
    defaultMessage: 'As soon as you start or receive a conversation, it will appear here',
  },
  startTopic: {
    id: 'app.components.Conversations.startTopic',
    defaultMessage: 'Start a Conversation',
  },
  startTopic2: {
    id: 'app.components.Conversations.startTopic2',
    defaultMessage: 'Start Conversation',
  },
  addMessage: {
    id: 'app.components.Conversations.addMessage',
    defaultMessage: 'Write a Message',
  },
  editMessage: {
    id: 'app.components.Conversations.editMessage',
    defaultMessage: 'Edit a Message',
  },
  groupConversation: {
    id: 'app.components.Conversations.groupConversation',
    defaultMessage: 'One Conversation with all Participants',
  },
  groupConvIntro: {
    id: 'app.components.Conversations.groupConvIntro',
    defaultMessage: 'All participants join the same group conversation',
  },
  individualConversations: {
    id: 'app.components.Conversations.individualConversations',
    defaultMessage: 'Separate Conversation with each Parent/Student',
  },
  indivConvIntro: {
    id: 'app.components.Conversations.indivConvIntro',
    defaultMessage: 'You have an individual conversation with each parent/student, together with the employees that you selected',
  },
  subject: {
    id: 'app.components.Conversations.subject',
    defaultMessage: 'Subject',
  },
  message: {
    id: 'app.components.Conversations.message',
    defaultMessage: 'Message',
  },
  empty: {
    id: 'app.components.CommunityPosts.empty',
    defaultMessage: 'There are no messages',
  },
  nomore: {
    id: 'app.components.CommunityPosts.nomore',
    defaultMessage: 'There are no further messages',
  },
  you: {
    id: 'app.components.Conversations.you',
    defaultMessage: 'You',
  },
  confirmDelete: {
    id: 'app.components.Conversations.confirmDelete',
    defaultMessage: 'Delete this message?',
  },
  deletedMessage: {
    id: 'app.components.Conversations.deletedMessage',
    defaultMessage: 'This message has been deleted',
  },
  editSubject: {
    id: 'app.components.Conversations.editSubject',
    defaultMessage: 'Edit Subject',
  },
  leaveTopic: {
    id: 'app.components.Conversations.leaveTopic',
    defaultMessage: 'Leqave Conversation',
  },
  closeTopic: {
    id: 'app.components.Conversations.closeTopic',
    defaultMessage: 'Close Conversation',
  },
  confirmCloseTopic: {
    id: 'app.components.Conversations.confirmCloseTopic',
    defaultMessage: 'This will close the conversation for all participants. Continue?',
  },
  confirmArchiveTopic: {
    id: 'app.components.Conversations.confirmArchiveTopic',
    defaultMessage: 'Do you want to archive this conversation?',
  },
  archive: {
    id: 'app.containers.ArchivePage.title',
    defaultMessage: 'Archive',
  },
  archiveTopic: {
    id: 'app.components.Conversations.archiveTopic',
    defaultMessage: 'Archive',
  },
  archived: {
    id: 'app.components.Conversations.archived',
    defaultMessage: 'Archived',
  },
  unarchiveTopic: {
    id: 'app.components.Conversations.unarchiveTopic',
    defaultMessage: 'Restore from Archive',
  },
  noArchived: {
    id: 'app.components.Conversations.noArchived',
    defaultMessage: 'When you archive a conversation you will find it here',
  },
  changeParticipants: {
    id: 'app.components.Conversations.changeParticipants',
    defaultMessage: 'Add/Remove Participants',
  },
  hide: {
    id: 'app.components.Button.hide',
    defaultMessage: 'Hide',
  },
  showAll: {
    id: 'app.components.Conversations.showAll',
    defaultMessage: 'Show all',
  },
  topicClosed: {
    id: 'app.components.Conversations.topicClosed',
    defaultMessage: 'This conversation has been closed',
  },
  topicNoWrite: {
    id: 'app.components.Conversations.topicNoWrite',
    defaultMessage: 'You are not a participant in this conversation anymore',
  },
  seeOldConversations: {
    id: 'app.components.Conversations.seeOldConversations',
    defaultMessage: 'View your messages from the old version',
  },
  newMessages: {
    id: 'app.components.CommunityPosts.newMessages',
    defaultMessage: '{count, plural, one {1 new message} other {# new messages}}',
  },
  searchResults: {
    id: 'app.common.searchResults',
    defaultMessage: "Found {count, plural, =0 {no results} one {1 result} other {# results}} for ''{query}''",
  },
  searchMinLength: {
    id: 'app.components.Conversations.searchMinLength',
    defaultMessage: 'At least {min} characters',
  },
  selectParticipants: {
    id: 'app.components.Conversations.selectParticipants',
    defaultMessage: 'Select Participants',
  },
  navigationWarning: {
    id: 'app.components.Form.navigationWarning',
    defaultMessage: 'Changes may not be saved, leave this page?',
  },
  participantsSeenMessage: {
    id: 'app.components.Conversations.participantsSeenMessage',
    defaultMessage: '{count, plural, =0 {No participants have} one { 1 participant has} other {# participants have}} seen this message',
  },
  viewedByTeachersOnly: {
    id: 'app.components.Conversations.viewedByTeachersOnly',
    defaultMessage: 'This information can only be viewed by teachers.',
  },
  messageSeenBy: {
    id: 'app.components.Conversations.messageSeenBy',
    defaultMessage: 'This message has been seen by',
  },
  messageNotSeenBy: {
    id: 'app.components.Conversations.messageNotSeenBy',
    defaultMessage: 'This message has not yet been seen by',
  },
  missingDate: {
    id: 'app.components.Conversations.missingDate',
    defaultMessage: 'Date missing',
  },
  noActiveParticipants: {
    id: 'app.components.Conversations.noActiveParticipants',
    defaultMessage: 'There are no active participants left in this conversation',
  },
  deleted: {
    id: 'app.common.deleted',
    defaultMessage: 'deleted',
  },
  draft: {
    id: 'app.common.draft',
    defaultMessage: 'Draft',
  },
  scheduled: {
    id: 'app.common.scheduled',
    defaultMessage: 'Scheduled',
  },
  pending: {
    id: 'app.components.PromptPanel.pending',
    defaultMessage: '{pendingCount, plural, one { 1 draft/scheduled post} other {# drafts/scheduled posts}}',
  },
  noTitle: {
    id: 'app.components.PendingList.noTitle',
    defaultMessage: 'No Title',
  },
  save: {
    id: 'app.common.save',
    defaultMessage: 'Save',
  },
  send: {
    id: 'app.common.send',
    defaultMessage: 'Send',
  },
  flag: {
    id: 'app.components.Conversations.flag',
    defaultMessage: 'Flag as important',
  },
});
