function archiveParser(item) {
  const created = item.created.getFullYear();
  const archived = item.archived.getFullYear();
  const period = created === archived ? created : `${created}–${archived}`;
  return {
    ...item,
    name: `${item.name} (${period})`,
  };
}

export default archiveParser;
