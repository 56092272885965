import produce from 'immer';
import useUserContext from 'containers/User/useUserContext.js';

function useUpdateConnections() {
  const { setProfile } = useUserContext();

  return (roleId, studentId, avatar) => setProfile(produce((draft) => {
    const studentIdx = draft.roles[roleId].connections.findIndex((c) => c.id === studentId);
    draft.roles[roleId].connections[studentIdx].avatar = avatar;
  }));
}

export default useUpdateConnections;
