/**
*
* NewActivityBadge
*
*/

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { Badge } from 'react-bootstrap';
import useStats from 'containers/User/UserProvider/useStats.js';

function NewActivityBadge(props) {
  const { group } = props;
  const { getIn } = useStats();
  const count = getIn('unseenPortfolioActivitiesCount');
  if (!count) {
    return null;
  }
  const groupCount = group ? count[group]?.unseenCount : count.total;
  if (!groupCount) {
    return null;
  }
  return <Badge variant="primary" className="ms-3">{groupCount}</Badge>;
}

NewActivityBadge.propTypes = {
  group: PropTypes.number,
};

export default NewActivityBadge;
