/*
 * UserModal Messages
 *
 * This contains all the text for the UserModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  contactDetails: {
    id: 'app.components.UserModal.contactDetails',
    defaultMessage: 'Contact Details',
  },
  children: {
    id: 'app.components.UserModal.children',
    defaultMessage: 'Children',
  },
  parents: {
    id: 'app.components.UserModal.parents',
    defaultMessage: 'Parents',
  },
  startConversation: {
    id: 'app.components.UserModal.startConversation',
    defaultMessage: 'Start conversation',
  },
  visitStudentPage: {
    id: 'app.components.UserModal.visitStudentPage',
    defaultMessage: 'Visit student page',
  },
  startConversationWith: {
    id: 'app.components.UserModal.startConversationWith',
    defaultMessage: 'Start conversation with parent',
  },
  noContactInfo: {
    id: 'app.components.UserModal.noContactInfo',
    defaultMessage: 'No shared contact details',
  },
  noNotifications: {
    id: 'app.components.UserModal.noNotifications',
    defaultMessage: 'Does not receive notifications at the moment',
  },
  deletedUser: {
    id: 'app.components.UserModal.deletedUser',
    defaultMessage: 'Deleted user',
  },
  noPermission: {
    id: 'app.components.UserModal.noPermission',
    defaultMessage: 'Unfortunately, you don\'t have permissions to view the users profile.',
  },
});
