/*
 * Banner Messages
 *
 * This contains all the text for the Banner component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  manageGroup: {
    id: 'app.components.Banner.manageGroup',
    defaultMessage: 'Manage Group',
  },
});
