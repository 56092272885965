/**
 *
 * EventHeader
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UserText from 'components/media/UserText/Loadable.js';
import { Modal } from 'react-bootstrap';
import { useApi2 } from 'containers/Api2/index.js';
import PublishModal, { PublishedIcons } from 'components/PublishModal/index.jsx';
import FormattedDate from 'components/FormattedDate/index.jsx';
import ControlMenu from 'components/interaction/ControlMenu/index.jsx';
import EventEditor from '../EventEditor/index.jsx';
import Groups from '../Views/Groups.jsx';
import useEventMenu from './useEventMenu.js';
import { SHOW_EDITOR, SHOW_NONE, SHOW_PUBLISH } from './constants.js';

const Wrapper = styled.div`
  .color-block {
    transform: translateY(15%);
  }
  width: 100%;
`;

function EventHeader(props) {
  const { event, onHide } = props;
  const channels = useApi2({ url: 'publish/channels', selector: 'channels', noReport: [401] });
  const [show, setShow] = useState(SHOW_NONE);
  const onMenu = useEventMenu(event, onHide, setShow);

  if (!event || event.loading || event.error) {
    return null;
  }
  const menuItemLabels = ['close', 'edit', 'remove'];
  const canPublish = channels?.list?.length > 0;
  if (canPublish) {
    if (event.publishedChannels.includes(1)) {
      menuItemLabels.splice(1, 0, 'revoke');
    } else {
      menuItemLabels.splice(1, 0, 'publish');
    }
  }
  const menuItems = menuItemLabels.map((label) => ({ label, handler: onMenu(label), danger: label === 'remove' }));
  const canEdit = event.permissions.moderate;
  return (
    <Modal.Header closeButton={!canEdit} className="border-bottom-0">
      <Wrapper>
        <div className="d-flex float-end align-items-center">
          <PublishedIcons channels={event.publishedChannels} className="text-gray" />
          {canEdit && <ControlMenu items={menuItems} />}
        </div>
        <Modal.Title><UserText>{event.title}</UserText></Modal.Title>
        <div className="time mt-1 text-muted">
          <FormattedDate format="LLLL">{event}</FormattedDate>
        </div>
        <div className="groups mt-2 small">
          <Groups event={event} withColors />
        </div>
        {show === SHOW_EDITOR && <EventEditor event={event} onHide={onHide} />}
        {show === SHOW_PUBLISH && channels.loading === false && (
          <PublishModal
            entity="events"
            item={event}
            // channels={channels}
            onHide={() => setShow(SHOW_NONE)}
          />
        )}
      </Wrapper>
    </Modal.Header>
  );
}

EventHeader.propTypes = {
  event: PropTypes.object,
  onHide: PropTypes.func,
};

export default EventHeader;
