/*
 * UsersByRole Messages
 *
 * This contains all the text for the UsersByRole component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  employee: {
    id: 'app.components.UsersByRole.employee',
    defaultMessage: 'Employees',
  },
  parent: {
    id: 'app.components.UsersByRole.parent',
    defaultMessage: 'Parents',
  },
  student: {
    id: 'app.components.UsersByRole.student',
    defaultMessage: 'Students',
  },
  noMembers: {
    id: 'app.components.UsersByRole.noMembers',
    defaultMessage: 'No members',
  },
});
