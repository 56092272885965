import styled from 'styled-components';
import DeleteButton from '../DeleteButton/index.jsx';

const ImageRemoveButton = styled(DeleteButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 4px;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
`;

export default ImageRemoveButton;
