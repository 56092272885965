/**
 *
 * CoverImage
 * Image covering the container
 */

import styled, { css } from 'styled-components';

export const coverImageCss = css`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: unset;
  @media all and (-ms-high-contrast:none) {
    height: auto;
  }
`;

const CoverImage = styled.img`
 ${coverImageCss};
`;

export default CoverImage;
