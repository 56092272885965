/**
 * Snoozing
 * Indicates when user is not receiving notifications
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/media/Icon/index.js';
import messages from './messages.js';

export function isSnoozing(user) {
  return user && user.notificationSchedule && !user.notificationSchedule.receiveNotification;
}

function Snoozing(props) {
  const { user, ...rest } = props;
  if (user && !isSnoozing(user)) {
    return null;
  }
  return (
    <div {...rest}>
      <Icon className="ss-notificationsdisabled" inline space />
      <FormattedMessage {...messages.noNotifications} />
    </div>
  );
}

Snoozing.propTypes = {
  user: PropTypes.object,
};

export default Snoozing;
