/**
 *
 * SelectFiles
 *
 */

import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { pick, uniqueId } from 'lodash-es';
import { useIntl } from 'react-intl';
import { mediaTypes, typeTest, isAllowedFile } from 'utils/files.js';
import useAlert from 'containers/Alerts/useAlert.js';
import Icon2 from 'components/media/Icon2/index.js';
import messages from './messages.js';
import InputFile from '../../InputFile/index.jsx';

const MAX_FILENAME = 128;
const MAX_IMAGE_SIZE = 25 * 1024 * 1024;
const MAX_IMAGE_SIZE_TEXT = '25MB';

function SelectFiles(props) {
  const { type, onChange, children, multiple, className, disabled, noIcon } = props;
  const intl = useIntl();
  const id = useRef(uniqueId(`upload_${type}_`));
  const { addError } = useAlert();

  const handleChange = useCallback((fileList) => {
    const handleError = (message, file) => {
      const error = new Error(message);
      error.file = JSON.stringify(pick(file, ['name', 'type']));
      error.noReport = true;
      addError(error, 'uploadfile');
    };
    const files = fileList.filter((file) => {
      if (/(\.(part|crdownload|download))$/i.test(file.name)) {
        const message = intl.formatMessage(messages.partialDownload, { name: file.name });
        handleError(message, file);
        return false;
      }
      if (!isAllowedFile(file, type === 'video')) {
        const values = {
          type,
          name: file.name,
        };
        const message = intl.formatMessage(messages.wrongType, values);
        handleError(message, file);
        return false;
      }
      if (typeTest('image', file) && file.size > MAX_IMAGE_SIZE) {
        const values = {
          maxSize: MAX_IMAGE_SIZE_TEXT,
          size: Math.ceil(file.size / 1024 / 1024),
          name: file.name,
        };
        const message = intl.formatMessage(messages.tooLarge, values);
        handleError(message, file);
        return false;
      }
      if (file.name.length <= MAX_FILENAME) {
        return true;
      }
      const values = {
        max: MAX_FILENAME,
        name: file.name,
      };
      const message = intl.formatMessage(messages.longName, values);
      handleError(message, file);
      return false;
    });
    onChange(files);
  }, [onChange, intl, addError, type]);

  const typeInfo = mediaTypes.info[type] || {};
  const title = intl.formatMessage(messages[type]);
  const icon = typeInfo.icon || 'ss-file';
  const endMargin = children === undefined ? '' : 'me-3';
  return (
    <InputFile id={id.current} onChange={handleChange} multiple={multiple} accept={typeInfo.pattern} className={className} title={title} disabled={disabled}>
      {!noIcon && <Icon2 className={`${icon} fs-1 ${endMargin}`} />}
      {children}
    </InputFile>
  );
}

SelectFiles.propTypes = {
  className: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  noIcon: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['image', 'video', 'document', 'any']),
};

SelectFiles.defaultProps = {
  className: 'btn btn-link',
};

export default SelectFiles;
