/**
 *
 * Parent
 * PortfolioMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApi2 } from 'containers/Api2/index.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import { hasItems } from 'utils/arrays.js';
import parsePortfolioStudent from 'components/portfolio/parsePortfolioStudent.js';
import ExpandItem from '../ExpandItem.jsx';
import messages from '../messages.js';
import DynamicListGroup from '../DynamicListGroup/index.jsx';

function Parent(props) {
  const { expanded, onClick, pathname } = props;
  const intl = useIntl();
  const students = useApi2({ url: 'portfolio/students', parser: parsePortfolioStudent, noReport: [401] });
  const hasPortfolio = students.list?.some((id) => students[id].portfolioEnabled);
  if (!hasPortfolio || !hasItems(students)) {
    return null;
  }
  return (
    <>
      <ExpandItem onClick={onClick} expanded={expanded}>
        <AvatarWithText icon="ss-suitcase" active={pathname.startsWith('/portfolio')}>
          <FormattedMessage {...messages.portfolio} />
        </AvatarWithText>
      </ExpandItem>
      {expanded && (
        <div className="list-group-indent">
          <DynamicListGroup
            data={students}
            link="/portfolio/:id"
            idProp="portfolioId"
            disabledTitle={intl.formatMessage(messages.noPortfolio)}
            noRedirect={pathname.startsWith('/portfolio')}
          />
        </div>
      )}
    </>
  );
}

Parent.propTypes = {
  expanded: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Parent;
