import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages.js';

export const CHANNEL_WEBSITE = 1;
export const CHANNEL_FACEBOOK = 2;
export const CHANNEL_TWITTER = 3;
export const CHANNEL_EXTERNAL = 4;

export const CHANNEL_PROPS = {
  [CHANNEL_WEBSITE]: {
    label: <FormattedMessage {...messages.website} />,
    icon: 'ss-earth',
  },
  [CHANNEL_FACEBOOK]: {
    label: 'Facebook',
    icon: 'ss-facebook',
  },
  [CHANNEL_TWITTER]: {
    label: 'Twitter',
    icon: 'ss-twitter',
  },
  [CHANNEL_EXTERNAL]: {
    label: <FormattedMessage {...messages.externalContacts} />,
    icon: 'ss-users',
  },
};
