/**
*
* VideoToggle
*
*/

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import useVideoEnabled from 'components/VideoConf/useVideoEnabled.js';
import Icon from 'components/media/Icon/index.js';

function VideoToggle(props) {
  const { onChange } = props;
  const [video, setVideo] = useVideoEnabled();
  const handleClick = useCallback(() => {
    setVideo(!video);
    onChange(!video);
  }, [video, setVideo, onChange]);
  if (!setVideo) {
    return null;
  }
  return (
    <Dropdown.Item {...props} onClick={handleClick}>
      <div className="d-flex">
        <span className="flex-grow-1 me-3">Force enable video:</span>
        <Icon className={video ? 'ss-check' : 'ss-check-empty'} />
      </div>
    </Dropdown.Item>
  );
}

VideoToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default VideoToggle;
