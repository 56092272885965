/*
 * PageTabs Messages
 *
 * This contains all the text for the PageTabs component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  home: {
    id: 'app.components.PageTabs.home',
    defaultMessage: 'Home',
  },
  calendar: {
    id: 'app.components.PageTabs.calendar',
    defaultMessage: 'Calendar',
  },
  documents: {
    id: 'app.components.PageTabs.documents',
    defaultMessage: 'Files',
  },
  media: {
    id: 'app.components.PageTabs.media',
    defaultMessage: 'Photo and Video',
  },
  members: {
    id: 'app.common.members',
    defaultMessage: 'Members',
  },
  absences: {
    id: 'app.components.PageTabs.absences',
    defaultMessage: 'Absences',
  },
  consent: {
    id: 'app.components.PageTabs.consent',
    defaultMessage: 'Consents',
  },
  parentMeetings: {
    id: 'app.components.PageTabs.parentMeetings',
    defaultMessage: 'Parent Meetings',
  },
  account: {
    id: 'app.components.PageTabs.account',
    defaultMessage: 'Account',
  },
  contactdetails: {
    id: 'app.components.PageTabs.contactDetails',
    defaultMessage: 'Contact Details',
  },
  profile: {
    id: 'app.components.PageTabs.profile',
    defaultMessage: 'Profile',
  },
});
