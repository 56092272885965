import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { formatDistanceStrict } from 'utils/date-fns/index.js';
import { get } from 'lodash-es';
import appConfig from '../config/index.js';
import pkg from '../../package.json';
import uniqueId from './uniqueId.js';

let errorCount = 0;
const sessionStart = new Date();
const sentryContext = {};

export const crashInfo = {};
export const ERROR_ID = uniqueId();

console.log('Release', pkg.version); // eslint-disable-line no-console

const ignoreMessages = [
  /^Script Error/,
  /^The request is not allowed by the user agent/,
  /^Cannot read properties of null.*postMessage/,
  /^Failed to fetch/,
  /^Network Error/,
  /^De netwerkverbinding is verbroken/,
  /^Load failed/,
  /change_ua/, // e.g. https://socialschools.sentry.io/issues/2547257684/?project=280505
  /dbankcloud/, // e.g. https://socialschools.sentry.io/issues/3688388371/?project=280505
  /^AbortError/,
  /^ResizeObserver/,
  /^Refused to evaluate/,
  /^Loading chunk \d+ failed/,
];

const denyUrls = [
  /chat\.socialschools\.nl/,
  /static\.zdassets\.com/,
  // /utils\/request\.js/,
];

const config = {
  dsn: appConfig.sentryDsn,
  release: pkg.version,
  environment: appConfig.env,
  serverName: window.location.hostname,
  autoSessionTracking: false,
  tags: { error_id: ERROR_ID },
  denyUrls,
  beforeSend,
};

const useSentry = appConfig.env !== 'local';
Sentry.init(config);

function shouldSend(data, ignore) {
  if (++errorCount > 10) {
    return false;
  }
  if (['logInfo', 'bugreport'].includes(data.tags?.logger)) {
    return true;
  }
  return useSentry && !ignore;
}

function getMessage(data) {
  const { message, exception } = data;
  const resultMessage = message ?? exception?.values?.[0]?.value;
  return {
    message: resultMessage,
    skipSending: !shouldSend(data, resultMessage && ignoreMessages.some((re) => re.test(resultMessage))),
  };
}

function getState(data) {
  const state = data.extra?.state;
  return state?.toJS ? state.toJS() : state;
}

function beforeSend(data, hint) {
  const noReport = get(hint, 'originalException.noReport');
  if (noReport) {
    return null;
  }
  const { message, skipSending } = getMessage(data);
  const info = get(hint, 'originalException.info');
  const logList = JSON.parse(window.sessionStorage.getItem('console'));
  if (appConfig.env !== 'production') {
    const state = getState(data);
    if (typeof process === 'object' && process.env.VITEST) {
      console.warn('sentry', message, hint.originalException.toString()); // eslint-disable-line no-console
    } else {
      console.warn('sentry', message, { data, hint, state, logList }); // eslint-disable-line no-console
    }
  }
  if (skipSending) {
    return null;
  }
  crashInfo.fragment = window.location.hash;
  crashInfo.inFrame = window.self !== window.top;
  crashInfo.webview = window.socsIsInAppWebView;
  const extra = {
    info,
    logList,
    crashInfo,
    context: sentryContext,
    sessionStart,
    ...data.extra,
  };
  const tags = {
    dbg_msg: message,
    logger: 'javascript',
    error_id: ERROR_ID,
    test_message: message,
    webview: window.socsIsInAppWebView,
    error_count: errorCount,
    session_duration: formatDistanceStrict(new Date(), sessionStart),
    visible: document.visibilityState,
    server_name: window.location.hostname,
    ...data.tags,
  };
  const result = {
    ...data,
    extra,
    tags,
  };
  console.warn('sentry', 'beforeSend', result); // eslint-disable-line no-console
  return result;
}

export function reportError(context, error, info) {
  if (error === undefined) {
    return curriedReport(context);
  }
  return simpleReport(context, error, info);
}

function curriedReport(context) {
  return (error, info) => simpleReport(context, error, info);
}

function getInfoTag(fullInfo) {
  const match = fullInfo.match?.(/in (\w+)/);
  return match ? match[1] : fullInfo;
}

function simpleReport(context, error, fullInfo) {
  Sentry.withScope((scope) => {
    scope.setTag('info', fullInfo && getInfoTag(fullInfo));
    scope.setTag('logger', context);
    Sentry.captureException(error);
  });
}

export function useSentryContext(key, value) {
  sentryContext[key] = value;
  useEffect(() => () => {
    delete sentryContext[key];
  }, [key, value]);
}

export function reportApiError(message, action, response) {
  const { error, options } = action;
  const { url, noReport = [] } = options;
  const ignore = response && noReport.includes(response.status);
  const force = url.startsWith('portfolio');
  if (ignore && !force) {
    return;
  }
  Sentry.withScope((scope) => {
    scope.setExtra('headers', error.request.headers);
    scope.setExtra('request', error.request);
    scope.setExtra('response', response);
    scope.setTag('status', response.status);
    scope.setTag('request_uri', error.request.uri);
    scope.setTag('logger', 'api');
    Sentry.captureMessage(message.defaultMessage || message);
  });
}

export function reportBug() {
  // eslint-disable-next-line no-alert
  const description = prompt('Report bug, extra info:');
  if (description === null) {
    return;
  }
  Sentry.withScope((scope) => {
    scope.setTag('logger', 'bugreport');
    Sentry.captureMessage(`Bug report: ${description}`);
    // eslint-disable-next-line no-alert
    alert(`Reported, id: ${ERROR_ID}`);
  });
}

export function reportLog(description, info, expand) {
  // eslint-disable-next-line no-alert
  Sentry.withScope((scope) => {
    scope.setExtra('time', (new Date()).toISOString());
    if (expand) {
      Object.keys(info).forEach((key) => {
        scope.setExtra(key, info[key]);
      });
    } else {
      scope.setExtra('info', info);
    }
    scope.setTag('logger', 'logInfo');
    Sentry.captureMessage(description);
  });
}

function isWebView() {
  return navigator.userAgent.includes('Social Schools iOS App') || navigator.userAgent.includes('; wv)') || window.location.search.includes('webview');
}

function reportWebView() {
  const keys = Object.keys(window).filter((k) => /^(socs|auth)/i.test(k));
  if (isWebView()) {
    keys.sort();
    const info = {};
    keys.forEach((key) => {
      info[key] = window[key];
    });
    reportLog('webview', info, true);
  }
}

if (appConfig.env !== 'production') {
  reportWebView();
}

export function addBreadcrumb(data) {
  Sentry.addBreadcrumb(data);
}

export default Sentry;
