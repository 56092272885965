/**
 *
 * ErrorBoundary
 * https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 */

import React from 'react';
import PropTypes from 'prop-types';
import config from 'config/index.js';
import { reportError } from 'utils/sentry.js';
import AlertList, { AlertItem } from '../AlertList/index.jsx';
import { IGNORE_ERROR_PATTERNS } from './constants.js';

class ErrorBoundary extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    const message = error.toString();
    if (IGNORE_ERROR_PATTERNS.some((re) => re.test(message))) {
      return;
    }
    if (config.env === 'local') {
      console.error(error, info); // eslint-disable-line no-console
    } else {
      // In local, react will throw error itself, otherwise report explicitly
      reportError('react', error, info.componentStack);
    }
    this.alert = {
      style: 'danger',
      message,
      context: info.componentStack,
    };
    this.setState({ hasError: true });
  }

  reset = () => this.setState({ hasError: false });

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError
      ? <AlertList.Wrapper><AlertItem alert={this.alert} onHide={this.reset} /></AlertList.Wrapper>
      : children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
