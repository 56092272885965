/*
 * PublishModal Messages
 *
 * This contains all the text for the PublishModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ExternalContacts.header',
    defaultMessage: 'Share with External Contacts',
  },
  intro: {
    id: 'app.components.ExternalContacts.intro',
    defaultMessage: 'An email with a public link to this post will be sent.',
  },
  share: {
    id: 'app.components.ExternalContacts.share',
    defaultMessage: 'Share',
  },
});
