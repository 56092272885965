import { getCurrentRole } from 'containers/User/UserProvider/userStorage.js';

function getUserContext() {
  const role = getCurrentRole();
  const result = {};
  if (role) {
    result.role = `${role.roleTypeDescription}, ${role.school.displayName}, ${role.school.city}`;
  }
  return result;
}

export default getUserContext;
