import config from 'config/index.js';
import request from 'utils/request.js';

const sentList = new Set([]);

function markSeen(params) {
  const { statisticsUrl } = config;
  if (sentList.has(params.postId) || !statisticsUrl) {
    return false;
  }
  sentList.add(params.postId);
  const options = {
    uri: statisticsUrl,
    method: 'POST',
    headers: {
      'x-functions-key': config.statisticsKey,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
    ignore: true,
  };
  request(options);
  return true;
}

export default markSeen;
