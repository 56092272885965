import styled from 'styled-components';

const DOTSIZE = '8px';

const Dot = styled.div`
  width: ${DOTSIZE};
  height: ${DOTSIZE};
  border-radius: 50%;
  background-color: ${(p) => p.theme.brandPrimary};
  float: right;
  margin-bottom: 5px;
  margin-left: 5px;
`;

export default Dot;
