/**
*
* FormControl
* This is a wrapper for react-bootstrap FormControl
* It adds emoji support and replaces default textarea with react-textarea-autosize
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import EmojiPicker from 'components/interaction/EmojiPicker/index.jsx';
import { useReplaceIntlProps } from 'utils/replaceIntlProps.js';
import cx from 'utils/cx.js';

function FormControl(props) {
  // eslint-disable-next-line react/prop-types
  const { type, rows } = props;
  const { emoji, className, ...intlProps } = useReplaceIntlProps(props, ['placeholder', 'aria-label']);
  const emojiClass = cx(className, emoji && 'with-emoji');

  function getControl() {
    switch (type) {
      case 'textarea':
        return <Form.Control as={Textarea} maxLength={2000} minRows={rows || 3} className={emojiClass} {...intlProps} />;
      case 'text':
        return <Form.Control maxLength={250} className={emojiClass} {...intlProps} />;
      default:
        return <Form.Control className={emojiClass} {...intlProps} />;
    }
  }
  return (
    <>
      {getControl()}
      {emoji && <EmojiPicker {...props} />}
    </>
  );
}

FormControl.propTypes = {
  type: PropTypes.string,
  rows: PropTypes.number,
  emoji: PropTypes.bool,
  className: PropTypes.string,
};

export default FormControl;
