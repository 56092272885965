/**
 *
 * Higher Order component to add dialog functions
 *
 */

import { connect } from 'react-redux';

import { DIALOG_ALERT, DIALOG_CONFIRM, DIALOG_PROMPT } from './constants.js';
import { showDialog } from './actions.js';

function dialog(dispatch, modalType, modalProps) {
  return new Promise((resolve, reject) => {
    dispatch(showDialog(modalType, modalProps, { resolve, reject, rejectOnFalse: modalType !== DIALOG_ALERT }));
  });
}

export function mapDispatchToProps(dispatch) {
  return {
    dlgAlert: (options) => dialog(dispatch, DIALOG_ALERT, options),
    dlgConfirm: (options) => dialog(dispatch, DIALOG_CONFIRM, options),
    dlgPrompt: (options) => dialog(dispatch, DIALOG_PROMPT, options),
  };
}

export default connect(null, mapDispatchToProps);
