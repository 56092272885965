/**
 *
 * Employee
 * PortfolioMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ListGroupItem } from 'react-bootstrap';
import { MODULES_STUDENTPORTFOLIOLIBRARY } from 'containers/User/constants.js';
import usePortfolioGroups from 'containers/PortfolioPage/usePortfolioGroups.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import NewActivityBadge from 'components/notifications/NewActivityBadge/index.jsx';
import { hasItems } from 'utils/arrays.js';
import ExpandItem from '../ExpandItem.jsx';
import messages from '../messages.js';
import DynamicListGroup from '../DynamicListGroup/index.jsx';
import TryNow from './TryNow.jsx';
import PathItem from '../PathItem.jsx';

function Employee(props) {
  const { pathname, isManager, modules, expanded, onClick } = props;
  const match = pathname.match('/groups/(\\d+)/portfolio');
  const groupId = match && Number(match[1]);
  const groups = usePortfolioGroups(groupId);
  const showLibraryLink = modules.includes(MODULES_STUDENTPORTFOLIOLIBRARY) && isManager;
  if (!showLibraryLink && !hasItems(groups)) {
    return null;
  }
  const header = (
    <ExpandItem onClick={onClick} expanded={expanded}>
      <AvatarWithText icon="ss-suitcase">
        <span>
          <FormattedMessage {...messages.portfolio} />
          {!expanded && <NewActivityBadge className="ms-3" />}
        </span>
      </AvatarWithText>
      <TryNow modules={modules} />
    </ExpandItem>
  );
  if (!expanded) {
    return header;
  }

  const counter = (id) => <NewActivityBadge group={id} className="ms-3" />;

  const classesPortfolio = (
    <DynamicListGroup
      data={groups}
      link="/groups/:id/portfolio"
      noRedirect={showLibraryLink || pathname.includes('portfolio')}
      counter={counter}
    />
  );

  const libraryLink = showLibraryLink && (
    <PathItem to="/portfolio/library">
      <AvatarWithText icon="ss-database" active={pathname.startsWith('/portfolio/library')}>
        <FormattedMessage {...messages.library} />
      </AvatarWithText>
    </PathItem>
  );

  function getBody() {
    if (!showLibraryLink) {
      return classesPortfolio;
    }
    if (!hasItems(groups)) {
      return libraryLink;
    }
    return (
      <>
        <ListGroupItem className="list-group-header">
          <FormattedMessage {...messages.classes} />
        </ListGroupItem>
        {classesPortfolio}
        {/* Portfolio Library */}
        <ListGroupItem className="list-group-header">
          <FormattedMessage {...messages.management} />
        </ListGroupItem>
        {libraryLink}
      </>
    );
  }

  return (
    <>
      {header}
      <div className="list-group-indent">
        {getBody()}
      </div>
    </>
  );
}

Employee.propTypes = {
  modules: PropTypes.array.isRequired,
  isManager: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Employee;
