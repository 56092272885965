/**
 *
 * User
 *
 */

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import LoginButton from 'components/user/LoginButton/index.jsx';
import UserMenu from 'components/user/UserMenu/index.jsx';
import useUserContext from 'containers/User/useUserContext.js';

function User() {
  const { auth, profile, roleId, setUserRole } = useUserContext();
  const { signinRedirect, signoutRedirect } = auth;
  const { pathname, search, hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hash === '#login') {
      login();
    }
  }, [hash]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleRoleChange(newRoleId) {
    setUserRole(newRoleId);
    navigate('/');
  }

  function login() {
    const path = `${pathname}${search}`;
    signinRedirect({ state: { path } });
  }

  function logout() {
    signoutRedirect();
  }

  return (
    <div>
      {profile
        ? <UserMenu profile={profile} currentRoleId={roleId} onRoleChange={handleRoleChange} onLogout={logout} aria-label="Select User Roles and Settings" />
        : <LoginButton onLogin={login} />}
    </div>
  );
}

export default User;
