import config from 'config/index.js';
import { getCredentials } from 'containers/User/UserProvider/userStorage.js';
import { LOCALE_KEY } from 'containers/LanguageProvider/constants.js';
import request from 'utils/request.js';
import storage from 'utils/storage/index.js';

async function authorizedRequest(options) {
  const locale = storage.getItem(LOCALE_KEY);
  const credentials = await getCredentials();
  const headers = {
    ...credentials,
    'Content-Type': 'application/json',
    'Accept-Language': locale,
  };
  let { apiHost } = config;
  if (options.version) {
    apiHost = apiHost.replace('v1', `v${options.version}`);
  }
  if (options.version === 0) {
    apiHost = apiHost.replace('/v1', '');
  }
  const requestOptions = {
    uri: `${apiHost}${options.url}`,
    method: options.method,
    body: options.body && JSON.stringify(options.body),
    headers,
    ignore: options.ignore,
  };
  if (options.mock) {
    console.log('Mocking request', requestOptions); // eslint-disable-line no-console
  }
  return options.mock || request(requestOptions);
}

export default authorizedRequest;
