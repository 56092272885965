/**
*
* PrintButton
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import withDialog from 'containers/Dialog/withDialog.js';
import IconButton from 'components/interaction/IconButton/index.jsx';
import cx from 'utils/cx.js';
import messages from './messages.js';

export function print(dlgAlert) {
  const t0 = Date.now();
  window.print();
  if (Date.now() - t0 < 100) {
    dlgAlert({ message: messages.useBrowserPrint });
  }
}

function PrintButton(props) {
  const { className, iconClass, dlgAlert } = props;
  const handlePrint = () => print(dlgAlert);
  const icon = cx('ss-print', iconClass);
  return <IconButton variant="link" icon={icon} className={className} onClick={handlePrint} />;
}

PrintButton.propTypes = {
  className: PropTypes.string,
  iconClass: PropTypes.string,
  dlgAlert: PropTypes.func,
};

export default withDialog(PrintButton);
