/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import moment from 'moment/dist/moment';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/en-gb';

import 'intl-pluralrules';
import useCurrentUser from 'containers/User/useCurrentUser.js';
import { updateLocales } from 'utils/timeFormat.js';
import storage from 'utils/storage/index.js';
import browserLanguage from 'utils/browserLanguage.js';
import { translationMessages } from '../../i18n.js';
import { LOCALE_KEY, LocaleContext } from './constants.js';
import { DEFAULT_LOCALE } from '../App/constants.js';

import setYupLocale from './setYupLocale.js';

const initialLocale = storage.getItem(LOCALE_KEY) || browserLanguage(['en', 'nl']) || DEFAULT_LOCALE;

function LanguageProvider(props) {
  const { children } = props;
  const [locale, setLocale] = useState(initialLocale);
  const user = useCurrentUser();
  const userLocale = user?.profile?.language.code;
  useEffect(updateLocales, []);

  useEffect(() => {
    if (userLocale) {
      setLocale(userLocale);
    }
  }, [userLocale]);

  function setHtmlLang() {
    document.getElementsByTagName('html')[0].setAttribute('lang', locale);
  }

  useEffect(() => {
    storage.setItem(LOCALE_KEY, locale);
    moment.locale(locale === 'en' ? 'en-gb' : locale);
    setYupLocale(locale);
    setHtmlLang();
  }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps

  const value = useMemo(() => ({ locale, setLocale }), [locale]);

  return (
    <LocaleContext.Provider value={value}>
      <IntlProvider
        locale={locale}
        key={locale}
        messages={translationMessages[locale.slice(0, 2)]}
        textComponent="span"
      >
        {React.Children.only(children)}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;
