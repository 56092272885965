/**
 *
 * SchoolDisplay
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import ColorSpan from '../layout/ColorSpan.js';

function SchoolDisplay(props) {
  const { school } = props;
  return school && <ColorSpan color={school.theme.themeColor.hex}>{school.name}</ColorSpan>;
}

SchoolDisplay.propTypes = {
  school: PropTypes.object,
};

export default SchoolDisplay;
