/*
 * SideMenu Messages
 *
 * This contains all the text for the SideMenu component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  home: {
    id: 'app.components.SideMenu.home',
    defaultMessage: 'Home',
  },
  classes: {
    id: 'app.components.SideMenu.classes',
    defaultMessage: 'Classes',
  },
  generic: {
    id: 'app.components.AdminGroups.general',
    defaultMessage: 'General Groups',
  },
  foundation: {
    id: 'app.components.SideMenu.foundation',
    defaultMessage: 'Foundation Groups',
  },
  myStudents: {
    id: 'app.components.SideMenu.myStudents',
    defaultMessage: 'My Students',
  },
  myGroups: {
    id: 'app.components.SideMenu.myGroups',
    defaultMessage: 'My Groups',
  },
  groupPages: {
    id: 'app.components.SideMenu.groupPages',
    defaultMessage: 'Group Pages',
  },
  administration: {
    id: 'app.components.SideMenu.administration',
    defaultMessage: 'Administration',
  },
  myChildren: {
    id: 'app.components.SideMenu.myChildren',
    defaultMessage: 'My Children',
  },
  absences: {
    id: 'app.components.SideMenu.absences',
    defaultMessage: 'Absences',
  },
  conversations2: {
    id: 'app.components.Conversations.conversations',
    defaultMessage: 'Conversations',
  },
  conversations: {
    id: 'app.components.SideMenu.conversations',
    defaultMessage: 'Conversations',
  },
  calendar: {
    id: 'app.components.SideMenu.calendar',
    defaultMessage: 'Calendar',
  },
  dashboard: {
    id: 'app.components.SideMenu.dashboard',
    defaultMessage: 'Dashboard',
  },
  overviews: {
    id: 'app.components.SideMenu.overviews',
    defaultMessage: 'Overviews',
  },
  parentMeetings: {
    id: 'app.components.SideMenu.parentMeetings',
    defaultMessage: 'Parent Meetings',
  },
  allGroups: {
    id: 'app.components.SideMenu.allGroups',
    defaultMessage: 'All Groups',
  },
  portfolio: {
    id: 'app.components.SideMenu.portfolio',
    defaultMessage: 'Portfolio',
  },
  library: {
    id: 'app.containers.PortfolioPage.library',
    defaultMessage: 'Library',
  },
  noPortfolio: {
    id: 'app.components.SideMenu.noPortfolio',
    defaultMessage: 'This child does not have a portfolio',
  },
  management: {
    id: 'app.components.SideMenu.management',
    defaultMessage: 'Management',
  },
  users: {
    id: 'app.containers.Dashboard.users',
    defaultMessage: 'Users',
  },
  groups: {
    id: 'app.components.AdminGroups.groups',
    defaultMessage: 'Groups',
  },
  tryNow: {
    id: 'app.common.tryNow',
    defaultMessage: 'Try Now!',
  },
  paymentRequests: {
    id: 'app.components.PaymentRequests.paymentRequests',
    defaultMessage: 'Payment Requests',
  },
  settings: {
    id: 'app.common.settings',
    defaultMessage: 'Settings',
  },
  paymentModule: {
    id: 'app.common.paymentModule',
    defaultMessage: 'Payment Module',
  },
  more: {
    id: 'app.components.AdminUsers.more',
    defaultMessage: 'More',
  },
  consents: {
    id: 'app.containers.ConsentPage.title',
    defaultMessage: 'Consents',
  },
  showArchivedInboxes: {
    id: 'app.components.SideMenu.showArchivedInboxes',
    defaultMessage: '{showArchived, select, true {Hide} other {Show}} archived inboxes',
  },
  newsletters: {
    id: 'app.components.Newsletter.newsletters',
    defaultMessage: 'Newsletters',
  },
  absenceReports: {
    id: 'app.components.AbsenceReports.absenceReports',
    defaultMessage: 'Absence Reports',
  },
});
