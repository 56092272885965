import styled from 'styled-components';

export const PageWrapper = styled.div`
  @media screen {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  background-color: ${(props) => props.loading ? props.theme.contentBg : 'transparent'};
  background-image: ${(props) => (props.theme.backgroundImage && !props.loading) ? `url(${props.theme.backgroundImage})` : 'none'};
  background-position: bottom;
  background-size: cover;
  &.webview { 
    && > div, main > .shaded.padding {
      padding-top: 0;
      padding-bottom: 0;
    }
    .no-webview {
      display: none !important;
    }
  }
`;
export const BodyWrapper = styled.div`
  position: relative;
  @media screen {
    padding-top: ${(p) => p.theme.headerHeight};
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
  }
  & > main {
    @media screen {
      flex: 1 1 100%;
      max-width: 100%;
    }
    display: flex;
    flex-direction: column;
    .padding {
      padding: 15px;
      @media (max-width: ${(props) => props.theme.screenXsMax}) {
        padding: 15px 0;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
        .panel {
          border-radius: 0;
        }
      }
    }
    .meta-info {
      color: ${(props) => props.theme.gray};
    }
    & > :last-child {
      flex-grow: 1;
    }
    a:not(.btn), a.btn-link {
      color: ${(p) => p.theme.linkColor};
      &:hover, &:focus {
        color: ${(p) => p.theme.linkHoverColor};
      }
    }
    .disabled > a:not(.btn), .disabled > a.btn-link {
      color: ${(p) => p.theme.grayLight};
    }
  }
  .alert {
      overflow-wrap: anywhere;
    * {
      max-width: 100%;
    }
  }
`;
