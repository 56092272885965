import { useState, useReducer, useEffect, useRef } from 'react';

export function useIsMounted() {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return () => mounted.current;
}

const useSafeState = (initialValue) => {
  const isMounted = useIsMounted();
  const [currentValue, setCurrentValue] = useState(initialValue);
  const setSafeState = (value) => {
    if (isMounted()) {
      setCurrentValue(value);
    }
  };
  return [currentValue, setSafeState];
};

export const useSafeReducer = (reducer, initialArg, init) => {
  const isMounted = useIsMounted();
  const [state, dispatch] = useReducer(reducer, initialArg, init);

  const saveDispatch = (value) => {
    if (isMounted()) {
      dispatch(value);
    }
  };
  return [state, saveDispatch];
};

export default useSafeState;
