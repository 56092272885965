export function fontSize(props) {
  switch (props.bsSize) {
    case 'xlarge':
    case 'large':
      return props.theme.fontSizeLarge;
    case 'small':
    case 'xsmall':
      return props.theme.fontSizeSmall;
    default:
      return props.theme.fontSizeBase;
  }
}

export function paddingVertical(props) {
  switch (props.bsSize) {
    case 'xlarge':
    case 'large':
      return props.theme.paddingLargeVertical;
    case 'small':
      return props.theme.paddingSmallVertical;
    case 'xsmall':
      return props.theme.paddingXsVertical;
    default:
      return props.theme.paddingBaseVertical;
  }
}

export function paddingHorizontal(props) {
  switch (props.bsSize) {
    case 'xlarge':
    case 'large':
      return props.theme.paddingLargeHorizontal;
    case 'small':
      return props.theme.paddingSmallHorizontal;
    case 'xsmall':
      return props.theme.paddingXsHorizontal;
    default:
      return props.theme.paddingBaseHorizontal;
  }
}

export function lineHeight(props) {
  switch (props.bsSize) {
    case 'xlarge':
    case 'large':
      return props.theme.lineHeightLarge;
    case 'small':
    case 'xsmall':
      return props.theme.lineHeightSmall;
    default:
      return props.theme.lineHeightBase;
  }
}

export function borderRadius(props) {
  switch (props.bsSize) {
    case 'xlarge':
    case 'large':
      return props.theme.borderRadiusLarge;
    case 'small':
    case 'xsmall':
      return props.theme.borderRadiusSmall;
    default:
      return props.theme.borderRadiusBase;
  }
}

export function btnBorderRadius(props) {
  switch (props.bsSize) {
    case 'xlarge':
    case 'large':
      return props.theme.btnBorderRadiusLarge;
    case 'small':
    case 'xsmall':
      return props.theme.btnBorderRadiusSmall;
    default:
      return props.theme.btnBorderRadiusBase;
  }
}

export function inputBorderRadius(props) {
  switch (props.bsSize) {
    case 'xlarge':
    case 'large':
      return props.theme.inputBorderRadiusLarge;
    case 'small':
    case 'xsmall':
      return props.theme.inputBorderRadiusSmall;
    default:
      return props.theme.inputBorderRadius;
  }
}

export function inputHeight(props) {
  switch (props.bsSize) {
    case 'xlarge':
    case 'large':
      return props.theme.inputHeightLarge;
    case 'small':
    case 'xsmall':
      return props.theme.inputHeightSmall;
    default:
      return props.theme.inputHeightBase;
  }
}

export function avatarSize(props) {
  switch (props.bsSize) {
    case 'xlarge':
      return props.theme.avatarSizeXLarge;
    case 'large':
      return props.theme.avatarSizeLarge;
    case 'small':
    case 'xsmall':
      return props.theme.avatarSizeSmall;
    default:
      return props.theme.avatarSizeBase;
  }
}

export function iconSize(props) {
  switch (props.bsSize) {
    case 'xlarge':
      return props.theme.iconSizeXLarge;
    case 'large':
      return props.theme.iconSizeLarge;
    case 'small':
    case 'xsmall':
      return props.theme.iconSizeSmall;
    default:
      return defaultValue(props.bsSize, props.theme.iconSizeBase);
  }
}

export function styleColor(props, style) {
  switch (style || props.variant) {
    case 'primary':
      return props.theme.brandPrimary;
    case 'info':
      return props.theme.brandInfo;
    case 'success':
      return props.theme.brandSuccess;
    case 'warning':
      return props.theme.brandWarning;
    case 'danger':
      return props.theme.brandDanger;
    default:
      return undefined;
  }
}

export function curriedStyleColor(style) {
  return (props) => styleColor(props, style);
}

function defaultValue(size, def) {
  return /^\d/.test(size) ? size : def;
}
