import { flatten, uniqBy, sortBy } from 'lodash-es';
import produce from 'immer';
import { format, compareAsc, addDays, startOfDay, differenceInCalendarDays, parseISO } from 'utils/date-fns/index.js';
import { getColorForId } from './materialColors.js';
import { makeIdObject, idValues } from './arrays.js';

function dateParser(date) {
  if (!date) {
    return date;
  }
  return typeof date === 'string' ? parseISO(date) : date;
}

export function eventParser(event) {
  if (!event.start) {
    // incomplete, e.g. after publishing
    return event;
  }
  const start = dateParser(event.start);
  const end = dateParser(event.end);
  const chipTitle = event.allDay ? event.title : `${format(start, 'p')} ${event.title}`;
  const communities = sortBy(event.communities, ['name', 'id']).map(withColor);
  const color = communities[0] ? communities[0].color : getColorForId(0);
  const materials = makeIdObject(event.materials || []);
  return {
    ...event,
    start,
    end,
    chipTitle,
    color,
    communities,
    materials,
  };
}

/**
 * Compare two events by start time
 * @param ev1
 * @param ev2
 * @returns {number}
 */
export function eventCompare(ev1, ev2) {
  return compareAsc(ev1.start, ev2.start) || ev1.title.localeCompare(ev2.title);
}

/**
 * Merge events with same id, create groups attribute for each event
 * list may be result of previous merges, so some events have groups already and no community
 * @param list
 */
export function mergeEvents(list) {
  const eventsById = {};
  list.forEach((event) => {
    if (eventsById[event.id] === undefined) {
      eventsById[event.id] = { ...event };
    }
    const combined = [...eventsById[event.id].communities, ...event.communities];
    eventsById[event.id].communities = uniqBy(combined, byId);
  });
  return Object.values(eventsById);
}

export function addCalendarGroups(currentGroups, newEvents) {
  const combined = uniqBy(flatten([currentGroups, ...newEvents.map((ev) => ev.communities)]), byId);
  const result = combined.map((comm) => ({
    selected: true,
    ...withColor(comm),
  }));
  return sortBy(result, (g) => g.name);
}

export function withColor(comm) {
  return comm.color ? comm : { ...comm, color: getColorForId(comm.id) };
}

export function splitEventsForCommunities(list) {
  return list.reduce((acc, event) => {
    if (!event.communities || event.communities.length === 0) {
      return [...acc, event];
    }
    return [...acc, ...event.communities.map((community) => ({ ...event, community, color: community.color }))];
  }, []);
}

const byId = (item) => item.id;

export function getMaterials(event) {
  if (!event.backers) {
    return { values: event.materials, entity: 'materials', loading: true };
  }
  if (event.backers.loading) {
    return { ...event.backers, values: event.materials };
  }
  const materialsObject = makeIdObject(event.materials);
  const values = event.backers.values.map((mat) => ({ ...materialsObject[mat.id], ...mat }));
  return { ...event.backers, values };
}

export function withEventCommunities(communities, events) {
  // Ensure event communities are in list and have permissions:author
  if (!communities || !communities.list || !events || !events.list) {
    return communities;
  }
  return produce(communities, (draft) => {
    idValues(events).forEach((event) => {
      event.communities.forEach((comm) => {
        if (draft[comm.id]) {
          draft[comm.id].permissions.author = true;
        } else {
          draft[comm.id] = { ...comm, permissions: { author: true } };
          draft.list.push(comm.id);
        }
      });
    });
  });
}

export function splitEventByDays(event) {
  const { start, end, allDay, id = 0 } = event;
  const nDays = differenceInCalendarDays(end, start);
  const result = [];
  const day0 = startOfDay(start);
  for (let i = 0; i <= nDays; i++) {
    const current = addDays(day0, i);
    result.push({
      ...event,
      key: `${id}-${i}`,
      start: i === 0 ? start : current,
      end: i === nDays ? end : current,
      allDay: allDay || nDays > 0,
    });
  }
  return result;
}
