import { takeEvery, put, select } from 'redux-saga/effects';
import config from 'config/index.js';
import { get, pick } from 'lodash-es';
import request from 'utils/request.js';
import { addAlert, addError } from 'containers/Alerts/actions.js';
import { getCurrentRole } from 'containers/User/UserProvider/userStorage.js';
import { reportError, reportApiError } from 'utils/sentry.js';
import { parseUrl } from 'utils/urlUtils.js';
import { getHeaders, getCacheBuster } from './helpers.js';
import makeSelectApi2 from './selectors.js';
import { API2_CALL, API2_CALL_ERROR, API2_CALL_SUCCESS, API2_CALL_REQUEST } from './constants.js';
import parseError from './parseError.js';
import parsePayload from './parsePayload.js';
import { api2cancel } from './actions.js';
import { addToStack, checkStack, removeFromStack } from './requestStack.js';

/**
 * api2call handle request sending
 * @param {*} action
 */
function* api2call(action) {
  const { options } = action;
  if (/:[a-z]\w+/.test(options.url)) {
    console.warn('Unresolved parameters', options); // eslint-disable-line no-console
    // Some params unresolved
    yield put(api2cancel(options, 'Unresolved parameters'));
    return;
  }
  const roleId = getCurrentRole()?.uniqueId;
  if (yield fromCurrent(options, roleId)) {
    if (options.promise) {
      const current = yield select(makeSelectApi2(options.selector)());
      options.promise.resolve(current);
    }
    yield put(api2cancel(options, 'Using current'));
    return;
  }
  addToStack(options);
  yield put({ ...action, type: API2_CALL_REQUEST, roleId });
  try {
    const payload = yield sendRequest(options);
    yield put({ ...action, type: API2_CALL_SUCCESS, payload, roleId });
  } catch (error) {
    yield put({ ...action, type: API2_CALL_ERROR, error });
  }
}

export function* sendRequest(options) {
  const headers = yield getHeaders();
  const cacheBuster = getCacheBuster(options);
  let { apiHost } = config;
  if (options.version) {
    apiHost = apiHost.replace('v1', `v${options.version}`);
  }
  if (options.version === 0) {
    apiHost = apiHost.replace('/v1', '');
  }
  const requestOptions = {
    uri: `${apiHost}${options.url}${cacheBuster}`,
    method: options.method,
    body: options.body && JSON.stringify(options.body),
    headers,
    ignore: options.ignore,
  };
  return yield request(requestOptions);
}

function* fromCurrent(options, roleId) {
  const { debug, ignoreNoSignature } = options;
  if (!options.url) {
    if (debug) console.log('api2: no url'); // eslint-disable-line no-console
    return true;
  }
  if (options.method !== 'GET' || options.reload || options.refresh) {
    if (debug) console.log('api2: not GET or reload or refresh'); // eslint-disable-line no-console
    return false;
  }
  if (checkStack(options)) {
    if (debug) console.log('api2: in stack'); // eslint-disable-line no-console
    return true;
  }
  const currentState = yield select(makeSelectApi2(options.selector)());
  if (currentState === undefined) {
    if (debug) console.log('api2: no current state'); // eslint-disable-line no-console
    return false;
  }
  if (options.reload !== undefined) {
    if (debug) console.log('api2: reload'); // eslint-disable-line no-console
    return !options.reload;
  }
  const { signature } = currentState;
  if (!signature && ignoreNoSignature) {
    if (debug) console.log('api2: ignored no signature'); // eslint-disable-line no-console
    return true;
  }
  if (debug) console.log('api2: signature', signature); // eslint-disable-line no-console
  return signature && sameUrl(signature.url, options.url) && (options.public || roleId === signature.roleId);
}

function api2request(action) {
  const { options } = action;
  removeFromStack(options);
}

function* api2success(action) {
  const { options: { noParse, promise, success }, payload } = action;
  if (success) {
    yield put(addAlert(success, 'success'));
  }
  if (promise) {
    promise.resolve(noParse ? payload : parsePayload(payload, action.options));
  }
}

function* api2error(action) {
  const { options, error } = action;
  const { promise, ignore, noReport } = options;
  const status = get(error, 'response.status');
  error.noReport = (noReport?.includes(status)) || !error.response;
  if (promise) {
    promise.reject(error);
  }
  if (ignore === true || (ignore?.includes(status))) {
    return;
  }
  if (!error.request) {
    console.error(error); // eslint-disable-line no-console
    reportError('request', error);
    yield put(addError(error));
    return;
  }
  if (!error.response) {
    // Failed to fetch, could not load, apparent connectivity problem
    yield put(addError(error));
    return;
  }
  const parsed = yield parseError(error);
  const message = (options.error?.[status]) || parsed.message;
  if (!error.noReport) {
    const response = { ...pick(error.response, ['status', 'statusText']), message };
    reportApiError(error.message, action, response);
  }
  yield put(addError({ ...error, message }));
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeEvery(API2_CALL, api2call);
  yield takeEvery(API2_CALL_REQUEST, api2request);
  yield takeEvery(API2_CALL_SUCCESS, api2success);
  yield takeEvery(API2_CALL_ERROR, api2error);
}

function sameUrl(sigUrl, optUrl) {
  const sig = parseUrl(sigUrl);
  const opt = parseUrl(optUrl);
  if (sig.path !== opt.path) {
    return false;
  }
  const sigKeys = Object.keys(sig.searchObject).filter((key) => key !== 'timeStamp');
  const optKeys = Object.keys(opt.searchObject).filter((key) => key !== 'timeStamp');
  if (sigKeys.length !== optKeys.length) {
    return false;
  }
  let sigLast = 0;
  let optLast = 0;
  const sameSearch = sigKeys.every((key) => {
    const optVal = opt.searchObject[key];
    if (optVal === undefined) {
      return false;
    }
    const sigVal = sig.searchObject[key];
    switch (key) {
      case 'offset':
      case 'limit':
        sigLast += Number(sigVal);
        optLast += Number(optVal);
        return true;
      default:
        return optVal === sigVal;
    }
  });
  return sameSearch && sigLast >= optLast;
}
