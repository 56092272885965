import { useDispatch } from 'react-redux';
import { selectUiControlDomain } from './selectors.js';
import { uiToggle, uiSet } from './actions.js';
import useCreateSelector from '../../utils/useCreateSelector.js';

function useUiControl(prop) {
  const dispatch = useDispatch();
  const value = useCreateSelector(
    selectUiControlDomain,
    (substate) => prop ? substate.toJS()[prop] : substate.toJS(),
  );
  const change = (v) => v === undefined ? dispatch(uiToggle(prop)) : dispatch(uiSet(prop, v));
  return [value, change];
}

export default useUiControl;
