import { fromUTCStrings } from 'utils/eventTimes.js';
import callRecursive from 'utils/callRecursive.js';

const REGEX_DATE = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;

const isDateString = (str) => REGEX_DATE.test(str);

function asDate(str) {
  const [yr, mo, dt] = str.split(/\D+/, 3).map(Number);
  return new Date(yr, mo - 1, dt); // month is 0-based
}

export function parseDateString(val) {
  if (typeof val !== 'string') {
    return val;
  }
  if (!isDateString(val)) {
    return val;
  }
  const dateInt = Date.parse(val);
  return dateInt ? new Date(dateInt) : val;
}

function parseTimes(obj) {
  if (!obj) {
    return obj;
  }
  const type = typeof obj;
  if (type === 'string') {
    return parseDateString(obj);
  }
  if (type !== 'object' || (!obj.start && !obj.schoolYearStart)) {
    return obj;
  }
  if (isDateString(obj.start) && (!obj.end || isDateString(obj.end))) {
    return fromUTCStrings(obj);
  }
  if (isDateString(obj.schoolYearStart) && isDateString(obj.schoolYearEnd)) {
    return {
      ...obj,
      schoolYearStart: asDate(obj.schoolYearStart),
      schoolYearEnd: asDate(obj.schoolYearEnd),
    };
  }
  return obj;
}

export function serverDatesParser(obj) {
  // Need to adapt end for allDay events
  return callRecursive(obj, undefined, 0, parseTimes);
}

export function sessionDatesParser(obj) {
  return callRecursive(obj, undefined, 0, parseDateString);
}
