/*
 * AlertList Messages
 *
 * This contains all the text for the AlertList component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  default: {
    id: 'app.components.AlertList.default',
    defaultMessage: 'Something went wrong',
  },
  error: {
    id: 'app.components.AlertList.error',
    defaultMessage: 'Error:',
  },
  message: {
    id: 'app.components.AlertList.message',
    defaultMessage: 'Error message',
  },
});
