/**
*
* useNotificationsLists
*
*/

import { useEffect } from 'react';
import useRestApi from 'utils/useRestApi/index.js';

function useNotificationsLists(stats, onReset, open) {
  const search = new URLSearchParams({
    offset: 0,
    limit: 20,
    filterFrom: stats.lastSeen,
  });

  const notifications = useRestApi({ url: `notifications?${search}` }, { enabled: open });
  useEffect(() => {
    if (notifications.status === 'success') {
      onReset();
    }
  }, [notifications.status]); // eslint-disable-line react-hooks/exhaustive-deps

  return notifications;
}

export default useNotificationsLists;
