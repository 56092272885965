/*
 *
 * UiControl actions
 *
 */

import {
  UI_TOGGLE,
  UI_SET,
} from './constants.js';

export function uiToggle(prop) {
  return {
    type: UI_TOGGLE,
    prop,
  };
}

export function uiSet(prop, val) {
  return {
    type: UI_SET,
    prop,
    val,
  };
}
