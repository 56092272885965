/**
 *
 * sameRole
 * Compare two roles by id
 *
 */

import { getIdNumber } from './userIdRole.js';

function sameRole(a, b) {
  if (!a || !b) {
    return a === b;
  }
  if (a.uniqueId && b.uniqueId) {
    return a.uniqueId === b.uniqueId;
  }
  return getIdNumber(a) === getIdNumber(b);
}

export const removeUser = (list, user) => list.filter((v) => !sameRole(v, user));

export default sameRole;
