/*
 * Materials Messages
 *
 * This contains all the text for the Materials component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  ask: {
    id: 'app.components.Materials.ask',
    defaultMessage: 'Can you help with the following?',
  },
  needed: {
    id: 'app.components.Materials.needed',
    defaultMessage: '{count} of {total} more needed',
  },
  view: {
    id: 'app.components.Materials.view',
    defaultMessage: 'View backers',
  },
  backers: {
    id: 'app.components.Calendar.backers',
    defaultMessage: 'Backers',
  },

});
