/**
 *
 * AlertItem
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import config from 'config/index.js';
import { Alert, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { limitLength } from 'utils/strings.js';
import { ERROR_ID } from 'utils/sentry.js';
import cx from 'utils/cx.js';
import messages from './messages.js';

const H4 = styled.h4`
  line-height: 1.5;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  &.btn.btn-link {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
`;

const PreWrap = styled.div`
  white-space: pre-wrap;
`;

const ErrorLabel = styled.span`
  ::after {
    content: ': '
  }
`;

function AlertItem(props) {
  const { onHide, alert, shaded } = props;
  const { style, context, message, toast } = alert;
  const [showDetail, setShowDetail] = useState(false);

  const title = (
    <>
      {style === 'danger'
          && <ErrorLabel><FormattedMessage {...messages.error} /></ErrorLabel>}
      { message.id ? <FormattedMessage {...message} /> : message }
    </>
  );

  const alertClass = cx(shaded && 'alert-shaded', toast && 'alert-toast');
  return (
    <Alert variant={style} className={alertClass} onClose={onHide} dismissible>
      <H4>
        { config.env !== 'production' && context
          ? <StyledButton variant="link" className="p-0" onClick={() => setShowDetail(!showDetail)}>{title}</StyledButton>
          : title}
      </H4>
      {style === 'danger' && (
        <code>
          Code:
          {ERROR_ID}
        </code>
      )}
      {context && showDetail
        && (
          <PreWrap className="font-monospace">
            {typeof context === 'object'
              ? limitLength(JSON.stringify(context, null, 2)) : context}
          </PreWrap>
        )}
    </Alert>
  );
}

AlertItem.propTypes = {
  alert: PropTypes.object,
  onHide: PropTypes.func,
  shaded: PropTypes.bool,
};

export default AlertItem;
