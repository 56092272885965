/*
 * AddImage Messages
 *
 * This contains all the text for the AddImage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.CropModal.header',
    defaultMessage: 'Crop Image',
  },
  confirmRemove: {
    id: 'app.components.AddImage.confirmRemove',
    defaultMessage: 'Delete Image?',
  },
});
