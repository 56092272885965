/*
 * Calendar Messages
 *
 * This contains all the text for the Calendar component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  noEvents: {
    id: 'app.components.Calendar.noEvents',
    defaultMessage: 'No events found',
  },
  calendar: {
    id: 'app.components.Calendar.calendar',
    defaultMessage: 'Calendar',
  },
  month: {
    id: 'app.components.Calendar.month',
    defaultMessage: 'Month',
  },
  week: {
    id: 'app.components.Calendar.week',
    defaultMessage: 'Week',
  },
  day: {
    id: 'app.components.Calendar.day',
    defaultMessage: 'Day',
  },
  listView: {
    id: 'app.components.Calendar.listView',
    defaultMessage: 'List View',
  },
  yearView: {
    id: 'app.components.Calendar.yearView',
    defaultMessage: 'Year View',
  },
  date: {
    id: 'app.components.Calendar.date',
    defaultMessage: 'Date',
  },
  time: {
    id: 'app.components.Calendar.time',
    defaultMessage: 'Time',
  },
  event: {
    id: 'app.components.Calendar.event',
    defaultMessage: 'Event',
  },
  groups: {
    id: 'app.components.Calendar.groups',
    defaultMessage: 'Groups',
  },
  create: {
    id: 'app.components.Calendar.create',
    defaultMessage: 'Create Event',
  },
  import: {
    id: 'app.common.import',
    defaultMessage: 'Import',
  },
  remove: {
    id: 'app.common.delete',
    defaultMessage: 'Delete',
  },
  revoke: {
    id: 'app.common.revoke',
    defaultMessage: 'Revoke',
  },
  subscribe: {
    id: 'app.components.Calendar.subscribe',
    defaultMessage: 'Subscribe',
  },
  download: {
    id: 'app.components.Calendar.download',
    defaultMessage: 'Download .ics',
  },
  published: {
    id: 'app.components.Calendar.published',
    defaultMessage: 'The event has been published',
  },
  revoked: {
    id: 'app.components.Calendar.revoked',
    defaultMessage: 'Publication of the event has been revoked',
  },
  removeTitle: {
    id: 'app.components.Calendar.removeTitle',
    defaultMessage: 'Delete Event',
  },
  removeMessage: {
    id: 'app.components.Calendar.removeMessage',
    defaultMessage: 'Delete event "{title}"?',
  },
  publishTitle: {
    id: 'app.components.Calendar.publishTitle',
    defaultMessage: 'Publish Event',
  },
  publishMessage: {
    id: 'app.components.Calendar.publishMessage',
    defaultMessage: 'Publish event "{title}" on the web site?',
  },
  revokeTitle: {
    id: 'app.components.Calendar.revokeTitle',
    defaultMessage: 'Revoke Event',
  },
  revokeMessage: {
    id: 'app.components.Calendar.revokeMessage',
    defaultMessage: 'Revoke event "{title}" from the web site?',
  },
  contactDetails: {
    id: 'app.components.UserModal.contactDetails',
    defaultMessage: 'Contact Details',
  },
  includeContactDetails: {
    id: 'app.components.UserModal.includeContactDetails',
    defaultMessage: 'Print with contact details?',
  },
  allDay: {
    id: 'app.components.StartEndPicker.allDay',
    defaultMessage: 'All day',
  },
});
