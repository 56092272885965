// https://github.com/IdentityModel/oidc-client-js/issues/269

import Cookies from 'js-cookie';

const options = {
  secure: window.location.protocol === 'https:',
};

export default class CookieStorage {
  setItem(key, value) {
    Cookies.set(key, value, options);
  }

  getItem(key) {
    return Cookies.get(key, options);
  }

  removeItem(key) {
    Cookies.remove(key, options);
  }

  key(index) {
    const allKeys = Object.keys(Cookies.getJSON());

    return (index > -1 && index <= allKeys.length) ? allKeys[index] : '';
  }
}
