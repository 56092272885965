/**
 *
 * formHelpers
 * usage in constructor of form component with Bootstrap forms and this.validate method:
 * this.formHelper = formHelper.bind(this)
 * Expects state.values, state.touched, state.submitted, validate, fields (optional) on this
 *
 */

const FOCUS_TAGS = 'a[href] button textarea input select [tabindex]'.split(' ').map((el) => `${el}:not([disabled])`).join(', ');

function setFocus(element) {
  if (element) {
    const activeElement = element.parentNode.querySelector('.active');
    const focusElement = activeElement || element;
    focusElement.focus();
  }
}

export function focusFirstInput(query = 'form') {
  window.setTimeout(() => {
    const element = document.querySelector(query);
    const focusElement = element?.querySelector(FOCUS_TAGS);
    if (!focusElement) {
      return;
    }
    if (/simplemde-editor-\d+/.test(focusElement.id)) {
      window.setTimeout(() => {
        const altTextarea = element.querySelector(`textarea:not([id='${focusElement.id}'])`);
        setFocus(altTextarea || focusElement);
      }, 0);
    } else {
      setFocus(focusElement);
    }
  }, 0);
}

export function focusGroupInput(groupElem) {
  if (!groupElem) {
    return;
  }
  window.setTimeout(() => {
    const focusElement = groupElem.querySelector(FOCUS_TAGS);
    if (!focusElement) {
      return;
    }
    if (/simplemde-editor-\d+/.test(focusElement.id)) {
      window.setTimeout(() => {
        const altTextarea = groupElem.querySelector(`textarea:not([id='${focusElement.id}'])`);
        setFocus(altTextarea || focusElement);
      }, 0);
    } else {
      setFocus(focusElement);
    }
  }, 0);
}
