/**
*
* useTabbing
*/

import { useState } from 'react';
// import PropTypes from 'prop-types';
import { useEvent } from 'react-use';

const isTabbing = () => document.querySelector('.user-is-tabbing') !== null;

function useTabbing() {
  // Keep track of tabbing
  const [tabbing, setTabbing] = useState(isTabbing());
  const handleMessage = (event) => {
    const { data, origin } = event;
    if (origin === window.location.origin && data.userIsTabbing !== undefined) {
      setTabbing(data.userIsTabbing);
    }
  };
  useEvent('message', handleMessage);
  return tabbing;
}

export default useTabbing;
