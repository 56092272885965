/**
 *
 * UserModal
 * Load and Display a user
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import { PARENT_ROLE, STUDENT_ROLE } from 'containers/User/constants.js';
import useApi2Userroles from 'containers/User/useApi2Userroles.js';
import AlertList from 'components/AlertList/index.jsx';
import { Modal } from 'react-bootstrap';
import Spinner from 'components/Spinner/index.jsx';
import Connections from './Connections.jsx';
import ContactDetails from './ContactDetails.jsx';
import UserHeader from './UserHeader.jsx';

const Header = styled(Modal.Header)`
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: ${(props) => props.theme.brandPrimary};
  color: ${(props) => props.theme.bodyBg};
  .btn-close {
    position: absolute;
    top: 10px;
    right: 16px;
  }
`;

const Body = styled(Modal.Body)`
  .card-header {
    padding: 0;
  }
  .list-group-item {
    padding: 10px 0
  }
`;

function UserModal(props) {
  const { onHide, userId } = props;
  const role = useCurrentRole();
  const userrole = useApi2Userroles(userId);
  if (!role) {
    return null;
  }
  return (
    <Modal show onHide={onHide}>
      <Header key="modalHeader" closeButton>
        <UserHeader onHide={onHide} user={userrole} />
      </Header>
      {userrole.loading ? <Spinner /> : (
        <Body key="modalBody">
          <AlertList />
          {!userrole.error && role.type !== STUDENT_ROLE && ( // Students see no details
            <>
              {userrole.roleType !== STUDENT_ROLE // Not for students
              && <ContactDetails user={userrole} />}
              {userrole.roleType === STUDENT_ROLE // students only
              && <Connections user={userrole} type="parents" className="border-0" />}
              {userrole.roleType === PARENT_ROLE // parents only
              && <Connections user={userrole} type="children" className="border-0" />}
            </>
          )}
        </Body>
      )}
    </Modal>
  );
}

UserModal.propTypes = {
  userId: PropTypes.number,
  onHide: PropTypes.func,
};

export default UserModal;
