/**
*
* RsvpPrint
*
*/

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import { ListGroup } from 'react-bootstrap';
import { withApi2 } from 'containers/Api2/index.js';
import Spinner from 'components/Spinner/index.jsx';
import UserListItem from 'components/user/UserListItem.jsx';

import messages from '../messages.js';

function RsvpPrint(props) { // eslint-disable-line react/prefer-stateless-function
  const { rsvpList, className } = props;
  if (!rsvpList) {
    return null;
  }
  const replies = ['yes', 'maybe', 'no'];
  return (
    <div className={className}>
      {rsvpList.loading ? <Spinner />
        : replies
          .filter((reply) => rsvpList[reply].length > 0)
          .map((reply) => (
            <React.Fragment key={reply}>
              <h5><FormattedMessage {...messages[reply]} /></h5>
              <ListGroup variant="flush">
                {rsvpList[reply].map((item) => (
                  <UserListItem key={item.id} user={item} className="px-0" withRole />
                ))}
              </ListGroup>
            </React.Fragment>
          ))}
    </div>
  );
}

RsvpPrint.propTypes = {
  rsvpList: PropTypes.object,
  className: PropTypes.string,
};

export default withApi2([{ url: 'events/:eventId/rsvp', selector: 'events/:eventId/rsvpList' }])(RsvpPrint);
