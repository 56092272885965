/**
*
* NewPostBadge
*
*/

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import { Badge, Button } from 'react-bootstrap';
import useStats from 'containers/User/UserProvider/useStats.js';
import messages from './messages.js';

function NewPostBadge(props) {
  const { onClick, ...rest } = props;
  const { getIn } = useStats();
  const newPostCount = getIn?.('newPostCount');
  if (!newPostCount) {
    return null;
  }
  const values = { count: newPostCount };
  return onClick
    ? (
      <div className="text-center mb-4">
        <Button variant="primary" onClick={onClick} {...rest}>
          <i className="ss-refresh" />
          <FormattedMessage {...messages.newPosts} values={values} />
        </Button>
      </div>
    )
    : <Badge variant="primary" {...rest}>{newPostCount}</Badge>;
}

NewPostBadge.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default NewPostBadge;
