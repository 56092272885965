/**
 *
 * Header
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import User from 'containers/User/index.jsx';
import useTheme from 'containers/ThemeProvider/useTheme.js';
import NotificationsDropdown from 'components/notifications/NotificationsDropdown/index.jsx';
import IconButton from 'components/interaction/IconButton/index.jsx';

const Wrapper = styled.header`
  height: ${(props) => props.theme.headerHeight};
  z-index: 1050;  /* ensure dropdown stay on top */
  background-color: ${(props) => props.theme.btnPrimaryBg};
  color: ${(props) => props.theme.btnPrimaryColor};
  padding: 0 15px; 
`;

const SkipContent = styled.a`
  opacity: 0;
  margin-top: -100px;
  width: 0px;
  &:focus {
    margin-top: 0;
    color: #fff;
    width: auto;
    opacity: 1;
  }
`;

function Header(props) {
  const { role, onClick } = props;
  const theme = useTheme();

  return (
    <Wrapper className="no-webview w-100 d-print-none d-flex align-items-center position-fixed">
      {role && (
        <IconButton variant="link" icon="ss-rows" aria-label="menu" className="d-lg-none pt-3 px-3 ms-n3 text-white" onClick={onClick} />
      )}
      <h1 className="text-truncate flex-grow-1 fs-5 my-3">{theme.displayName || 'Social Schools'}</h1>
      <SkipContent href="#main-content" className="text-nowrap">Skip to Main Content</SkipContent>
      {role && <NotificationsDropdown />}
      <User />
    </Wrapper>
  );
}

Header.propTypes = {
  role: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

export default Header;
