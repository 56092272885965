/**
*
* Iewarning
*
*/

import { useIntl } from 'react-intl';
import messages from './messages.js';

function IEWarning() {
  const seen = sessionStorage.getItem('IE-Warning');
  const intl = useIntl();
  if (!seen) {
    // eslint-disable-next-line no-alert
    alert(intl.formatMessage(messages.update));
    sessionStorage.setItem('IE-Warning', true);
  }
  return null;
}

export default IEWarning;
