/**
*
* RsvpList
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useIntl } from 'react-intl';
import { ListGroup, Modal, Tab, Tabs } from 'react-bootstrap';
import { useApi2 } from 'containers/Api2/index.js';
import Spinner from 'components/Spinner/index.jsx';
import UserListItem from 'components/user/UserListItem.jsx';

import messages from '../messages.js';

const Wrapper = styled(Modal)`
  .tab-content {
    min-height: 200px;
  }
  .list-group-item:first-child {
    border-top: 0;
  }
`;

function RsvpList(props) {
  const { event, onHide } = props;
  const intl = useIntl();
  const rsvpList = useApi2({ url: `events/${event.id}/rsvp`, selector: `events/${event.id}/rsvpList` });
  if (!rsvpList) {
    return null;
  }
  const replies = ['yes', 'maybe', 'no'];
  return (
    <Wrapper show animation={false} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{event.title}</Modal.Title>
      </Modal.Header>
      {rsvpList.loading ? <Spinner /> : (
        <Tabs defaultActiveKey="yes" className="socs-tabs">
          {replies.map((reply) => {
            const title = intl.formatMessage(messages[reply]);
            const list = rsvpList[reply];
            const count = (list)
              ? ` (${rsvpList[reply].length})` : '';
            return (
              <Tab eventKey={reply} key={reply} title={`${title}${count}`}>
                <ListGroup>
                  {list.map((item) => (
                    <UserListItem key={item.id} user={item} withRole />
                  ))}
                </ListGroup>
              </Tab>
            );
          })}
        </Tabs>
      )}
    </Wrapper>
  );
}

RsvpList.propTypes = {
  onHide: PropTypes.func,
  event: PropTypes.object,
};

export default RsvpList;
