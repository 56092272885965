/*
 * EventEditor Messages
 *
 * This contains all the text for the EventEditor component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.EventEditor.title',
    defaultMessage: 'Title',
  },
  otherFields: {
    id: 'app.components.EventEditor.otherFields',
    defaultMessage: 'More options',
  },
  location: {
    id: 'app.components.EventEditor.location',
    defaultMessage: 'Location',
  },
  description: {
    id: 'app.components.EventEditor.description',
    defaultMessage: 'Description',
  },
  rsvp: {
    id: 'app.components.EventEditor.rsvp',
    defaultMessage: 'Request group members to indicate their presence (RSVP)',
  },
  shareAsMessage: {
    id: 'app.components.EventEditor.shareAsMessage',
    defaultMessage: 'Share as Post',
  },
  shareAsMessageDays: {
    id: 'app.components.EventEditor.shareAsMessageDays',
    defaultMessage: 'days before start',
  },
  submit: {
    id: 'app.components.EventEditor.submit',
    defaultMessage: 'Save Event',
  },
  successPost: {
    id: 'app.components.EventEditor.successPost',
    defaultMessage: 'The event has been posted.',
  },
  successEdit: {
    id: 'app.components.EventEditor.successEdit',
    defaultMessage: 'The event has been updated.',
  },
  warnClassNextYear: {
    id: 'app.components.EventEditor.warnClassNextYear',
    defaultMessage: 'You are planning an event for next school year, but with the old class. Wait with planning events for next year until the transition has been made.',
  },
});
