import { getCredentials } from 'containers/User/UserProvider/userStorage.js';
import storage from 'utils/storage/index.js';
import { LOCALE_KEY } from '../LanguageProvider/constants.js';

export function* getHeaders() {
  const credentials = yield getCredentials();
  const locale = storage.getItem(LOCALE_KEY);
  return {
    ...credentials,
    'Content-Type': 'application/json', // default, can be overwritten
    'Accept-Language': locale,
  };
}

export function getCacheBuster(options) {
  const IS_IE11 = document.documentMode !== undefined;
  if (options.method !== 'GET' || !IS_IE11) {
    return '';
  }
  const prefix = options.url.includes('?') ? '&' : '?';
  return `${prefix}cachebuster=${Date.now().toString(36)}`;
}
