/**
 *
 * EventEditor
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { omit, pick } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { Alert, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import { useApi2call } from 'containers/Api2/index.js';
import useSelectFromLocation from 'containers/Api2/useSelectFromLocation.js';
import Icon from 'components/media/Icon/index.js';
import Form4 from 'components/interaction/Form4/index.jsx';
import Trigger from 'components/layout/Trigger/index.js';
import { eventParser } from 'utils/events.js';
import { toUTCStrings, TIME_FIELDS } from 'utils/eventTimes.js';
import messages from './messages.js';
import schema from './form.jsx';
import useEventEditor from './useEventEditor.js';

const EVENT_FIELDS = ['communities', 'title', 'description', 'time', 'location', 'rsvp', 'materials', 'shareAsMessage', 'shareAsMessageDays'];

function EventEditor(props) {
  const { event, onHide } = props;
  const current = useSelectFromLocation();
  const api2call = useApi2call();
  useEffect(() => {
    if (event.id && !event.permissions.moderate) {
      onHide();
    }
  }, [event]); // eslint-disable-line react-hooks/exhaustive-deps

  const { values, showOthers, warnNextYear, checkNextYear, toggleOthers } = useEventEditor(event);

  function handleSubmit(data) {
    const result = { ...data, ...toUTCStrings(data.time) };
    delete result.time;
    if (!result.shareAsMessage) {
      delete result.shareAsMessageDays;
    }
    if (event.id) {
      const body = { ...pick(result, [...EVENT_FIELDS, ...TIME_FIELDS]), materials: result.materials.map((mat) => omit(mat, ['backers'])) };
      api2call({ method: 'PUT', url: `events/${event.id}`, body, success: messages.successEdit, parser: eventParser });
    } else {
      api2call({ method: 'POST', url: 'events', body: result, success: messages.successPost, parser: eventParser });
    }
    onHide();
  }

  return (
    <Modal backdrop="static" show onHide={onHide}>
      <Form4
        onSubmit={handleSubmit}
        onCancel={onHide}
        onChange={checkNextYear}
        schema={schema}
        messages={messages}
        defaultValues={values}
      >
        {({ render, action }) => (
          <>
            <ListGroup>
              <ListGroupItem className="position-static">
                {render('communities', { groupLevel: current && current.level })}
                {render('title')}
                {render('time')}
                {warnNextYear && <Alert variant="danger"><FormattedMessage {...messages.warnClassNextYear} /></Alert>}
              </ListGroupItem>
              <ListGroupItem className="position-static">
                <Trigger onClick={toggleOthers}>
                  <h4>
                    <span className="float-end">
                      <Icon className="ss-directionright inline" bsSize="large" rotate={showOthers ? 90 : 0} />
                    </span>
                    <FormattedMessage {...messages.otherFields} />
                  </h4>
                </Trigger>
                {showOthers && (
                  <div className="mt-4">
                    {render('location')}
                    {render('description')}
                    {render('rsvp')}
                    {render('materials')}
                  </div>
                )}
              </ListGroupItem>
            </ListGroup>
            <div id="event-actions" className="form-group p-4">
              {action}
            </div>
          </>
        )}
      </Form4>
    </Modal>
  );
}

EventEditor.propTypes = {
  event: PropTypes.object,
  onHide: PropTypes.func,
};

EventEditor.defaultProps = {
  event: {},
};

export default EventEditor;
