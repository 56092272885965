/**
 *
 * useEventEditor
 *
 */

import { useReducer } from 'react';
import { pick } from 'lodash-es';
import produce from 'immer';
import useCommunities from 'containers/Api2/useCommunities.js';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import useSelectFromLocation from 'containers/Api2/useSelectFromLocation.js';
import { GROUPTYPES_CLASS } from 'containers/User/constants.js';
import { idList, idValues } from 'utils/arrays.js';
import isNextYear from 'utils/isNextYear.js';
import { groupTime, defaultTime } from 'utils/eventTimes.js';

function useEventEditor(event) {
  const role = useCurrentRole();
  const current = useSelectFromLocation();
  const communities = useCommunities();

  function init() {
    const yearEnd = new Date(role?.school.schoolYear.schoolYearEnd);
    const initialEvent = event.id ? {
      ...groupTime(event),
      communities: idList(event.communities),
      materials: idValues(event.materials).map((mat) => ({ ...pick(mat, ['id', 'name', 'backers']), totalCount: mat.backing.needed })),
    } : event;
    const values = {
      communities: current ? [current.id] : [],
      title: '',
      time: { allDay: false, ...defaultTime(2, 'hour') },
      description: '',
      location: '',
      rsvp: false,
      materials: [],
      shareAsMessage: false,
      shareAsMessageDays: 1,
      ...initialEvent,
    };
    return {
      values,
      yearEnd,
      communities,
      hasClasses: false,
      isForNextYear: isNextYear(values.time.start, yearEnd),
      showOthers: event.location || event.description || event.rsvp || (event.materials || []).length > 0,
    };
  }

  const reducer = produce((draft, { key, value }) => {
    switch (key) {
      case 'time':
        draft.isForNextYear = isNextYear(value.start, draft.yearEnd);
        break;
      case 'communities':
        draft.hasClasses = value.some((id) => draft.communities[id].type === GROUPTYPES_CLASS);
        break;
      case 'toggleOthers':
        draft.showOthers = !draft.showOthers;
        break;
      default:
    }
  });

  const [state, dispatch] = useReducer(reducer, null, init);
  const checkNextYear = (key, value) => dispatch({ key, value });
  const toggleOthers = () => dispatch({ key: 'toggleOthers' });
  const { values, showOthers, hasClasses, isForNextYear } = state;
  const warnNextYear = hasClasses && isForNextYear;
  return { values, showOthers, warnNextYear, checkNextYear, toggleOthers };
}

export default useEventEditor;
