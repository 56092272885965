/**
*
* CommunityBadge
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';

function CommunityBadge(props) {
  const { color, label } = props;
  return <AvatarWithText color={color} align="center" group title={label}>{label}</AvatarWithText>;
}

CommunityBadge.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

export default CommunityBadge;
