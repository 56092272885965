/**
*
* Checklist
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Form, ListGroup, ListGroupItem } from 'react-bootstrap';
import messages from './messages.js';
import checklistHandlers from './checklistHandlers.jsx';

const Wrapper = styled(ListGroup)`
  .list-group-item label {
    display: block
  }
`;

function Checklist(props) {
  const {
    items, value, asList, selectAll, onChange, readOnly, name, inline, attribs, renderItem, className, ...rest
  } = props;

  const { handleChange, handleCheckAll } = checklistHandlers(items, value, onChange, readOnly);

  function editableItems() {
    if (!readOnly) {
      return items;
    }
    return items.filter((item) => !readOnly(item));
  }

  function renderCheckbox(item) {
    const checked = value.includes(item.id);
    return (
      <Form.Check
        key={item.id}
        id={item.id}
        checked={checked}
        name={name}
        type="checkbox"
        onChange={handleChange(item)}
        disabled={readOnly && readOnly(item)}
        inline={inline}
        label={renderItem(item, checked)}
        {...attribs}
      />
    );
  }

  const allChecked = selectAll && editableItems().every(((item) => value.includes(item.id)));
  const classList = classNames([className, 'form-control-unstyled form-control-around']);
  return asList ? (
    <Wrapper className={className} {...rest}>
      {selectAll && (
        <ListGroupItem>
          <Form.Check
            id="check-all"
            checked={allChecked}
            type="checkbox"
            onChange={handleCheckAll}
            label={<span className="small text-primary"><FormattedMessage {...messages.select} /></span>}
          />
        </ListGroupItem>
      )}
      {items.map((item) => (
        <ListGroupItem key={item.id}>
          {renderCheckbox(item)}
        </ListGroupItem>
      ))}
    </Wrapper>
  ) : (
    <div className={classList} {...rest}>
      {items.map(renderCheckbox)}
    </div>
  );
}

Checklist.propTypes = {
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  className: PropTypes.string,
  asList: PropTypes.bool,
  inline: PropTypes.bool,
  selectAll: PropTypes.bool,
  onChange: PropTypes.func,
  renderItem: PropTypes.func,
  readOnly: PropTypes.func,
  attribs: PropTypes.object,
};

Checklist.defaultProps = {
  value: [],
  attribs: {},
  renderItem: (item) => item.toString(),
};

export default Checklist;
