
export const svgList = {
  dropdown: `<!-- Generated by IcoMoon.io -->
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="23" height="32" viewBox="0 0 23 32">
      <title>dropdown</title>
      <path fill="#777" d="M3.248 9.576q-0.315-0.558 0-1.091 0.267-0.533 0.921-0.582 0.024-0.024 1.055-0.073t2.873-0.109 3.539-0.061 3.539 0.061 2.873 0.109 1.055 0.073q0.606 0.048 0.921 0.582t0 1.091q-1.285 2.279-3.212 4.727t-2.982 3.564-1.418 1.43q-0.339 0.291-0.776 0.291t-0.776-0.291q-0.364-0.315-1.418-1.43t-2.982-3.564-3.212-4.727z"></path>
    </svg>`,
  search: `<!-- Generated by IcoMoon.io -->
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="23" height="32" viewBox="0 0 23 32">
      <title>search</title>
      <path fill="#aaa" d="M13.576 17.939q2.812 0 4.8-1.988t1.988-4.8-1.988-4.8-4.8-1.988-4.8 1.988-1.988 4.8 1.988 4.8 4.8 1.988zM13.576 1.455q4.024 0 6.861 2.836t2.836 6.861-2.836 6.861-6.861 2.836q-2.133 0-4.024-0.873l-5.842 4.291q-0.776 0.582-1.721 0.436t-1.527-0.921q-0.461-0.63-0.461-1.394t0.461-1.394l4.291-5.818q-0.873-1.891-0.873-4.024 0-4.024 2.836-6.861t6.861-2.836z"></path>
    </svg>`,
};

export function setColor(str, color) {
  if (!color) {
    return str;
  }
  return str.replace(/ fill="[^"]+"/g, ` fill="${color}"`);
}

function colorSvg(svg, color) {
  const svgString = svgList[svg];
  if (!svgString) {
    return '';
  }
  const colored = setColor(svgString, color);
  return `data:image/svg+xml;charset=utf8,${encodeURIComponent(colored)}`;
}

export default colorSvg;
