/**
 * UserProvider
 * Use oidc-context or webview to provide user authentication
 * Load user profiles from API
 */

import React from 'react';
import { AuthProvider } from 'react-oidc-context';
import oidcConfig from 'config/oidc.js';
import storage from 'utils/storage/index.js';
import CurrentProvider from './CurrentProvider.jsx';
import sessionConsole from '../../../utils/sessionConsole.js';
import { TEST_OIDC } from '../constants.js';

function UserProvider(props) {
  function handleSignin(user) {
    // Next time, no need to prompt for login
    storage.setItem('signinPrompt', 'none');
    if (TEST_OIDC) {
      sessionConsole.log('UserProvider.handleSignin', user?.state?.path);
    }
    window.signinRedirectionPath = user?.state?.path || '/';
  }
  return (
    <AuthProvider {...oidcConfig} onSigninCallback={handleSignin}>
      <CurrentProvider {...props} />
    </AuthProvider>
  );
}

export default UserProvider;
