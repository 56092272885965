/**
 *
 * Alerts
 *
 */

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga.jsx';
import { DAEMON } from 'utils/constants.js';

import makeSelectAlerts from './selectors.js';
import { dismissAlert } from './actions.js';
import saga from './saga.js';

const mapStateToProps = createStructuredSelector({
  alerts: makeSelectAlerts(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dismiss: (index) => () => dispatch(dismissAlert(index)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'alerts', saga, mode: DAEMON });

export function withAlerts(Component) {
  return compose(
    withSaga,
    withConnect,
  )(Component);
}
export { default as withAddError } from './withAddError.js';
export { default as withAddAlert } from './withAddAlert.js';
export { default as useAlert } from './useAlert.js';
