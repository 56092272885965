import { array, object, date, string, number, boolean, mixed } from 'yup';
import { useIntl } from 'react-intl';
import { useApi2fetch } from 'containers/Api2/useApi2.js';
import messages from './messages.js';
import { reportLog } from '../../../utils/sentry.js';

const reported = new Set();

export const requiredString = (options) => options.required ? string().required() : string().nullable();

const defaultValidator = (options) => mixed().test(
  'report',
  options.label || messages.this,
  (val) => {
    if (!reported.has(options.id)) {
      reported.add(options.id);
      reportLog('uncovered validator', { options, val }, true);
    }
    if (val) {
      return true;
    }
    return !options.required;
  },
);

function useConvertSchema() {
  const intl = useIntl();
  const api2fetch = useApi2fetch();

  function createRule(options) {
    const { as, type, required, attributes } = options;
    const makeRequired = (f) => (required ? f.required() : f.nullable());
    if (options?.schema) {
      return options.schema;
    }
    if (as?.getSchema) {
      return as.getSchema(options, intl, api2fetch);
    }
    switch (type) {
      case 'email':
        return makeRequired(string().trim()).email();
      case 'url':
        return makeRequired(string().trim()).url();
      case 'number': {
        let result = makeRequired(number());
        if (attributes?.max) {
          result = result.max(attributes.max);
        }
        if (attributes?.min) {
          result = result.min(attributes.min);
        }
        return result;
      }
      case 'date': {
        let result = makeRequired(date());
        if (attributes?.max) {
          result = result.max(new Date(attributes.max));
        }
        if (attributes?.min) {
          result = result.min(new Date(attributes.min));
        }
        return result;
      }
      case 'array':
        return required ? array().min(1) : array();
      case 'tel':
      case 'text':
      case 'textarea':
      case 'time':
        return makeRequired(string().trim());
      case 'checkbox':
        return boolean();
      default:
        return defaultValidator(options);
    }
  }

  function convertSchema(schema) {
    const res = {};
    function getLabel(label = messages.this) {
      if (typeof label !== 'object') {
        return label;
      }
      return label.id ? intl.formatMessage(label) : intl.formatMessage(messages.this);
    }
    schema.properties.filter((o) => !o.noValidate).forEach((o) => {
      res[o.id] = createRule(o).label(getLabel(o.label));
    });
    const result = object().shape(res);
    return result;
  }

  return { convertSchema, createRule };
}

export default useConvertSchema;
