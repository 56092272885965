/*
 * NewPostBadge Messages
 *
 * This contains all the text for the NewPostBadge component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  newPosts: {
    id: 'app.components.NewPostBadge.newPosts',
    defaultMessage: `There {count, plural,
      one {is # new post}
      other {are # new posts}
  }. Click to reload.`,
  },
});
