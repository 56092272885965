/*
 *
 * Dialog reducer
 *
 */

import { fromJS } from 'immutable';
import {
  DIALOG_SHOW,
  DIALOG_HIDE,
} from './constants.js';

export const initialState = fromJS({
  modalType: null,
  modalProps: {},
});

// eslint-disable-next-line default-param-last
function dialogReducer(state = initialState, action) {
  switch (action.type) {
    case DIALOG_SHOW:
      return fromJS({
        modalType: action.modalType,
        modalProps: action.modalProps,
        promise: action.promise,
      });
    case DIALOG_HIDE:
      return initialState;
    default:
      return state;
  }
}

export default dialogReducer;
