/**
 *
 * useSeenItems
 */

import { useContext } from 'react';
import { StatsContext } from './StatsProvider.jsx';

function useSeenItems(key) {
  const { data, seenItems, setSeenItems } = useContext(StatsContext);
  const { lastSeen } = data || {};

  function setSeen(id) {
    const currentSet = seenItems[key];
    if (currentSet?.has(id)) {
      return;
    }
    const newSet = new Set(currentSet || []);
    newSet.add(id);
    setSeenItems({ ...seenItems, [key]: newSet });
  }

  function isNew(id, created) {
    return lastSeen && created > lastSeen && !(seenItems[key]?.has(id));
  }
  return { seenItems: seenItems[key], setSeen, isNew };
}

export default useSeenItems;
