// From: https://redux.js.org/docs/advanced/Middleware.html

const logger = (store) => (next) => (action) => {
  const result = next(action);
  // eslint-disable-next-line no-console
  console.log(action.type.replace(/.*\//, 'action '), { action, state: store.getState().toJS() });
  return result;
};

export default logger;
