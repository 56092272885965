/**
 *
 * UserBanner
 *
 */

import React, { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useCurrentRole from 'containers/User/useCurrentRole.js';
import useSelectFromLocation from 'containers/Api2/useSelectFromLocation.js';
import { useApi2, useApi2update } from 'containers/Api2/index.js';
import { MODULES_STUDENTAVATAR } from 'containers/User/constants.js';
import UploadModal from 'components/user/UploadAvatar/UploadModal.jsx';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import useUpdateAvatar from 'components/user/UploadAvatar/useUpdateAvatar.js';
import sameRole from 'utils/sameRole.js';
import { getIdNumber } from 'utils/userIdRole.js';
import ScreenWrapper from './ScreenWrapper.jsx';
import useUpdateConnections from './useUpdateConnections.js';

function UserBanner() {
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();
  const current = useSelectFromLocation();
  const api2update = useApi2update();
  const role = useCurrentRole();
  const params = useParams();
  const { wid, gid, sid } = params;
  const workspace = useApi2({ selector: `portfolio/workspaces/${wid}` });
  const student = getStudent();
  const updateConnections = useUpdateConnections();
  const studentId = student?.id ?? student?.userId;

  const editable = pathname.startsWith('/students/');
  const canEditAvatar = editable
    && role?.school.modules.includes(MODULES_STUDENTAVATAR)
    && role?.connections.some((conn) => sameRole(conn, student));

  function getStudent() {
    if (wid) {
      return workspace?.student;
    }
    if (gid) {
      return current.students?.[sid];
    }
    if (sid) {
      return role?.connections.find((u) => u.id === Number(sid));
    }
    return undefined;
  }

  const updateAvatar = useUpdateAvatar({
    url: `students/${studentId}/avatar`,
    selector: `students/${student?.id}`,
  });

  const handleChange = useCallback(async (item) => {
    await updateAvatar(item);
    api2update({
      selector: `userroles/${student && getIdNumber(student)}`,
      updater: (state) => state.set('avatar', item?.preview),
    });
    updateConnections(role?.uniqueId, studentId, item?.preview);
  }, [role?.uniqueId, studentId]);

  if (!student) {
    return null;
  }

  const handleEdit = canEditAvatar ? () => setShow(true) : undefined;
  return (
    <ScreenWrapper>
      <AvatarWithText src={student.avatar} bsSize="large" align="center" onEdit={handleEdit}>
        <h1 className="fs-0 fw-bold">{student.displayName}</h1>
      </AvatarWithText>
      <UploadModal show={show} onHide={() => setShow(false)} user={student} onChange={handleChange} />
    </ScreenWrapper>
  );
}

export default UserBanner;
